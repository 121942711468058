import React, { useEffect, useRef } from "react"; 
import { gsap } from "gsap"; 
import { ScrollTrigger } from "gsap/ScrollTrigger"; 
import { motion } from "framer-motion";
gsap.registerPlugin(ScrollTrigger);

const ServiceHome = () => {
  const sectionRef = useRef(null);
  const imageRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      imageRef.current,
      { opacity: 0, x: -100 },
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      }
    );

    gsap.fromTo(
      contentRef.current,
      { opacity: 0, x: 100 },
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      }
    );
  }, []);

  return (
    <section
      ref={sectionRef}
      className="flex flex-col md:flex-row items-center justify-between px-6 md:px-16 py-2  gap-12"
    >
      <div className="md:w-1/2 relative" ref={imageRef}>
      <motion.div 
             initial={{ opacity: 0, y: 50 }}
             animate={{ opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } }}className=" p-6  transform scale-105 hover:scale-110 transition-transform duration-500 ease-in-out">
        <motion.img
                    
                    initial={{ scale: 0.9 }}
                    animate={{ scale: 1, transition: { duration: 0.8, ease: "easeOut" } }}
                    whileHover={{ scale: 1.02, rotate: 3 }}
            src="/assets/key.png"
            alt="Digital Team"
            className="w-full h-auto rounded-lg"
          />
        </motion.div>
        {/* <div className="absolute top-5 left-5 bg-white px-4 py-2 rounded-lg shadow-md text-gray-900 font-bold text-lg">
          Digital <span className="text-gray-500 font-medium">SINCE 2018</span>
        </div> */}
      </div>

      <div className="md:w-1/2 mt-2 md:mt-0 py-2" ref={contentRef}>
        <h2 className="text-2xl md:text-5xl font-bold text-black">
          Key Features & <span className="bg-gradient-to-r from-purple-600 to-blue-600 px-2 rounded-md text-white"> Benefits</span> 
        </h2>
        <p className="text-gray-900 text-lg mt-4">
          We are here to allow teams to center their operations around revenue, driving
          growth, efficiency, and sustainable success.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          {[
            { 
              title: "AI-Powered Targeting", 
              description: "Reach high-intent users with data-driven precision.",
              icon: "🔍" // Example: Search icon
            },
            { 
              title: "Smart Budget Allocation", 
              description: "Optimize spend with automated real-time bidding (RTB).",
              icon: "💰" // Example: Money icon
            },
            { 
              title: "Advanced Analytics", 
              description: "Measure, track, and optimize campaign performance.",
              icon: "📊" // Example: Chart icon
            },
            { 
              title: "Omnichannel Execution", 
              description: "Run campaigns across CTV, mobile, display, and native ads.",
              icon: "🌐" // Example: Globe icon
            }
          ].map((feature, index) => (
            <div
              key={index}
              className="flex items-start space-x-4 bg-gradient-to-r from-purple-500 to-blue-500 p-4 rounded-lg shadow-md hover:shadow-2xl transform hover:scale-105 transition-all duration-300 ease-in-out border border-transparent hover:border-white"
            >
              <span className="text-yellow-500 text-2xl">{feature.icon}</span>
              <div>
                <h3 className="text-white font-semibold text-lg">{feature.title}</h3>
                <p className="text-white text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceHome;


// import React, { useEffect, useRef } from "react";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

// const ServiceHome = () => {
//   const sectionRef = useRef(null);
//   const imageRef = useRef(null);
//   const contentRef = useRef(null);

//   useEffect(() => {
//     gsap.fromTo(
//       imageRef.current,
//       { opacity: 0, x: -100 },
//       {
//         opacity: 1,
//         x: 0,
//         duration: 1.5,
//         ease: "power3.out",
//         scrollTrigger: {
//           trigger: sectionRef.current,
//           start: "top 80%",
//           toggleActions: "play none none none",
//         },
//       }
//     );

//     gsap.fromTo(
//       contentRef.current,
//       { opacity: 0, x: 100 },
//       {
//         opacity: 1,
//         x: 0,
//         duration: 1.5,
//         ease: "power3.out",
//         scrollTrigger: {
//           trigger: sectionRef.current,
//           start: "top 80%",
//           toggleActions: "play none none none",
//         },
//       }
//     );
//   }, []);

//   return (
//     <section
//       ref={sectionRef}
//       className="flex flex-col md:flex-row items-center justify-between px-6 md:px-16 py-12 bg-black gap-12"
//     >
//       <div className="md:w-1/2 relative" ref={imageRef}>
//         <div className="bg-gradient-to-r from-purple-600 to-blue-600 p-6 rounded-lg shadow-lg transform scale-105 hover:scale-110 transition-transform duration-500 ease-in-out">
//           <img
//             src="/image1.png"
//             alt="Digital Team"
//             className="w-full h-auto rounded-lg"
//           />
//         </div>
//         <div className="absolute top-5 left-5 bg-white px-4 py-2 rounded-lg shadow-md text-gray-900 font-bold text-lg">
//           Digital <span className="text-gray-500 font-medium">SINCE 2018</span>
//         </div>
//       </div>

//       <div className="md:w-1/2 mt-10 md:mt-0 py-10" ref={contentRef}>
//         <h2 className="text-4xl md:text-5xl font-bold text-white">
//         Key Features & <span className="bg-gradient-to-r from-purple-600 to-blue-600 px-2 rounded-md"> Benefits</span> 
//         </h2>
//         <p className="text-gray-300 text-lg mt-4">
//           We are here to allow teams to center their operations around revenue, driving
//           growth, efficiency, and sustainable success.
//         </p>

//         {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
//           {["AI-Powered Targeting", "Smart Budget Allocation", "Advanced Analytics", "Multi-Channel Execution"].map((feature, index) => (
//             <div
//               key={index}
//               className="flex items-start space-x-4 bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
//             >
//               <span className="text-yellow-500 text-2xl">📁</span>
//               <div>
//                 <h3 className="text-white font-semibold text-lg">{feature}</h3>
//                 <p className="text-gray-300 text-sm">Enhanced capabilities for digital success.</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div> */}
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
//   {[
//     { title: "AI-Powered Targeting", description: "Reach high-intent users with data-driven precision." },
//     { title: "Smart Budget Allocation", description: "Optimize spend with automated real-time bidding (RTB). " },
//     { title: "Advanced Analytics", description: "Measure, track, and optimize campaign performance." },
//     { title: "Omnichannel Execution ", description: "Run campaigns across CTV, mobile, display, and native ads." }
//   ].map((feature, index) => (
//     <div
//       key={index}
//       className="flex items-start space-x-4 bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
//     >
//       <span className="text-yellow-500 text-2xl">📁</span>
//       <div>
//         <h3 className="text-white font-semibold text-lg">{feature.title}</h3>
//         {/* <p className="text-gray-300 text-sm">Enhanced capabilities for digital success.</p> */}
//         <p className="text-gray-400 text-sm">{feature.description}</p>
//       </div>
//     </div>
//   ))}
// </div>
// </div>
//     </section>
//   );
// };

// export default ServiceHome;
