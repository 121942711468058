import React, { useState } from "react";
import SliderChannelwise from "./SliderChannelwise";
import { ChevronDown } from "lucide-react";
import BannerChannelWise from "./BannerCtv";
import BannerCtv from "./BannerCtv";
import { motion} from "framer-motion";
import { Link } from "react-router-dom";
import ServiceForPush from "./SeviceForPush";
import ServiceForInAppAds from "./ServiceForInAppAds";
import { Helmet } from "react-helmet";

const InappMobileAds  = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);
  const items = [
    {
      title: "Precision-Powered Targeting",
      content:
        "Go beyond generic demographics—reach high-intent audiences with AI-driven insights and privacy-compliant data for maximum impact.",
    },
    {
      title: "Unmatched Streaming Access",
      content:
        "Tap into an expansive inventory of premium content, from top-tier streaming platforms to live sports and exclusive entertainment.",
    },
    {
      title: "Cross-Platform Synergy",
      content:
        "Seamlessly integrate CTV with mobile, web, and social campaigns, ensuring a cohesive brand experience across every screen.",
    },
    {
      title: "AI-Optimized Performance",
      content:
        "Leverage BidBox’s smart algorithms to optimize ad spend, enhance engagement, and ensure better performance with real-time adjustments to maximize your ROI.",
    },
    {
      title: "Engagement Without Disruption",
      content:
        "Deliver high-quality, non-intrusive ads in immersive environments, keeping audiences engaged without interrupting their viewing experience.",
    },
    {
      title: "Real-Time Measurement & Insights",
      content:
        "Track every interaction with advanced analytics  with 25+ KPIs—monitor app installs, conversions, and even in-store visits with real-time precision —quantifying real impact.",
    },
    {
      title: "Smart Frequency Control",
      content:
        "Manage ad exposure across devices and networks to avoid over-saturation and enhance user experience while maintaining brand recall.",
    },
  ];
  const caseStudies = [
    {
      id: 1,
      image:
        "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
      category: "CASE STUDY",
      title: "Old Navy leans into decisioned upfronts with live sports",
      stat1: "1.2M",
      desc1:
        "incremental households reached with live sports by excluding users reached via programmatic guaranteed",
      stat2: "70%",
      desc2:
        "reduction in cost per unique household on live sports versus programmatic guaranteed",
      link: "#",
    },
    {
      id: 2,
      image:
        "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
      category: "CASE STUDY",
      title: "Brand X improves digital advertising efficiency",
      stat1: "850K",
      desc1:
        "users engaged through targeted ads leading to better conversion rates",
      stat2: "60%",
      desc2: "increase in ROI from programmatic advertising strategies",
      link: "#",
    },
  ];

  // Animation variants
  const fadeInVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const staggerItem = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const scaleUp = {
    initial: { scale: 1 },
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };
  return (
    <>
    <Helmet>
        <title>In-App Mobile Ads – Engage Users with Seamless In-App Advertising</title>
        <meta name='description' content='Increase app engagement and drive conversions with BidBox AI’s precision-targeted in-app mobile ads that blend seamlessly with user experiences.' />
        <meta name='keywords' content='In-app advertising, mobile app ads, rewarded video ads, interstitial ads, native in-app ads, mobile marketing, programmatic in-app ads, app monetization, interactive mobile ads, mobile game advertising, AI-driven app targeting, ad-supported mobile apps.' />               
    </Helmet>
      {/* <div
      className="relative flex items-center justify-center h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('https://t4.ftcdn.net/jpg/09/49/36/63/240_F_949366383_tyZF61HUtUectJuzOQGxzUW8vjWXVO1b.jpg')" }}
    >
      <div className="bg-white/20 backdrop-blur-md p-8 rounded-lg shadow-lg text-center max-w-lg">
        <h1 className="text-4xl font-bold text-white mb-4">Welcome to Our Platform</h1>
        <p className="text-white text-lg mb-6">
          Explore amazing features and elevate your experience with our services.
        </p>
        <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-105 transition transform duration-300">
          Get Started
        </button>
      </div>
    </div> */}
      {/* <div class="flex min-h-screen justify-center items-center dark:bg-slate-800 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            type: "spring",
            stiffness: 50,
          }}
          class="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8"
        >
          <div class="text-center ">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              class="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
            >
              <span class="block xl:inline">
                <span class="mb-1 block">Tap Into Engagement </span>
                <motion.span
                  initial={{ backgroundPosition: "0%" }}
                  animate={{ backgroundPosition: "100%" }}
                  transition={{
                    repeat: Infinity,
                    duration: 3,
                    ease: "linear",
                    repeatType: "reverse",
                  }}
                  class="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                >
                  The Power of In-App Advertising
                </motion.span>
              </span>
              <div class="mt-2">
                10X Efficiency
                <span class="relative mt-3 whitespace-nowrap text-blue-600">
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 418 42"
                    class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
                    preserveAspectRatio="none"
                  >
                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                  </svg>
                  <span class="relative">with Bidbox Ai</span>
                </span>
              </div>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              class="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
            >
Reach users in their favorite apps with non-intrusive, high-performing ads. Drive engagement, boost conversions, and stay top-of-mind where it matters most.            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              class="mt-5 sm:mt-8 sm:flex sm:justify-center"
            >
              <motion.div
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                class="rounded-md shadow"
              >
                <a
                  class="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                  href="/contact"
                >
                  Get started
                </a>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div> */}
         <div className="flex min-h-[100dvh] justify-center items-center dark:bg-slate-800 overflow-x-hidden">
                          <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
                            className="mx-auto flex h-full min-h-[100dvh] w-full items-center justify-center px-4 sm:px-6 lg:px-8"
                          >
                            <div className="text-center max-w-4xl">
                              <motion.h1
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.3, duration: 0.5 }}
                                className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
                                style={{
                                  WebkitTransform: "translateY(0px)",
                                  transform: "translateY(0px)",
                                }}
                              >
                                <span className="block xl:inline">
                                  <span className="mb-1 block">Tap Into Engagement</span>
                                  <motion.span
                                    initial={{ backgroundPosition: "0%" }}
                                    animate={{ backgroundPosition: "100%" }}
                                    transition={{
                                      repeat: Infinity,
                                      duration: 3,
                                      ease: "linear",
                                      repeatType: "reverse",
                                    }}
                                    className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                                  >
                                   The Power of In-App Advertising
                                  </motion.span>
                                  <p className="mb-1 block">
                                    10X Efficiency
                                    <span className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                                      with Bidbox.Ai
                                    </span>{" "}
                                  </p>
                                </span>
                              </motion.h1>
                  
                              <motion.p
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
                              >
Reach users in their favorite apps with non-intrusive, high-performing ads. Drive engagement, boost conversions, and stay top-of-mind where it matters most.                              </motion.p>
                  
                              <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.8, duration: 0.5 }}
                                className="mt-5 sm:mt-8 sm:flex sm:justify-center"
                              >
                                <motion.div
                                  variants={scaleUp}
                                  initial="initial"
                                  whileHover="hover"
                                  className="rounded-md shadow"
                                >
                                  <a
                                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                                    href="/contact"
                                  >
                                    Get started
                                  </a>
                                </motion.div>
                              </motion.div>
                            </div>
                          </motion.div>
                        </div>
      <div className="bg-white py-12 px-4 md:px-6">
        <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
          {/* Image on Left */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0  opacity-20 rounded-lg"></div>
            <motion.img
               variants={{
                initial: { scale: 1, rotateY: 0 },
                hover: { scale: 1.05, rotateY: 10 },
                float: {
                  y: [0, -10, 0], // Floating effect
                  transition: {
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut"
                  }
                }
              }}
              initial="initial"
              whileHover="hover"
              animate="float"
              src="/assets/inapp.png"
              alt="Connected TV"
              className="w-full h-auto md:h-full object-cover "
            />
          </motion.div>

          {/* Text on Right */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left "
          >
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
            In-App Advertising: Engaging Users Where It Matters
            </h2>
            <p className="text-gray-700 text-lg md:text-lg leading-relaxed mb-6">
            In-app advertising enables brands to display targeted, high-impact ads within mobile applications, seamlessly integrating with the user's experience. This method ensures better engagement than traditional web ads, as users are more immersed in apps. AI-powered targeting allows advertisers to reach specific audiences based on behavior, interests, and location. Real-time bidding (RTB) ensures cost-effective placements while maximizing ad impressions and conversions. With mobile usage continuing to rise, in-app advertising remains a dominant channel for digital marketers.            </p>
            {/* <Link to="/contact">
              <motion.button
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-110 transition duration-300"
              >
                Learn More
              </motion.button>
            </Link> */}
          </motion.div>
        </div>

        {/* SECOND SECTION - Alternating Layout */}
        <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-12 mt-16">
          {/* Image on Right */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0 opacity-20 rounded-lg"></div>
            <motion.img
              variants={{
                initial: { scale: 1, rotateY: 0 },
                hover: { scale: 1.05, rotateY: 10 },
                float: {
                  y: [0, -10, 0], // Floating effect
                  transition: {
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut"
                  }
                }
              }}
              initial="initial"
              whileHover="hover"
              animate="float"
              src="/assets/inapp1.png"
              alt="CTV Advertising"
              // className="w-full h-auto md:h-96 object-cover rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"

              className="w-full h-auto md:h-full object-cover "
            />
          </motion.div>

          {/* Text on Left */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
            Why In-App Advertising? Engage Where It Matters
            </h2>
            <p className="text-gray-700 text-lg md:text-lg leading-relaxed mb-6">
            Mobile users spend nearly 90% of their screen time inside applications, making in-app advertising the most effective way to capture and hold attention. These ads offer a seamless, engaging experience that aligns with user behavior, leading to higher interaction rates. With advanced targeting options such as contextual and behavioral targeting, brands can deliver personalized ad experiences. AI-driven insights allow for real-time campaign optimization, ensuring the best performance at lower acquisition costs. In-app advertising helps brands not just reach audiences but truly connect with them in meaningful ways.            </p>
            {/* <Link to="/contact">
              <motion.button
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg hover:scale-110 transition duration-300"
              >
                Learn More
              </motion.button>
            </Link> */}
          </motion.div>
        </div>
      </div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="max-w-4xl mx-auto text-center py-10"
      >
        <h2 className="text-4xl md:text-5xl font-bold mb-6">
        Unlock High-Impact Mobile Advertising with BidBox
        </h2>
        <p className="text-lg text-gray-600 mb-12">
        BidBox elevates in-app advertising by combining precision targeting, immersive ad formats, and AI-powered optimizations. Our platform ensures your ads reach premium mobile apps, where user engagement is at its highest. Advanced analytics provide real-time tracking and insights, allowing brands to measure impact and fine-tune their campaigns effortlessly. With fraud prevention mechanisms and brand safety tools, advertisers can ensure their ads appear in a trusted, high-quality environment. Leverage BidBox’s technology to achieve seamless in-app brand engagement and maximize ROI.        </p>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="px-4 md:px-10"
      >
        <div className=" mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-6 md:px-8 rounded-lg shadow-lg mt-6 mb-6">
          <div className="container mx-auto flex flex-col md:flex-row items-center text-white text-center md:text-left space-y-8 md:space-y-0 md:space-x-8">
            {/* Column 1 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">
              2X Higher Click-Through Rate (CTR) Than Websites
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
              In-app ads deliver twice the CTR compared to web ads, ensuring higher engagement and more efficient user acquisition.              </p>
            </motion.div>
            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>{" "}
            {/* Mobile Divider */}
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>{" "}
            {/* Desktop Divider */}
            {/* Column 2 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1 "
            >
              <h2 className="text-2xl md:text-2xl font-bold">Precision Targeting with 1st-Party Data</h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
              Leverage behavioral, contextual, and location-based targeting to reach high-intent mobile users with unmatched accuracy.
              </p>
            </motion.div>
            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>{" "}
            {/* Mobile Divider */}
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>{" "}
            {/* Desktop Divider */}
            {/* Column 3 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1 "
            >
              <h2 className="text-2xl md:text-2xl font-bold">
              70%+ Ad Viewability in Premium In-App Environments
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
              In-app ads are strategically placed within high-quality, brand-safe mobile apps, ensuring maximum visibility, seamless user experience, and higher interaction rates.
              </p>
            </motion.div>
          </div>
        </div>
      </motion.div>
      {/* <div className='px-10'>
    <div className=" mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-8 rounded-lg shadow-lg mt-6 mb-6">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between text-white text-center">
       
        <div className="flex-1 px-10">
          <h2 className="text-3xl font-bold">95%+ Video Completion</h2>
          <p className="text-lg opacity-90 mt-1">BidBox delivers unskippable, high-impact CTV ads that keep audiences engaged till the very end.
          </p>
        </div>

       
        <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>

        
        <div className="flex-1 px-10">
          <h2 className="text-3xl font-bold">35% Lower CPM</h2>
          <p className="text-lg opacity-90 mt-1">BidBox optimizes ad spend with AI-driven bidding, reducing costs while maximizing reach.</p>
        </div>

        <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>

        <div className="flex-1 px-10">
          <h2 className="text-3xl font-bold">80% engagement</h2>
          <p className="text-lg opacity-90 mt-1">Advanced targeting ensures your ads reach the right viewers, boosting interaction and brand recall.</p>
        </div>

      </div>
    </div>
    </div> */}
    
      {/* <div className="w-full max-w-7xl mx-auto py-12 px-4">
        
        <h2 className="text-4xl md:text-5xl font-bold text-center mb-10">
          Unskippable. Unmissable. Unmatched
        </h2>
\
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
         
          <div className="w-full md:w-[50%] flex justify-center">
            <img
              src="https://www.intandemcommunications.co.uk/wp-content/uploads/2019/08/What-is-marketing.jpg" // Replace with actual image
              alt="Marketing"
              className="w-full max-w-2xl h-auto rounded-lg shadow-lg"
            />
          </div>

          
          <div className="w-full md:w-[50%] space-y-4">
            {items.map((item, index) => (
              <div
                key={index}
                className="border-b border-gray-300 cursor-pointer"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
              
                <div className="flex justify-between items-center py-4">
                  <h2 className="text-lg font-semibold">{item.title}</h2>
                  <ChevronDown
                    className={`w-5 h-5 transition-transform ${
                      openIndex === index ? "rotate-180" : ""
                    }`}
                  />
                </div>

               
                {openIndex === index && (
                  <p className="text-gray-600 pb-4">{item.content}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div> */}
      {/* Expandable Panels on Hover */}
      {/* <div className="w-full max-w-7xl mx-auto py-12 px-4">
        
        <motion.h2
          initial={{ opacity: 0, y: -30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="text-4xl md:text-5xl font-bold text-center mb-10"
        >
          Unskippable. Unmissable. Unmatched
        </motion.h2>

      
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
        
          <div className="w-full md:w-[50%] flex justify-center">
            <img
              src="https://www.intandemcommunications.co.uk/wp-content/uploads/2019/08/What-is-marketing.jpg"
              alt="Marketing"
              className="w-full max-w-2xl h-auto rounded-lg shadow-lg"
            />
          </div>

          <div className="w-full md:w-[50%] space-y-4">
            {items.map((item, index) => (
              <div
                key={index}
                className="border-b border-gray-300 cursor-pointer"
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
              >
              
                <div className="flex justify-between items-center py-4">
                  <h2 className="text-lg font-semibold">{item.title}</h2>
                  <ChevronDown
                    className={`w-5 h-5 transition-transform ${
                      hoverIndex === index ? "rotate-180" : ""
                    }`}
                  />
                </div>

                
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={
                    hoverIndex === index
                      ? { height: "auto", opacity: 1 }
                      : { height: 0, opacity: 0 }
                  }
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="overflow-hidden"
                >
                  <p className="text-gray-600 pb-4">{item.content}</p>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      <ServiceForInAppAds/>
<motion.div
    initial="hidden"
    whileInView="visible"
    variants={fadeInVariant}
    viewport={{ once: true, amount: 0.5 }}
    className="w-full flex justify-center mt-10">
   <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
             className="w-[95%] mb-20 h-[320px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
        <div className="p-5 md:p-10 z-10 flex justify-between">
          <div className="flex flex-col justify-between">
            <div>
            <motion.h1
                              initial={{ opacity: 0, y: -20 }}
                              whileInView={{ opacity: 1, y: 0 }}
                              transition={{ duration: 0.5 }}
                              viewport={{ once: true }} className="text-white font-bold text-xl md:text-5xl">          
              Ready to Dominate the Mobile Experience?
             </motion.h1>
                             <motion.h2
                               initial={{ opacity: 0 }}
                               whileInView={{ opacity: 1 }}
                               transition={{ duration: 0.5, delay: 0.2 }}
                               viewport={{ once: true }} className="text-gray-300  text-xl md:text-xl py-2">
              Engage users where they spend the most time—inside their favorite apps. Deliver high-impact, immersive ads that drive action, boost conversions, and maximize brand visibility. Start your in-app mobile campaign today!
              </motion.h2>
                           </div>
                           <motion.div
                             initial={{ opacity: 0, y: 20 }}
                             whileInView={{ opacity: 1, y: 0 }}
                             transition={{ duration: 0.5, delay: 0.4 }}
                             viewport={{ once: true }}>
              <a href="/contact">
                <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                Get Started Now
                </button>
              </a>
          </motion.div>
                    </div>
                  </div>
                  </motion.div>
                </motion.div>
      {/* Case Studies */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.4 }}
        className="max-w-7xl mx-auto py-12 px-4"
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-3xl md:text-4xl font-bold text-center mb-10"
        >
          Success Stories
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {caseStudies.map((study, index) => (
            <motion.div
              key={study.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
              whileHover={{
                scale: 1.03,
                boxShadow:
                  "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
              }}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300"
            >
              {/* Top: Image with overlay effect */}
              <div className="w-full relative overflow-hidden">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                  src={study.image}
                  alt="Case Study"
                  className="w-full h-48 md:h-64 object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
                <div className="absolute bottom-0 left-0 p-4">
                  <span className="text-xs font-bold text-white bg-blue-600 px-2 py-1 rounded">
                    {study.category}
                  </span>
                </div>
              </div>

              {/* Bottom: Text Content */}
              <div className="p-6 text-center">
                <h3 className="text-xl font-bold mt-2">{study.title}</h3>
                <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-8 mt-4">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">
                      {study.stat1}
                    </p>
                    <p className="text-gray-600 text-sm">{study.desc1}</p>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">
                      {study.stat2}
                    </p>
                    <p className="text-gray-600 text-sm">{study.desc2}</p>
                  </motion.div>
                </div>
                <motion.a
                  whileHover={{ scale: 1.1 }}
                  href={study.link}
                  className="text-blue-600 font-semibold mt-6 inline-block border-b-2 border-transparent hover:border-blue-600"
                >
                  READ CASE STUDY
                </motion.a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export default InappMobileAds ;

// import React, { useState } from 'react'
// import SliderChannelwise from './SliderChannelwise'
// import { ChevronDown } from 'lucide-react';
// import BannerChannelWise from './BannerCtv';

// const  InappMobileAds = () => {
//     const [openIndex, setOpenIndex] = useState(0);
//     const items = [
//         {
//           title: "Hyper-Targeted Reach",
//           content:
//             "Connect with highly engaged users through AI-driven, data-backed targeting that delivers ads to the right audience at the perfect moment.",
//         },
//         { title: "Immersive Ad Experiences", content: "Leverage interactive, engaging ad formats—video, playable, rewarded, and native ads—to enhance user experience and drive higher conversions." },
//         { title: "Seamless In-App Integration", content: "Ads blend naturally within mobile apps, ensuring a non-intrusive experience that captures user attention without disrupting their journey." },
//         { title: "Real-Time Performance Tracking", content: "Access instant analytics with deep insights into engagement, installs, and user actions—allowing you to optimize campaigns on the go." },
//         { title: "Cross-Device Consistency", content: "Unify messaging across mobile, tablet, and desktop to create a seamless brand experience for users across multiple touchpoints." },
//         { title: "Smart Budget Allocation", content: "Optimize ad spend with AI-powered bidding, ensuring you reach high-value users while maximizing ROI and minimizing wasted impressions." },
//         { title: "High Viewability & Brand Safety", content: "Ads appear within premium mobile apps with verified inventory, ensuring maximum visibility in a fraud-free, brand-safe environment." },

//     ];
//       const caseStudies = [
//         {
//           id: 1,
//           image: "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
//           category: "CASE STUDY",
//           title: "Old Navy leans into decisioned upfronts with live sports",
//           stat1: "1.2M",
//           desc1: "incremental households reached with live sports by excluding users reached via programmatic guaranteed",
//           stat2: "70%",
//           desc2: "reduction in cost per unique household on live sports versus programmatic guaranteed",
//           link: "#",
//         },
//         {
//           id: 2,
//           image: "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
//           category: "CASE STUDY",
//           title: "Brand X improves digital advertising efficiency",
//           stat1: "850K",
//           desc1: "users engaged through targeted ads leading to better conversion rates",
//           stat2: "60%",
//           desc2: "increase in ROI from programmatic advertising strategies",
//           link: "#",
//         },
//       ];
//   return (
//     <>
//        {/* <div
//       className="relative flex items-center justify-center h-screen bg-cover bg-center"
//       style={{ backgroundImage: "url('https://t4.ftcdn.net/jpg/09/49/36/63/240_F_949366383_tyZF61HUtUectJuzOQGxzUW8vjWXVO1b.jpg')" }}
//     >
//       <div className="bg-white/20 backdrop-blur-md p-8 rounded-lg shadow-lg text-center max-w-lg">
//         <h1 className="text-4xl font-bold text-white mb-4">Welcome to Our Platform</h1>
//         <p className="text-white text-lg mb-6">
//           Explore amazing features and elevate your experience with our services.
//         </p>
//         <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-105 transition transform duration-300">
//           Get Started
//         </button>
//       </div>
//     </div> */}
//     <div class="flex h-screen justify-center items-center dark:bg-slate-800">
//     <div class="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8">
//         <div class="text-center ">
//             <h1
//                 class="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl">
//                 <span class="block xl:inline"><span class="mb-1 block">Tap Into Engagement: </span>
//                 <span class="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
//                 The Power of In-App Advertising
//                 </span>
//                 </span>
//                 <div class="mt-2">10X Efficiency
//                     <span class="relative mt-3 whitespace-nowrap text-blue-600"><svg aria-hidden="true" viewBox="0 0 418 42"
//                         class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
//                         preserveAspectRatio="none">
//                         <path
//                             d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z">
//                         </path>
//                     </svg>
//                     <span class="relative">with BidBox Ai</span>
//                     </span>
//                 </div>
//             </h1>
//             <p class="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5">
//             Reach users in their favorite apps with non-intrusive, high-performing ads. Drive engagement, boost conversions, and stay top-of-mind where it matters most. </p>
//             <div class="mt-5 sm:mt-8 sm:flex sm:justify-center">
//                 <div class="rounded-md shadow"><a
//                         class="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
//                         href="#">Get started for free 🚀
//                     </a>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// <div className="bg-white py-16 px-6">

// {/* <div className="bg-gradient-to-r from-gray-100 to-gray-300 py-16 px-6"> */}
//       <div className="container mx-auto flex flex-col md:flex-row items-center gap-8 md:gap-16">
        
//         {/* Image on Left */}
//         <div className="relative w-full md:w-5/12">
//           <div className="absolute inset-0 bg-purple-600 opacity-20 rounded-lg"></div>
//           <img
//             src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
//             alt="About"
//             className="w-full h-auto rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"
//           />
//         </div>

//         {/* Text on Right */}
//         <div className="w-full md:w-7/12">
//           <h2 className="text-5xl font-bold text-gray-900 mb-4">
//           In-App Advertising: Engaging Users Where It Matters</h2>
//           <p className="text-gray-700 text-xl leading-relaxed mb-6">
//           In-app advertising delivers targeted, high-impact ads within mobile applications, ensuring seamless user engagement. With precise targeting and real-time bidding, brands can reach audiences in their favorite apps, maximizing visibility and conversions.
//           </p>
//           {/* <p className="text-gray-700 text-lg leading-relaxed mb-6">The poet also sees a child climbing up a steep ground collecting blackberries, a homeless man standing and watching as the train passes by, village women making garland out of daisies, a cart moving laboriously on the highway with the weight of the load and a man on it. He also sees a mill and rivers. They all pass by very quickly, disappearing as fast as they appeared.          </p> */}
//           <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-110 transition duration-300">
//             Learn More
//           </button>
//         </div>

//       </div>

//       {/* SECOND SECTION - Alternating Layout */}
//       <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-8 md:gap-16 mt-16">
        
//         {/* Image on Right */}
//         <div className="relative w-full md:w-5/12">
//           <div className="absolute inset-0 bg-blue-600 opacity-20 rounded-lg"></div>
//           <img
//             src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
//             alt="About"
//             className="w-full h-auto rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"
//           />
//         </div>

//         {/* Text on Left */}
//         <div className="w-full md:w-7/12">
//           <h2 className="text-5xl font-bold text-gray-900 mb-4"> Why In-App Advertising? Engage Where It Matters</h2>
//           <p className="text-gray-700 text-xl leading-relaxed mb-6">
//           Mobile users spend 90% of their time in apps—making in-app advertising the most effective way to capture attention. With precise targeting, immersive formats, and real-time optimization, deliver high-impact ads that drive engagement and conversions effortlessly.
//    </p>
//           {/* <p className="text-gray-700 text-lg leading-relaxed mb-6"> The poet also sees a child climbing up a steep ground collecting blackberries, a homeless man standing and watching as the train passes by, village women making garland out of daisies, a cart moving laboriously on the highway with the weight of the load and a man on it. He also sees a mill and rivers. They all pass by very quickly, disappearing as fast as they appeared.          </p> */}
//           <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg hover:scale-110 transition duration-300">
//             Learn More
//           </button>
//         </div>

//       </div>
//     </div>


//     <div 
//     className="max-w-4xl mx-auto text-center"
    
//   >
//     <h2 className="text-4xl md:text-5xl font-bold mb-6"> Unlock High-Impact Mobile Advertising with BidBox
//     </h2>
//     <p className="text-xl text-gray-600 mb-12">
//     BidBox supercharges your in-app advertising with precision targeting, immersive formats, and AI-driven optimization. Reach high-intent users inside premium mobile apps, ensuring seamless brand engagement, real-time performance tracking, and maximized ROI.

//     </p>
//   </div>


//   <div className='px-4 md:px-10'>
//     <div className=" mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-6 md:px-8 rounded-lg shadow-lg mt-6 mb-6">
//       <div className="container mx-auto flex flex-col md:flex-row items-center text-white text-center md:text-left space-y-8 md:space-y-0 md:space-x-8">
        
//         {/* Column 1 */}
//         <div className="flex-1">
//           <h2 className="text-2xl md:text-2xl font-bold">30% Lower (CPC)  </h2>
//           <p className="text-base md:text-sm opacity-90 mt-2">AI-powered bidding optimizes ad spend, delivering more conversions at lower costs.

//           </p>
//         </div>

//         {/* Divider */}
//       <div className="w-full h-px bg-white opacity-50 md:hidden"></div> {/* Mobile Divider */}
//       <div className="hidden md:block w-px h-16 bg-white opacity-50"></div> {/* Desktop Divider */}


//         {/* Column 2 */}
//         <div className="flex-1 ">
//           <h2 className="text-2xl md:text-2xl font-bold">Data Driven Precision Targeting </h2>
//           <p className="text-base md:text-sm opacity-90 mt-2">Leverage behavioral, contextual, and location-based targeting to reach high-intent mobile users.</p>
//         </div>

//      {/* Divider */}
//      <div className="w-full h-px bg-white opacity-50 md:hidden"></div> {/* Mobile Divider */}
//       <div className="hidden md:block w-px h-16 bg-white opacity-50"></div> {/* Desktop Divider */}


//         {/* Column 3 */}
//         <div className="flex-1 ">
//           <h2 className="text-2xl md:text-2xl font-bold">40% Higher Engagement Rates</h2>
//           <p className="text-base md:text-sm opacity-90 mt-2">Interactive and native ad formats drive seamless user interaction within mobile apps.
//           </p>
//         </div>

//       </div>
//     </div>
//     </div>
//     {/* <div className='px-10'>
//     <div className=" mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-8 rounded-lg shadow-lg mt-6 mb-6">
//       <div className="container mx-auto flex flex-col md:flex-row items-center justify-between text-white text-center">
        
     
//         <div className="flex-1 px-10">
//           <h2 className="text-3xl font-bold">30% Lower (CPC)</h2>
//           <p className="text-lg opacity-90 mt-1">AI-powered bidding optimizes ad spend, delivering more conversions at lower costs.



//           </p>
//         </div>

//         <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>

//         <div className="flex-1 px-10">
//           <h2 className="text-3xl font-bold">Precision Targeting with 1st-Party Data</h2>
//           <p className="text-lg opacity-90 mt-1">Leverage behavioral, contextual, and location-based targeting to reach high-intent mobile users.
//           </p>
//         </div>

  
//         <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>

      
//         <div className="flex-1 px-10">
//           <h2 className="text-3xl font-bold">40% Higher Engagement Rates
//           </h2>
//           <p className="text-lg opacity-90 mt-1">Interactive and native ad formats drive seamless user interaction within mobile apps.

//           </p>
//         </div>

//       </div>
//     </div>
//     </div> */}
//     <SliderChannelwise/>
//     <div className="w-full max-w-7xl mx-auto py-12 px-4">
//       {/* Title */}
//       <h2 className="text-4xl md:text-5xl font-bold text-center mb-10">Stay in Their Hands—Advertise Inside Apps.</h2>

//       {/* Container */}
//       <div className="flex flex-col md:flex-row items-center justify-between gap-12">
//         {/* Left Side: Image */}
//         <div className="w-full md:w-[50%] flex justify-center">
//           <img
//             src="https://www.intandemcommunications.co.uk/wp-content/uploads/2019/08/What-is-marketing.jpg" // Replace with actual image
//             alt="Marketing"
//             className="w-full max-w-2xl h-auto rounded-lg shadow-lg"
//           />
//         </div>

//         {/* Right Side: Accordion */}
//         <div className="w-full md:w-[50%] space-y-4">
//           {items.map((item, index) => (
//             <div
//               key={index}
//               className="border-b border-gray-300 cursor-pointer"
//               onClick={() => setOpenIndex(openIndex === index ? null : index)}
//             >
//               {/* Title */}
//               <div className="flex justify-between items-center py-4">
//                 <h2 className="text-lg font-semibold">{item.title}</h2>
//                 <ChevronDown
//                   className={`w-5 h-5 transition-transform ${
//                     openIndex === index ? "rotate-180" : ""
//                   }`}
//                 />
//               </div>

//               {/* Content */}
//               {openIndex === index && (
//                 <p className="text-gray-600 pb-4">{item.content}</p>
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>

    // <div className="w-full flex justify-center mt-10">
    //   <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
    //     <div className="p-5 md:p-10 z-10 flex justify-between">
    //       <div className="flex flex-col justify-between">
    //         <div>
    //           <h1 className="text-white font-bold text-xl md:text-5xl">          
    //           Ready to Dominate the Mobile Experience?
    //           </h1>
    //           <h2 className="text-gray-300  text-xl md:text-xl py-2">
    //           Engage users where they spend the most time—inside their favorite apps. Deliver high-impact, immersive ads that drive action, boost conversions, and maximize brand visibility. Start your in-app mobile campaign today!
    //           </h2>
    //         </div>
    //         <div>
    //           <a href="/contact">
    //             <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
    //             Get Started Now
    //             </button>
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
//     <div className="max-w-7xl mx-auto py-12 px-4">
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//         {caseStudies.map((study) => (
//           <div
//             key={study.id}
//             className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl"
//           >
//             {/* Top: Image */}
//             <div className="w-full">
//               <img
//                 src={study.image}
//                 alt="Case Study"
//                 className="w-full h-auto object-cover"
//               />
//             </div>

//             {/* Bottom: Text Content */}
//             <div className="p-6 text-center">
//               <p className="text-sm text-blue-600 font-semibold">{study.category}</p>
//               <h3 className="text-xl font-bold mt-2">{study.title}</h3>
//               <div className="flex justify-center gap-8 mt-4">
//                 <div>
//                   <p className="text-2xl font-bold">{study.stat1}</p>
//                   <p className="text-gray-600">{study.desc1}</p>
//                 </div>
//                 <div>
//                   <p className="text-2xl font-bold">{study.stat2}</p>
//                   <p className="text-gray-600">{study.desc2}</p>
//                 </div>
//               </div>
//               <a href={study.link} className="text-blue-600 font-semibold mt-4 inline-block">
//                 READ CASE STUDY
//               </a>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//     </>
//   )
// }

// export default InappMobileAds
