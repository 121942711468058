import React, { useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { 
  ChevronLeft, 
  ChevronRight,
  ArrowRight,
  Globe,
  BarChart3,
  Users,
  Target,
  TrendingUp,
  Award,
  MonitorPlay,
  ShoppingCart,
  PieChart,
  Film
} from 'lucide-react';
import { Link } from 'react-router-dom';

const ServiceVideoStory = () => {
  const { scrollY } = useScroll();
  const [activeTab, setActiveTab] = useState(0);

  const services = [
    {
      icon: <Film className="w-8 h-8" />,
      title: "Captivating Brand Storytelling",
      description: "Engage audiences with compelling video narratives that evoke emotion, build trust, and drive brand loyalty.",
    //   stat: "Learn More",
      color: "from-blue-600 to-cyan-500"
      
    },
    {
      icon: <TrendingUp className="w-8 h-8" />,
     
      title: "Intelligent Video Optimization",
      description: "Leverage programmatic technology to dynamically serve the right ad at the perfect moment for peak performance and efficiency.",
      //   stat: "Learn More",
      color: "from-purple-600 to-pink-500"
   
    },
    {
      icon: <Target className="w-8 h-8" />,
      title: "AI-Driven Personalization",
      description: "Harness cutting-edge AI to deliver precision-targeted video ads that align seamlessly with user behavior and interests.",
    //   stat: "Learn More",
      color: "from-orange-500 to-red-500"
     
    },
    {
        icon: <ShoppingCart className="w-8 h-8" />,
        title: " Interactive & Shoppable Video Experiences",
        description: "Transform engagement into action with commerce-enabled video ads designed to drive seamless conversions.",
        // stat: "Learn More",
        color: "from-orange-500 to-red-500"
       
      },
      {
        icon: <MonitorPlay className="w-8 h-8" />,
        title: " Premium OTT, CTV & Mobile Video Integration",
        description: "Maximize reach with strategic placements across high-quality streaming platforms, ensuring superior visibility and impact.",
        // stat: "Learn More",
        color: "from-purple-600 to-pink-500"

      },
      {
        icon: <PieChart className="w-8 h-8" />,
        title: "High-Impact Pre-Roll & Mid-Roll Insertions",
        description: "Command attention with premium ad placements at pivotal moments, enhancing user engagement and brand recall.",
        // stat: "Learn More",
        color: "from-blue-600 to-cyan-500"
      
      },
      {
        icon: <BarChart3 className="w-8 h-8" />,
        title: "Real-Time Performance Analytics",
        description: "Gain deep insights into video completion rates, engagement metrics, and brand lift with advanced measurement tools.",
        // stat: "Learn More",
        color: "from-blue-600 to-cyan-500"
     
      },
      {
        icon: <Users className="w-8 h-8" />,
        title: "Seamless Cross-Device Video Experience",
        description: "Deliver a unified brand message across CTV, mobile, and desktop, ensuring a cohesive user journey.",
        // stat: "Learn More",
        color: "from-purple-600 to-pink-500"

      },
      {
        icon: <Film className="w-8 h-8" />,
        title: "Tailored Short-Form & Long-Form Content",
        description: "Adapt video formats to audience preferences—whether delivering impactful short clips or immersive storytelling experiences.",
        // stat: "Learn More",
        color: "from-orange-500 to-red-500"
      
      },
      
      
      
  ];

  const features = [
    { title: "Dynamic Optimization", value: "93%", subtitle: "Campaign Success Rate" },
    { title: "Global Coverage", value: "200+", subtitle: "Countries Reached" },
    { title: "Client Satisfaction", value: "98%", subtitle: "Retention Rate" },
    { title: "ROI Improvement", value: "180%", subtitle: "Average Increase" }
  ];

  const statsY = useTransform(scrollY, [0, 300], [100, 0]);
  const statsOpacity = useTransform(scrollY, [0, 300], [0, 1]);

  return (
    <div className="w-full">
      {/* Services Section */}
      <section className="py-20 px-6 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
            Bring Your Brand Story to Life

            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Leverage AI-powered video storytelling to deliver high-impact brand narratives across premium digital platforms. Maximize engagement, enhance recall, and connect with the right audience through precision-targeted, immersive video experiences.            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {services.map((service, index) => (
                <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }} // Hover effect
                className="relative group"
              >
                <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:bg-gray-100">
                  <div className={`absolute inset-x-0 h-2 top-0 rounded-t-2xl bg-gradient-to-r ${service.color}`} />
                  <div className="mb-6 text-blue-600">{service.icon}</div>
                  <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <div className="flex items-center justify-between">
                  <Link to={service.link} className="text-lg font-semibold text-blue-600 hover:underline">
    {service.stat}
  </Link>
                  </div>
                </div>
              </motion.div>
              
            //   <motion.div
            //     key={index}
            //     initial={{ opacity: 0, y: 20 }}
            //     whileInView={{ opacity: 1, y: 0 }}
            //     viewport={{ once: true }}
            //     transition={{ duration: 0.6, delay: index * 0.2 }}
            //     className="relative group"
            //   >
            //     <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300">
            //       <div className={`absolute inset-x-0 h-2 top-0 rounded-t-2xl bg-gradient-to-r ${service.color}`} />
            //       <div className="mb-6 text-blue-600">{service.icon}</div>
            //       <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
            //       <p className="text-gray-600 mb-6">{service.description}</p>
            //       <div className="flex items-center justify-between">
            //         <span className="text-lg font-semibold">{service.stat}</span>
            //         <ArrowRight className="w-5 h-5 text-gray-400 group-hover:text-blue-600 transition-colors" />
            //       </div>
            //     </div>
            //   </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      {/* <motion.section 
        style={{ y: statsY, opacity: statsOpacity }}
        className="py-20 px-6 bg-gradient-to-r from-blue-600 to-purple-600"
      >
        <div className="max-w-7xl mx-auto">
          <div className="grid md:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4, delay: index * 0.1 }}
                className="text-center text-white"
              >
                <div className="text-5xl font-bold mb-2">{feature.value}</div>
                <div className="text-xl font-semibold mb-1">{feature.title}</div>
                <div className="text-blue-200">{feature.subtitle}</div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section> */}
    </div>
  );
};

export default ServiceVideoStory;