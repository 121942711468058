import React from "react";

const BannerHome = () => {
  return (
    <div className="w-full flex justify-center mt-10">
      <div className="w-[95%] mb-20 h-[260px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
        <div className="p-5 md:p-10 z-10 flex justify-between">
          <div className="flex flex-col justify-between">
            <div>
              <h1 className="text-white font-bold text-xl md:text-5xl">
              Ready to Elevate Your Advertising?
              </h1>
              <h2 className="text-white  text-xl md:text-2xl py-2">
              Harness the power of AI-driven programmatic advertising and transform your digital campaigns with Bidbox.ai.
              </h2>
            </div>
            <div>
              <a href="/contact">
                <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                Get Started Now
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerHome;
