import React, { useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { GiCheckMark, GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { MdCampaign, MdOutlineCategory } from "react-icons/md";
import { TbTargetArrow } from "react-icons/tb";
import { BiNetworkChart, BiRadioCircle } from "react-icons/bi";
import { FiMonitor } from "react-icons/fi";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { TbDeviceTvOld } from "react-icons/tb";
import { BiVideo } from "react-icons/bi";
import { MdOutlinePushPin } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { RiAdvertisementLine } from "react-icons/ri";
import { FiTarget } from "react-icons/fi";
import { TbTarget } from "react-icons/tb";
import {  BiUser, BiGroup, BiGlobe, BiHome, BiTargetLock } from "react-icons/bi";
import { BiBuilding, BiBasket, BiCameraMovie, BiCar,  BiChip, BiBookContent } from "react-icons/bi";
import { FiVideo, FiRefreshCw } from "react-icons/fi";
import { TbDeviceTv, TbDevices } from "react-icons/tb";
import {  FiBell } from "react-icons/fi";
import { FaGraduationCap, FaEllipsisH } from "react-icons/fa";

const NavBar = () => {
  const [showSolution, setShowSolution] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const [showBlogs, setShowBlogs] = useState(false);
  const [isNav, setIsNav] = useState(false);
  const [campaignWiseNav, setCampaignWiseNav] = useState(false);
  const [campaignWiseMobileNav, setCampaignWisemobileNav] = useState(false);
  const [displayNavbar, setDisplayNavbar] = useState(false);
  const [channelMobileNav, setChannelMobileNav] = useState(false);
  const [caseStudyMobileNav, setCaseStudyMobileNav] = useState(false);
  const [targetingMobileNav, SetTargetingMobileNav] = useState(false);
  const [showBlogsNav, setShowBlogsNav] = useState(false);
  const [activeSection, setActiveSection] = useState(null);

  const [backgroundColor, setBackgroundColor] = useState("white");
  // const navigation = navigator.platform.indexOf('Mac')

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 30) {
      setBackgroundColor("white"); // Change background color to gradient when scrolled
    } else {
      setBackgroundColor("white"); // Reset background color when at the top
    }
  };
  const solutionsData = {
    'Campaign Wise': {
      icon: <MdCampaign className="w-5 h-5" />,
      items: [
        { name: 'Brand Awareness', icon: <TbTarget className="w-4 h-4" />, path: '/brand-awareness' },
        { name: 'Video StorytellingAds', icon: <FiVideo className="w-4 h-4" />, path: '/video-storytelling-ads' },
        { name: ' Reengagement Retargeting', icon: <FiRefreshCw className="w-4 h-4" />, path: '/reengagement-retargeting' },
        { name: 'Premium Takeovers Ads', icon: <IoPhonePortraitOutline className="w-4 h-4" />, path: '/premium-takeovers' },
      ]
    },
    'Channel Wise': {
      icon: <MdOutlineCategory className="w-5 h-5" />,
      items: [
        { name: 'CTV & OTT', icon: <TbDeviceTv className="w-4 h-4" />, path: '/ctv&ott' },
        { name: 'Display & Native Ads', icon: <TbDevices className="w-4 h-4" />, path: '/display-native-ads' },
        { name: ' Push Notification Ads', icon: <FiBell className="w-4 h-4" />, path: '/push-ads' },
        { name: 'In-App Mobile Ads', icon: <IoPhonePortraitOutline className="w-4 h-4" />, path: '/inapp-mobile-Ads' },
        // { name: 'Display', icon: <FiMonitor className="w-4 h-4" />, path: '/display' },
        // { name: 'In-App', icon: <IoPhonePortraitOutline className="w-4 h-4" />, path: '/in-app' },
        // { name: 'CTV', icon: <TbDeviceTvOld className="w-4 h-4" />, path: '/ctv' },
        // { name: 'Video', icon: <BiVideo className="w-4 h-4" />, path: '/video' },
        // { name: 'Push-Ads', icon: <MdOutlinePushPin className="w-4 h-4" />, path: '/push-ads' },
        // { name: 'Native-Ads', icon: <CgNotes className="w-4 h-4" />, path: '/native-ads' },
        // { name: 'Interstitial-Ads', icon: <RiAdvertisementLine className="w-4 h-4" />, path: '/interstitial-ads' },
        // { name: 'Targeting Options', icon: <BiNetworkChart className="w-4 h-4" />, path: '/targeting-options' }
      ]
    },
    'Targeting Option': {
      icon: <BiNetworkChart className="w-5 h-5" />,
      items: [
        { name: "Geo & Hyperlocal Targeting", icon: <BiGlobe className="w-4 h-4" />, path: "/geo-hyperlocal-targeting" },
  { name: "Demographics Targeting", icon: <BiUser className="w-4 h-4" />, path: "/demographics-targeting" },
  { name: "Interest & Behavior Targeting", icon: <BiGroup className="w-4 h-4" />, path: "/interest-behavior-targeting" },
  { name: "Household Sync Targeting", icon: <BiHome className="w-4 h-4" />, path: "/householdsync-targeting" },
  { name: "Lookalike & Custom Audiences", icon: <BiTargetLock className="w-4 h-4" />, path: "/lookalike-custom-audiences" },

      ]
    }
  };
  // Add event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    background: backgroundColor,
    transition: "background-color 0.3s ease", // Adjust the duration as needed
  };

  return (
    <>
      {isNav && (
        <>
          <div className="fixed top-0 flex justify-center z-50 w-full  bg-gradient-to-r from-purple-600 to-blue-600">
            <div className="w-[95%] mt-20 flex justify-between">
              <ul className="text-white font-medium flex py-5 overflow-scroll flex-col gap-5">
                <li
                  onClick={() => setShowSolution((prev) => !prev)}
                  className="relative cursor-pointer flex items-center gap-1"
                >
                  SOLUTIONS{" "}
                  {showSolution ? <AiFillCaretUp /> : <AiFillCaretDown />}
                </li>
                {showSolution && (
                  <>
                    {" "}
                    <li>
                      <p
                        onClick={() =>
                          setCampaignWisemobileNav((prev) => !prev)
                        }
                        className={`whitespace-nowrap ${
                          campaignWiseMobileNav && "font-medium"
                        } text-white py-2 px-5 flex items-center gap-2`}
                      >
                        {campaignWiseMobileNav ? (
                          <RxCross2 className="text-xl" />
                        ) : (
                          <HiPlus className="text-xl" />
                        )}{" "}
                        Campaign Wise
                      </p>

                      {campaignWiseMobileNav && (
                        <>
                          <a href="/brand-awareness">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Brand Awareness
                            </p>
                          </a>
                          <a href="/video-storytelling-ads">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Video StorytellingAds
                            </p>
                          </a>
                          <a href="/reengagement-retargeting">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Reengagement Retargeting
                            </p>
                          </a>
                          <a href="/premium-takeovers">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Premium Takeovers Ads
                            </p>
                          </a>
                        </>
                      )}

                      <p
                        onClick={() => setChannelMobileNav((prev) => !prev)}
                        className={`whitespace-nowrap ${
                          channelMobileNav && "font-medium"
                        } text-white py-4 px-5 flex items-center gap-2`}
                      >
                        {channelMobileNav ? (
                          <RxCross2 className="text-xl" />
                        ) : (
                          <HiPlus className="text-xl" />
                        )}{" "}
                        Channel Wise
                      </p>

                      {channelMobileNav && (
                        <>
                          <a href="/ctv&ott">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              CTV & OTT
                            </p>
                          </a>
                          <a href="/display-native-ads">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Display & Native Ads
                            </p>
                          </a>
                          <a href="/push-ads">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Push Notification Ads
                            </p>
                          </a>
                          <a href="/inapp-mobile-Ads">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              In-App Mobile Ads
                            </p>
                          </a>
                          {/* <a href="/push-ads">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Push-Ads
                            </p>
                          </a>
                          <a href="/native-ads">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Native-Ads
                            </p>
                          </a>
                          <a href="/interstitial-ads">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Interstitial-Ads
                            </p>
                          </a> */}
                        </>
                      )}
                      <p
                        onClick={() => SetTargetingMobileNav((prev) => !prev)}
                        className={`whitespace-nowrap ${
                          targetingMobileNav && "font-medium"
                        } text-white py-2 px-5 flex items-center gap-2`}
                      >
                        {targetingMobileNav ? (
                          <RxCross2 className="text-xl" />
                        ) : (
                          <HiPlus className="text-xl" />
                        )}{" "}
                        Targeting Option 
                      </p>

                      {targetingMobileNav && (
                        <>
                          <a href="/geo-hyperlocal-targeting">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              GeoHyperlocalTargeting
                            </p>
                          </a>
                          <a href="/demographics-targeting">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              DemographicsTargeting
                            </p>
                          </a>
                          <a href="/interest-behavior-targeting">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              InterestBehaviorTargeting
                            </p>
                          </a>
                          <a href="/householdsync-targeting">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              HouseholdSyncTargeting
                            </p>
                          </a>
                          <a href="/lookalike-custom-audiences">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              LookalikeCustomAudience
                            </p>
                          </a>
                          {/* <a href="/push-ads">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Push-Ads
                            </p>
                          </a>
                          <a href="/native-ads">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Native-Ads
                            </p>
                          </a>
                          <a href="/interstitial-ads">
                            <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
                              <GiCheckMark />
                              Interstitial-Ads
                            </p>
                          </a> */}
                        </>
                      )}
                      {/* <a href="/targeting-options">
                        <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                          <GiCheckMark />
                          Targeting Options
                        </p>
                      </a> */}
                    </li>
                  </>
                )}

                <li
                  onClick={() => setCaseStudyMobileNav((prev) => !prev)}
                  className="relative cursor-pointer flex items-center gap-1"
                >
                  <a className="flex items-center gap-1">
                    CASE STUDIES{" "}
                    {caseStudyMobileNav ? (
                      <AiFillCaretUp />
                    ) : (
                      <AiFillCaretDown />
                    )}
                  </a>
                </li>
                {caseStudyMobileNav && (
                  <li>
                    <div>
                      <a href="/bfsi">
                        <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                          <GiCheckMark />
                          BFSI
                        </p>
                      </a>
                      <a href="/fmcg">
                        <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                          <GiCheckMark />
                          FMCG
                        </p>
                      </a>
                      <a href="/entertainment">
                        <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                          <GiCheckMark />
                          Entertainment
                        </p>
                      </a>
                      <a href="/automobile">
                        <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                          <GiCheckMark />
                          Automobile
                        </p>
                      </a>
                      <a href="/fashion-lifestyle">
                        <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                          <GiCheckMark />
                          Fashion & Lifestyle
                        </p>
                      </a>
                      <a href="/electronics">
                        <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                          <GiCheckMark />
                          Electronics
                        </p>
                      </a>
                      <a href="/education">
                        <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                          <GiCheckMark />
                          Education
                        </p>
                      </a>
                      <a href="/other-case-study">
                        <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                          <FaEllipsisH />

                          Others
                        </p>
                      </a>
                    </div>
                  </li>
                )}
                <li
                  onClick={() => setShowBlogsNav((prev) => !prev)}
                  className="relative cursor-pointer flex items-center gap-1"
                >
                  <a className="flex items-center gap-1">
                    RESOURCES{" "}
                    {showBlogsNav ? <AiFillCaretUp /> : <AiFillCaretDown />}
                  </a>
                </li>
                {showBlogsNav && (
                  <li>
                    <div>
                      <a href="/blog">
                        <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                          <GiCheckMark />
                          Blogs
                        </p>
                      </a>
                    
                    
                    </div>
                  </li>
                )}

                <a href="/about">
                  <li className="relative cursor-pointer">ABOUT US</li>
                </a>
                <a href="/contact">
                  <li className="relative cursor-pointer">CONTACT US</li>
                </a>
              </ul>
            </div>
          </div>
        </>
      )}

      <div
        style={navbarStyle}
        className="w-full fixed top-0 z-50 flex flex-col items-center"
      >
        <div className="w-[95%] caption py-3 flex justify-between">
          <a href="/">
            <div className="text-white w-18 h-10 z-20">
              <img
                className={"min-w-[50%] min-h-[100%] max-h-[100%] object-fill"}
                src="/logo1.jpg"
                alt="logo"
              />
            </div>
          </a>
          {/* <a href='/'>
                    <div className='hidden md:block text-white w-18 h-10 z-20'>
                <img className='w-[100%] h-[100%] object-fill' src='/logo1.jpg' alt='B Logo' />
            </div>
            <div className='block md:hidden text-white w-18 h-10 z-20'>
                <img className='w-[100%] h-[100%] object-fill' src='/b.jpeg' alt='Logo' />
            </div>
</a> */}
          <ul
            className={`hidden text-white font-medium md:flex gap-12 md:items-center mr-4`}
          >
           {/* <li
              onMouseOver={() => setShowSolution(true)}
              onMouseLeave={() => setShowSolution(false)}
              className="relative cursor-pointer flex items-center gap-1 text-black"
            >
              SOLUTIONS {showSolution ? <AiFillCaretUp /> : <AiFillCaretDown />}
              {showSolution && (
                <div className="absolute z-50 bottom-0 translate-y-[100%] bg-white rounded-lg w-[800px] shadow-lg py-6 border left-0">
                  <div className="grid grid-cols-3 gap-4 p-4">
                 
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg mb-4">Campaign Wise</h3>
                      <a href="/awareness" className="block hover:bg-gray-50 p-2 rounded">
                        <span className="text-gray-700">Awareness</span>
                      </a>
                      <a href="/re-targeting" className="block hover:bg-gray-50 p-2 rounded">
                        <span className="text-gray-700">Re-Targeting</span>
                      </a>
                    </div>

                  
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg mb-4">Channel Wise</h3>
                      <a href="/display" className="block hover:bg-gray-50 p-2 rounded">
                        <span className="text-gray-700">Display</span>
                      </a>
                      <a href="/in-app" className="block hover:bg-gray-50 p-2 rounded">
                        <span className="text-gray-700">In-App</span>
                      </a>
                      <a href="/ctv" className="block hover:bg-gray-50 p-2 rounded">
                        <span className="text-gray-700">CTV</span>
                      </a>
                      <a href="/video" className="block hover:bg-gray-50 p-2 rounded">
                        <span className="text-gray-700">Video</span>
                      </a>
                    </div>
                    <div className="space-y-2">
                      <h3 className="font-semibold text-lg mb-4">More Channels</h3>
                      <a href="/push-ads" className="block hover:bg-gray-50 p-2 rounded">
                        <span className="text-gray-700">Push-Ads</span>
                      </a>
                      <a href="/native-ads" className="block hover:bg-gray-50 p-2 rounded">
                        <span className="text-gray-700">Native-Ads</span>
                      </a>
                      <a href="/interstitial-ads" className="block hover:bg-gray-50 p-2 rounded">
                        <span className="text-gray-700">Interstitial-Ads</span>
                      </a>
                      <a href="/targeting-options" className="block hover:bg-gray-50 p-2 rounded">
                        <span className="text-gray-700">Targeting Options</span>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </li> */}
{/* <li
              onMouseEnter={() => setShowSolution(true)}
              onMouseLeave={() => setShowSolution(false)}
              className="relative cursor-pointer flex items-center gap-1 text-black"
            >
              SOLUTIONS {showSolution ? <AiFillCaretUp /> : <AiFillCaretDown />}
              {showSolution && (
                <div className="absolute z-50 bottom-0 translate-y-[100%] bg-white rounded-lg w-[800px] shadow-lg py-6 border left-0 right-5
                ">
                  <div className="grid grid-cols-4 gap-6 p-6">
                   
                    <div className="col-span-1 border-r pr-4">
                      {Object.keys(solutionsData).map((section) => (
                        <div
                          key={section}
                          onMouseEnter={() => setActiveSection(section)}
                          className={`px-4 py-2 cursor-pointer rounded-lg transition-all ${
                            activeSection === section
                              ? "bg-blue-50 text-blue-600"
                              : "hover:bg-gray-50"
                          }`}
                        >
                          {solutionsData[section].icon}
                          {section}
                        </div>
                      ))}
                    </div>

                  
                    <div className="col-span-3 pl-4">
                      {activeSection && (
                        <div className="grid grid-cols-2 gap-4">
                          {solutionsData[activeSection].items.map((item, index) => (
                            <a
                              key={item}
                              href={item.path}
                              className="p-3 rounded-lg hover:bg-gray-50 transition-all"
                            >
 <div className="font-medium text-gray-900 flex items-center gap-2">
                                {item.icon}
                                {item.name}
                              </div>                              <p className="text-sm text-gray-500 mt-1 ml-6">
                                Explore our {item.name.toLowerCase()} solutions
                              </p>
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </li> */}
            <li
              onMouseEnter={() => setShowSolution(true)}
              onMouseLeave={() => setShowSolution(false)}
              className="relative cursor-pointer flex items-center gap-1 text-black"
            >
              SOLUTIONS {showSolution ? <AiFillCaretUp /> : <AiFillCaretDown />}
              {showSolution && (
                <div className="absolute z-50 bottom-0 translate-y-[100%] bg-white rounded-lg w-[750px] shadow-lg py-6 border left-0">
                  <div className="grid grid-cols-4 gap-6 p-6">
                    <div className="col-span-1 border-r pr-4">
                      {Object.keys(solutionsData).map((section) => (
                        <div
                          key={section}
                          onMouseEnter={() => setActiveSection(section)}
                          className={`px-4 py-2 cursor-pointer rounded-lg transition-all flex items-center gap-2 ${
                            activeSection === section
                              // ? "bg-blue-50 text-blue-600"
                              // : "hover:bg-gray-50"
                              ? "bg-[#00308F] text-white shadow-md"
                  : "hover:bg-gray-100 hover:text-[#00308F]"
                          }`}
                        >
                          {solutionsData[section].icon}
                          {section}
                        </div>
                      ))}
                    </div>

                    <div className="col-span-3 pl-4">
                      {activeSection && (
                        <div className="grid grid-cols-2 gap-4">
                          {solutionsData[activeSection].items.map((item) => (
                            <a
                              key={item.name}
                              href={item.path}
                              className="p-3 rounded-lg hover:bg-gray-50  hover:shadow-md   transition-all"
                            >
                              <div className="font-medium textColor flex items-center  gap-2">
                                {item.icon}
                                {item.name}
                              </div>
                              <p className="text-sm text-gray-500 mt-1 ml-6">
                                Explore our {item.name.toLowerCase()} solutions
                              </p>
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </li>
            <li
              onMouseOver={() => setShowResources(true)}
              onMouseLeave={() => setShowResources(false)}
              className="relative cursor-pointer flex items-center gap-1 text-black"
            >
              CASE STUDIES{" "}
              {showResources ? <AiFillCaretUp /> : <AiFillCaretDown />}
              {showResources && (
                <>
                  <div
                    onMouseOver={() => setShowResources(true)}
                    className="absolute z-50 bottom-0 translate-y-[100%] bg-white rounded-lg w-[250px] shadow-lg py-3 border"
                  >
                    <a href="/bfsi">
                      <p className="whitespace-nowrap font-medium text-black py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                      <BiBuilding />
                        BFSI
                      </p>
                    </a>
                    <a href="/fmcg">
                      <p className="whitespace-nowrap font-medium  text-black py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                      <BiBuilding />
                        FMCG
                      </p>
                    </a>
                    <a href="/entertainment">
                      <p className="whitespace-nowrap font-medium  text-black py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                      <BiCameraMovie />
                        Entertainment
                      </p>
                    </a>
                    <a href="/automobile">
                      <p className="whitespace-nowrap font-medium  text-black py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                      <BiCar />
                        Automobile
                      </p>
                    </a>
                    <a href="/fashion-lifestyle">
                      <p className="whitespace-nowrap font-medium  text-black py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                      <BiUser />

                      Fashion & Lifestyle
                      </p>
                    </a>
                    <a href="/electronics">
                      <p className="whitespace-nowrap font-medium  text-black py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                      <BiChip />
                        Electronics
                      </p>
                    </a>
                     <a href="/education">
                      <p className="whitespace-nowrap font-medium  text-black py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                      <FaGraduationCap />
                        Education
                      </p>
                    </a>
                    <a href="/other-case-study">
                      <p className="whitespace-nowrap font-medium  text-black py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                      <FaEllipsisH />
                      Others
                      </p>
                    </a>
                  </div>
                </>
              )}
            </li>
            <li
              onMouseOver={() => setShowBlogs(true)}
              onMouseLeave={() => setShowBlogs(false)}
              className="relative cursor-pointer flex items-center gap-1 text-black"
            >
              RESOURCES {showBlogs ? <AiFillCaretUp /> : <AiFillCaretDown />}
              {showBlogs && (
                <>
                  <div
                    onMouseOver={() => setShowBlogs(true)}
                    className="absolute z-50 bottom-0 translate-y-[100%] bg-white rounded-lg w-[250px] shadow-lg py-3 border"
                  >
                    <a href="/blog">
                      <p className="whitespace-nowrap font-medium  text-black py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
                      <BiBookContent/>
                        Blogs
                      </p>
                    </a>
                  </div>
                </>
              )}
            </li>
            <a href="/about">
              <li className="relative cursor-pointer text-black">ABOUT US</li>
            </a>

            <a href="/contact">
              <li className="relative cursor-pointer text-black">CONTACT US</li>
            </a>
          </ul>

          <div className="flex items-center md:hidden">
            {isNav ? (
              <RxCross2
                className="text-black text-2xl cursor-pointer"
                onClick={() => setIsNav((prev) => !prev)}
              />
            ) : (
              <GiHamburgerMenu
                onClick={() => setIsNav((prev) => !prev)}
                className="text-black text-2xl cursor-pointer"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;





// import React, { useEffect, useState } from "react";
// import { HiPlus } from "react-icons/hi";
// import { Link } from "react-router-dom";
// import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
// import { GiCheckMark, GiHamburgerMenu } from "react-icons/gi";
// import { RxCross2 } from "react-icons/rx";
// import { MdCampaign } from "react-icons/md";
// import { TbTargetArrow } from "react-icons/tb";
// import { BiNetworkChart, BiRadioCircle } from "react-icons/bi";

// const NavBar = () => {
//   const [showSolution, setShowSolution] = useState(false);
//   const [showResources, setShowResources] = useState(false);
//   const [showBlogs, setShowBlogs] = useState(false);
//   const [isNav, setIsNav] = useState(false);
//   const [campaignWiseNav, setCampaignWiseNav] = useState(false);
//   const [campaignWiseMobileNav, setCampaignWisemobileNav] = useState(false);
//   const [displayNavbar, setDisplayNavbar] = useState(false);
//   const [channelMobileNav, setChannelMobileNav] = useState(false);
//   const [caseStudyMobileNav, setCaseStudyMobileNav] = useState(false);
//   const [showBlogsNav, setShowBlogsNav] = useState(false);

//   const [backgroundColor, setBackgroundColor] = useState("white");
//   // const navigation = navigator.platform.indexOf('Mac')

//   // Function to handle scroll event
//   const handleScroll = () => {
//     if (window.scrollY > 30) {
//       setBackgroundColor("white"); // Change background color to gradient when scrolled
//     } else {
//       setBackgroundColor("white"); // Reset background color when at the top
//     }
//   };

//   // Add event listener when the component mounts
//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);

//     // Remove event listener when the component unmounts
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
//   const navbarStyle = {
//     background: backgroundColor,
//     transition: "background-color 0.3s ease", // Adjust the duration as needed
//   };

//   return (
//     <>
//       {isNav && (
//         <>
//           <div className="fixed top-0 flex justify-center z-50 w-full  bg-gradient-to-r from-purple-600 to-blue-600">
//             <div className="w-[95%] mt-20 flex justify-between">
//               <ul className="text-white font-medium flex py-5 overflow-scroll flex-col gap-5">
//                 <li
//                   onClick={() => setShowSolution((prev) => !prev)}
//                   className="relative cursor-pointer flex items-center gap-1"
//                 >
//                   SOLUTIONS{" "}
//                   {showSolution ? <AiFillCaretUp /> : <AiFillCaretDown />}
//                 </li>
//                 {showSolution && (
//                   <>
//                     {" "}
//                     <li>
//                       <p
//                         onClick={() =>
//                           setCampaignWisemobileNav((prev) => !prev)
//                         }
//                         className={`whitespace-nowrap ${
//                           campaignWiseMobileNav && "font-medium"
//                         } text-white py-2 px-5 flex items-center gap-2`}
//                       >
//                         {campaignWiseMobileNav ? (
//                           <RxCross2 className="text-xl" />
//                         ) : (
//                           <HiPlus className="text-xl" />
//                         )}{" "}
//                         Campaign Wise
//                       </p>

//                       {campaignWiseMobileNav && (
//                         <>
//                           <a href="/awareness">
//                             <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
//                               <GiCheckMark />
//                               Awareness
//                             </p>
//                           </a>
//                           <a href="/re-targeting">
//                             <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
//                               <GiCheckMark />
//                               Re-Targeting
//                             </p>
//                           </a>
//                         </>
//                       )}

//                       <p
//                         onClick={() => setChannelMobileNav((prev) => !prev)}
//                         className={`whitespace-nowrap ${
//                           channelMobileNav && "font-medium"
//                         } text-white py-4 px-5 flex items-center gap-2`}
//                       >
//                         {channelMobileNav ? (
//                           <RxCross2 className="text-xl" />
//                         ) : (
//                           <HiPlus className="text-xl" />
//                         )}{" "}
//                         Channel Wise
//                       </p>

//                       {channelMobileNav && (
//                         <>
//                           <a href="/display">
//                             <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
//                               <GiCheckMark />
//                               Display
//                             </p>
//                           </a>
//                           <a href="/in-app">
//                             <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
//                               <GiCheckMark />
//                               In-App
//                             </p>
//                           </a>
//                           <a href="/ctv">
//                             <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
//                               <GiCheckMark />
//                               CTV
//                             </p>
//                           </a>
//                           <a href="/video">
//                             <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
//                               <GiCheckMark />
//                               Video
//                             </p>
//                           </a>
//                           <a href="/push-ads">
//                             <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
//                               <GiCheckMark />
//                               Push-Ads
//                             </p>
//                           </a>
//                           <a href="/native-ads">
//                             <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
//                               <GiCheckMark />
//                               Native-Ads
//                             </p>
//                           </a>
//                           <a href="/interstitial-ads">
//                             <p className="whitespace-nowrap text-white py-2 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3 ml-7">
//                               <GiCheckMark />
//                               Interstitial-Ads
//                             </p>
//                           </a>
//                         </>
//                       )}
//                       <a href="/targeting-options">
//                         <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                           <GiCheckMark />
//                           Targeting Options
//                         </p>
//                       </a>
//                     </li>
//                   </>
//                 )}

//                 <li
//                   onClick={() => setCaseStudyMobileNav((prev) => !prev)}
//                   className="relative cursor-pointer flex items-center gap-1"
//                 >
//                   <a className="flex items-center gap-1">
//                     CASE STUDIES{" "}
//                     {caseStudyMobileNav ? (
//                       <AiFillCaretUp />
//                     ) : (
//                       <AiFillCaretDown />
//                     )}
//                   </a>
//                 </li>
//                 {caseStudyMobileNav && (
//                   <li>
//                     <div>
//                       <a href="/bfsi">
//                         <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                           <GiCheckMark />
//                           BFSI
//                         </p>
//                       </a>
//                       <a href="/fmcg">
//                         <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                           <GiCheckMark />
//                           FMCG
//                         </p>
//                       </a>
//                       <a href="/entertainment">
//                         <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                           <GiCheckMark />
//                           Entertainment
//                         </p>
//                       </a>
//                       <a href="/automobile">
//                         <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                           <GiCheckMark />
//                           Automobile
//                         </p>
//                       </a>
//                       <a href="/gaming">
//                         <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                           <GiCheckMark />
//                           Gaming
//                         </p>
//                       </a>
//                       <a href="/aviation">
//                         <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                           <GiCheckMark />
//                           Aviation
//                         </p>
//                       </a>
//                     </div>
//                   </li>
//                 )}
//                 <li
//                   onClick={() => setShowBlogsNav((prev) => !prev)}
//                   className="relative cursor-pointer flex items-center gap-1"
//                 >
//                   <a className="flex items-center gap-1">
//                     RESOURCES{" "}
//                     {showBlogsNav ? <AiFillCaretUp /> : <AiFillCaretDown />}
//                   </a>
//                 </li>
//                 {showBlogsNav && (
//                   <li>
//                     <div>
//                       <a href="/blog">
//                         <p className="whitespace-nowrap text-white py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                           <GiCheckMark />
//                           Blogs
//                         </p>
//                       </a>
                    
                    
//                     </div>
//                   </li>
//                 )}

//                 <a href="/about">
//                   <li className="relative cursor-pointer">ABOUT US</li>
//                 </a>
//                 <a href="/contact">
//                   <li className="relative cursor-pointer">CONTACT US</li>
//                 </a>
//               </ul>
//             </div>
//           </div>
//         </>
//       )}

//       <div
//         style={navbarStyle}
//         className="w-full fixed top-0 z-50 flex flex-col items-center"
//       >
//         <div className="w-[95%] caption py-3 flex justify-between">
//           <a href="/">
//             <div className="text-white w-18 h-10 z-20">
//               <img
//                 className={"min-w-[50%] min-h-[100%] max-h-[100%] object-fill"}
//                 src="/logo1.jpg"
//                 alt="logo"
//               />
//             </div>
//           </a>
//           {/* <a href='/'>
//                     <div className='hidden md:block text-white w-18 h-10 z-20'>
//                 <img className='w-[100%] h-[100%] object-fill' src='/logo1.jpg' alt='B Logo' />
//             </div>
//             <div className='block md:hidden text-white w-18 h-10 z-20'>
//                 <img className='w-[100%] h-[100%] object-fill' src='/b.jpeg' alt='Logo' />
//             </div>
// </a> */}
//           <ul
//             className={`hidden text-white font-medium md:flex gap-10 md:items-center`}
//           >
//             <li
//               onMouseOver={() => setShowSolution(true)}
//               onMouseLeave={() => setShowSolution(false)}
//               className="relative cursor-pointer flex items-center gap-1 text-black"
//             >
//               SOLUTIONS {showSolution ? <AiFillCaretUp /> : <AiFillCaretDown />}
//               {showSolution && (
//                 <>
//                   <div
//                     onMouseOver={() => setShowSolution(true)}
//                     className="absolute z-50 bottom-0 translate-y-[100%] bg-white rounded-lg w-[250px] pb-5 shadow-lg py-3 border"
//                   >
//                     <div
//                       className={`flex  ${
//                         campaignWiseNav ? "mb-0" : "mb-8"
//                       } mt-3 items-center justify-center gap-2`}
//                     >
//                       <div className="rounded-lg  flex items-center justify-center w-10 h-10">
//                         {/* <MdCampaign className='textColor text-4xl' /> */}
//                         <img
//                           className="w-[100%] h-[100%] object-cover"
//                           src="campaignNav.png"
//                           alt="navIcon"
//                         />
//                       </div>
//                       <div>
//                         <p
//                           onMouseEnter={() => setCampaignWiseNav(true)}
//                           onClick={() => setCampaignWiseNav(false)}
//                           className={`whitespace-nowrap ${
//                             campaignWiseNav && "font-semibold"
//                           } textColor pb-1 px-2 flex items-center gap-1`}
//                         >
//                           Campaign Wise{" "}
//                           {campaignWiseNav ? (
//                             <AiFillCaretUp className="text-xl" />
//                           ) : (
//                             <AiFillCaretDown className="text-xl" />
//                           )}
//                         </p>
//                         {/* <p className='text-gray-500 text-sm font-normal px-2'>Lorem ipsum dolor sit</p> */}
//                       </div>
//                     </div>

//                     {campaignWiseNav && (
//                       <>
//                         <a
//                           href="/awareness"
//                           onMouseEnter={() => setCampaignWiseNav(true)}
//                         >
//                           <p className="whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-white hover:shadow-md flex items-center gap-3 ml-20 pl-3">
//                             <BiRadioCircle />
//                             Awareness
//                           </p>
//                         </a>
//                         <a
//                           href="/re-targeting"
//                           onMouseEnter={() => setCampaignWiseNav(true)}
//                         >
//                           <p className="whitespace-nowrap textColor py-2 mb-4 hover:bg-[#00308F] hover:text-white hover:shadow-md flex items-center gap-3 ml-20 pl-3">
//                             <BiRadioCircle />
//                             Re-Targeting
//                           </p>
//                         </a>
//                       </>
//                     )}

//                     <div
//                       className={`flex ${
//                         displayNavbar ? "mb-0" : "mb-8"
//                       } items-center justify-center gap-2`}
//                     >
//                       <div className="rounded-lg  flex items-center justify-center w-10 h-10">
//                         {/* <BiNetworkChart className='textColor text-4xl' /> */}
//                         <img
//                           className="w-[100%] h-[100%] object-cover"
//                           src="networking.png"
//                           alt="navIcon"
//                         />
//                       </div>
//                       <div>
//                         <p
//                           onMouseEnter={() => setDisplayNavbar(true)}
//                           onClick={() => setDisplayNavbar(false)}
//                           className={`whitespace-nowrap ${
//                             displayNavbar && "font-semibold"
//                           } textColor pb-1 px-2 flex items-center gap-2`}
//                         >
//                           Channel Wise{" "}
//                           {displayNavbar ? (
//                             <AiFillCaretUp className="text-xl" />
//                           ) : (
//                             <AiFillCaretDown className="text-xl" />
//                           )}
//                         </p>
//                         {/* <p className='text-gray-500 text-sm font-normal px-2'>Lorem ipsum dolor sit</p> */}
//                       </div>
//                     </div>

//                     {displayNavbar && (
//                       <>
//                         <a
//                           href="/display"
//                           onMouseEnter={() => setDisplayNavbar(true)}
//                           onMouseLeave={() => setDisplayNavbar(false)}
//                         >
//                           <p className="whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-white hover:shadow-md flex items-center gap-3 ml-20 pl-3">
//                             <BiRadioCircle />
//                             Display
//                           </p>
//                         </a>
//                         <a
//                           href="/in-app"
//                           onMouseEnter={() => setDisplayNavbar(true)}
//                           onMouseLeave={() => setDisplayNavbar(false)}
//                         >
//                           <p className="whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-white hover:shadow-md flex items-center gap-3 ml-20 pl-3">
//                             <BiRadioCircle />
//                             In-App
//                           </p>
//                         </a>
//                         <a
//                           href="/ctv"
//                           onMouseEnter={() => setDisplayNavbar(true)}
//                           onMouseLeave={() => setDisplayNavbar(false)}
//                         >
//                           <p className="whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-white hover:shadow-md flex items-center gap-3 ml-20 pl-3">
//                             <BiRadioCircle />
//                             CTV
//                           </p>
//                         </a>
//                         <a
//                           href="/video"
//                           onMouseEnter={() => setDisplayNavbar(true)}
//                         >
//                           <p className="whitespace-nowrap textColor py-2  hover:bg-[#00308F] hover:text-white hover:shadow-md flex items-center gap-3 ml-20 pl-3">
//                             <BiRadioCircle />
//                             Video
//                           </p>
//                         </a>
//                         <a
//                           href="/push-ads"
//                           onMouseEnter={() => setDisplayNavbar(true)}
//                         >
//                           <p className="whitespace-nowrap textColor py-2  hover:bg-[#00308F] hover:text-white hover:shadow-md flex items-center gap-3 ml-20 pl-3">
//                             <BiRadioCircle />
//                             Push-Ads
//                           </p>
//                         </a>
//                         <a
//                           href="/native-ads"
//                           onMouseEnter={() => setDisplayNavbar(true)}
//                         >
//                           <p className="whitespace-nowrap textColor py-2 hover:bg-[#00308F] hover:text-white hover:shadow-md flex items-center gap-3 ml-20 pl-3">
//                             <BiRadioCircle />
//                             Native-Ads
//                           </p>
//                         </a>
//                         <a
//                           href="/interstitial-ads"
//                           onMouseEnter={() => setDisplayNavbar(true)}
//                         >
//                           <p className="whitespace-nowrap textColor py-2  hover:bg-[#00308F] hover:text-white hover:shadow-md flex items-center gap-3 ml-20 pl-3">
//                             <BiRadioCircle />
//                             Interstitial-Ads
//                           </p>
//                         </a>
//                       </>
//                     )}

//                     <div className="flex items-center justify-center  gap-2">
//                       <div className="rounded-lg  flex items-center justify-center w-10 h-10">
//                         {/* <TbTargetArrow className='textColor text-4xl' /> */}
//                         <img
//                           className="w-[100%] h-[100%] object-cover"
//                           src="target.png"
//                           alt="navIcon"
//                         />
//                       </div>
//                       <div>
//                         <a href="/targeting-options">
//                           <p className="whitespace-nowrap textColor pb-1 px-2">
//                             Targeting Options
//                           </p>
//                         </a>
//                         {/* <p className='text-gray-500 text-sm font-normal px-2'>Lorem ipsum dolor sit</p> */}
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               )}
//             </li>

//             <li
//               onMouseOver={() => setShowResources(true)}
//               onMouseLeave={() => setShowResources(false)}
//               className="relative cursor-pointer flex items-center gap-1 text-black"
//             >
//               CASE STUDIES{" "}
//               {showResources ? <AiFillCaretUp /> : <AiFillCaretDown />}
//               {showResources && (
//                 <>
//                   <div
//                     onMouseOver={() => setShowResources(true)}
//                     className="absolute z-50 bottom-0 translate-y-[100%] bg-white rounded-lg w-[250px] shadow-lg py-3 border"
//                   >
//                     <a href="/bfsi">
//                       <p className="whitespace-nowrap textColor py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                         <BiRadioCircle />
//                         BFSI
//                       </p>
//                     </a>
//                     <a href="/fmcg">
//                       <p className="whitespace-nowrap textColor py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                         <BiRadioCircle />
//                         FMCG
//                       </p>
//                     </a>
//                     <a href="/entertainment">
//                       <p className="whitespace-nowrap textColor py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                         <BiRadioCircle />
//                         Entertainment
//                       </p>
//                     </a>
//                     <a href="/automobile">
//                       <p className="whitespace-nowrap textColor py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                         <BiRadioCircle />
//                         Automobile
//                       </p>
//                     </a>
//                     <a href="/gaming">
//                       <p className="whitespace-nowrap textColor py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                         <BiRadioCircle />
//                         Fasion & Lifestyle
//                       </p>
//                     </a>
//                     <a href="/aviation">
//                       <p className="whitespace-nowrap textColor py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                         <BiRadioCircle />
//                         Electronics
//                       </p>
//                     </a>
//                   </div>
//                 </>
//               )}
//             </li>
//             <li
//               onMouseOver={() => setShowBlogs(true)}
//               onMouseLeave={() => setShowBlogs(false)}
//               className="relative cursor-pointer flex items-center gap-1 text-black"
//             >
//               RESOURCES {showBlogs ? <AiFillCaretUp /> : <AiFillCaretDown />}
//               {showBlogs && (
//                 <>
//                   <div
//                     onMouseOver={() => setShowBlogs(true)}
//                     className="absolute z-50 bottom-0 translate-y-[100%] bg-white rounded-lg w-[250px] shadow-lg py-3 border"
//                   >
//                     <a href="/blog">
//                       <p className="whitespace-nowrap textColor py-4 hover:bg-[#00308F] hover:text-white px-5 hover:shadow-md flex items-center gap-3">
//                         <BiRadioCircle />
//                         Blogs
//                       </p>
//                     </a>
//                   </div>
//                 </>
//               )}
//             </li>
//             <a href="/about">
//               <li className="relative cursor-pointer text-black">ABOUT US</li>
//             </a>

//             <a href="/contact">
//               <li className="relative cursor-pointer text-black">CONTACT US</li>
//             </a>
//           </ul>

//           <div className="flex items-center md:hidden">
//             {isNav ? (
//               <RxCross2
//                 className="text-black text-2xl cursor-pointer"
//                 onClick={() => setIsNav((prev) => !prev)}
//               />
//             ) : (
//               <GiHamburgerMenu
//                 onClick={() => setIsNav((prev) => !prev)}
//                 className="text-black text-2xl cursor-pointer"
//               />
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default NavBar;
