import React, { useState } from "react";
import SliderChannelwise from "./SliderChannelwise";
import { ChevronDown } from "lucide-react";
import BannerChannelWise from "./BannerCtv";
import BannerCtv from "./BannerCtv";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import CtvService from "./CtvService";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import {
  FaRocket,
  FaBookmark,
  FaVideo,
  FaQuestionCircle,
  FaFileAlt,
} from "react-icons/fa";
import { Helmet } from "react-helmet";
import BlogSection from "../landingPage/BlogHome";
const CtvNew = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);
  const items = [
    {
      title: "Precision-Powered Targeting",
      content:
        "Go beyond generic demographics—reach high-intent audiences with AI-driven insights and privacy-compliant data for maximum impact.",
    },
    {
      title: "Unmatched Streaming Access",
      content:
        "Tap into an expansive inventory of premium content, from top-tier streaming platforms to live sports and exclusive entertainment.",
    },
    {
      title: "Cross-Platform Synergy",
      content:
        "Seamlessly integrate CTV with mobile, web, and social campaigns, ensuring a cohesive brand experience across every screen.",
    },
    {
      title: "AI-Optimized Performance",
      content:
        "Leverage BidBox’s smart algorithms to optimize ad spend, enhance engagement, and ensure better performance with real-time adjustments to maximize your ROI.",
    },
    {
      title: "Engagement Without Disruption",
      content:
        "Deliver high-quality, non-intrusive ads in immersive environments, keeping audiences engaged without interrupting their viewing experience.",
    },
    {
      title: "Real-Time Measurement & Insights",
      content:
        "Track every interaction with advanced analytics  with 25+ KPIs—monitor app installs, conversions, and even in-store visits with real-time precision —quantifying real impact.",
    },
    {
      title: "Smart Frequency Control",
      content:
        "Manage ad exposure across devices and networks to avoid over-saturation and enhance user experience while maintaining brand recall.",
    },
  ];
  const caseStudies = [
    {
      id: 1,
      image: "/de.jpg", // Replace with actual image
      category: "CASE STUDY",
      title: "A CTV Triumph",
      stat1: "3M+ Impressions",
      desc1:
        "Garnered over 3 million impressions, amplifying brand visibility and festive impact.",
      stat2: "97%+ VTR",
      desc2:
        "Achieved an outstanding 97%+ View-Through Rate (VTR), showcasing exceptional audience engagement.",
      link: "/fashion-lifestyle",
    },
    {
      id: 2,
      image: "/smallindofood.jpg", // Replace with actual image
      category: "CASE STUDY",
      title: "A Programmatic Triumph ",
      stat1: "853K Consumer",
      desc1:
        "Reached over 853K consumers, reinforcing Indofoods’ market leadership in Indonesia.",
      stat2: "98%+ VTR",
      desc2:
        "Achieved an impressive 98% View-Through Rate (VTR), highlighting strong audience engagement.",
      link: "/fmcg",
    },
  ];

  // Animation variants
  const fadeInVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const staggerItem = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const scaleUp = {
    initial: { scale: 1 },
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };

  const fadeInUpVariant = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const images = ["/assets/crousel1.png", "/assets/householdctv.png"];
  const SmallCarousel = () => {
    return (
      <div className="w-full max-w-2xl mx-auto overflow-hidden">
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={10}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 2000 }}
          className="w-full"
        >
          {images.map((src, index) => (
            <SwiperSlide key={index}>
              <img
                src={src}
                alt={`Slide ${index + 1}`}
                className="w-full h-72 sm:h-80 md:h-96 lg:h-[550px] xl:h-[650px] 2xl:h-[650px] object-cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };

  //   const SmallCarousel = () => {
  //     return (
  //       <div className="w-full max-w-sm mx-auto overflow-hidden ">
  //       <Swiper
  //         modules={[Navigation, Autoplay]}
  //         spaceBetween={10}
  //         slidesPerView={1}
  //         loop={true}
  //         autoplay={{ delay: 2000 }}
  //         navigation
  //         className="w-full"
  //       >
  //         {images.map((src, index) => (
  //           <SwiperSlide key={index}>
  //             <img
  //               src={src}
  //               alt={`Slide ${index + 1}`}
  //               className="w-full h-48 sm:h-64 md:h-80 lg:h-full object-contain"
  //             />
  //           </SwiperSlide>
  //         ))}
  //       </Swiper>
  //     </div>
  //   );
  // };
  return (
    <>
      <Helmet>
        <title>
          CTV & OTT Advertising – Targeted Streaming Ads with BidBox AI
        </title>
        <meta
          name="description"
          content="Deliver high-impact ads on CTV and OTT platforms with BidBox AI, ensuring premium audience engagement across smart TVs and streaming services."
        />
        <meta
          name="keywords"
          content="CTV advertising, OTT ads, streaming ads, connected TV marketing, programmatic CTV, video-on-demand ads, cross-screen advertising, Smart TV ads, ad-supported streaming, premium video ads, digital TV advertising, cord-cutting audience targeting, FAST channels advertising, audience-first CTV campaigns."
        />
      </Helmet>
      {/* <div
      className="relative flex items-center justify-center h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('https://t4.ftcdn.net/jpg/09/49/36/63/240_F_949366383_tyZF61HUtUectJuzOQGxzUW8vjWXVO1b.jpg')" }}
    >
      <div className="bg-white/20 backdrop-blur-md p-8 rounded-lg shadow-lg text-center max-w-lg">
        <h1 className="text-4xl font-bold text-white mb-4">Welcome to Our Platform</h1>
        <p className="text-white text-lg mb-6">
          Explore amazing features and elevate your experience with our services.
        </p>
        <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-105 transition transform duration-300">
          Get Started
        </button>
      </div>
    </div> */}
      {/* <div className="flex min-h-screen justify-center items-center dark:bg-slate-800 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            type: "spring",
            stiffness: 50,
          }}
          className="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8"
        >
          <div className="text-center ">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              class="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
            >
              <span className="block xl:inline">
                <span className="mb-1 block">CTV Advertising, </span>
                <motion.span
                  initial={{ backgroundPosition: "0%" }}
                  animate={{ backgroundPosition: "100%" }}
                  transition={{
                    repeat: Infinity,
                    duration: 3,
                    ease: "linear",
                    repeatType: "reverse",
                  }}
                  className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                >
                  Redefined for Impact
                </motion.span>
              </span>
              <div className="mt-2">
                10X Efficiency
                <span className="relative mt-3 whitespace-nowrap text-blue-600">
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 418 42"
                    className="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
                    preserveAspectRatio="none"
                  >
                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                  </svg>
                  <span className="relative">with Bidbox Ai</span>
                </span>
              </div>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              class="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
            >
              Engage audiences with precision-targeted ads on premium streaming
              platforms, seamlessly integrating brand storytelling across every
              screen in the connected home.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              className="mt-5 sm:mt-8 sm:flex sm:justify-center"
            >
              <motion.div
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                className="rounded-md shadow"
              >
                <a
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                  href="/contact"
                >
                  Get started
                </a>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div> */}
      <div className="flex min-h-[100dvh] justify-center items-center dark:bg-slate-800 overflow-x-hidden">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
          className="mx-auto flex h-full min-h-[100dvh] w-full items-center justify-center px-4 sm:px-6 lg:px-8"
        >
          <div className="text-center max-w-4xl">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
              style={{
                WebkitTransform: "translateY(0px)",
                transform: "translateY(0px)",
              }}
            >
              <span className="block xl:inline">
                <span className="mb-1 block">CTV Advertising,</span>
                <motion.span
                  initial={{ backgroundPosition: "0%" }}
                  animate={{ backgroundPosition: "100%" }}
                  transition={{
                    repeat: Infinity,
                    duration: 3,
                    ease: "linear",
                    repeatType: "reverse",
                  }}
                  className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                >
                  Redefined for Impact
                </motion.span>
                <p className="mb-1 block">
                  10X Efficiency
                  <span className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                    with Bidbox.Ai
                  </span>{" "}
                </p>
              </span>
            </motion.h1>

            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
            >
              Engage audiences with precision-targeted ads on premium streaming
              platforms, seamlessly integrating brand storytelling across every
              screen in the connected home.
            </motion.p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              className="mt-5 sm:mt-8 sm:flex sm:justify-center"
            >
              <motion.div
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                className="rounded-md shadow"
              >
                <a
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                  href="/contact"
                >
                  Get started
                </a>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div>
      {/* <div className="bg-white py-12 px-4 md:px-6">
          <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
           
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={fadeInVariant}
              viewport={{ once: true }}
              className="relative w-full md:w-5/12"
            >
              <div className="absolute inset-0 bg-purple-600 opacity-20 rounded-lg"></div>
              <motion.img
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
                alt="Connected TV"
                className="w-full h-auto md:h-96 object-cover rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"
              />
            </motion.div>

          
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={fadeInVariant}
              viewport={{ once: true, amount: 0.4 }}
              className="w-full md:w-7/12 text-center md:text-left"
            >
              <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
                Connected TV: The Future of Streaming & Smart Advertising
              </h2>
              <p className="text-gray-700 text-lg md:text-xl leading-relaxed mb-6">
                Connected TV (CTV) advertising delivers targeted video ads on
                smart TVs and streaming devices. It connects brands with viewers
                watching premium content across OTT platforms, extending reach
                beyond traditional cable and satellite TV.
              </p>
              <Link to="/contact">
                <motion.button
                  variants={scaleUp}
                  initial="initial"
                  whileHover="hover"
                  className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-110 transition duration-300"
                >
                  Learn More
                </motion.button>
              </Link>
            </motion.div>
          </div>

          
          <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-12 mt-16">
           
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={fadeInVariant}
              viewport={{ once: true }}
              className="relative w-full md:w-5/12"
            >
              <div className="absolute inset-0 bg-blue-600 opacity-20 rounded-lg"></div>
              <motion.img
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
                alt="CTV Advertising"
                className="w-full h-auto md:h-96 object-cover rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"
              />
            </motion.div>

           
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={fadeInVariant}
              viewport={{ once: true, amount: 0.4 }}
              className="w-full md:w-7/12 text-center md:text-left"
            >
              <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
                Why CTV Advertising? More Reach, More Impact, Zero Wastage{" "}
              </h2>
              <p className="text-gray-700 text-lg md:text-xl leading-relaxed mb-6">
                CTV connects you to cord-cutters and streaming-first audiences
                with precision. Unify TV and digital ad buys, control who sees
                your ads, and measure performance—ensuring maximum impact with
                minimal waste.
              </p>
              <Link to="/contact">
                <motion.button
                  variants={scaleUp}
                  initial="initial"
                  whileHover="hover"
                  className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg hover:scale-110 transition duration-300"
                >
                  Learn More
                </motion.button>
              </Link>
            </motion.div>
          </div>
        </div> */}
      <div className="bg-white py-16 px-6 md:px-12">
        <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0 opacity-20 rounded-lg"></div>
            <motion.img
              variants={{
                initial: { scale: 1, rotateY: 0 },
                hover: { scale: 1.05, rotateY: 10 },
                float: {
                  y: [0, -10, 0], // Floating effect
                  transition: {
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut",
                  },
                },
              }}
              initial="initial"
              whileHover="hover"
              animate="float"
              src="/assets/ctv1.png"
              alt="Connected TV"
              className="w-full h-auto md:h-96 object-cover "
            />
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Connected TV: The Future of Streaming & Smart Advertising
            </h2>
            <p className="text-gray-700 text-lg leading-relaxed mb-4">
              Connected TV (CTV) advertising delivers targeted video ads on
              smart TVs and streaming devices, allowing brands to connect with
              viewers watching premium content across OTT platforms. With the
              rise of cord-cutting and streaming-first audiences, CTV extends
              reach beyond traditional cable and satellite TV—offering a
              data-driven, measurable, and highly engaging ad experience.
            </p>
            <ul className="list-disc pl-6 text-gray-700 text-lg mb-6">
              <li>
                CTV Viewership is Growing Rapidly – Millions of users are
                shifting to streaming.
              </li>
              <li>
                Ad-Supported Streaming is the Future – Platforms are introducing
                ad tiers.
              </li>
              <li>
                CTV Ads are 100% Viewable – Ensuring full engagement and
                retention.
              </li>
            </ul>
            {/* <Link to="/contact">
            <motion.button
              variants={scaleUp}
              initial="initial"
              whileHover="hover"
              className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg"
            >
              Learn More
            </motion.button>
          </Link> */}
          </motion.div>
        </div>

        {/* SECOND SECTION */}
        <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-12 mt-16">
          {/* Image on Right */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0  opacity-20 rounded-lg"></div>
            <motion.img
              variants={{
                initial: { scale: 1, rotateY: 0 },
                hover: { scale: 1.05, rotateY: 10 },
                float: {
                  y: [0, -10, 0], // Floating effect
                  transition: {
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut",
                  },
                },
              }}
              initial="initial"
              whileHover="hover"
              animate="float"
              src="/assets/ctv2.png"
              alt="CTV Advertising"
              className="w-full h-auto md:h-96 object-cover "
            />
          </motion.div>

          {/* Text on Left */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Why CTV Advertising? More Reach, More Impact, Zero Wastage
            </h2>
            <p className="text-gray-700 text-lg leading-relaxed mb-4">
              CTV advertising enables brands to reach highly engaged streaming
              audiences with precision, combining the best of TV and digital
              advertising.
            </p>
            <ul className="list-disc pl-6 text-gray-700 text-lg mb-6">
              <li>
                Premium Audience Access – Target cord-cutters and digital-first
                consumers.
              </li>
              <li>
                Precision Targeting – Use behavioral, demographic, and
                contextual insights.
              </li>
              <li>
                Unified TV + Digital Strategy – Create a seamless brand
                experience across screens.
              </li>
              <li>
                Performance-Based Buying – Optimize in real-time and measure
                ROI.
              </li>
            </ul>
            {/* <Link to="/contact">
            <motion.button
              variants={scaleUp}
              initial="initial"
              whileHover="hover"
              className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg"
            >
              Learn More
            </motion.button>
          </Link> */}
          </motion.div>
        </div>
      </div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="max-w-4xl mx-auto text-center"
      >
        <h2 className="text-4xl md:text-5xl font-bold mb-6">
          Power Your CTV with Bidbox
        </h2>
        <p className="text-xl text-gray-600 mb-12">
          BidBox unlocks premium CTV advertising with precise targeting,
          real-time optimization, and seamless reach across top streaming
          platforms. With AI-powered buying, premium inventory access, and
          privacy-compliant targeting, BidBox ensures high-impact ad placements
          that drive measurable results.
        </p>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="px-4 md:px-10"
      >
        <div className=" mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-6 md:px-8 rounded-lg shadow-lg mt-6 mb-6">
          <div className="container mx-auto flex flex-col md:flex-row items-center text-white text-center md:text-left space-y-8 md:space-y-0 md:space-x-8">
            {/* Column 1 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">
                95%+ Video Completion Rate
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
                Unskippable, premium-quality ads keep audiences engaged till the
                very end.
              </p>
            </motion.div>
            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>{" "}
            {/* Mobile Divider */}
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>{" "}
            {/* Desktop Divider */}
            {/* Column 2 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1 "
            >
              <h2 className="text-2xl md:text-2xl font-bold">
                60% Higher Ad Recall
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
                CTV ads deliver immersive brand experiences, ensuring viewers
                remember your message long after exposure.
              </p>
            </motion.div>
            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>{" "}
            {/* Mobile Divider */}
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>{" "}
            {/* Desktop Divider */}
            {/* Column 3 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1 "
            >
              <h2 className="text-2xl md:text-2xl font-bold">
                5X More Effective{" "}
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
                Interactive and high-impact CTV formats drive significantly
                higher retention.
              </p>
            </motion.div>
          </div>
        </div>
      </motion.div>
      {/* <div className='px-10'>
    <div className=" mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-8 rounded-lg shadow-lg mt-6 mb-6">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between text-white text-center">
       
        <div className="flex-1 px-10">
          <h2 className="text-3xl font-bold">95%+ Video Completion</h2>
          <p className="text-lg opacity-90 mt-1">BidBox delivers unskippable, high-impact CTV ads that keep audiences engaged till the very end.
          </p>
        </div>

       
        <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>

        
        <div className="flex-1 px-10">
          <h2 className="text-3xl font-bold">35% Lower CPM</h2>
          <p className="text-lg opacity-90 mt-1">BidBox optimizes ad spend with AI-driven bidding, reducing costs while maximizing reach.</p>
        </div>

        <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>

        <div className="flex-1 px-10">
          <h2 className="text-3xl font-bold">80% engagement</h2>
          <p className="text-lg opacity-90 mt-1">Advanced targeting ensures your ads reach the right viewers, boosting interaction and brand recall.</p>
        </div>

      </div>
    </div>
    </div> */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.4 }}
        variants={fadeInVariant}
      >
        <div className="bg-white py-12 px-4 md:px-6">
          <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
            {/* Image on Left */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={fadeInVariant}
              viewport={{ once: true }}
              className="relative w-full md:w-5/12"
            >
              <div className="absolute inset-0 opacity-20 rounded-lg"></div>
              <motion.img
                variants={{
                  initial: { scale: 1, rotateY: 0 },
                  hover: { scale: 1.05, rotateY: 10 },
                  float: {
                    y: [0, -10, 0], // Floating effect
                    transition: {
                      duration: 3,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "easeInOut",
                    },
                  },
                }}
                initial="initial"
                whileHover="hover"
                animate="float"
                src="/assets/ctv3.png"
                alt="Connected TV"
                className="w-full h-auto md:h-full object-cover "
              />
            </motion.div>

            {/* Text on Right */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={fadeInVariant}
              viewport={{ once: true, amount: 0.4 }}
              className="w-full md:w-7/12 text-center md:text-left"
            >
              <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
                Maximize Reach with OTT, OEM & FAST Ads
              </h2>
              <p className="text-gray-700 text-lg md:text-xl leading-relaxed mb-6">
                Go beyond traditional digital advertising and tap into the power
                of OTTs, OEMs, and FAST channels to maximize your brand’s reach.
                With BidBox, your ads seamlessly integrate into premium
                streaming environments—whether it’s top OTT platforms, smart TV
                OEM ecosystems, or high-impact FAST channels. From live news and
                sports to entertainment and beyond, we ensure uninterrupted
                engagement with precise targeting and measurable results. Get
                seen where your audience is watching and make every impression
                count!
              </p>
            </motion.div>
          </div>
        </div>
        <SliderChannelwise />
      </motion.div>
      {/* <div className="w-full max-w-7xl mx-auto py-12 px-4">
        
        <h2 className="text-4xl md:text-5xl font-bold text-center mb-10">
          Unskippable. Unmissable. Unmatched
        </h2>
\
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
         
          <div className="w-full md:w-[50%] flex justify-center">
            <img
              src="https://www.intandemcommunications.co.uk/wp-content/uploads/2019/08/What-is-marketing.jpg" // Replace with actual image
              alt="Marketing"
              className="w-full max-w-2xl h-auto rounded-lg shadow-lg"
            />
          </div>

          
          <div className="w-full md:w-[50%] space-y-4">
            {items.map((item, index) => (
              <div
                key={index}
                className="border-b border-gray-300 cursor-pointer"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
              
                <div className="flex justify-between items-center py-4">
                  <h2 className="text-lg font-semibold">{item.title}</h2>
                  <ChevronDown
                    className={`w-5 h-5 transition-transform ${
                      openIndex === index ? "rotate-180" : ""
                    }`}
                  />
                </div>

               
                {openIndex === index && (
                  <p className="text-gray-600 pb-4">{item.content}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div> */}
      {/* Expandable Panels on Hover */}
      {/* <div className="w-full max-w-7xl mx-auto py-12 px-4">
    
        <motion.h2
          initial={{ opacity: 0, y: -30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="text-4xl md:text-5xl font-bold text-center mb-10"
        >
          Unskippable. Unmissable. Unmatched
        </motion.h2>

    
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          
          <div className="w-full md:w-[50%] flex justify-center">
            <img
              src="https://www.intandemcommunications.co.uk/wp-content/uploads/2019/08/What-is-marketing.jpg"
              alt="Marketing"
              className="w-full max-w-2xl h-auto rounded-lg shadow-lg"
            />
          </div>

        
          <div className="w-full md:w-[50%] space-y-4">
            {items.map((item, index) => (
              <div
                key={index}
                className="border-b border-gray-300 cursor-pointer"
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
              >
             
                <div className="flex justify-between items-center py-4">
                  <h2 className="text-lg font-semibold">{item.title}</h2>
                  <ChevronDown
                    className={`w-5 h-5 transition-transform ${
                      hoverIndex === index ? "rotate-180" : ""
                    }`}
                  />
                </div>

                
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={
                    hoverIndex === index
                      ? { height: "auto", opacity: 1 }
                      : { height: 0, opacity: 0 }
                  }
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="overflow-hidden"
                >
                  <p className="text-gray-600 pb-4">{item.content}</p>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      {/* <div className="bg-gray-900 text-white py-16 px-6 md:px-12">
      <div className="container mx-auto space-y-16">
     
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-4xl font-bold text-center"
        >
          Household Sync Targeting: Reaching Every Screen in the Home
        </motion.h2>


        <div className="flex flex-col md:flex-row items-center gap-12">
      
          <motion.img
            src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
            alt="Household Sync Targeting"
            className="w-full md:w-5/12 rounded-lg shadow-lg"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          />

          <motion.div
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <p className="text-lg mb-4">
              Your audience isn’t just watching TV—they’re multi-screening. With Household Sync Targeting, BidBox enables brands to seamlessly extend CTV ad exposure across multiple household devices.
            </p>
            <ul className="list-disc pl-6 text-lg mb-6 space-y-2">
              <li>Sync CTV Ads with Mobile & Desktop</li>
              <li>Omnichannel Reinforcement</li>
              <li>Dynamic Sequential Messaging</li>
              <li>Household-Level Attribution</li>
            </ul>
            <Link to="/contact">
              <motion.button
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                className="px-6 py-3 bg-blue-600 font-semibold rounded-lg shadow-lg hover:bg-blue-700 transition"
              >
                Learn More
              </motion.button>
            </Link>
          </motion.div>
        </div>

      ]
        <motion.div
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="bg-gray-800 p-6 md:p-10 rounded-lg shadow-lg"
        >
          <h3 className="text-2xl font-semibold mb-4">Use Case Example</h3>
          <p className="text-lg">
            A viewer sees a 30-second CTV ad for a fashion brand on their smart TV. Later, they receive a mobile ad featuring a discount code on their phone, encouraging purchase.
          </p>
        </motion.div>
      </div>
    </div> */}

      {/* <section class="">
  <div class="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
    <div>
      <h2 class="text-3xl font-bold text-center sm:text-5xl">Household Sync Targeting: Reaching Every Screen in the Home</h2>
      <p class="max-w-3xl mx-auto mt-4 text-xl text-center ">Your audience isn’t just watching TV—they’re multi-screening. With Household Sync Targeting, BidBox enables brands to seamlessly extend CTV ad exposure across multiple household devices.</p>
    </div>
    <div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
      <div>
        <div class="mt-4 space-y-12">
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center w-12 h-12 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-rocket">
                  <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z">
                  </path>
                  <path
                    d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z">
                  </path>
                  <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0"></path>
                  <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5"></path>
                </svg></div>
            </div>
            <div class="ml-4">
              <h4 class="text-lg font-medium leadi ">Sync CTV Ads with Mobile & Desktop</h4>
              <p class="mt-2 ">Engage audiences with follow-up ads on their phones, tablets, and laptops after a CTV ad airs.</p>
            </div>
          </div>
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center w-12 h-12 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-bookmark-plus">
                  <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z"></path>
                  <line x1="12" x2="12" y1="7" y2="13"></line>
                  <line x1="15" x2="9" y1="10" y2="10"></line>
                </svg></div>
            </div>
            <div class="ml-4">
              <h4 class="text-lg font-medium leadi ">Interactive Learning Resources</h4>
              <p class="mt-2 ">Access an extensive library of interactive resources that make your learning journey engaging and interactive.</p>
            </div>
          </div>
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center w-12 h-12 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-video">
                  <path d="m22 8-6 4 6 4V8Z"></path>
                  <rect width="14" height="12" x="2" y="6" rx="2" ry="2"></rect>
                </svg></div>
            </div>
            <div class="ml-4">
              <h4 class="text-lg font-medium leadi ">Omnichannel Reinforcement</h4>
              <p class="mt-2 ">Reinforce brand messaging across CTV, social, display, and video for consistent exposure.</p>
            </div>
          </div>
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center w-12 h-12 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-file-question">
                  <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                  <path d="M10 10.3c.2-.4.5-.8.9-1a2.1 2.1 0 0 1 2.6.4c.3.4.5.8.5 1.3 0 1.3-2 2-2 2"></path>
                  <path d="M12 17h.01"></path>
                </svg></div>
            </div>
            <div class="ml-4">
              <h4 class="text-lg font-medium leadi ">Dynamic Sequential Messaging</h4>
              <p class="mt-2 ">Tell a compelling brand story by delivering ad sequences across multiple devices over time.</p>
            </div>
          </div>
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center w-12 h-12 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-file-question">
                  <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                  <path d="M10 10.3c.2-.4.5-.8.9-1a2.1 2.1 0 0 1 2.6.4c.3.4.5.8.5 1.3 0 1.3-2 2-2 2"></path>
                  <path d="M12 17h.01"></path>
                </svg></div>
            </div>
            <div class="ml-4">
              <h4 class="text-lg font-medium leadi ">Household-Level Attribution</h4>
              <p class="mt-2 ">Track conversions with real-time insights on how CTV drives engagement across devices.</p>
            </div>
          </div>
        </div>
      </div>
      <div aria-hidden="true" class="mt-10 lg:mt-0">
        <img width="600" height="600" src="https://images.unsplash.com/photo-1516542076529-1ea3854896f2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxNHx8Y29tcHV0ZXJ8ZW58MHwwfHx8MTY5OTE3MDk1N3ww&ixlib=rb-4.0.3&q=80&w=1080" class="mx-auto rounded-lg shadow-lg dark-bg-gray-500" style="color:transparent"/>
      </div>
    </div>
  </div>
</section> */}
      {/* <section>
  <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl">
    <div>
      <h2 className="text-3xl font-bold text-center sm:text-5xl">
        Household Sync Targeting: Reach Every Screen 
      </h2>
      <p className="max-w-3xl mx-auto mt-4 text-xl text-center">
        Your audience isn’t just watching TV—they’re multi-screening. With Household Sync Targeting, BidBox enables brands to seamlessly extend CTV ad exposure across multiple household devices.
      </p>
    </div>
    <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
      <div>
        <div className="mt-4 space-y-12">
        
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 rounded-md">
                <FaRocket size={24} />
              </div>
            </div>
            <div className="ml-4">
              <h4 className="text-lg font-medium">Sync CTV Ads with Mobile & Desktop</h4>
              <p className="mt-2">Engage audiences with follow-up ads on their phones, tablets, and laptops after a CTV ad airs.</p>
            </div>
          </div>

          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 rounded-md">
                <FaBookmark size={24} />
              </div>
            </div>
            <div className="ml-4">
              <h4 className="text-lg font-medium">Interactive Learning Resources</h4>
              <p className="mt-2">Access an extensive library of interactive resources that make your learning journey engaging and interactive.</p>
            </div>
          </div>

          
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 rounded-md">
                <FaVideo size={24} />
              </div>
            </div>
            <div className="ml-4">
              <h4 className="text-lg font-medium">Omnichannel Reinforcement</h4>
              <p className="mt-2">Reinforce brand messaging across CTV, social, display, and video for consistent exposure.</p>
            </div>
          </div>

         
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 rounded-md">
                <FaQuestionCircle size={24} />
              </div>
            </div>
            <div className="ml-4">
              <h4 className="text-lg font-medium">Dynamic Sequential Messaging</h4>
              <p className="mt-2">Tell a compelling brand story by delivering ad sequences across multiple devices over time.</p>
            </div>
          </div>

     
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 rounded-md">
                <FaFileAlt size={24} />
              </div>
            </div>
            <div className="ml-4">
              <h4 className="text-lg font-medium">Household-Level Attribution</h4>
              <p className="mt-2">Track conversions with real-time insights on how CTV drives engagement across devices.</p>
            </div>
          </div>

        </div>
      </div>

 
      <div aria-hidden="true" className="mt-10 lg:mt-0">
        <img 
          width="600" 
          height="600" 
          src="https://images.unsplash.com/photo-1516542076529-1ea3854896f2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxNHx8Y29tcHV0ZXJ8ZW58MHwwfHx8MTY5OTE3MDk1N3ww&ixlib=rb-4.0.3&q=80&w=1080" 
          className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" 
          style={{ color: "transparent" }} 
        />
      </div>
    </div>
  </div>
</section> */}
      {/* <section className="px-4">
      <div className="container max-w-7xl p-6 mx-auto space-y-12 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold text-center sm:text-5xl">
            Household Sync Targeting: Reach Every Screen
          </h2>
          <p className="max-w-3xl mx-auto mt-4 text-xl text-center">
            Your audience isn’t just watching TV—they’re multi-screening. With Household Sync Targeting, BidBox enables brands to seamlessly extend CTV ad exposure across multiple household devices.
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-8 lg:items-center">
         
          <div>
            <div className="space-y-6">
              {[
                { icon: <FaRocket size={24} />, title: "Sync CTV Ads with Mobile & Desktop", text: "Engage audiences with follow-up ads on their phones, tablets, and laptops after a CTV ad airs." },
                { icon: <FaBookmark size={24} />, title: "Interactive Learning Resources", text: "Access an extensive library of interactive resources that make your learning journey engaging and interactive." },
                { icon: <FaVideo size={24} />, title: "Omnichannel Reinforcement", text: "Reinforce brand messaging across CTV, social, display, and video for consistent exposure." },
                { icon: <FaQuestionCircle size={24} />, title: "Dynamic Sequential Messaging", text: "Tell a compelling brand story by delivering ad sequences across multiple devices over time." },
                { icon: <FaFileAlt size={24} />, title: "Household-Level Attribution", text: "Track conversions with real-time insights on how CTV drives engagement across devices." },
              ].map((item, idx) => (
                <div key={idx} className="flex">
                  <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center text-blue-800">
                    {item.icon}
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium">{item.title}</h4>
                    <p className="mt-2 text-gray-700">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          
          <div className="mt-6 lg:mt-0">
            <SmallCarousel />
          </div>
        </div>
      </div>
    </section> */}

      <section className="px-4">
        <div className="container max-w-7xl p-6 mx-auto space-y-12 lg:px-8">
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-center sm:text-5xl">
              Household Sync Targeting: Reach Every Screen
            </h2>
            <p className="max-w-3xl mx-auto mt-4 text-xl  text-gray-700 text-center">
              Your audience isn’t just watching TV—they’re multi-screening. With
              Household Sync Targeting, BidBox enables brands to seamlessly
              extend CTV ad exposure across multiple household devices.
            </p>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.5 }}
            className="grid lg:grid-cols-2 gap-8 lg:items-center"
          >
            <div>
              <div className="space-y-6">
                {[
                  {
                    icon: <FaRocket size={24} />,
                    title: "Sync CTV Ads with Mobile & Desktop",
                    text: "Engage audiences with follow-up ads on their phones, tablets, and laptops after a CTV ad airs.",
                  },
                  {
                    icon: <FaBookmark size={24} />,
                    title: "Interactive Learning Resources",
                    text: "Access an extensive library of interactive resources that make your learning journey engaging and interactive.",
                  },
                  {
                    icon: <FaVideo size={24} />,
                    title: "Omnichannel Reinforcement",
                    text: "Reinforce brand messaging across CTV, social, display, and video for consistent exposure.",
                  },
                  {
                    icon: <FaQuestionCircle size={24} />,
                    title: "Dynamic Sequential Messaging",
                    text: "Tell a compelling brand story by delivering ad sequences across multiple devices over time.",
                  },
                  {
                    icon: <FaFileAlt size={24} />,
                    title: "Household-Level Attribution",
                    text: "Track conversions with real-time insights on how CTV drives engagement across devices.",
                  },
                ].map((item, idx) => (
                  <div key={idx} className="flex">
                    <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center text-blue-800">
                      {item.icon}
                    </div>
                    <div className="ml-4">
                      <h4 className="text-lg font-medium">{item.title}</h4>
                      <p className="mt-2 text-gray-700">{item.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Scrollable Image Carousel */}
            <div className="overflow-x-auto mt-6 lg:mt-0">
              <SmallCarousel />
            </div>
          </motion.div>
        </div>
      </section>

      <div class="bg-white py-12">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeInUpVariant}
            class="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center"
          >
            <h1 class="lg:text-5xl text-2xl md:text-5xl font-bold tracking-tight text-gray-900 text-center">
              Advanced CTV Creative Ad Solutions
            </h1>
            <p class="mt-6 text-md text-gray-600 max-w-lg text-center">
              CTV ads aren’t just about showing commercials—they’re about
              creating engaging, interactive experiences that drive higher
              engagement and conversions.
            </p>
          </motion.div>

          <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-6xl">
            <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              <div class="relative">
                <img
                  src="/assets/ctvl.png"
                  alt="L-Band"
                  class="w-full rounded-lg"
                />
                <dt class="text-xl font-semibold leading-7 text-gray-900 mt-4">
                  L-Band CTV Creative
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600">
                  Non-intrusive, always-visible branding overlay during
                  streaming content, ensuring constant exposure without
                  disrupting the user experience.
                </dd>
              </div>

              <div class="relative">
                <img
                  src="/assets/ctvqr.png"
                  alt="QR Code"
                  class="w-full rounded-lg"
                />
                <dt class="text-xl font-semibold leading-7 text-gray-900 mt-4">
                  QR Code Integration
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600">
                  Add scannable QR codes to CTV ads, leading viewers directly to
                  your website, app, or promotional landing page—bridging TV and
                  mobile engagement.
                </dd>
              </div>

              <div class="relative">
                <img
                  src="/assets/ctvdco.png"
                  alt="DCO"
                  class="w-full rounded-lg"
                />
                <p class="text-xl font-semibold leading-7 text-gray-900 mt-4">
                  Dynamic Creative Optimization (DCO) for CTV
                </p>
                <dd class="mt-2 text-base leading-7 text-gray-600">
                  Serve personalized ad variations based on user location,
                  behavior, and preferences, ensuring tailored messaging that
                  drives higher engagement.
                </dd>
              </div>

              <div class="relative">
                <img
                  src="/assets/ctvremote.png"
                  alt="Remote Integration"
                  class="w-full rounded-lg"
                />
                <dt class="text-xl font-semibold leading-7 text-gray-900 mt-4">
                  Remote Integration (Interactive Ads)
                </dt>
                <dd class="mt-2 text-base leading-7 text-gray-600">
                  Viewers can interact with CTV ads using their remote, choosing
                  different product views, answering polls, or even engaging
                  with shoppable TV ads.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <BannerCtv />

      <CtvService />
      {/* Case Studies */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.4 }}
        className="max-w-7xl mx-auto py-12 px-4"
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-3xl md:text-4xl font-bold text-center mb-10"
        >
          Success Stories
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {caseStudies.map((study, index) => (
            <motion.div
              key={study.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
              whileHover={{
                scale: 1.03,
                boxShadow:
                  "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
              }}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300"
            >
              {/* Top: Image with overlay effect */}
              <div className="w-full relative overflow-hidden">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                  src={study.image}
                  alt="Case Study"
                  className="w-full h-48 md:h-64 object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
                <div className="absolute bottom-0 left-0 p-4">
                  <span className="text-xs font-bold text-white bg-blue-600 px-2 py-1 rounded">
                    {study.category}
                  </span>
                </div>
              </div>

              {/* Bottom: Text Content */}
              <div className="p-6 text-center">
                <h3 className="text-xl font-bold mt-2">{study.title}</h3>
                <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-8 mt-4">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">
                      {study.stat1}
                    </p>
                    <p className="text-gray-600 text-sm">{study.desc1}</p>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">
                      {study.stat2}
                    </p>
                    <p className="text-gray-600 text-sm">{study.desc2}</p>
                  </motion.div>
                </div>
                <motion.a
                  whileHover={{ scale: 1.1 }}
                  href={study.link}
                  className="text-blue-600 font-semibold mt-6 inline-block border-b-2 border-transparent hover:border-blue-600"
                >
                  READ CASE STUDY
                </motion.a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.4 }}
        variants={fadeInUpVariant}
      >
        <BlogSection />
      </motion.div>
    </>
  );
};

export default CtvNew;
