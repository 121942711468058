import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion} from "framer-motion";

// Logo data
const logos = [
  { id: 1, name: "Logo 1", image: "/disney.png" },
  { id: 2, name: "Logo 2", image: "/sliderctv2.jpg" },
  { id: 3, name: "Logo 3", image: "/sliderctv3.jpg" },
  { id: 4, name: "Logo 4", image: "/sliderctv18.jpg" },
  { id: 5, name: "Logo 5", image: "/mx.png" },
  { id: 6, name: "Logo 6", image: "/sliderctv6.jpg" },
  { id: 7, name: "Logo 7", image: "/sliderctv7.jpg" },
  { id: 8, name: "Logo 8", image: "/sliderctv8.jpg" },
  { id: 9, name: "Logo 9", image: "/sliderctv9.jpg" },
  { id: 10, name: "Logo 10", image: "/sliderctv10.jpg" },
  { id: 11, name: "Logo 11", image: "/sliderctv12.jpg" },
  { id: 12, name: "Logo 12", image: "/sliderctv13.jpg" },
  { id: 13, name: "Logo 13", image: "/sliderctv14.jpg" },
  { id: 14, name: "Logo 14", image: "/sliderctv15.jpg" },
  { id: 15, name: "Logo 15", image: "/sliderctv16.jpg" },
  { id: 16, name: "Logo 16", image: "/sliderctv17.jpg" },
  { id: 17, name: "Logo 17", image: "/sliderctv19.jpg" },
  { id: 18, name: "Logo 17", image: "/sliderctv20.jpg" },
  { id: 19, name: "Logo 17", image: "/sliderctv21.jpg" },
  { id: 20, name: "Logo 17", image: "/sliderctv22.jpg" },
  { id: 21, name: "Logo 17", image: "/sliderctv23.jpg" },


];

const SliderChannelwise = () => {
  const commonSettings = {
    infinite: true,
    speed: 1500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 2 },
      },
    ],
  };

  const slider1Settings = { ...commonSettings, rtl: false }; // Left to right
  const slider2Settings = { ...commonSettings, rtl: true }; // Right to left
 // Animation variants
 const fadeInVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};
  return (
    <div className="py-20 bg-white">
      {/* Title */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}  className="text-center mb-10">

       <h2 className="text-3xl md:text-5xl font-bold textColor">
          Premium CTV Ad Inventory
        </h2>
        <p className="text-gray-600 text-lg">
        Showcase your brand on high-quality streaming platforms with unbeatable viewer engagement.
        </p>
        </motion.div>

      {/* Two Sliders */}
      <div className="space-y-8">
        {/* Slider 1 */}
        <Slider {...slider1Settings} className="px-4 lg:px-20">
          {logos.map((logo) => (
            <div key={logo.id} className="p-4">
              <div className="bg-white shadow-lg rounded-lg flex justify-center items-center">
                <img
                  src={logo.image}
                  alt={logo.name}
                  className="object-contain h-28 w-28"
                />
              </div>
            </div>
          ))}
        </Slider>

        {/* Slider 2 */}
        <Slider {...slider2Settings} className="px-4 lg:px-20">
          {logos.map((logo) => (
            <div key={logo.id} className="p-4">
              <div className="bg-white shadow-lg rounded-lg flex justify-center items-center">
                <img
                  src={logo.image}
                  alt={logo.name}
                  className="object-contain h-28 w-28"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SliderChannelwise;
