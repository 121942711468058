import React from "react";
import CaseStudyCard from "./CaseStudyCard";
import { Helmet } from "react-helmet";

const Entertainment = () => {
  return (
    <>
     <Helmet>
                <title>Entertainment Industry Advertising – BidBox AI Case Study</title>
                <meta name='description' content='Learn how entertainment brands used BidBox AIs DSP to maximize audience reach, increase viewership, and boost ticket sales through strategic ad placements.' />
                <meta name='keywords' content='Entertainment advertising, OTT marketing, movie promotions, event ticketing ads, music streaming campaigns, media buying for entertainment, digital ads for movies, content engagement strategy, programmatic advertising for entertainment, gaming industry ads, viral marketing, CTV advertising, trailer promotions, audience engagement, media content monetization.' />               
            </Helmet>
    <div className="w-full">
      <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl font-bold text-center mt-14">
          Entertainment Platforms
        </h1>
      </div>

      <div className="w-full overflow-hidden">
        <div className="w-full bg-white flex flex-col items-center">
          <div className="w-[95%] my-10">
            <h1 className="text-3xl md:text-5xl font-bold text-center">
              Explore Diverse Case Studies
            </h1>
            <p className="text-center py-5 text-xl font-medium">
              Dive into success stories spanning various industries and
              verticals to see how we deliver value.
            </p>

            <div className="my-10 flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between">
              <CaseStudyCard
                image="/zee 5.jpg"
                heading="ZEE5’s Brand Awareness Breakthrough"
                impression="Clicks : 175000+"
                vtr="Customer Acquired : 32500+"
                content="In a meticulously crafted campaign to elevate ZEE5’s brand presence in the vibrant Indian market, we employed a dynamic mix of compelling visuals, precision targeting, and optimized digital strategies. Our approach was designed to not only capture attention but also drive meaningful engagement among a diverse audience."
                content1="By leveraging data-driven insights and strategic placements across high-impact digital touchpoints, we ensured maximum visibility and resonance. The campaign delivered remarkable results, generating over 175,000 clicks and successfully acquiring 32,500+ new customers."
                content2="This case study highlights the power of innovative digital marketing in amplifying brand awareness and fostering deep consumer interactions. The success of this campaign stands as a testament to our ability to craft impactful branding strategies that deliver tangible business outcomes in highly competitive markets."
              />
              <CaseStudyCard
                image="/disneyhotstar.jpg"
                heading="A Game-Changing Strategy"
                impression="Clicks: 15 Mn+"
                vtr="Video Views: 6.7 Mn+"
                content="The Cricket World Cup 2023 presented a high-stakes opportunity to capture massive audiences, and our Hotstar campaign was designed to do just that. With a strategic focus on key India match days, we set out to maximize video views and engagement, leveraging real-time optimizations to ensure maximum impact within a limited timeframe."
                content1="Our data-driven approach combined precision targeting, dynamic ad placements, and audience segmentation to attract viewers at peak moments. By aligning our strategy with the fast-paced nature of cricket, we successfully navigated the challenge of one-day event-driven marketing, ensuring that Hotstar remained the go-to platform for fans."
                content2='The results were nothing short of remarkable: over 15 million clicks and 6.7 million video views, solidifying the campaign’s success. This case study exemplifies our ability to execute agile, high-impact campaigns under tight deadlines, proving that with the right strategy, even the shortest windows can drive phenomenal engagement and brand visibility.'
              />
              <CaseStudyCard
                image="/LogoVidio.png"
                heading="A Branding Triumph in Indonesia"
                impression="Impressions: 1.2 Mn+"
                vtr="Customer Acquired : 2750+ "
                content="In a strategic move to fortify its presence in the Indonesian market, Vidio embarked on a branding campaign designed to maximize engagement and visibility. Our approach focused on leveraging data-driven insights and precision targeting to reach the diverse Indonesian audience effectively."
                content1="By implementing a well-optimized mix of digital placements, we ensured that Vidio’s messaging resonated with its core audience, driving high engagement across platforms. The campaign exceeded expectations, with both Click-Through Rates (CTR) and View-Through Rates (VTR) surpassing industry benchmarks, reinforcing Vidio’s position as a leading entertainment platform."
                content2="The impact was evident in the numbers: over 1.2 million impressions and 2,750+ customer acquisitions, proving the campaign’s success in not only driving visibility but also fostering meaningful user conversions. This case study highlights our ability to execute highly effective branding strategies, demonstrating that targeted digital efforts can yield exceptional results in competitive markets."
              />
            </div>
          </div>

          <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
            <div className="p-5 md:p-10 z-10 flex justify-between">
              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="text-white font-bold text-xl md:text-5xl">
                    Questions or ready to get started?
                  </h1>
                  <h2 className="text-white  text-xl md:text-2xl">
                    Reach out and let's supercharge your campaigns together!
                  </h2>
                </div>
                <div>
                  <a href="/contact">
                    <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                      Get Started
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Entertainment;
