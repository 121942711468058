import React, { useEffect, useState, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';

const features = [
  { title: "Brand Lift", value: 96, subtitle: "Increase in Awareness & Recall", suffix: "%" },
  { title: "Audience Reached", value: 250, subtitle: "Across Digital Channels", suffix: "M" },
  { title: "Engagement Rate", value: 92, subtitle: "High Interaction & Viewability", suffix: "%" },
  { title: "Ad Recall Improvement", value: 3, subtitle: "Compared to Industry Benchmarks", suffix: "x" }
];

const StatsSection = () => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const [counts, setCounts] = useState(features.map(() => 0));

  useEffect(() => {
    if (isInView) {
      features.forEach((feature, index) => {
        let start = 0;
        const end = feature.value;
        const duration = 2000;
        const stepTime = 50;
        const steps = duration / stepTime;
        const increment = end / steps;

        const counter = setInterval(() => {
          start += increment;
          setCounts(prev => {
            const newCounts = [...prev];
            newCounts[index] = Math.round(start);
            return newCounts;
          });

          if (start >= end) {
            clearInterval(counter);
            setCounts(prev => {
              const newCounts = [...prev];
              newCounts[index] = end;
              return newCounts;
            });
          }
        }, stepTime);
      });
    }
  }, [isInView]);

  return (
    <motion.section 
      ref={ref}
      className="py-20 px-6 bg-gradient-to-r from-blue-600 to-purple-600"
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1 }}
    >
      <div className="max-w-7xl mx-auto">
        <div className="grid md:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="text-center text-white"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.4, delay: index * 0.2 }}
            >
              <div className="text-5xl font-bold mb-2">
                {counts[index]}
                {feature.suffix}
              </div>
              <div className="text-xl font-semibold mb-1">{feature.title}</div>
              <div className="text-blue-200">{feature.subtitle}</div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default StatsSection;
