import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Logo data
const logos = [
  { id: 1, name: "Logo 1", image: "/assets/ind.png" },
  { id: 2, name: "Logo 2", image: "/assets/angel.png" },
  { id: 3, name: "Logo 3", image: "/assets/citron.png" },
  { id: 4, name: "Logo 4", image: "/assets/dlf.png" },
  { id: 5, name: "Logo 5", image: "/assets/hdfc.png" },
  { id: 6, name: "Logo 6", image: "/assets/indo.png" },
  { id: 7, name: "Logo 7", image: "/assets/jago.png" },
  { id: 8, name: "Logo 8", image: "/assets/jeep.png" },
  { id: 9, name: "Logo 9", image: "/assets/kotak.png" },
  { id: 10, name: "Logo 10", image: "/assets/lenovo.png" },
  { id: 11, name: "Logo 11", image: "/assets/maruti.png" },
  { id: 12, name: "Logo 12", image: "/assets/maybank.png" },
  { id: 13, name: "Logo 13", image: "/assets/ola.png" },
  { id: 14, name: "Logo 14", image: "/assets/oneplus1.png" },
  { id: 15, name: "Logo 15", image: "/assets/paytem.png" },
  { id: 16, name: "Logo 16", image: "/assets/plat.png" },
  { id: 17, name: "Logo 17", image: "/assets/samsung.png" },
  { id: 18, name: "Logo 17", image: "/assets/spot.png" },
  { id: 19, name: "Logo 17", image: "/assets/thums.png" },
  { id: 20, name: "Logo 17", image: "/assets/western.png" },
  { id: 21, name: "Logo 17", image: "/assets/zee.png" },


];

const SliderHome = () => {
  const commonSettings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 2 },
      },
    ],
  };

  const slider1Settings = { ...commonSettings, rtl: false }; // Left to right
  const slider2Settings = { ...commonSettings, rtl: true }; // Right to left

  return (
    <div className="py-2 md:py-20 lg:py-20 bg-whit mt-10">
      {/* Title */}
      <div className="text-center mb-10">
        <h2 className="text-4xl md:text-5xl font-bold">
        Clientele
        </h2>
        <p className="text-gray-600 text-lg">
         Leading brands and agencies that trust Bidbox.ai for precision advertising.
        </p>
      </div>

      {/* Two Sliders */}
      <div className="space-y-8">
        {/* Slider 1 */}
        <Slider {...slider1Settings} className="px-4 lg:px-20">
          {logos.map((logo) => (
            <div key={logo.id} className="p-4">
              <div className="bg-white shadow-lg rounded-lg flex justify-center items-center">
                <img
                  src={logo.image}
                  alt={logo.name}
                  className="object-contain h-28 w-28"
                />
              </div>
            </div>
          ))}
        </Slider>

        {/* Slider 2 */}
        <Slider {...slider2Settings} className="px-4 lg:px-20">
          {logos.map((logo) => (
            <div key={logo.id} className="p-4">
              <div className="bg-white shadow-lg rounded-lg flex justify-center items-center">
                <img
                  src={logo.image}
                  alt={logo.name}
                  className="object-contain h-28 w-28"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SliderHome;
