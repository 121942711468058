import { useState, useEffect } from "react";

const HeroCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      heading: "1 Billion+  User Profiles ",
      description:
        "Bidbox.ai integrates with leading Data Management Platforms (DMPs) to analyze vast datasets, enabling advertisers to precisely target high-intent users. With access to over 1 billion user profiles, brands can create hyper-personalized campaigns based on demographics, behavior, and real-time interactions, ensuring ads reach the most relevant audiences for maximum engagement and conversions.",
      image: "/assets/home1.png",
    },
    {
      heading: "Gigantic Inventories ",
      description:
        "By partnering with top Supply-Side Platforms (SSPs), Bidbox.ai offers advertisers access to a vast inventory of high-quality ad placements across premium publishers, streaming platforms, and mobile networks. This ensures brand-safe environments, optimal reach, and competitive pricing, allowing brands to efficiently distribute their ads at scale while maximizing their return on ad spend (ROAS).",
      image:  "/assets/iven.png",
    },
    {
      heading: "Exclusive OEM  Partnerships",
      description:
        " Bidbox.ai collaborates with leading Original Equipment Manufacturers (OEMs) to provide exclusive advertising opportunities on smart TVs, mobile devices, and in-app ecosystems. These premium placements allow brands to deliver non-intrusive, full-screen, and interactive ad experiences, ensuring high visibility and engagement from tech-savvy, media-consuming audiences.",
      image: "/assets/partner.png",
    },
    {
      heading: "CTV &  Omnichannel Expertise",
      description:
        " With deep expertise in Connected TV (CTV) and omnichannel advertising, Bidbox.ai enables brands to create cohesive, cross-platform campaigns that engage users across CTV, mobile, web, and social channels. Our AI-driven technology optimizes ad placement, frequency, and personalization, ensuring consistent messaging, better brand recall, and increased conversions across all touchpoints.",
      image: "/assets/ctv.png",
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 2000);
    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <section className="relative overflow-hidden">
      <div
        className={`flex flex-col md:flex-row items-center justify-between px-6 md:px-16 py-12 bg-white transition-all duration-500 ${
          currentSlide % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
        }`}
      >
        {/* Left Content */}
        <div className="w-full md:w-[45%] text-center md:text-left flex flex-col justify-center">
          {/* Icon */}
          <div className="w-10 h-10 bg-gray-200 flex items-center justify-center rounded-md mb-4 mx-auto md:mx-0">
            <span className="text-blue-500 text-lg">⚡</span>
          </div>

          {/* Heading */}
          <h1 className="text-3xl md:text-5xl font-bold text-gray-900 leading-tight">
            {slides[currentSlide].heading.split(" ").map((word, i) =>
              i === 1 ? (
                <span key={i} className=" bg-gradient-to-r from-purple-600 to-blue-600 px-2 rounded-md text-white">
                  {word}
                </span>
              ) : (
                word +  " "
              )
            )}
          </h1>
          {/* Description */}
          <p className="text-gray-600 text-base md:text-lg mt-4 leading-relaxed">
            {slides[currentSlide].description}
          </p>

          {/* Button - Fixed for Mobile Responsiveness */}
         
        </div>

        {/* Right Image - Increased Height */}
        <div className="w-full md:w-[50%] flex justify-center">
          <img
            src={slides[currentSlide].image}
            alt="Tech Solutions"
            className="w-full max-w-[550px] h-auto md:h-[400px] object-contain  transition-opacity duration-500"
          />
        </div>
      </div>

      {/* Navigation Dots */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              currentSlide === index ? "bg-indigo-600 w-6" : "bg-gray-300 hover:bg-gray-400"
            }`}
          />
        ))}
      </div>
    </section>
  );
};

export default HeroCarousel;


// import { useState, useEffect } from "react";

// const HeroCarousel = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const slides = [
//     {
//       heading: "Exclusive Inventories",
//       description:
//         "A marketing agency is a company that helps businesses plan, create, and execute marketing strategies. They can also help evaluate marketing campaigns.",
//       image: "/image1.png",
//     },
//     {
//       heading: "Gigantic Inventories",
//       description:
//         "A marketing agency is a company that helps businesses plan, create, and execute marketing strategies. They can also help evaluate marketing campaigns.",
//       image: "/image1.png",
//     },
//     {
//       heading: "1 Billion+ User Profiles",
//       description:
//         "A marketing agency is a company that helps businesses plan, create, and execute marketing strategies. They can also help evaluate marketing campaigns.",
//       image: "/image1.png",
//     },
//     {
//       heading: "Expertise",
//       description:
//         "A marketing agency is a company that helps businesses plan, create, and execute marketing strategies. They can also help evaluate marketing campaigns.",
//       image: "/image1.png",
//     },
//   ];

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentSlide((prev) => (prev + 1) % slides.length);
//     }, 2000);
//     return () => clearInterval(timer);
//   }, []);

//   const nextSlide = () => {
//     setCurrentSlide((prev) => (prev + 1) % slides.length);
//   };

//   const prevSlide = () => {
//     setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
//   };

//   return (
//     <section className="relative overflow-hidden">
//       <div
//         className={`flex flex-col md:flex-row items-center justify-between px-6 md:px-12 py-12 bg-white transition-all duration-500 ${
//           currentSlide % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
//         }`}
//       >
//         {/* Left Content */}
//         <div className="w-full md:w-[45%] text-center md:text-left flex flex-col justify-center">
//           {/* Icon */}
//           <div className="w-10 h-10 bg-gray-200 flex items-center justify-center rounded-md mb-4 mx-auto md:mx-0">
//             <span className="text-blue-500 text-lg">⚡</span>
//           </div>

//           {/* Heading */}
//           <h1 className="text-3xl md:text-5xl font-bold text-gray-900 leading-tight">
//             {slides[currentSlide].heading.split(" ").map((word, i) =>
//               i === 1 ? (
//                 <span key={i} className="bg-blue-200 px-2 rounded-md">
//                   {word}
//                 </span>
//               ) : (
//                 word + " "
//               )
//             )}
//           </h1>

//           {/* Description */}
//           <p className="text-gray-600 text-base md:text-lg mt-2 leading-relaxed">
//             {slides[currentSlide].description}
//           </p>

//           {/* Button - Reduced Width */}
//           <button className="mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md w-36">
//             Know More
//           </button>
//         </div>

//         {/* Right Image - Increased Height */}
//         <div className="w-full md:w-[50%] flex justify-center">
//           <img
//             src={slides[currentSlide].image}
//             alt="Tech Solutions"
//             className="w-full max-w-[550px] h-auto md:h-[400px] object-contain rounded-xl shadow-lg transition-opacity duration-500"
//           />
//         </div>
//       </div>

//       {/* Navigation Dots */}
//       <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
//         {slides.map((_, index) => (
//           <button
//             key={index}
//             onClick={() => setCurrentSlide(index)}
//             className={`w-3 h-3 rounded-full transition-all duration-300 ${
//               currentSlide === index ? "bg-indigo-600 w-6" : "bg-gray-300 hover:bg-gray-400"
//             }`}
//           />
//         ))}
//       </div>
//     </section>
//   );
// };

// export default HeroCarousel;



// import { useState, useEffect } from 'react';
// import { ChevronLeft, ChevronRight } from 'lucide-react';

// const HeroCarousel = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const slides = [
//     {
//       heading: "1 Billion+ User Profiles",
//       description: "DMP Partnerships.",
//       image: "/image1.png",
//     },
//     {
//       heading: "Gigantic Inventories ",
//       description: "SSPs Partnerships",
//       image: "/image1.png",
//     },
//     {
//       heading: "Exclusive Inventories",
//       description: " OEM Partnerships",
//       image: "/image1.png",
//     },
//     {
//       heading: "Expertise ",
//       description: " (CTV Advertising)",
//       image: "/image1.png",
//     }
//   ];

//   // Auto-advance slides
//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentSlide((prev) => (prev + 1) % slides.length);
//     }, 5000);
//     return () => clearInterval(timer);
//   }, []);

//   const nextSlide = () => {
//     setCurrentSlide((prev) => (prev + 1) % slides.length);
//   };

//   const prevSlide = () => {
//     setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
//   };

//   const goToSlide = (index) => {
//     setCurrentSlide(index);
//   };

//   return (
//     <section className="relative overflow-hidden">
//       <div className="flex flex-col md:flex-row items-center justify-between px-6 md:px-16 py-12 bg-white">
//         {/* Left Content */}
//         <div className="md:w-1/2 text-center md:text-left">
//           {/* Icon */}
//           <div className="w-12 h-12 bg-gray-100 flex items-center justify-center rounded-lg mb-4">
//             <span className="text-blue-500 text-xl">⚡</span>
//           </div>

//           {/* Heading */}
//           <h1 className="text-3xl md:text-5xl font-bold textColor">
//             {slides[currentSlide].heading.split(' ').map((word, i) => 
//               i === 1 ? <span key={i} className="bg-indigo-100 px-2 rounded-md">{word} </span> : word + ' '
//             )}
//           </h1>

//           {/* Description */}
//           <p className="text-gray-600 text-lg mt-4">
//             {slides[currentSlide].description}
//           </p>

//           {/* Button */}
//           <button className="mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 px-6 rounded-lg">
//             Know More
//           </button>
//         </div>

//         {/* Right Image */}
//         <div className="md:w-1/2 mt-10 md:mt-0 relative">
//           <img
//             src={slides[currentSlide].image}
//             alt="Tech Solutions"
//             className="w-full rounded-lg shadow-md transition-opacity duration-500"
//           />
//         </div>
//       </div>

//       {/* Navigation Arrows */}
//       <button 
//         onClick={prevSlide}
//         className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg"
//       >
//         <ChevronLeft className="w-6 h-6 text-gray-800" />
//       </button>
//       <button 
//         onClick={nextSlide}
//         className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg"
//       >
//         <ChevronRight className="w-6 h-6 text-gray-800" />
//       </button>

//       {/* Navigation Dots */}
//       <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
//         {slides.map((_, index) => (
//           <button
//             key={index}
//             onClick={() => goToSlide(index)}
//             className={`w-3 h-3 rounded-full transition-all duration-300 ${
//               currentSlide === index 
//                 ? 'bg-indigo-600 w-6' 
//                 : 'bg-gray-300 hover:bg-gray-400'
//             }`}
//           />
//         ))}
//       </div>
//     </section>
//   );
// };

// export default HeroCarousel;