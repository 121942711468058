import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import { Plus, Minus, ChevronDown } from "lucide-react";
import ServiceVideoStory from "./ServiceVideoStory";
import { Helmet } from "react-helmet";
const VideoStorytellingAds = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Handle screen resize for responsive behaviors
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const togglePanel = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const caseStudies = [
    {
      id: 1,
      image:
        "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
      category: "CASE STUDY",
      title: "Old Navy leans into decisioned upfronts with live sports",
      stat1: "1.2M",
      desc1:
        "incremental households reached with live sports by excluding users reached via programmatic guaranteed",
      stat2: "70%",
      desc2:
        "reduction in cost per unique household on live sports versus programmatic guaranteed",
      link: "#",
    },
    {
      id: 2,
      image:
        "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
      category: "CASE STUDY",
      title: "Brand X improves digital advertising efficiency",
      stat1: "850K",
      desc1:
        "users engaged through targeted ads leading to better conversion rates",
      stat2: "60%",
      desc2: "increase in ROI from programmatic advertising strategies",
      link: "#",
    },
  ];

  const content = [
    {
      title: "Short-Form & Long-Form Video Ads",
      description:
        "Tailor video content to different audience segments for maximum impact.",
    },
    {
      title: "AI-Powered Personalization",
      description:
        "Serve customized video ads based on user preferences and behavior.",
    },
    {
      title: "Interactive & Shoppable Video Ads",
      description:
        "Enhance engagement with click-to-action and commerce-enabled video formats.",
    },
    {
      title: "OTT, CTV & Mobile Video",
      description:
        "Deliver seamless video experiences across premium streaming platforms.",
    },
    {
      title: "High-Impact Pre-Roll & Mid-Roll Placements",
      description: "Engage users at key moments with premium video ad slots.",
    },
    {
      title: "Advanced Performance Analytics",
      description:
        "Track completion rates, engagement, and brand lift with real-time insights.",
    },
  ];
  // Animation variants
  const fadeInVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const staggerItem = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const scaleUp = {
    initial: { scale: 1 },
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };
  return (
    <>
      <Helmet>
        <title>Engage Your Audience with Video & Storytelling Ads – BidBox AI</title>
        <meta name='description' content='Capture attention and drive engagement with compelling video and storytelling ads, leveraging BidBox AI’s premium digital ad placements and data-driven strategies.' />
        <meta name='keywords' content='Video advertising, storytelling ads, brand storytelling, video marketing strategy, programmatic video, in-stream ads, out-stream ads, digital storytelling, video branding, emotional marketing, video content engagement, social video campaigns, YouTube ads, OTT video ads, high-impact video creatives.' />               
    </Helmet>
      {/* <div
      className="relative flex items-center justify-center h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('https://t4.ftcdn.net/jpg/09/49/36/63/240_F_949366383_tyZF61HUtUectJuzOQGxzUW8vjWXVO1b.jpg')" }}
    >
      <div className="bg-white/20 backdrop-blur-md p-8 rounded-lg shadow-lg text-center max-w-lg">
        <h1 className="text-4xl font-bold text-white mb-4">Welcome to Our Platform</h1>
        <p className="text-white text-lg mb-6">
          Explore amazing features and elevate your experience with our services.
        </p>
        <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-105 transition transform duration-300">
          Get Started
        </button>
      </div>
    </div> */}
      {/* <div class="flex min-h-screen justify-center items-center dark:bg-slate-800 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            type: "spring",
            stiffness: 50,
          }}
          class="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8"
        >
          <div class="text-center ">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              class="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
            >
              <span class="block xl:inline">
                <span class="mb-1 block">Captivate, Engage & Inspire </span>
                <motion.span
                  initial={{ backgroundPosition: "0%" }}
                  animate={{ backgroundPosition: "100%" }}
                  transition={{
                    repeat: Infinity,
                    duration: 3,
                    ease: "linear",
                    repeatType: "reverse",
                  }}
                  class="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                >
                  with Video Storytelling
                </motion.span>
              </span>
              <div class="mt-2">
                10X Efficiency
                <span class="relative mt-3 whitespace-nowrap text-blue-600">
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 418 42"
                    class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
                    preserveAspectRatio="none"
                  >
                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                  </svg>
                  <span class="relative">with Bidbox Ai</span>
                </span>
              </div>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              class="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
            >
           Harness the power of immersive video ads to captivate audiences, spark emotions, and leave a lasting impression. With a blend of dynamic visuals, compelling narratives, and strategic placement, video ads go beyond traditional advertising—they tell stories that resonate.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              class="mt-5 sm:mt-8 sm:flex sm:justify-center"
            >
              <motion.div
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                class="rounded-md shadow"
              >
                <a
                  class="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                  href="/contact"
                >
                  Get started
                </a>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div> */}
        <div className="flex min-h-[100dvh] justify-center items-center dark:bg-slate-800 overflow-x-hidden">
                   <motion.div
                     initial={{ opacity: 0, scale: 0.8 }}
                     animate={{ opacity: 1, scale: 1 }}
                     transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
                     className="mx-auto flex h-full min-h-[100dvh] w-full items-center justify-center px-4 sm:px-6 lg:px-8"
                   >
                     <div className="text-center max-w-4xl">
                       <motion.h1
                         initial={{ opacity: 0, y: -20 }}
                         animate={{ opacity: 1, y: 0 }}
                         transition={{ delay: 0.3, duration: 0.5 }}
                         className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
                         style={{ WebkitTransform: "translateY(0px)", transform: "translateY(0px)" }}
                       >
                         <span className="block xl:inline">
                           <span className="mb-1 block">Captivate, Engage & Inspire</span>
                           <motion.span 
                             initial={{ backgroundPosition: "0%" }}
                             animate={{ backgroundPosition: "100%" }}
                             transition={{ repeat: Infinity, duration: 3, ease: "linear", repeatType: "reverse" }}
                             className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                           >
                       with Video Storytelling
                           </motion.span>
                           <p className="mb-1 block">10X Efficiency<span className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">with Bidbox.Ai</span> </p>
                         </span>
                       </motion.h1>
                       
                       <motion.p
                         initial={{ opacity: 0 }}
                         animate={{ opacity: 1 }}
                         transition={{ delay: 0.6, duration: 0.5 }}
                         className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
                       >
     Harness the power of immersive video ads to captivate audiences, spark emotions, and leave a lasting impression. With a blend of dynamic visuals, compelling narratives, and strategic placement, video ads go beyond traditional advertising—they tell stories that resonate.</motion.p>
                 
                       <motion.div
                         initial={{ opacity: 0, y: 20 }}
                         animate={{ opacity: 1, y: 0 }}
                         transition={{ delay: 0.8, duration: 0.5 }}
                         className="mt-5 sm:mt-8 sm:flex sm:justify-center"
                       >
                         <motion.div 
                           variants={scaleUp}
                           initial="initial"
                           whileHover="hover" 
                           className="rounded-md shadow"
                         >
                           <a
                             className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                             href="/contact"
                           >
                             Get started 
                           </a>
                         </motion.div>
                       </motion.div>
                     </div>
                   </motion.div>
                 </div>
      <div className="bg-white py-12 px-4 md:px-6">
        <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
          {/* Image on Left */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0  opacity-20 rounded-lg"></div>
            <motion.img
              variants={{
                initial: { scale: 1, rotateY: 0 },
                hover: { scale: 1.05, rotateY: 10 },
                float: {
                  y: [0, -10, 0], // Floating effect
                  transition: {
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut"
                  }
                }
              }}
              initial="initial"
              whileHover="hover"
              animate="float"
              src="/assets/video.png"
              alt="Connected TV"
              className="w-full h-auto md:h-96 object-cover  "
            />
          </motion.div>

          {/* Text on Right */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
              Why Video? The Most Powerful Engagement Tool
            </h2>
            <p className="text-gray-700 text-lg md:text-lg leading-relaxed mb-6">
            In today’s fast-paced digital landscape, capturing consumer attention is more challenging than ever. Video storytelling cuts through the clutter, delivering visually compelling narratives that spark emotions, reinforce brand identity, and drive consumer action. With a combination of motion, sound, and storytelling, video ads hold attention longer, enhance message retention, and create a deeper connection with audiences than static formats.
            </p>
            
          </motion.div>
        </div>

        {/* SECOND SECTION - Alternating Layout */}
        <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-12 mt-16">
          {/* Image on Right */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0 opacity-20 rounded-lg"></div>
            <motion.img
             variants={{
              initial: { scale: 1, rotateY: 0 },
              hover: { scale: 1.05, rotateY: 10 },
              float: {
                y: [0, -10, 0], // Floating effect
                transition: {
                  duration: 3,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut"
                }
              }
            }}
            initial="initial"
            whileHover="hover"
            animate="float"
              src="/assets/video1.png"
              alt="CTV Advertising"
              className="w-full h-auto md:h-96 object-cover "
            />
          </motion.div>

          {/* Text on Left */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
              Why Video Storytelling? Build Emotional Connections & Lasting
              Impact{" "}
            </h2>
            <p className="text-gray-700 text-lg md:text-lg leading-relaxed mb-6">
            Consumers don’t just buy products—they connect with brands that tell stories. Whether through cinematic storytelling, bite-sized social videos, or interactive video formats, brands can craft meaningful, engaging experiences that foster trust and loyalty. Video ads provide multi-sensory engagement, making them twice as effective in brand recall compared to static ads. With the right storytelling, brands can leave a lasting impression, drive conversions, and build a community of loyal advocates.
            </p>
          
          </motion.div>
        </div>
      </div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="max-w-4xl mx-auto text-center mt-20"
      >
        <h2 className="text-4xl md:text-5xl font-bold mb-6">
          AI-Optimized Video Advertising with Bidbox.Ai
        </h2>
        <p className="text-lg text-gray-600 mb-12">
        BidBox revolutionizes video advertising with precision targeting, premium placements, and real-time optimizations. Our AI-driven platform ensures that video ads are delivered to the right audience, at the right time, on the right device, maximizing engagement and ROI. With seamless integration across CTV, mobile, and web, brands achieve higher viewability, increased retention, and measurable brand impact. Whether it's programmatic video, interactive formats, or dynamic creative optimization, BidBox.Ai ensures your storytelling reaches its full potential. 
        </p>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="px-4 md:px-10"
      >
        <div className="mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-6 md:px-8 rounded-lg shadow-lg mt-6 mb-6">
          <div className="container mx-auto flex flex-col md:flex-row items-center text-white text-center md:text-left space-y-8 md:space-y-0 md:space-x-8">
            {/* Column 1 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">
              Completion Rate (60-80%)
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
              Ensure your message is fully delivered, with higher completion rates, especially for short-form videos, driving impactful brand storytelling.
              </p>
            </motion.div>
            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>{" "}
            {/* Mobile Divider */}
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>{" "}
            {/* Desktop Divider */}
            {/* Column 2 */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">
              10x Higher Engagement
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
              Video ads captivate audiences, generating up to ten times more interaction than static creatives, fostering deeper connections with your brand.
              </p>
            </motion.div>
            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>{" "}
            {/* Mobile Divider */}
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>{" "}
            {/* Desktop Divider */}
            {/* Column 3 */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">
              95% Brand Recall
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
              Leave a lasting impression—video storytelling achieves a remarkable 95% recall rate, far surpassing the 10% retention of text-based ads.
              </p>
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* <div className="w-full min-h-screen flex flex-col items-center px-6 py-12 bg-black text-white">
       
        <motion.h1
          initial={{ opacity: 0, y: -30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="text-4xl md:text-6xl font-extrabold text-center max-w-4xl neon-text"
        >
          Bring Your Brand
          <br /> Story to Life
        </motion.h1>

       
        <div className="w-full flex justify-center my-10">
          <motion.img
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
            src="https://img.freepik.com/free-photo/futuristic-metaverse-empty-room-product-display-presentation-abstract-technology-scifi-with-neon-light-3d-background_56104-2314.jpg?semt=ais_hybrid"
            alt="Brand Awareness"
            className="w-full md:w-[600px] rounded-3xl shadow-2xl border-2 border-gray-700 backdrop-blur-lg"
          />
        </div>

        
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          className="w-full max-w-4xl space-y-4"
        >
          {content.map((item, index) => (
            <motion.div
              key={index}
              variants={staggerItem}
              className="bg-gradient-to-r from-gray-900 to-gray-800 text-white rounded-xl shadow-xl p-5 relative transition-all duration-300"
              whileHover={{ scale: 1.03, transition: { duration: 0.3 } }}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <button
                className="w-full flex justify-between items-center text-lg font-semibold tracking-wide hover:text-blue-400 transition"
                onClick={() => togglePanel(index)}
              >
                <span>{item.title}</span>
                <motion.div
                  initial={{ rotate: 0 }}
                  animate={{ rotate: openIndex === index ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ChevronDown size={24} />
                </motion.div>
              </button>

              <AnimatePresence>
                {(openIndex === index ||
                  (screenWidth >= 768 && hoverIndex === index)) && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden mt-3"
                  >
                    <p className="text-gray-300">{item.description}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </motion.div>

   
        <style>
          {`
            .neon-text {
              text-shadow: 0 0 10px #00bcd4, 0 0 20px #00bcd4, 0 0 40px #00bcd4;
              animation: pulse 2s infinite alternate;
            }
            
            @keyframes pulse {
              0% {
                text-shadow: 0 0 10px #00bcd4, 0 0 20px #00bcd4, 0 0 40px #00bcd4;
              }
              100% {
                text-shadow: 0 0 15px #00bcd4, 0 0 25px #00bcd4, 0 0 45px #00bcd4;
              }
            }
          `}
        </style>
      </div> */}
<ServiceVideoStory/>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="w-full flex justify-center mt-10"
      >
        <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
          className="w-[95%] mb-20 h-[320px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden"
        >
          <div className="p-5 md:p-10 z-10 flex justify-between">
            <div className="flex flex-col justify-between">
              <div>
                <motion.h1
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  viewport={{ once: true }}
                  className="text-white font-bold text-xl md:text-5xl"
                >
                  Ready to Inspire with Video?
                </motion.h1>
                <motion.h2
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  viewport={{ once: true }}
                  className="text-gray-300  text-xl md:text-xl py-2"
                >
                  Launch data-driven, high-impact video storytelling ads today
                  and create lasting connections with your audience—everywhere
                  they watch.
                </motion.h2>
              </div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                viewport={{ once: true }}
              >
                <a href="/contact">
                  <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                    Get Started Now
                  </button>
                </a>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </motion.div>

      {/* Case Studies */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.4 }}
        className="max-w-7xl mx-auto py-12 px-4"
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-3xl md:text-4xl font-bold text-center mb-10"
        >
          Success Stories
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {caseStudies.map((study, index) => (
            <motion.div
              key={study.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
              whileHover={{
                scale: 1.03,
                boxShadow:
                  "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
              }}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300"
            >
              {/* Top: Image with overlay effect */}
              <div className="w-full relative overflow-hidden">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                  src={study.image}
                  alt="Case Study"
                  className="w-full h-48 md:h-64 object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
                <div className="absolute bottom-0 left-0 p-4">
                  <span className="text-xs font-bold text-white bg-blue-600 px-2 py-1 rounded">
                    {study.category}
                  </span>
                </div>
              </div>

              {/* Bottom: Text Content */}
              <div className="p-6 text-center">
                <h3 className="text-xl font-bold mt-2">{study.title}</h3>
                <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-8 mt-4">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">
                      {study.stat1}
                    </p>
                    <p className="text-gray-600 text-sm">{study.desc1}</p>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">
                      {study.stat2}
                    </p>
                    <p className="text-gray-600 text-sm">{study.desc2}</p>
                  </motion.div>
                </div>
                <motion.a
                  whileHover={{ scale: 1.1 }}
                  href={study.link}
                  className="text-blue-600 font-semibold mt-6 inline-block border-b-2 border-transparent hover:border-blue-600"
                >
                  READ CASE STUDY
                </motion.a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export default VideoStorytellingAds;
