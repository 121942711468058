import React, { useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { 
  ChevronLeft, 
  ChevronRight,
  ArrowRight,
  Globe,
  BarChart3,
  Users,
  Target,
  TrendingUp,
  Layers,
  Award,
  Activity ,
  Eye,
  MonitorPlay
} from 'lucide-react';
import { Link } from 'react-router-dom';

const ServiceBrandAware = () => {
  const { scrollY } = useScroll();
  const [activeTab, setActiveTab] = useState(0);

  const services = [
    {
      icon: <Target  className="w-8 h-8" />,
      title: "Precision-Powered Targeting",
      description: "We go beyond demographics. Our AI-driven approach uses audience behavior, intent signals, and privacy-compliant data to maximize impact.",
    //   stat: "Learn More",
      color: "from-blue-600 to-cyan-500"
      
    },
    {
      icon: <BarChart3 className="w-8 h-8" />,
      title: "AI-Optimized Performance",
      description: "BidBox’s intelligent algorithms refine your campaign in real-time, ensuring every ad dollar is spent for maximum efficiency and impact.",
    //   stat: "Learn More",
      color: "from-purple-600 to-pink-500"
   
    },
    {
      icon: <MonitorPlay className="w-8 h-8" />,
      title: "Premium Inventory Access",
      description: "Your brand deserves the best. BidBox places your ads alongside high-quality, trusted content for credibility, impact, and superior engagement.",
    //   stat: "Learn More",
      color: "from-orange-500 to-red-500"
     
    },
    {
        icon: <Layers  className="w-8 h-8" />,
        title: "Multi-Channel Reach & Brand Visibility",
        description: "Boost visibility with interactive video ads, dynamic rich media, and expandable banners. Strengthen brand recall through compelling, story-driven contents. ",
        // stat: "Learn More",
        color: "from-orange-500 to-red-500"
       
      },
      {
        icon: <Activity className="w-8 h-8" />,
        title: "Engaging & High-Impact Creative",
        description: "Make your brand unforgettable with interactive video ads, dynamic rich media, and compelling narrative-driven content that captivates and engages audiences.",
        // stat: "Learn More",
        color: "from-purple-600 to-pink-500"

      },
      {
        icon: <BarChart3 className="w-8 h-8" />,
        title: "Real-Time Measurement & Insights",
        description: "Measure and refine your brand impact with brand lift analysis, audience engagement tracking, and precise geographical and demographic insights to optimize performance.",
        // stat: "Learn More",
        color: "from-blue-600 to-cyan-500"
      
      },
      {
        icon: <Users className="w-8 h-8" />,
        title: "Strategic Frequency Optimization",
        description: "Strike the perfect balance between exposure and repetition. Avoid oversaturation while reinforcing recall, ensuring an optimal brand presence without ad fatigue.",
        // stat: "Learn More",
        color: "from-blue-600 to-cyan-500"
     
      },
      {
        icon: <Globe className="w-8 h-8" />,
        title: "Seamless Cross-Device Experience",
        description: "Ensure a cohesive brand journey across CTV, mobile, web, and social platforms. BidBox enables synchronized delivering, high-impact storytelling at every touchpoint. ",
        // stat: "Learn More",
        color: "from-purple-600 to-pink-500"

      },
      {
        icon: <Eye className="w-8 h-8" />,
        title: "Contextually Relevant Placements",
        description: "Align your brand with premium content that resonates with your audience. Our AI-driven contextual targeting places ads within the most relevant and high-performing environments.",
        // stat: "Learn More",
        color: "from-orange-500 to-red-500"
      
      },
      
      
      
  ];

  const features = [
    { title: "Dynamic Optimization", value: "93%", subtitle: "Campaign Success Rate" },
    { title: "Global Coverage", value: "200+", subtitle: "Countries Reached" },
    { title: "Client Satisfaction", value: "98%", subtitle: "Retention Rate" },
    { title: "ROI Improvement", value: "180%", subtitle: "Average Increase" }
  ];

  const statsY = useTransform(scrollY, [0, 300], [100, 0]);
  const statsOpacity = useTransform(scrollY, [0, 300], [0, 1]);

  return (
    <div className="w-full">
      {/* Services Section */}
      <section className="py-20 px-6 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
            Comprehensive Brand Awareness Solutions

            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Elevate your brand’s presence with BidBox’s advanced advertising solutions designed to captivate, engage, and leave a lasting impression. From precision-targeted campaigns to immersive creative formats, we ensure maximum visibility and brand recall across premium digital environments.            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {services.map((service, index) => (
                <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }} // Hover effect
                className="relative group"
              >
                <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:bg-gray-100">
                  <div className={`absolute inset-x-0 h-2 top-0 rounded-t-2xl bg-gradient-to-r ${service.color}`} />
                  <div className="mb-6 text-blue-600">{service.icon}</div>
                  <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <div className="flex items-center justify-between">
                  <Link to={service.link} className="text-lg font-semibold text-blue-600 hover:underline">
    {service.stat}
  </Link>
                    {/* <ArrowRight className="w-5 h-5 text-gray-400 group-hover:text-blue-600 transition-colors" /> */}
                  </div>
                </div>
              </motion.div>
              
           
            ))}
          </div>
        </div>
      </section>

   
    </div>
  );
};

export default ServiceBrandAware;