import React from "react";
import Banner from "../Banner";
import CaseStudyCard from "./CaseStudyCard";
import { Helmet } from "react-helmet";

const Others = () => {
  return (
    <>
      {/* <Helmet>
        <title>Others – BidBox AI Case Study</title>
        <meta name='description' content='Learn how top electronics brands enhanced brand awareness, customer engagement, and sales through advanced programmatic advertising and audience segmentation.' />
        <meta name='keywords' content='Electronics advertising, tech product marketing, gadget launch promotions, home appliances ads, consumer electronics branding, online sales strategy for tech, AI-powered ad targeting, omnichannel advertising for electronics, digital shelf placement, e-commerce tech campaigns, influencer marketing for electronics, product demo ads, CTV ads for gadgets, customer acquisition for electronics brands.' />               
    </Helmet> */}
      <div className="w-full">
        <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
          <h1 className="text-3xl md:text-6xl font-bold  text-center mt-14">
            Others Case Study
          </h1>
        </div>

        <div className="w-full overflow-hidden">
          <div className="w-full bg-white flex flex-col items-center">
            <div className="w-[95%] my-10">
              <h1 className="text-3xl md:text-5xl font-bold text-center">
                Explore Diverse Case Studies
              </h1>
              <p className="text-center py-5 text-xl font-medium">
                Dive into success stories spanning various industries and
                verticals to see how we deliver value.
              </p>

              <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5 justify-center md:justify-between">
                <CaseStudyCard
                  image="/assets/lalamove22.png"
                  heading="Lalamove’s Seamless Drive to Success"
                  impression="Registrations: 3000+"
                  // vtr='Walk Ins -  86%↑ '
                  content="In a bid to strengthen its foothold in the on-demand logistics sector, Lalamove embarked on a strategic programmatic display ad campaign aimed at high-intent audiences. With an increasing demand for quick and efficient deliveries, the campaign was meticulously crafted to target key audience cohorts, ensuring precision in outreach and engagement."
                  content1="Through data-driven insights, we identified and engaged diverse segments, including families, lifestyle engagers, retailers, freelancers, SME owners, and online shoppers. This refined targeting approach ensured that the campaign reached those most likely to convert, making every impression count. By leveraging a CPR-based buying strategy, we optimized for registrations while maintaining cost efficiency. Additionally, mobile-first display ads were deployed to maximize accessibility and impact, catering to the digital-first behavior of Lalamove’s audience."
                  content2="The campaign delivered exceptional results within just two months, securing over 3,000 registrations. With a balanced gender split of 55% male and 45% female, and a dominant age group of 25-45 years, the strategy effectively engaged the ideal consumer base. These impressive metrics highlight not only the power of strategic ad placements but also the effectiveness of personalized messaging in the logistics industry."
                  content3="Lalamove’s success in this campaign underscores the impact of well-executed programmatic advertising. By combining precise targeting with continuous optimization, we successfully positioned Lalamove as a go-to solution for seamless, on-demand delivery services. This case study stands as a testament to how innovative ad strategies can drive tangible business outcomes in a highly competitive market."
                />
                <CaseStudyCard
                  image="/assets/gojek23.png"
                  heading="Driving Growth for Gojek’s Super App"
                  impression="Registrations: 1200+"
                  // vtr='CTR - 0.85%'
                  content="In an effort to strengthen its position as Indonesia’s leading multi-service platform, Gojek launched a strategic programmatic display campaign aimed at driving adoption across its ride-hailing, food delivery, and logistics services. By leveraging precise audience segmentation, we ensured maximum engagement from high-intent users, further boosting brand awareness and service utilization."
                  content1="Our campaign was designed to resonate with a diverse set of consumer cohorts, including families, lifestyle engagers, food enthusiasts, entrepreneurs, daily commuters, and online shoppers. By tailoring ad creatives to appeal to these specific groups, we ensured higher relatability and engagement. The campaign ran on a CPR model, optimizing registrations while maintaining cost efficiency. Mobile-first display ads were deployed to align with user behavior, ensuring seamless reach and interaction across devices."
                  content2="Over the course of just one month, the campaign generated over 1,200 registrations, showcasing its efficiency in driving service adoption. The audience demographic split was well-balanced, with 54% male and 46% female users engaging with the ads, while the dominant age group ranged between 20-40 years, aligning perfectly with Gojek’s core consumer base."
                  content3="This campaign’s success reaffirms the power of targeted programmatic advertising in accelerating brand growth and customer acquisition. Through data-driven optimization and dynamic ad placements, we successfully positioned Gojek as the go-to super app, offering seamless solutions for everyday needs. The results demonstrate how strategic digital marketing can create lasting impact in highly competitive service-based industries."
                />
                <CaseStudyCard
                  image="/assets/be28.png"
                  heading="Accelerating Growth for Be’s Ride-Hailing & Delivery Services"
                  impression="Acquisitions: 3500+"
                  // vtr='Clicks - 195K+'
                  content="To strengthen Be’s position in the competitive ride-hailing and parcel delivery market, we launched a data-driven programmatic display campaign focused on maximizing acquisitions. By leveraging precise audience segmentation, we successfully engaged high-intent users, driving conversions while maintaining cost efficiency."
                  content1="The campaign strategically targeted families, daily commuters, retailers, students, gig workers, and food enthusiasts—cohorts with a high demand for mobility and delivery services. By deploying mobile-first display ads with compelling creatives, we ensured seamless engagement across devices, enhancing Be’s brand recall and service adoption. The CPA-driven strategy was optimized for efficiency, ensuring every impression contributed to actual acquisitions."
                  content2="Running over four months, the campaign delivered exceptional results, driving 3,500 new acquisitions. The audience was well-balanced, with a 53:47 male-to-female ratio, and the dominant age group fell between 21-40 years—aligning perfectly with Be’s core user base."
                  content3="This campaign underscores the power of targeted digital advertising in expanding Be’s customer base and increasing platform adoption. Through meticulous optimization, personalized ad experiences, and strategic placements, we reinforced Be’s reliability as a go-to service for both ride-hailing and parcel delivery needs, ensuring long-term growth in a dynamic market."
                />
                <CaseStudyCard
                  image="/assets/jeeny24.png"
                  heading="Driving Growth for Jeeny’s Ride-Hailing & Delivery Services"
                  impression="Acquisitions: 1500+"
                //   vtr=""
                  content="To amplify Jeeny’s presence in the ride-hailing and delivery market, we implemented a programmatic display campaign designed to attract high-intent users and drive app installations. By leveraging precise audience targeting and optimized ad placements, we successfully enhanced engagement and increased user adoption."
                  content1="The campaign targeted families, daily commuters, retailers, students, gig workers, and food enthusiasts—segments with a strong reliance on mobility and delivery services. With mobile-first display ads and personalized creatives, we ensured a seamless experience, driving interactions and conversions. Our CPA-focused strategy was structured to deliver cost-effective acquisitions while maintaining a high engagement rate."
                  content2="Over two months, the campaign generated 1,500 new acquisitions, reinforcing Jeeny’s value proposition to its core audience. The campaign’s gender distribution was nearly balanced, with a 52:48 male-to-female ratio, while the dominant age group fell between 20-45 years. The use of mobile display ads ensured maximum reach and interaction, aligning perfectly with Jeeny’s digital-first approach."
                  content3="By executing a data-driven, targeted campaign, we successfully positioned Jeeny as a preferred ride-hailing and delivery service for its key audience segments. Through continuous optimization and strategic ad placements, we strengthened Jeeny’s market presence, ultimately driving long-term service adoption and user loyalty."
                />
                <CaseStudyCard
                  image="/assets/blibli25.png"
                  heading="Boosting Engagement for Mom & Kids Fair"
                  impression="Impressions: 15M+"
                   vtr="CTR: 1.14"
                  content="Blibli aimed to drive awareness and participation for the Mom & Kids Fair, ensuring maximum reach among relevant audience segments. Our programmatic display campaign strategically targeted families, lifestyle engagers, fashion enthusiasts, wellness seekers, online shoppers, and entertainment lovers, aligning with users most likely to engage with the event."
                  content1="The goal? Maximize event awareness and attendance. We focused on 21-45-year-olds (52% male, 48% female), leveraging the DSP Bidbox.ai to execute a precise digital strategy. Using data-driven audience insights, we optimized ad placements across mobile devices, ensuring seamless engagement through high-impact display ads. The creatives were tailored to resonate with parents and lifestyle-conscious consumers, encouraging participation."
                  content2="The campaign delivered 15 million+ impressions, leading to 45,450+ sessions and a strong 1.14% CTR, proving its effectiveness in driving engagement. Our real-time optimization tactics ensured that ads were served to users actively interested in parenting, fashion, and wellness, maximizing interaction and reducing wasted impressions."
                  content3="Through strategic execution, Blibli successfully positioned the Mom & Kids Fair at the forefront of its target audience. The campaign not only increased visibility but also reinforced Blibli’s credibility as a go-to platform for family-centric events, leveraging programmatic efficiency to connect with the right consumers at the right time."
                />
                 <CaseStudyCard
                  image="/assets/lazada26.png"
                  heading="Driving Engagement with Precision Targeting"
                  impression="Sessions: 45K+"
                //    vtr="CTR: 1.14"
                  content="Lazada aimed to strengthen its digital presence and drive high-value user sessions in the competitive Indonesian market. To achieve this, we executed a cost-per-session display ad campaign, ensuring optimal visibility and engagement among relevant audience segments."
                  content1="Our approach targeted families, lifestyle engagers, millennials, fashion enthusiasts, music lovers, and online shoppers, aligning with 21-45-year-olds (65% male, 35% female). Leveraging Bidbox.ai, we optimized ad placements across mobile and desktop devices, using audience behavior insights to drive users to the Lazada platform efficiently."
                  content2="The results? The campaign generated 45K+ sessions, maintaining a 35%+ MV rate while ensuring a bounce rate of less than 35%—a clear indication of high-quality traffic and strong engagement. Our programmatic buying strategy ensured cost efficiency, reaching the most relevant users with personalized creatives and seamless ad placements."
                  content3="By leveraging real-time data and continuous optimization, we successfully elevated Lazada’s brand presence while driving meaningful interactions. This campaign not only reinforced Lazada’s position in the e-commerce space but also demonstrated the power of precision targeting in boosting digital engagement."
                />
                 <CaseStudyCard
                  image="/assets/ticket27.png"
                  heading="Driving High-Quality Sessions for Tiket.com"
                  impression="Session: 75,222"
                //    vtr="CTR: 1.14"
                  content="Tiket.com, one of Indonesia’s leading travel platforms, aimed to boost user engagement and drive high-value sessions through a strategic digital campaign. The focus was on ensuring seamless execution without geo leakage while reaching audiences based on age and mobile carrier data. By implementing a highly targeted approach, the campaign successfully maximized user interactions across Indonesia."
                  content1="Powered by Bidbox.ai, an AI-driven Demand-Side Platform (DSP), the campaign leveraged precision-based push notification advertising. This approach enabled real-time optimization, ensuring the right audiences received relevant messaging at optimal times. With machine learning-driven segmentation, the campaign efficiently engaged users who were most likely to interact with Tiket.com’s offerings, ultimately leading to a surge in high-quality sessions."
                  content2="The results were a testament to the accuracy of the targeting and execution. Over 75,000 active sessions were recorded, emphasizing the effectiveness of data-driven advertising in delivering measurable outcomes. By utilizing a Cost-Per-Session (CPS) model, the campaign ensured that every interaction contributed to maximizing user engagement and brand impact."
                  content3="Through Bidbox.ai’s programmatic expertise, the campaign not only met but exceeded performance benchmarks, reinforcing Tiket.com’s position as a go-to platform for seamless travel bookings while demonstrating the power of AI-optimized digital advertising."
                />
              </div>
            </div>
          </div>
        </div>

        <Banner />
      </div>
    </>
  );
};

export default Others;
