import React from "react";
import CaseStudyCard from "./CaseStudyCard";
import { Helmet } from "react-helmet";

const Gaming = () => {
  return (
    <>
    <Helmet>
    <title>Fashion & Lifestyle Digital Advertising – BidBox AI Case Study</title>
    <meta name='description' content='Discover how leading fashion and lifestyle brands leveraged BidBox AIs programmatic advertising for brand awareness, engagement, and sales growth.' />
    <meta name='keywords' content='Fashion advertising, lifestyle brand marketing, programmatic fashion ads, apparel digital campaigns, beauty product promotion, influencer marketing for fashion, omnichannel retail advertising, luxury brand engagement, audience segmentation for lifestyle, e-commerce fashion campaigns, brand awareness for clothing, digital transformation in fashion, shoppable ads, dynamic creative optimization, customer retention in fashion.' />               
</Helmet>
    <div className="w-full">
      <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl font-bold  text-center mt-14">
          Fashion & Lifestyle
        </h1>
      </div>

      <div className="w-full overflow-hidden">
        <div className="w-full bg-white flex flex-col items-center">
          <div className="w-[95%] my-10">
            <h1 className="text-3xl md:text-5xl font-bold text-center">
              Explore Diverse Case Studies
            </h1>
            <p className="text-center py-5 text-xl font-medium">
              Dive into success stories spanning various industries and
              verticals to see how we deliver value.
            </p>

            <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5 justify-center md:justify-between">
              <CaseStudyCard
                image="/de.jpg"
                heading="A CTV Triumph"
                impression="Impressions 3 Million+"
                vtr="VTR 97% +"
                content="Diwali, the festival of lights, is synonymous with celebration, luxury, and timeless elegance—an ideal moment to showcase De Beers Forevermark’s exquisite jewelry collection. To elevate the brand’s presence during this festive season, we crafted a high-impact Connected TV (CTV) campaign that seamlessly blended sophistication with cutting-edge digital advertising."
                content1="Our strategy focused on precision audience targeting, ensuring De Beers Forevermark’s message reached high-intent consumers in premium households. A visually captivating L-band creative was designed to enhance brand recall while subtly complementing the grandeur of Diwali. By leveraging CTV’s immersive format, we delivered an experience that resonated deeply with luxury shoppers, reinforcing the brand’s exclusivity."
                content2='The campaign’s impact was nothing short of extraordinary. We achieved an exceptional View-Through Rate (VTR) exceeding 97%, a testament to the content’s compelling nature and the audience’s engagement. Additionally, the campaign garnered over 3 million views, amplifying De Beers Forevermark’s visibility and solidifying its position as the go-to luxury jewelry brand for festive gifting.'
                content3='This case study highlights the power of CTV advertising in transforming brand storytelling during culturally significant moments. By aligning cutting-edge ad formats with strategic targeting, we successfully positioned De Beers Forevermark at the heart of Diwali celebrations, capturing the spirit of the festival while delivering remarkable brand engagement.'

             />
              <CaseStudyCard
                image="/tata1.jpg"
                heading="Tata CLiQ - TimeVallée’s Luxury Branding Success"
                impression="Impressions 3 Million+"
                vtr="VTR 97% +"
                content="In a strategic branding campaign for Tata CLiQ - TimeVallée, we set out to enhance brand visibility and drive engagement among premium watch enthusiasts across India’s top six metropolitan cities. Our approach was meticulously designed to target high-net-worth individuals and connoisseurs of luxury timepieces, ensuring maximum impact and resonance."
                content1="To achieve this, we harnessed the power of visually captivating creatives and compelling ad copy, crafting a campaign that seamlessly blended sophistication with digital precision. By leveraging premium audience segments and strategic ad placements, we ensured that Tata CLiQ - TimeVallée's offerings reached the right consumers at the right time, enhancing both brand perception and consumer engagement."
                content2="The results exceeded expectations, with the campaign delivering substantial impressions and high engagement metrics. The significant volume of clicks reinforced Tata CLiQ - TimeVallée’s presence in the competitive Indian luxury market, positioning it as a premier destination for premium watch lovers."
                content3="This case study exemplifies our expertise in crafting tailored branding strategies for geo-targeted campaigns, demonstrating the power of precision marketing in elevating luxury brands. By aligning data-driven targeting with compelling storytelling, we successfully positioned Tata CLiQ - TimeVallée as the ultimate choice for discerning watch collectors in India’s most influential cities."

             />
              <CaseStudyCard
                image="/hm.jpg"
                heading="H&M’s Fashion Forward Triumph in India"
                impression="Impressions: 8 Million+"
                vtr="Viewability: 68%"
                content="In our strategic branding initiative for H&M, we set out to elevate the brand’s presence and drive meaningful engagement in India’s dynamic and ever-evolving fashion market. Our approach was centered on delivering maximum impressions and clicks, ensuring that H&M’s latest collections captivated fashion-conscious audiences across the country."
                content1="By curating visually compelling creatives and crafting enticing ad copy, we seamlessly connected with diverse consumer segments, reinforcing H&M’s identity as a trendsetter in the fast-fashion space. Leveraging strategic ad placements and data-driven targeting, we ensured optimal visibility across premium digital platforms, sparking curiosity and engagement among potential shoppers."
                 content2='The campaign’s success was evident in the substantial surge in impressions and high engagement rates, solidifying H&M’s foothold in the Indian retail landscape. This case study highlights our expertise in executing high-impact branding strategies that not only resonate with a culturally diverse audience but also create lasting brand recall.'
                 content3='Through this initiative, we demonstrated our ability to craft campaigns that transcend borders, effectively positioning H&M as a go-to fashion destination in India while shaping the future of digital branding in the retail industry.'

              />
                <CaseStudyCard
                image="/assets/clove.jpg"
                heading="Transforming Patient Acquisition with Strategic Digital Marketing"
                impression="Impressions: 8 Million+"
                vtr="Viewability: 68%"
                content="To strengthen Clove Dental’s digital presence and drive patient growth, we implemented a tailored marketing strategy that combined targeted digital campaigns, strategic content, and personalized outreach. This approach not only enhanced brand visibility but also resulted in a significant increase in patient leads and appointment bookings."
                content1="By leveraging multi-channel marketing—spanning search ads, social media, and programmatic advertising—we ensured that Clove Dental reached the right audience at the right time. Our data-driven targeting helped the clinic stand out in a highly competitive healthcare market, attracting both new patients and reinforcing loyalty among existing ones."
                 content2='Through continuous optimization and engagement-driven content, we created a seamless patient journey, making it easier for potential customers to connect with the brand and schedule appointments. This personalized approach fostered trust and improved patient retention, further strengthening Clove Dental’s market positioning.'
                 content3='This case study highlights the power of an integrated digital strategy in delivering measurable success for healthcare providers. By focusing on precision targeting, compelling content, and data-backed optimizations, we enabled Clove Dental to expand its reach, increase patient engagement, and establish itself as a leading name in dental care.'

              />
               <CaseStudyCard
                image="/assets/crocs17.png"
                heading="Crocs’ Precision Targeting Success"
                impression="CTR: 3.14%"
                vtr=" Clicks: 25500+"
                content="In a bid to amplify brand engagement and drive impactful interactions, our campaign for Crocs was meticulously designed to hit the bullseye. Recognizing the importance of precise audience segmentation, we leveraged city-specific targeting while executing push notification ads flawlessly. This ensured that every impression reached the intended audience, minimizing any geo leakage and maximizing efficiency."
                content1="Our primary objective was to achieve an ambitious 3% Click-Through Rate (CTR)—a benchmark that signifies strong engagement and audience relevance. The results spoke for themselves! In Denpasar, we surpassed expectations with an outstanding 3.14% CTR, while Tangerang closely followed with an impressive 3.12%. This achievement validated our data-driven approach, proving that well-structured campaigns tailored to hyperlocal audiences can yield remarkable engagement rates."
                 content2='Beyond just numbers, this campaign exemplified the power of precision targeting and strategic ad placements. By understanding the behavioral patterns of our audience and deploying highly relevant messaging at the right time, we ensured that Crocs achieved maximum engagement and brand impact.'
                 content3='This case study underscores our expertise in crafting highly efficient, results-driven digital campaigns that not only meet but exceed performance benchmarks. The Crocs campaign stands as a testament to the effectiveness of well-orchestrated digital strategies, demonstrating how focused targeting and innovative execution can create lasting brand resonance in competitive markets.'

              />
               <CaseStudyCard
                image="/assets/teva18.png"
                heading="Teva’s Precision Targeting Triumph"
                impression="Clicks: 1.6M+ "
                vtr="CTR: 3.08%"
                content="In our mission to elevate Teva’s brand engagement, we executed a laser-focused campaign designed for maximum impact. By leveraging city-specific targeting and flawlessly delivering push notification ads, we ensured that every impression reached the right audience without any geo leakage—optimizing both efficiency and engagement."
                content1="Our primary goal was to achieve an ambitious 3% Click-Through Rate (CTR), setting a high-performance benchmark. The campaign not only met but exceeded expectations, delivering a stellar 3.08% CTR in Kota Kasablanka and an impressive 3.07% CTR in Plaza Indonesia. These outstanding results underscore the power of hyperlocal audience targeting and strategic campaign execution in driving brand interaction."
                 content2='Beyond the numbers, this campaign was a testament to the effectiveness of precise targeting, real-time optimization, and compelling messaging. By aligning our strategy with audience behavior and deploying engaging, high-impact push notifications, we successfully amplified Teva’s presence in key locations, fostering higher engagement and stronger brand recall.'
                 content3='This case study exemplifies our expertise in executing highly efficient, data-driven marketing strategies that not only meet but exceed industry benchmarks. The Teva campaign serves as a powerful reminder that well-structured digital initiatives, coupled with precision targeting, can drive exceptional engagement and brand growth in competitive markets.'

              />
               <CaseStudyCard
                image="/assets/skechers19.png"
                heading="Driving Engagement with Precision"
                impression="Clicks: 60k+ "
                vtr="CTR: 3%"
                content="In our mission to enhance Skechers' brand engagement, we crafted a data-driven campaign designed to capture the attention of comfort seekers and lifestyle enthusiasts. By deploying precise geo-targeting around the Senayan City Mall area, we ensured that our messaging resonated with the right audience, maximizing both engagement and cost efficiency."
                content1="Our objective was clear—achieve a 3.00% CTR while driving high-intent clicks within budget constraints. The results spoke for themselves: the campaign delivered an impressive 60,618 clicks at an ultra-efficient CPC of Rp 600, demonstrating the effectiveness of our strategic approach."
                 content2='This campaign’s success highlights our ability to merge precise audience segmentation with optimized ad placements, ensuring that each impression translated into meaningful engagement. By leveraging real-time performance monitoring and data-driven adjustments, we not only met our CTR goal but maximized Skechers  visibility among its core audience.'
                 content3='Beyond the metrics, this case study showcases the power of intelligent programmatic strategies in driving brand growth. Our expertise in hyperlocal targeting, budget optimization, and engagement-driven advertising positioned Skechers as a standout brand within the competitive lifestyle and footwear market. By blending precision marketing with creative execution, we ensured Skechers remained a top choice for style-conscious consumers in key retail hotspots.'

              />
                <CaseStudyCard
                image="/assets/lotus20.png"
                heading=" Elevating Brand Awareness with CTV Advertising"
                impression="Impression: 750K+  "
                vtr="VTR: 91%"
                content="Lotus Herbals sought to enhance brand awareness and position itself as a top beauty brand through an immersive Connected TV (CTV) campaign. To achieve this, we leveraged BidBox.ai as our DSP, utilizing premium streaming platforms and precise audience targeting to ensure maximum visibility and engagement."
                content1="Our strategy focused on placing Lotus Herbals on top-tier CTV platforms such as JioCinema, Hotstar, and MX Player. By delivering engaging ad creatives to beauty and lifestyle enthusiasts, we maximized the brand’s exposure and resonance with the right audience. The campaign also took advantage of OEM-based targeting, with Samsung, LG, and Xiaomi leading in viewership, ensuring strong reach across high-impact screens."
                 content2='Geographically, Maharashtra and Delhi emerged as the top-performing regions, showcasing strong engagement and brand recall. The CTV format provided a seamless and non-intrusive viewing experience, enhancing audience retention and interaction with the brand.'
                 content3='Beyond just impressions, the campaign ensured measurable impact through broad reach and precise targeting, reinforcing Lotus Herbals position in the beauty industry. By tapping into the power of CTV advertising with BidBox.ai, we successfully drove meaningful connections, making the brand a prominent choice among consumers.'

              />
                 <CaseStudyCard
                image="/assets/plat21.png"
                heading="Elevating Brand Presence Through CTV Advertising"
                impression="Impressions: 632,576   "
                vtr=" VTR: 90%"
                content="Platinum Love Bands set out to strengthen its brand recall and awareness by leveraging a highly targeted CTV campaign. To achieve this, we utilized BidBox.ai as our DSP, ensuring seamless ad placements and precise audience targeting across premium streaming platforms."
                content1="The campaign focused on promoting three distinct collections—Collection Pure, Collection Strength, and Lifestyle—each resonating with modern couples seeking symbolic expressions of love and commitment. By harnessing CTV’s immersive ad experience, we maximized engagement while ensuring the brand’s presence in premium digital environments."
                 content2='Through captivating video storytelling and strategic audience segmentation, we reached a highly engaged audience, effectively reinforcing Platinum Love Bands’ position as the ultimate choice for meaningful jewelry. The campaigns success was reflected in its impressive performance metrics.'
                 content3='Each collection received exceptional view-through rates (VTR), with the Lifestyle collection achieving the highest at 90.43%, proving the effectiveness of CTV advertising in fostering deep emotional connections.'
               content4='By seamlessly integrating data-driven targeting and premium ad placements, the campaign enhanced brand visibility, strengthened consumer engagement, and reinforced Platinum Love Bands’ positioning as a timeless symbol of love.'
             />
            </div>
          </div>

          <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
            <div className="p-5 md:p-10 z-10 flex justify-between">
              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="text-white font-bold text-xl md:text-5xl">
                   Questions or ready to get started?
                  </h1>
                  <h2 className="text-white  text-xl md:text-2xl">
                    Reach out and let's supercharge your campaigns together!
                  </h2>
                </div>
                <div>
                  <a href="/contact">
                    <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                      Get Started
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Gaming;
