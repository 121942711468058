import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import { Plus, Minus, ChevronDown } from "lucide-react";
import { FaBookmark, FaFileAlt, FaQuestionCircle, FaRegBookmark, FaRocket, FaVideo } from 'react-icons/fa';
import ServiceBrandAware from './ServiceBrandAware';
import { Helmet } from 'react-helmet';

const BrandAwareness = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Handle screen resize for responsive behaviors
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const togglePanel = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const caseStudies = [
    {
      id: 1,
      image: "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=",
      category: "CASE STUDY",
      title: "Old Navy leans into decisioned upfronts with live sports",
      stat1: "1.2M",
      desc1: "incremental households reached with live sports by excluding users reached via programmatic guaranteed",
      stat2: "70%",
      desc2: "reduction in cost per unique household on live sports versus programmatic guaranteed",
      link: "#",
    },
    {
      id: 2,
      image: "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=",
      category: "CASE STUDY",
      title: "Brand X improves digital advertising efficiency",
      stat1: "850K",
      desc1: "users engaged through targeted ads leading to better conversion rates",
      stat2: "60%",
      desc2: "increase in ROI from programmatic advertising strategies",
      link: "#",
    },
  ];

  const content = [
    {
      title: "Precision-Powered Visibility",
      description:
        "Ensure your brand reaches the most relevant audience using AI-driven targeting and privacy-compliant data. Our intelligent algorithms optimize impressions to maximize exposure among high-intent users, increasing recall and engagement.",
    },
    {
      title: "Multi-Channel Reach",
      description:
        "Expand brand awareness seamlessly across CTV, mobile, web, and social platforms. Integrate your message into the digital spaces where your audience is most active, ensuring consistent brand presence and increased engagement.",
    },
    {
      title: "Engaging & Impactful Creative",
      description:
        "Leverage immersive formats such as video ads, interactive content, and rich media to create a lasting impression. Our high-impact creatives enhance brand recall and drive emotional connections with your audience.",
    },
    {
      title: "AI-Optimized Performance",
      description:
        "BidBox's smart optimization tools analyze real-time data to adjust campaigns for maximum impact and efficiency. By dynamically refining targeting and bid strategies, we ensure your ad spend is utilized effectively to drive awareness.",
    },
    {
      title: "Real-Time Analytics & Insights",
      description:
        "Track audience engagement, impression quality, and brand lift with our advanced measurement tools. Gain valuable insights into consumer behavior, optimize future campaigns, and measure awareness impact with precise data and reporting.",
    },
    {
      title: "Smart Frequency Control",
      description:
        "Avoid ad fatigue while maintaining brand recall. Our frequency capping tools ensure optimal exposure levels across different devices and platforms—reinforcing awareness without overwhelming your audience.",
    },
    {
      title: "Premium Inventory Access",
      description:
        "Position your brand alongside top-tier content and trusted digital environments. With access to high-quality ad placements, your brand benefits from greater credibility, ensuring strong visibility and consumer trust.",
    },
  ];

  // Animation variants
  const fadeInVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const staggerItem = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        type: "spring", 
        stiffness: 100 
      }
    }
  };

  const scaleUp = {
    initial: { scale: 1 },
    hover: { 
      scale: 1.05,
      transition: { duration: 0.3 }
    }
  };

  return (
    <>
      
        <Helmet>
        <title>Boost Brand Awareness with BidBox AI’s Targeted Advertising</title>
        <meta name='description' content='Elevate your brand’s visibility with BidBox AI’s powerful programmatic advertising solutions designed to maximize reach and recall across digital channels.' />
        <meta name='keywords' content='Brand awareness, digital branding, brand recall strategy, top-funnel marketing, programmatic advertising, audience engagement, brand lift campaigns, omnichannel awareness, media buying, display advertising, video marketing, customer perception, ad impressions, brand storytelling, advertising reach expansion.' />               
    </Helmet>
      {/* Hero Section */}
      {/* <div className="flex min-h-screen justify-center items-center dark:bg-slate-800 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ 
            duration: 0.8,
            type: "spring",
            stiffness: 50
          }}
          className="mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-center h-full"
        >
          <div className="text-center max-w-4xl">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
            >
              <span className="block xl:inline">
                <span className="mb-1 block">Make Your Brand </span>
                <motion.span 
                  initial={{ backgroundPosition: "0%" }}
                  animate={{ backgroundPosition: "100%" }}
                  transition={{ repeat: Infinity, duration: 3, ease: "linear", repeatType: "reverse" }}
                  className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                >
                  Unforgettable.
                </motion.span>
              </span>
              <div className="mt-2">
                10X Efficiency
                <span className="relative mt-3 whitespace-nowrap text-blue-600">
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 418 42"
                    className="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
                    preserveAspectRatio="none"
                  >
                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                  </svg>
                  <span className="relative">with Bidbox Ai</span>
                </span>
              </div>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
            >
Build lasting connections with your audience through strategic, high-impact brand awareness campaigns. Stay top-of-mind and drive recognition across premium digital channels.            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              className="mt-5 sm:mt-8 sm:flex sm:justify-center"
            >
              <motion.div 
                variants={scaleUp}
                initial="initial"
                whileHover="hover" 
                className="rounded-md shadow"
              >
                <a
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                  href="/contact"
                >
                  Get started 
                </a>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div> */}
<div className="flex min-h-[100dvh] justify-center items-center dark:bg-slate-800 overflow-x-hidden">
  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
    className="mx-auto flex h-full min-h-[100dvh] w-full items-center justify-center px-4 sm:px-6 lg:px-8"
  >
    <div className="text-center max-w-4xl">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
        style={{ WebkitTransform: "translateY(0px)", transform: "translateY(0px)" }}
      >
        <span className="block xl:inline">
          <span className="mb-1 block">Make Your Brand </span>
          <motion.span 
            initial={{ backgroundPosition: "0%" }}
            animate={{ backgroundPosition: "100%" }}
            transition={{ repeat: Infinity, duration: 3, ease: "linear", repeatType: "reverse" }}
            className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
          >
            Unforgettable.
          </motion.span>
          <p className="mb-1 block">10X Efficiency<span className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">with Bidbox.Ai</span> </p>
        </span>
      </motion.h1>
      
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.5 }}
        className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
      >
Build lasting connections with your audience through strategic, high-impact brand awareness campaigns. Stay top-of-mind and drive recognition across premium digital channels.      </motion.p>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8, duration: 0.5 }}
        className="mt-5 sm:mt-8 sm:flex sm:justify-center"
      >
        <motion.div 
          variants={scaleUp}
          initial="initial"
          whileHover="hover" 
          className="rounded-md shadow"
        >
          <a
            className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
            href="/contact"
          >
            Get started 
          </a>
        </motion.div>
      </motion.div>
    </div>
  </motion.div>
</div>

      {/* First Features Section */}
      <div className="bg-white py-12 px-4 md:px-6">
        <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
          {/* Image on Left */}
          {/* <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0  opacity-20 rounded-lg"></div>
            <motion.img
              variants={scaleUp}
              initial="initial"
              whileHover="hover"
              src="/assets/brand.png"
              alt="Connected TV"
              className="w-full h-auto md:h-96 object-cover "
            />
          </motion.div> */}
<motion.div
  initial="hidden"
  whileInView="visible"
  variants={fadeInVariant}
  viewport={{ once: true }}
  className="relative w-full md:w-5/12"
>
  <div className="absolute inset-0 opacity-20 rounded-lg"></div>

  <motion.img
    variants={{
      initial: { scale: 1, rotateY: 0 },
      hover: { scale: 1.05, rotateY: 10 },
      float: {
        y: [0, -10, 0], // Floating effect
        transition: {
          duration: 3,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut"
        }
      }
    }}
    initial="initial"
    whileHover="hover"
    animate="float"
    src="/assets/brand.png"
    alt="Connected TV"
    className="w-full h-auto md:h-96 object-cover"
  />
</motion.div>

          {/* Text on Right */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
              Brand Awareness: The First Step to Loyalty
            </h2>
            <p className="text-gray-700 text-lg md:text-lg leading-relaxed mb-6">
            Brand awareness isn't just about visibility—it’s about making a lasting impression. When your audience recognizes, trusts, and remembers your brand, they’re more likely to engage, convert, and become loyal advocates.            </p>
            <p className="text-gray-700 text-lg md:text-lg leading-relaxed mb-6">
            At BidBox, we turn brand awareness into measurable success, ensuring your message reaches the right audience, in the right place, at the right time.
</p>
           
          
          </motion.div>
        </div>

        {/* Second Features Section */}
        <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-12 mt-16">
          {/* Image on Right */}
          <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={fadeInVariant}
                    viewport={{ once: true }}
                    className="relative w-full md:w-5/12"
                  >
                    <div className="absolute inset-0  opacity-20 rounded-lg"></div>
                    <motion.img
                      variants={{
                        initial: { scale: 1, rotateY: 0 },
                        hover: { scale: 1.05, rotateY: 10 },
                        float: {
                          y: [0, -10, 0], // Floating effect
                          transition: {
                            duration: 3,
                            repeat: Infinity,
                            repeatType: "reverse",
                            ease: "easeInOut"
                          }
                        }
                      }}
                      initial="initial"
                      whileHover="hover"
                      animate="float"
                      src="/assets/brand2.png"
                      alt="CTV Advertising"
                      className="w-full h-auto md:h-full object-cover "
                    />
                  </motion.div>
          
                  {/* Text on Left */}
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                     
                    variants={fadeInVariant}
                    viewport={{ once: true, amount: 0.4 }}
                    className="w-full md:w-7/12 text-center md:text-left"
                  >
                    <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
                    Why Brand Awareness? Build Recognition, Drive Loyalty
                                        </h2>
                    <p className="text-gray-700 text-lg leading-relaxed mb-4">
                    Brand awareness is the foundation of customer trust and long-term success. The more people recognize and remember your brand, the more likely they are to engage, convert, and become loyal advocates.                    </p>
                    <ul className="list-disc pl-6 text-gray-700 text-lg mb-6">
                      <li>Drives Consumer Trust & Credibility– Brands with strong awareness gain higher trust, leading to increased conversions.                      </li>
                      <li>Increases Market Share & Preference– When consumers recall your brand first, they choose you over competitors.</li>
                      <li>Boosts Advertising Efficiency– Well-known brands achieve lower CPA (Cost Per Acquisition) and higher ROAS (Return on Ad Spend).</li>
                      <li>Accelerates Word-of-Mouth Marketing– Brand recognition leads to organic advocacy, amplifying your reach.</li>
                    </ul>
                    {/* <Link to="/contact">
                      <motion.button
                        variants={scaleUp}
                        initial="initial"
                        whileHover="hover"
                        className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg"
                      >
                        Learn More
                      </motion.button>
                    </Link> */}
                  </motion.div>
          {/* <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0 bg-blue-600 opacity-20 rounded-lg"></div>
            <motion.img
              variants={scaleUp}
              initial="initial"
              whileHover="hover"
              src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
              alt="CTV Advertising"
              className="w-full h-auto md:h-96 object-cover rounded-lg shadow-2xl"
            />
          </motion.div> */}

          {/* Text on Left */}
          {/* <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
              Why Brand Awareness? Build Recognition, Drive Loyalty
            </h2>
            <p className="text-gray-700 text-lg md:text-xl leading-relaxed mb-6">
              Brand awareness is the foundation of customer trust and long-term success. The more people recognize and remember your brand, the more likely they are to engage, convert, and become loyal advocates. A strong brand presence enhances credibility, drives word-of-mouth marketing, and ultimately leads to sustained business growth and market dominance.
            </p>
            <Link to='/contact'>
              <motion.button
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg"
              >
                Learn More
              </motion.button>
            </Link>
          </motion.div> */}
        </div>
      </div>

      {/* Value Proposition */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="max-w-4xl mx-auto text-center px-4 mt-20"
      >
        <h2 className="text-4xl md:text-5xl font-bold mb-6">
        Power Up Your Brand Awareness with BidBox
        </h2>
        <p className="text-lg text-gray-600 mb-12">
        BidBox helps businesses build a powerful presence across premium digital platforms through AI-powered targeting, high-impact creative formats, and real-time performance tracking.        </p>
      </motion.div>

      {/* Stats Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="px-4 md:px-10"
      >
        <div className="mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-6 md:px-8 rounded-lg shadow-lg mt-6 mb-6">
          <div className="container mx-auto flex flex-col md:flex-row items-center text-white text-center md:text-left space-y-8 md:space-y-0 md:space-x-8">
            {/* Column 1 */}
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">4X Higher Ad Recall</h2>
              <p className="text-base md:text-sm opacity-90 mt-2">Immersive, full-screen ad formats enhance brand recognition and message retention.
              </p>
            </motion.div>

            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>

            {/* Column 2 */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">90%+ Video Completion Rate</h2>
              <p className="text-base md:text-sm opacity-90 mt-2">Our premium, non-skippable ad placements keep audiences engaged till the very last second.</p>
            </motion.div>

            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>

            {/* Column 3 */}
            <motion.div 
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">30% More Cost-Efficient</h2>
              <p className="text-base md:text-sm opacity-90 mt-2">AI-optimized bidding ensures maximum reach while reducing cost-per-impression</p>
            </motion.div>
          </div>
        </div>
      </motion.div>

     
      {/* <div className="w-full min-h-screen flex flex-col items-center px-6 py-12 bg-black text-white">
      
        <motion.h1
          initial={{ opacity: 0, y: -30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="text-4xl md:text-6xl font-extrabold text-center max-w-4xl neon-text"
        >
          Unforgettable Impressions. <br /> Unmatched Awareness.
        </motion.h1>

      =
        <div className="w-full flex justify-center my-10">
          <motion.img
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
            src="https://img.freepik.com/free-photo/futuristic-metaverse-empty-room-product-display-presentation-abstract-technology-scifi-with-neon-light-3d-background_56104-2314.jpg?semt=ais_hybrid"
            alt="Brand Awareness"
            className="w-full md:w-[600px] rounded-3xl shadow-2xl border-2 border-gray-700 backdrop-blur-lg"
          />
        </div>

      
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          className="w-full max-w-4xl space-y-4"
        >
          {content.map((item, index) => (
            <motion.div
              key={index}
              variants={staggerItem}
              className="bg-gradient-to-r from-gray-900 to-gray-800 text-white rounded-xl shadow-xl p-5 relative transition-all duration-300"
              whileHover={{ scale: 1.03, transition: { duration: 0.3 } }}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <button
                className="w-full flex justify-between items-center text-lg font-semibold tracking-wide hover:text-blue-400 transition"
                onClick={() => togglePanel(index)}
              >
                <span>{item.title}</span>
                <motion.div
                  initial={{ rotate: 0 }}
                  animate={{ rotate: openIndex === index ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ChevronDown size={24} />
                </motion.div>
              </button>

              
              <AnimatePresence>
                {(openIndex === index || screenWidth >= 768 && hoverIndex === index) && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden mt-3"
                  >
                    <p className="text-gray-300">{item.description}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </motion.div>

    
        <style>
          {`
            .neon-text {
              text-shadow: 0 0 10px #00bcd4, 0 0 20px #00bcd4, 0 0 40px #00bcd4;
              animation: pulse 2s infinite alternate;
            }
            
            @keyframes pulse {
              0% {
                text-shadow: 0 0 10px #00bcd4, 0 0 20px #00bcd4, 0 0 40px #00bcd4;
              }
              100% {
                text-shadow: 0 0 15px #00bcd4, 0 0 25px #00bcd4, 0 0 45px #00bcd4;
              }
            }
          `}
        </style>
      </div> */}

<section>
  <div className="container max-w-xl p-6 mx-auto space-y-12 lg:px-8 lg:max-w-7xl mt-20">
  <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}>
      <h2 className="text-2xl font-bold text-center sm:text-5xl">
      The BidBox Advantage: Brand Awareness at Scale
      </h2>
      <p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-600">
        
Achieve unparalleled brand awareness with BidBox’s AI-driven targeting and multi-platform reach. Deliver compelling, high-impact ads that captivate audiences while ensuring full transparency and measurable results.
      </p>
      </motion.div>
    <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center ">
    <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}>
        <div className="mt-4 space-y-12">
          {/** Feature 1 */}
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 rounded-md">
                <FaRocket size={24} className='textColor'/>
              </div>
            </div>
            <div className="ml-4 text-gray-600">
              <h4 className="text-lg font-medium">AI-Powered Targeting</h4>
              <p className="mt-2">Leverage data-driven insights to reach the right audience at the right time.</p>
            </div>
          </div>

          {/** Feature 2 */}
          <div className="flex ">
            <div className="flex-shrink-0 ">
              <div className="flex items-center justify-center w-12 h-12 rounded-md">
                <FaRegBookmark size={24} className='textColor'/>
              </div>
            </div>
            <div className="ml-4 text-gray-600">
              <h4 className="text-lg font-medium">Multi-Platform Strategy</h4>
              <p className="mt-2">Expand your brand’s footprint across connected TV, web, mobile, and premium video inventory.
              </p>
            </div>
          </div>

          {/** Feature 3 */}
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 rounded-md">
                <FaVideo size={24} className='textColor' />
              </div>
            </div>
            <div className="ml-4 text-gray-600">
              <h4 className="text-lg font-medium">Engaging Creative Formats</h4>
              <p className="mt-2">From interactive ads to high-impact video, we ensure your brand message is visually compelling.
              </p>
            </div>
          </div>

          {/** Feature 4 */}
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 rounded-md">
                <FaQuestionCircle size={24}  className='textColor'/>
              </div>
            </div>
            <div className="ml-4 text-gray-600">
              <h4 className="text-lg font-medium">Full Transparency & Measurable Impact</h4>
              <p className="mt-2">Access real-time reporting and in-depth analytics to track awareness, engagement, and ROI.</p>
            </div>
          </div>

         

        </div>
        </motion.div>

      {/** Image Section */}
      <motion.div
  initial="hidden"
  whileInView="visible"
  variants={fadeInVariant}
  viewport={{ once: true }} aria-hidden="true" className="mt-10 lg:mt-0">
       <motion.img
    variants={{
      initial: { scale: 1, rotateY: 0 },
      hover: { scale: 1.05, rotateY: 10 },
      float: {
        y: [0, -10, 0], // Floating effect
        transition: {
          duration: 3,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut"
        }
      }
    }}
    initial="initial"
    whileHover="hover"
    animate="float"
          width="600" 
          height="600" 
          src="/assets/brand3.png" 
          className="mx-auto  " 
          style={{ color: "transparent" }} 
          alt='No imag'
        />
      </motion.div>
    </div>
  </div>
</section>
<ServiceBrandAware/>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="w-full flex justify-center mt-10"
      >
       <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }} className="w-[95%] mb-20 h-[370px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
        <div className="p-5 md:p-10 z-10 flex justify-between">
          <div className="flex flex-col justify-between">
            <div>
            <motion.h1 
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }} className="text-white font-bold text-xl md:text-5xl">
            Ready to Make Your Brand Unforgettable?

              </motion.h1>
              <motion.h2 
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }} className="text-gray-300  text-xl md:text-xl py-2">
Amplify your presence across premium digital channels and ensure your brand stays top-of-mind. Engage audiences, boost recognition, and build lasting connections—everywhere they spend their time.</motion.h2 >
            </div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              viewport={{ once: true }}>
              <a href="/contact">
                <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                Get Started Now
                </button>
              </a>
              </motion.div>
          </div>
        </div>
        </motion.div>
      </motion.div>
      {/* CTA Banner */}
      {/* <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="w-full flex justify-center mt-10"
      >
        <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
          className="w-[95%] mb-20 bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden relative p-5 md:p-10"
        >
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-10"></div>
          
          <div className="z-10 flex flex-col h-full justify-between">
            <div>
              <motion.h1 
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="text-white font-bold text-xl md:text-5xl"
              >
                Ready to Make Your Brand Unforgettable?
              </motion.h1>
              <motion.h2 
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
                className="text-gray-100 text-base md:text-xl py-2 max-w-3xl"
              >
                Amplify your brand presence across premium digital channels and ensure your message stays top-of-mind. Engage audiences, drive recognition, and build lasting connections—everywhere they spend their time.
              </motion.h2>
            </div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              viewport={{ once: true }}
              className="mt-4"
            >
              <a href="/contact">
                <motion.button 
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-white hover:bg-gray-100 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold"
                >
                  Get Started Now
                </motion.button>
              </a>
            </motion.div>
          </div>
        </motion.div>
      </motion.div> */}

      {/* Case Studies */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.4 }}
        className="max-w-7xl mx-auto py-12 px-4"
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-3xl md:text-4xl font-bold text-center mb-10"
        >
          Success Stories
        </motion.h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {caseStudies.map((study, index) => (
            <motion.div
              key={study.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
              whileHover={{ 
                scale: 1.03, 
                boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)" 
              }}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300"
            >
              {/* Top: Image with overlay effect */}
              <div className="w-full relative overflow-hidden">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                  src={study.image}
                  alt="Case Study"
                  className="w-full h-48 md:h-64 object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
                <div className="absolute bottom-0 left-0 p-4">
                  <span className="text-xs font-bold text-white bg-blue-600 px-2 py-1 rounded">
                    {study.category}
                  </span>
                </div>
              </div>

              {/* Bottom: Text Content */}
              <div className="p-6 text-center">
                <h3 className="text-xl font-bold mt-2">{study.title}</h3>
                <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-8 mt-4">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">{study.stat1}</p>
                    <p className="text-gray-600 text-sm">{study.desc1}</p>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">{study.stat2}</p>
                    <p className="text-gray-600 text-sm">{study.desc2}</p>
                  </motion.div>
                </div>
                <motion.a
                  whileHover={{ scale: 1.1 }}
                  href={study.link}
                  className="text-blue-600 font-semibold mt-6 inline-block border-b-2 border-transparent hover:border-blue-600"
                >
                  READ CASE STUDY
                </motion.a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export default BrandAwareness;

// import React, { useState } from 'react'
// import { Link } from 'react-router-dom';
// import { motion } from "framer-motion";
// import { Plus, Minus } from "lucide-react"; 
// const BrandAwareness = () => {

//   const [openIndex, setOpenIndex] = useState(null);

//   const togglePanel = (index) => {
//     setOpenIndex(openIndex === index ? null : index);
//   };

//   const [hoverIndex, setHoverIndex] = useState(null);
//     const caseStudies = [
//         {
//           id: 1,
//           image: "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
//           category: "CASE STUDY",
//           title: "Old Navy leans into decisioned upfronts with live sports",
//           stat1: "1.2M",
//           desc1: "incremental households reached with live sports by excluding users reached via programmatic guaranteed",
//           stat2: "70%",
//           desc2: "reduction in cost per unique household on live sports versus programmatic guaranteed",
//           link: "#",
//         },
//         {
//           id: 2,
//           image: "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
//           category: "CASE STUDY",
//           title: "Brand X improves digital advertising efficiency",
//           stat1: "850K",
//           desc1: "users engaged through targeted ads leading to better conversion rates",
//           stat2: "60%",
//           desc2: "increase in ROI from programmatic advertising strategies",
//           link: "#",
//         },
//       ];

//       const content = [
//         {
//           title: "Precision-Powered Visibility",
//           description:
//             "Ensure your brand reaches the most relevant audience using AI-driven targeting and privacy-compliant data. Our intelligent algorithms optimize impressions to maximize exposure among high-intent users, increasing recall and engagement.",
//         },
//         {
//           title: "Multi-Channel Reach",
//           description:
//             "Expand brand awareness seamlessly across CTV, mobile, web, and social platforms. Integrate your message into the digital spaces where your audience is most active, ensuring consistent brand presence and increased engagement.",
//         },
//         {
//           title: "Engaging & Impactful Creative",
//           description:
//             "Leverage immersive formats such as video ads, interactive content, and rich media to create a lasting impression. Our high-impact creatives enhance brand recall and drive emotional connections with your audience.",
//         },
//         {
//           title: "AI-Optimized Performance",
//           description:
//             "BidBox’s smart optimization tools analyze real-time data to adjust campaigns for maximum impact and efficiency. By dynamically refining targeting and bid strategies, we ensure your ad spend is utilized effectively to drive awareness.",
//         },
//         {
//           title: "Real-Time Analytics & Insights",
//           description:
//             "Track audience engagement, impression quality, and brand lift with our advanced measurement tools. Gain valuable insights into consumer behavior, optimize future campaigns, and measure awareness impact with precise data and reporting.",
//         },
//         {
//           title: "Smart Frequency Control",
//           description:
//             "Avoid ad fatigue while maintaining brand recall. Our frequency capping tools ensure optimal exposure levels across different devices and platforms—reinforcing awareness without overwhelming your audience.",
//         },
//         {
//           title: "Premium Inventory Access",
//           description:
//             "Position your brand alongside top-tier content and trusted digital environments. With access to high-quality ad placements, your brand benefits from greater credibility, ensuring strong visibility and consumer trust.",
//         },
//       ];
//       const fadeInVariant = {
//         hidden: { opacity: 0, y: 50 },
//         visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
//       };
//   return (
//     <>
//        {/* <div
//       className="relative flex items-center justify-center h-screen bg-cover bg-center"
//       style={{ backgroundImage: "url('https://t4.ftcdn.net/jpg/09/49/36/63/240_F_949366383_tyZF61HUtUectJuzOQGxzUW8vjWXVO1b.jpg')" }}
//     >
//       <div className="bg-white/20 backdrop-blur-md p-8 rounded-lg shadow-lg text-center max-w-lg">
//         <h1 className="text-4xl font-bold text-white mb-4">Welcome to Our Platform</h1>
//         <p className="text-white text-lg mb-6">
//           Explore amazing features and elevate your experience with our services.
//         </p>
//         <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-105 transition transform duration-300">
//           Get Started
//         </button>
//       </div>
//     </div> */}
//     <div class="flex h-screen justify-center items-center dark:bg-slate-800">
//     <motion.div
//           initial={{ opacity: 0, scale: 0.8 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8 }}
//     class="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8">
//         <div class="text-center ">
//             <h1
//                 class="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl">
//                 <span class="block xl:inline"><span class="mb-1 block">Make Your Brand </span>
//                 <span class="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
//                 Unforgettable.
//                 </span>
//                 </span>
//                 <div class="mt-2">10X Efficiency
//                     <span class="relative mt-3 whitespace-nowrap text-blue-600"><svg aria-hidden="true" viewBox="0 0 418 42"
//                         class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
//                         preserveAspectRatio="none">
//                         <path
//                             d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z">
//                         </path>
//                     </svg>
//                     <span class="relative">with Bidbox Ai</span>
//                     </span>
//                 </div>
//             </h1>
//             <p class="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5">
//             Build lasting connections with your audience through strategic, high-impact brand awareness campaigns. Stay top-of-mind and drive recognition across premium digital channels with AI-driven precision and cross-platform reach.
//             </p>
//             <div class="mt-5 sm:mt-8 sm:flex sm:justify-center">
//             <motion.div whileHover={{ scale: 1.1 }}class="rounded-md shadow">
//                   <a
//                         class="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
//                         href="/contact">Get started 
//                     </a>
//                     </motion.div>
//             </div>
//         </div>
//         </motion.div>
// </div>
// <div className="bg-white py-12 px-4 md:px-6">
//       <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
        
//         {/* Image on Left */}
//         <motion.div
//             initial="hidden"
//             whileInView="visible"
//             variants={fadeInVariant}
//             viewport={{ once: true }} className="relative w-full md:w-5/12">
//           <div className="absolute inset-0 bg-purple-600 opacity-20 rounded-lg"></div>
//           <motion.img
//               whileHover={{ scale: 1.05 }}
//               transition={{ duration: 0.3 }}
//             src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
//             alt="Connected TV"
//             className="w-full h-auto md:h-96 object-cover rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"
//           />
//     </motion.div>

//         {/* Text on Right */}
//         <motion.div
//             initial="hidden"
//             whileInView="visible"
//             variants={fadeInVariant}
//             // viewport={{ once: true }}
//             viewport={{ once: true, amount: 0.4 }}
//             className="w-full md:w-7/12 text-center md:text-left">
//           <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
//           Brand Awareness: The First Step to Loyalty
//           </h2>
//           <p className="text-gray-700 text-lg md:text-xl leading-relaxed mb-6">
//           A strong brand presence keeps your business front and center in consumers' minds. Through strategic advertising and consistent messaging, you build lasting recognition, influence consumer perception, and establish credibility with your target audience—driving long-term success.          </p>
//           <Link to='/contact'>
//           <motion.button
//                 whileHover={{ scale: 1.1 }}
//                 transition={{ duration: 0.3 }}className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-110 transition duration-300">
//             Learn More
//             </motion.button>
//           </Link>
//           </motion.div>

//       </div>

      
//       {/* <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-12 mt-16">
        
        
//         <div className="relative w-full md:w-5/12">
//           <div className="absolute inset-0 bg-blue-600 opacity-20 rounded-lg"></div>
//           <img
//             src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
//             alt="CTV Advertising"
//             className="w-full h-auto md:h-96 object-cover rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"
//           />
//         </div>

       
//         <div className="w-full md:w-7/12 text-center md:text-left">
//           <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
//           Why Brand Awareness? Build Recognition, Drive Loyalty
//           </h2>
//           <p className="text-gray-700 text-lg md:text-xl leading-relaxed mb-6">
//           Brand awareness is the foundation of customer trust and long-term success. The more people recognize and remember your brand, the more likely they are to engage, convert, and become loyal advocates. A strong brand presence enhances credibility, drives word-of-mouth marketing, and ultimately leads to sustained business growth and market dominance.
//           </p>
//           <Link to='/contact'>
//           <button className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg hover:scale-110 transition duration-300">
//             Learn More
//           </button>
//           </Link>
//         </div>

//       </div> */}
//        <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-12 mt-16">
//         {/* Image on Right */}
//         <motion.div
//           initial="hidden"
//           whileInView="visible"
//           variants={fadeInVariant}
//           viewport={{ once: true }}
//           className="relative w-full md:w-5/12"
//         >
//           <div className="absolute inset-0 bg-blue-600 opacity-20 rounded-lg"></div>
//           <motion.img
//             whileHover={{ scale: 1.05 }}
//             transition={{ duration: 0.3 }}
//             src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
//             alt="CTV Advertising"
//             className="w-full h-auto md:h-96 object-cover rounded-lg shadow-2xl"
//           />
//         </motion.div>

//         {/* Text on Left */}
//         <motion.div
//           initial="hidden"
//           whileInView="visible"
//           variants={fadeInVariant}
//           // viewport={{ once: true }}
//           viewport={{ once: true, amount: 0.4 }}
//           className="w-full md:w-7/12 text-center md:text-left"
//         >
//           <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
//             Why Brand Awareness? Build Recognition, Drive Loyalty
//           </h2>
//           <p className="text-gray-700 text-lg md:text-xl leading-relaxed mb-6">
//             Brand awareness is the foundation of customer trust and long-term success. The more people recognize and remember your brand, the more likely they are to engage, convert, and become loyal advocates. A strong brand presence enhances credibility, drives word-of-mouth marketing, and ultimately leads to sustained business growth and market dominance.
//           </p>
//           <Link to='/contact'>
//             <motion.button
//               whileHover={{ scale: 1.1 }}
//               transition={{ duration: 0.3 }}
//               className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg"
//             >
//               Learn More
//             </motion.button>
//           </Link>
//         </motion.div>
//       </div>
//     </div>

//      <motion.div
//           initial="hidden"
//           whileInView="visible"
//           variants={fadeInVariant}
//           // viewport={{ once: true }}
//           viewport={{ once: true, amount: 0.5 }}
//     className="max-w-4xl mx-auto text-center"
    
//   >
//     <h2 className="text-4xl md:text-5xl font-bold mb-6">Amplify Your Brand with BidBox
//     </h2>
//     <p className="text-xl text-gray-600 mb-12">
//     BidBox helps you build a powerful brand presence by delivering your message to the right audience at scale. Through AI-driven targeting, premium ad placements, and real-time analytics, we ensure your brand remains visible, memorable, and impactful. Our intelligent optimization tools maximize reach and engagement, helping you turn awareness into customer action.
//     </p>
//     </motion.div>
//     <motion.div
//           initial="hidden"
//           whileInView="visible"
//           variants={fadeInVariant}
//           // viewport={{ once: true }}
//           viewport={{ once: true, amount: 0.5 }}
  
//   className="px-4 md:px-10">
//   <div className="mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-6 md:px-8 rounded-lg shadow-lg mt-6 mb-6">
//     <div className="container mx-auto flex flex-col md:flex-row items-center text-white text-center md:text-left space-y-8 md:space-y-0 md:space-x-8">
      
//       {/* Column 1 */}
//       <div className="flex-1">
//         <h2 className="text-2xl md:text-2xl font-bold">3X More Viewability</h2>
//         <p className="text-base md:text-sm opacity-90 mt-2">Access premium inventory and leverage advanced bidding strategies to maximize ad visibility, ensuring your brand stands out in the digital landscape</p>
//       </div>

//       {/* Divider */}
//       {/* <div className="hidden md:block w-px h-16 bg-white opacity-50"></div> */}
//  {/* Divider */}
//  <div className="w-full h-px bg-white opacity-50 md:hidden"></div> {/* Mobile Divider */}
//       <div className="hidden md:block w-px h-16 bg-white opacity-50"></div> {/* Desktop Divider */}

//       {/* Column 2 */}
//       <div className="flex-1">
//         <h2 className="text-2xl md:text-2xl font-bold">85% Video Completion Rate</h2>
//         <p className="text-base md:text-sm opacity-90 mt-2">High-quality, non-skippable ad formats engage viewers till the end, reinforcing your brand message and improving recall.</p>
//       </div>

//       {/* Divider */}
//        {/* Divider */}
//        <div className="w-full h-px bg-white opacity-50 md:hidden"></div> {/* Mobile Divider */}
//       <div className="hidden md:block w-px h-16 bg-white opacity-50"></div> {/* Desktop Divider */}


//       {/* Column 3 */}
//       <div className="flex-1">
//         <h2 className="text-2xl md:text-2xl font-bold">20% Lower CPM</h2>
//         <p className="text-base md:text-sm opacity-90 mt-2">Efficient AI-driven bidding reduces ad costs while expanding reach, ensuring you get maximum exposure without overspending.</p>
//       </div>

//     </div>
//   </div>
//   </motion.div>

// <div 
// className="w-full min-h-screen flex flex-col items-center px-6 py-12 bg-black text-white">
//       {/* Title */}
//       <h1 className="text-4xl md:text-6xl font-extrabold text-center max-w-4xl neon-text">
//         Unforgettable Impressions. <br /> Unmatched Awareness.
//       </h1>

//       {/* Centered 3D Image */}
//       <div className="w-full flex justify-center my-10">
//         <motion.img  
//           src="https://img.freepik.com/free-photo/futuristic-metaverse-empty-room-product-display-presentation-abstract-technology-scifi-with-neon-light-3d-background_56104-2314.jpg?semt=ais_hybrid"
//           alt="Brand Awareness"
//           className="w-full md:w-[600px] rounded-3xl shadow-2xl border-2 border-gray-700 backdrop-blur-lg"      
//               whileHover={{ scale: 1.05, transition: { duration: 0.3 } }} // Image hover effect
//         />
//       </div>

//       {/* Expanding Panels */}
//       <div className="w-full max-w-4xl space-y-4">
//         {content.map((item, index) => (
//            <motion.div
//            key={index}
//             className="bg-gradient-to-r from-gray-900 to-gray-800 text-white rounded-xl shadow-xl p-5 relative transition-all duration-300"
//             initial={{ opacity: 0.8, scale: 1 }}
//             whileHover={{ opacity: 1, scale: 1.05, transition: { duration: 0.3 } }} // Panel hover effect
//             onMouseEnter={() => setHoverIndex(index)}
//             onMouseLeave={() => setHoverIndex(null)}
//           >
//             {/* Panel Header */}
//             <button
//               className="w-full flex justify-between items-center text-lg font-semibold tracking-wide hover:text-blue-400 transition"
//               onClick={() => togglePanel(index)}
//             >
//               {item.title}
//               {openIndex === index ? <Minus size={24} /> : <Plus size={24} />}
//             </button>

//             {/* Expanding Content */}
//             <motion.div
//              initial={{ height: 0, opacity: 0 }}
//              animate={hoverIndex === index ? { height: "auto", opacity: 1 } : { height: 0, opacity: 0 }}
//              transition={{ duration: 0.3 }}
//              className="overflow-hidden mt-3"
//               // className={`overflow-hidden transition-all duration-300 ${
//               //   openIndex === index ? "max-h-[200px] mt-3 opacity-100" : "max-h-0 opacity-0"
//               // }`}
//             >
//               <p className="text-gray-300">{item.description}</p>
//               </motion.div>
//               </motion.div>
//         ))}
//       </div>

//       {/* Glow Effect for Heading */}
//       <style>
//         {`
//           .neon-text {
//             text-shadow: 0 0 10px #00bcd4, 0 0 20px #00bcd4, 0 0 40px #00bcd4;
//           }
//         `}
//       </style>
//     </div>
//     <motion.div
//           initial="hidden"
//           whileInView="visible"
//           variants={fadeInVariant}
//           // viewport={{ once: true }}
//           viewport={{ once: true, amount: 0.5 }}
// className="w-full flex justify-center mt-10">
//       <div className="w-[95%] mb-20 h-[370px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
//         <div className="p-5 md:p-10 z-10 flex justify-between">
//           <div className="flex flex-col justify-between">
//             <div>
//               <h1 className="text-white font-bold text-xl md:text-5xl">
//               Ready to Make Your Brand Unforgettable?
//               </h1>
//               <h2 className="text-gray-300  text-xl md:text-xl py-2">
//               Amplify your brand presence across premium digital channels and ensure your message stays top-of-mind. Engage audiences, drive recognition, and build lasting connections—everywhere they spend their time. Start your brand awareness journey with BidBox today.
//               </h2>
//             </div>
//             <div>
//               <a href="/contact">
//                 <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
//                 Get Started Now
//                 </button>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//       </motion.div>
//     <motion.div
//           initial="hidden"
//           whileInView="visible"
//           variants={fadeInVariant}
//           // viewport={{ once: true }}
//           viewport={{ once: true, amount: 0.4 }} 
//     className="max-w-7xl mx-auto py-12 px-4">
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//         {caseStudies.map((study) => (
//           <div
//             key={study.id}
//             className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl"
//           >
//             {/* Top: Image */}
//             <div className="w-full">
//               <img
//                 src={study.image}
//                 alt="Case Study"
//                 className="w-full h-auto object-cover"
//               />
//             </div>

//             {/* Bottom: Text Content */}
//             <div className="p-6 text-center">
//               <p className="text-sm text-blue-600 font-semibold">{study.category}</p>
//               <h3 className="text-xl font-bold mt-2">{study.title}</h3>
//               <div className="flex justify-center gap-8 mt-4">
//                 <div>
//                   <p className="text-2xl font-bold">{study.stat1}</p>
//                   <p className="text-gray-600">{study.desc1}</p>
//                 </div>
//                 <div>
//                   <p className="text-2xl font-bold">{study.stat2}</p>
//                   <p className="text-gray-600">{study.desc2}</p>
//                 </div>
//               </div>
//               <a href={study.link} className="text-blue-600 font-semibold mt-4 inline-block">
//                 READ CASE STUDY
//               </a>
//             </div>
//           </div>
//         ))}
//       </div>
//       </motion.div>
  
   
    
    
    
//     </>
//   )
// }

// export default BrandAwareness
