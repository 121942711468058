import { Outlet, useLocation } from 'react-router-dom';
import './App.css';
import React, { useEffect } from 'react'

import NavBar from './components/NavBar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const location = useLocation()
  useEffect(() => {
  
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <NavBar />
        <Outlet />
      <Footer />
      <ScrollToTop/>
    </>
  );
}

export default App;
