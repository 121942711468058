import React from "react";
import { motion, AnimatePresence } from "framer-motion";
const BannerCtv = () => {
  const fadeInVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  
  
  return (
    <motion.div
    initial="hidden"
    whileInView="visible"
    variants={fadeInVariant}
    viewport={{ once: true, amount: 0.5 }}
    className="w-full flex justify-center mt-10">
   <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
      className="w-[95%] mb-20 h-[300px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
        <div className="p-5 md:p-10 z-10 flex justify-between">
          <div className="flex flex-col justify-between">
            <div>
            <motion.h1
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  viewport={{ once: true }}
              className="text-white font-bold text-xl md:text-5xl">
             Ready to Elevate Your CTV Advertising?
              </motion.h1>
                <motion.h2
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  viewport={{ once: true }} className="text-gray-300  text-xl md:text-xl py-2">
             Advertise on premium streaming platforms and place your brand next to blockbuster movies, trending shows, and live events—right where your audience is watching.
              </motion.h2>
              </div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                viewport={{ once: true }}>
              <a href="/contact">
                <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                Get Started Now
                </button>
              </a>
              </motion.div>
          </div>
        </div>
        </motion.div>
      </motion.div>
  );
};

export default BannerCtv;
