import { useState, useEffect } from "react";
import { motion } from "framer-motion";

const data = [
  {
    id: 1,
    title: "Campaign Wise",
    description:
      "Brand awareness campaigns focus on maximizing visibility by placing your ads in high-traffic environments, ensuring maximum recall and engagement. Retargeting re-engages users who have previously interacted with your brand, using AI-powered audience segmentation and personalized messaging. High-intent conversion campaigns strategically target users in the final decision-making phase, delivering relevant ads that drive purchases, sign-ups, and lead generation with precision-based bidding and optimized placements.",
    image: "/assets/campagin.png",
  },
  {
    id: 2,
    title: "Channel Wise",
    description:
      "Connected TV (CTV) and Over-the-Top (OTT) advertising help brands reach cord-cutting audiences through premium, non-skippable, and high-engagement ad formats on smart TVs and streaming platforms. Mobile & In-App advertising ensures seamless brand exposure across apps, games, and mobile browsing, capturing users where they are most engaged. Programmatic display ads appear across relevant websites and digital placements, using AI to optimize ad delivery based on context, user behavior, and real-time bidding efficiency.",
    image: "/assets/channel.png",
  },
  {
    id: 3,
    title: "Targeting Wise",
    description:
      " Demographics targeting helps advertisers refine their audience selection by considering factors like age, gender, income, and education, ensuring that the right message reaches the right users. Behavioral targeting analyzes past interactions, browsing habits, and shopping patterns, serving ads to users most likely to engage. Interest-based targeting leverages AI-driven insights to segment audiences based on hobbies, preferences, and affinities, increasing engagement. Household sync targeting ensures multi-device ad coordination, reinforcing brand messaging across CTV, mobile, and desktop devices for a cohesive experience.",
    image: "/assets/target.png",
  },
  
];

export default function EmailCampaignUI() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <>
      {/* Main Heading Section */}
      <div className="text-center  mt-16">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-900">Services</h1>
        <h2 className="text-xl text-gray-700 mt-2">Smarter Tools. Stronger Results.</h2>
        <p className="text-gray-500 max-w-2xl mx-auto mt-2">
        Unlock AI-powered solutions designed to streamline campaigns and maximize impact.
        </p>
      </div>

      <div className="flex flex-col items-center p-8 shadow-lg rounded-lg w-full max-w-7xl mx-auto mt-6">
        {/* Content and Image Section */}
        <div className="flex flex-col md:flex-row items-center justify-between w-full">
          {/* Left Section - Text Content */}
          <motion.div
            key={data[selectedIndex].id}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="md:w-1/2 space-y-4 text-center md:text-left"
          >
            <h2 className="text-3xl font-bold">{data[selectedIndex].title}</h2>
            <p className="text-gray-600">{data[selectedIndex].description}</p>
            {/* <a href="#" className="text-blue-600 font-semibold hover:underline">
            Explore Services &rarr;
            </a> */}
          </motion.div>

          {/* Right Section - Image */}
          <motion.div
            key={data[selectedIndex].image}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="md:w-1/2 flex justify-center relative mt-6 md:mt-0"
          >
            <img
              src={data[selectedIndex].image}
              alt={data[selectedIndex].title}
              className="w-[300px] h-[250px] md:w-[400px] md:h-[300px] lg:w-[500px] lg:h-[350px]  object-cover"
            />
            {/* Floating UI Elements */}
            {/* <motion.div
              className="absolute top-4 right-4 bg-white shadow-lg p-3 rounded-lg"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <p className="text-sm font-semibold text-green-600">Opened</p>
              <span className="text-lg font-bold">545 / 673</span>
            </motion.div> */}
            {/* <motion.div
              className="absolute bottom-4 right-4 bg-white shadow-lg p-3 rounded-lg"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <p className="text-sm font-semibold text-purple-600">Bounced</p>
              <span className="text-lg font-bold">120 / 459</span>
            </motion.div> */}
          </motion.div>
        </div>
      </div>

      {/* Buttons Section */}
      <div className="mt-6 flex flex-wrap gap-4 w-full justify-center border-t pt-6 pb-10">
        {data.map((item, index) => (
          <motion.button
            key={item.id}
            className={`px-6 py-3 rounded-full text-sm font-medium transition-all ${
              selectedIndex === index
                ? "bg-gradient-to-r from-purple-600 to-blue-600 text-white shadow-lg scale-105"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
            onMouseEnter={() => setSelectedIndex(index)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            {item.title}
          </motion.button>
        ))}
      </div>
    </>
  );
}


// import { useState } from "react";
// import { motion } from "framer-motion";

// const data = [
//   {
//     id: 1,
//     title: "Email Campaign Tracking",
//     description:
//       "Monitor the performance of your email campaigns with real-time insights. Track open rates, replies, clicks, and bounces effortlessly, giving you the data you need to refine your strategy.",
//     image: "/tg2.jpg",
//   },
//   {
//     id: 2,
//     title: "Analytics & Reports",
//     description:
//       "Gain valuable insights with analytics and reports. Understand user engagement, open rates, and conversions to optimize your email campaigns.",
//     image: "/tg2.jpg",
//   },
//   {
//     id: 3,
//     title: "Contact Sync",
//     description:
//       "Seamlessly sync your contacts and manage email lists with ease. Ensure accurate data and smooth communication with your audience.",
//     image: "/tg2.jpg",
//   },
//   {
//     id: 4,
//     title: "Sales Pipeline",
//     description:
//       "Optimize your sales pipeline with targeted email campaigns. Convert leads into customers with data-driven email marketing strategies.",
//     image: "/tg2.jpg",
//   },
// ];

// export default function EmailCampaignUI() {
//   const [selected, setSelected] = useState(data[0]);

//   return (
//     <>
//       {/* Main Heading Section */}
//       <div className="text-center mb-8 mt-8">
//         <h1 className="text-4xl font-bold text-gray-900">Services</h1>
//         <h2 className="text-xl text-gray-700 mt-2">Tools to Simplify Your Work</h2>
//         <p className="text-gray-500 max-w-2xl mx-auto mt-2">
//           Explore a wide array of specialized services tailored to meet diverse client needs.
//         </p>
//       </div>

//       <div className="flex flex-col items-center p-8 shadow-lg rounded-lg w-full max-w-7xl mx-auto">
//         {/* Content and Image Section */}
//         <div className="flex flex-col md:flex-row items-center justify-between w-full">
//           {/* Left Section - Text Content */}
//           <motion.div
//             key={selected.id}
//             initial={{ opacity: 0, x: -50 }}
//             animate={{ opacity: 1, x: 0 }}
//             transition={{ duration: 0.5 }}
//             className="md:w-1/2 space-y-4 text-center md:text-left"
//           >
//             <h2 className="text-3xl font-bold">{selected.title}</h2>
//             <p className="text-gray-600">{selected.description}</p>
//             <a href="#" className="text-blue-600 font-semibold hover:underline">
//               Know More &rarr;
//             </a>
//           </motion.div>

//           {/* Right Section - Image */}
//           <motion.div
//             key={selected.image}
//             initial={{ opacity: 0, x: 50 }}
//             animate={{ opacity: 1, x: 0 }}
//             transition={{ duration: 0.5 }}
//             className="md:w-1/2 flex justify-center relative mt-6 md:mt-0"
//           >
//             <img
//               src={selected.image}
//               alt={selected.title}
//               className="rounded-lg w-[300px] h-[250px] md:w-[400px] md:h-[300px] lg:w-[500px] lg:h-[350px] shadow-lg object-cover"
//             />
//             {/* Floating UI Elements */}
//             <motion.div
//               className="absolute top-4 right-4 bg-white shadow-lg p-3 rounded-lg"
//               initial={{ opacity: 0, y: -10 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: 0.3 }}
//             >
//               <p className="text-sm font-semibold text-green-600">Opened</p>
//               <span className="text-lg font-bold">545 / 673</span>
//             </motion.div>
//             <motion.div
//               className="absolute bottom-4 right-4 bg-white shadow-lg p-3 rounded-lg"
//               initial={{ opacity: 0, y: 10 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: 0.3 }}
//             >
//               <p className="text-sm font-semibold text-purple-600">Bounced</p>
//               <span className="text-lg font-bold">120 / 459</span>
//             </motion.div>
//           </motion.div>
//         </div>
//       </div>

//       {/* Buttons Section */}
//       <div className="mt-6 flex flex-wrap gap-4 w-full justify-center border-t pt-4 pb-10">
//         {data.map((item) => (
//           <motion.button
//             key={item.id}
//             className={`px-6 py-3 rounded-full text-sm font-medium transition-all ${
//               selected.id === item.id
//                 ? "bg-black text-white shadow-lg scale-105"
//                 : "bg-gray-200 text-gray-700 hover:bg-gray-300"
//             }`}
//             onMouseEnter={() => setSelected(item)} // Changed from onClick to onMouseEnter
//             whileHover={{ scale: 1.1 }}
//             whileTap={{ scale: 0.95 }}
//           >
//             {item.title}
//           </motion.button>
//         ))}
//       </div>
//     </>
//   );
// }



// import { useState } from "react";
// import { motion } from "framer-motion";

// const data = [
//   {
//     id: 1,
//     title: "Email Campaign Tracking",
//     description:
//       "Monitor the performance of your email campaigns with real-time insights. Track open rates, replies, clicks, and bounces effortlessly, giving you the data you need to refine your strategy.",
//     image: "/tg2.jpg",
//   },
//   {
//     id: 2,
//     title: "Analytics & Reports",
//     description:
//       "Gain valuable insights with analytics and reports. Understand user engagement, open rates, and conversions to optimize your email campaigns.",
//     image: "/tg2.jpg",
//   },
//   {
//     id: 3,
//     title: "Contact Sync",
//     description:
//       "Seamlessly sync your contacts and manage email lists with ease. Ensure accurate data and smooth communication with your audience.",
//     image: "/tg2.jpg",
//   },
//   {
//     id: 4,
//     title: "Sales Pipeline",
//     description:
//       "Optimize your sales pipeline with targeted email campaigns. Convert leads into customers with data-driven email marketing strategies.",
//     image: "/tg2.jpg",
//   },
// ];

// export default function EmailCampaignUI() {
//   const [selected, setSelected] = useState(data[0]);

//   return (
//     <>
//       {/* Main Heading Section */}
//       <div className="text-center mb-8 mt-8">
//         <h1 className="text-4xl font-bold text-gray-900">Services</h1>
//         <h2 className="text-xl text-gray-700 mt-2">
//           Tools to Simplify Your Work
//         </h2>
//         <p className="text-gray-500 max-w-2xl mx-auto mt-2">
//           Explore a wide array of specialized services tailored to meet diverse client needs.
//         </p>
//       </div>

//       <div className="flex flex-col items-center p-8 shadow-lg rounded-lg w-full max-w-7xl mx-auto">
//         {/* Content and Image Section */}
//         <div className="flex flex-col md:flex-row items-center justify-between w-full">
//           {/* Left Section - Text Content */}
//           <motion.div
//             key={selected.id}
//             initial={{ opacity: 0, x: -50 }}
//             animate={{ opacity: 1, x: 0 }}
//             transition={{ duration: 0.5 }}
//             className="md:w-1/2 space-y-4 text-center md:text-left"
//           >
//             <h2 className="text-3xl font-bold">{selected.title}</h2>
//             <p className="text-gray-600">{selected.description}</p>
//             <a href="#" className="text-blue-600 font-semibold hover:underline">
//               Know More &rarr;
//             </a>
//           </motion.div>

//           {/* Right Section - Image */}
//           <motion.div
//             key={selected.image}
//             initial={{ opacity: 0, x: 50 }}
//             animate={{ opacity: 1, x: 0 }}
//             transition={{ duration: 0.5 }}
//             className="md:w-1/2 flex justify-center relative mt-6 md:mt-0"
//           >
//             <img
//               src={selected.image}
//               alt={selected.title}
//               className="rounded-lg w-[300px] h-[250px] md:w-[400px] md:h-[300px] lg:w-[500px] lg:h-[350px] shadow-lg object-cover"
//             />
//             {/* Floating UI Elements */}
//             <motion.div
//               className="absolute top-4 right-4 bg-white shadow-lg p-3 rounded-lg"
//               initial={{ opacity: 0, y: -10 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: 0.3 }}
//             >
//               <p className="text-sm font-semibold text-green-600">Opened</p>
//               <span className="text-lg font-bold">545 / 673</span>
//             </motion.div>
//             <motion.div
//               className="absolute bottom-4 right-4 bg-white shadow-lg p-3 rounded-lg"
//               initial={{ opacity: 0, y: 10 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: 0.3 }}
//             >
//               <p className="text-sm font-semibold text-purple-600">Bounced</p>
//               <span className="text-lg font-bold">120 / 459</span>
//             </motion.div>
//           </motion.div>
//         </div>
//       </div>

//       {/* Buttons Section */}
//       <div className="mt-6 flex flex-wrap gap-4 w-full justify-center border-t pt-4 pb-10">
//         {data.map((item) => (
//           <motion.button
//             key={item.id}
//             className={`px-6 py-3 rounded-full text-sm font-medium transition-all ${
//               selected.id === item.id
//                 ? "bg-black text-white shadow-lg scale-105"
//                 : "bg-gray-200 text-gray-700 hover:bg-gray-300"
//             }`}
//             onClick={() => setSelected(item)}
//             whileHover={{ scale: 1.1 }}
//             whileTap={{ scale: 0.95 }}
//           >
//             {item.title}
//           </motion.button>
//         ))}
//       </div>
//     </>
//   );
// }

// import { useState } from "react";
// import { motion } from "framer-motion";

// const data = [
//   {
//     id: 1,
//     title: "Email Campaign Tracking",
//     description:
//       "Monitor the performance of your email campaigns with real-time insights. Track open rates, replies, clicks, and bounces effortlessly, giving you the data you need to refine your strategy.",
//     image: "/tg2.jpg",
//   },
//   {
//     id: 2,
//     title: "Analytics & Reports",
//     description:
//       "Gain valuable insights with analytics and reports. Understand user engagement, open rates, and conversions to optimize your email campaigns.",
//     image: "/tg2.jpg",
//   },
//   {
//     id: 3,
//     title: "Contact Sync",
//     description:
//       "Seamlessly sync your contacts and manage email lists with ease. Ensure accurate data and smooth communication with your audience.",
//     image: "/tg2.jpg",
//   },
//   {
//     id: 4,
//     title: "Sales Pipeline",
//     description:
//       "Optimize your sales pipeline with targeted email campaigns. Convert leads into customers with data-driven email marketing strategies.",
//     image: "/tg2.jpg",
//   },
// ];

// export default function EmailCampaignUI() {
//   const [selected, setSelected] = useState(data[0]);

//   return (
//     <>
//       <div className="flex flex-col items-center p-8  shadow-lg rounded-lg w-full max-w-7xl mx-auto mt-16  ">
//         {/* Content and Image Section */}
//         <div className="flex flex-col md:flex-row items-center justify-between w-full">
//           {/* Left Section - Text Content */}
//           <motion.div
//             key={selected.id}
//             initial={{ opacity: 0, x: -50 }}
//             animate={{ opacity: 1, x: 0 }}
//             transition={{ duration: 0.5 }}
//             className="md:w-1/2 space-y-4 text-center md:text-left"
//           >
//             <h2 className="text-3xl font-bold">{selected.title}</h2>
//             <p className="text-gray-600">{selected.description}</p>
//             <a href="#" className="text-blue-600 font-semibold hover:underline">
//               Know More &rarr;
//             </a>
//           </motion.div>

//           {/* Right Section - Image */}
//           <motion.div
//             key={selected.image}
//             initial={{ opacity: 0, x: 50 }}
//             animate={{ opacity: 1, x: 0 }}
//             transition={{ duration: 0.5 }}
//             className="md:w-1/2 flex justify-center relative mt-6 md:mt-0"
//           >
//            <img
//   src={selected.image}
//   alt={selected.title}
//   className="rounded-lg w-[300px] h-[250px] md:w-[400px] md:h-[300px] lg:w-[500px] lg:h-[350px] shadow-lg object-cover"
// />
//             {/* Floating UI Elements */}
//             <motion.div
//               className="absolute top-4 right-4 bg-white shadow-lg p-3 rounded-lg"
//               initial={{ opacity: 0, y: -10 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: 0.3 }}
//             >
//               <p className="text-sm font-semibold text-green-600">Opened</p>
//               <span className="text-lg font-bold">545 / 673</span>
//             </motion.div>
//             <motion.div
//               className="absolute bottom-4 right-4 bg-white shadow-lg p-3 rounded-lg"
//               initial={{ opacity: 0, y: 10 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: 0.3 }}
//             >
//               <p className="text-sm font-semibold text-purple-600">Bounced</p>
//               <span className="text-lg font-bold">120 / 459</span>
//             </motion.div>
//           </motion.div>
//         </div>
//       </div>

//       {/* Buttons Section */}
//       <div className="mt-6 flex flex-wrap gap-4 w-full justify-center border-t pt-4 pb-10">
//         {data.map((item) => (
//           <motion.button
//             key={item.id}
//             className={`px-6 py-3 rounded-full text-sm font-medium transition-all ${
//               selected.id === item.id
//                 ? "bg-black text-white shadow-lg scale-105"
//                 : "bg-gray-200 text-gray-700 hover:bg-gray-300"
//             }`}
//             onClick={() => setSelected(item)}
//             whileHover={{ scale: 1.1 }}
//             whileTap={{ scale: 0.95 }}
//           >
//             {item.title}
//           </motion.button>
//         ))}
//       </div>
//     </>
//   );
// }
