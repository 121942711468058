import React, { useEffect, useState } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useAnimation,
} from "framer-motion";
import { Link } from "react-router-dom";
import {
  FaBookmark,
  FaChartBar,
  FaUsers,
  FaRocket,
  FaFolder,
  FaSyncAlt,
  FaUsersCog,
  FaEye,
  FaChevronDown,
  FaChartLine,
  FaBullseye,
} from "react-icons/fa";
import SliderHome from "./SliderHome";
import TestimonialSlider from "./TestimonialSlider";
import HeroCarousel from "./HeroCarousel";
import BannerHome from "./BannerHome";
import CaseStudyApp from "./CaseStudies";
import BlogSection from "./BlogHome";
import EmailCampaignUI from "./HomeContentUi";
import ServiceHome from "./ServiceHome";
import ServiceSectionAll from "./ServiceSectionAll";
import StatsSection from "./StatsSection";
import { Helmet } from "react-helmet";
import { useRef } from "react";

// Scroll Down Indicator Component
const ScrollIndicator = () => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      y: [0, 8, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut",
      },
    });
  }, []);

  return (
    <motion.div
      className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex flex-col items-center gap-2 cursor-pointer z-50"
      onClick={() =>
        window.scrollBy({ top: window.innerHeight, behavior: "smooth" })
      }
    >
      <motion.p className="text-white text-sm">Scroll Down</motion.p>
      <motion.div animate={controls} className="text-white">
        <FaChevronDown size={24} />
      </motion.div>
    </motion.div>
  );
};

// Progress Bar Component
const ProgressBar = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <motion.div
      className="fixed top-0 left-0 right-0 h-1 bg-gradient-to-r from-purple-600 to-blue-600 z-50 origin-left"
      style={{ scaleX }}
    />
  );
};

const Home = () => {

  const { scrollY } = useScroll();

  useEffect(() => {
    setIsLoaded(true);
  }, []);
 const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
 useEffect(() => {
  if (videoRef.current) {
    videoRef.current.setAttribute("playsinline", "true");
    videoRef.current.setAttribute("muted", "true");
    videoRef.current
      .play()
      .then(() => setIsLoaded(true))
      .catch((error) => {
        console.log("Autoplay prevented:", error);
      });
  }
}, []);
  // Optimized transform values
  const heroOpacity = useTransform(scrollY, [0, 400], [1, 0]);
  const heroScale = useTransform(scrollY, [0, 400], [1, 0.95]);
  const { scrollYProgress } = useScroll();
  const titleY = useTransform(scrollYProgress, [0, 0.5], ["0%", "-30%"]); // Parallax effect
  const textOpacity = useTransform(scrollYProgress, [0.3, 0.7], [1, 0]); // Fades out as you scroll
  // Reusable animation variants
  const fadeInUpVariant = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  const services = [
    {
      icon: <FaBookmark />,
      title: "Smart Bookmarks",
      description:
        "Save and quickly access essential client details, streamlining your workflow and boosting productivity.",
    },
    // ... other services
  ];
  const caseStudies = [
    {
      id: 1,
      title: "A CTV Triumph",
      description:
        "We launched a high-impact CTV campaign for De Beers Forevermark, seamlessly blending luxury with digital innovation for Diwali. Precision targeting and a captivating L-band creative drove exceptional engagement, achieving a 97%+ VTR and 3M+ impressions. This campaign reinforced De Beers Forevermark as the premier choice for festive luxury jewelry.",
      image: "/de.jpg",
      link: "/fashion-lifestyle",
    },
    {
      id: 2,
      title: "A Game-Changing Strategy",
      description:
        "Our Hotstar campaign for the Cricket World Cup 2023 maximized engagement by targeting key India match days with real-time optimizations. Through precision targeting and dynamic ad placements, we captured peak audience moments, driving massive interaction. The result: 15M+ clicks and 6.7M+ video views, proving the power of agile, high-impact marketing.",
      image: "/disneyhotstar.jpg",
      link: "/entertainment",
    },
    {
      id: 3,
      title: "A Programmatic Triumph ",
      description:
        "We elevated Indofoods' brand presence in Indonesia through programmatic advertising, leveraging data-driven targeting to engage key consumer segments. With real-time optimizations and engaging creatives. The campaign achieved an impressive 98% VTR and reached 853K+ consumers, solidifying Indofoods' market leadership.",
      image: "/smallindofood.jpg",
      link: "/fmcg",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          BidBox AI – Your DSP Solution Partner for Targeted Advertising
          Campaigns
        </title>
        <meta
          name="description"
          content="Discover BidBox AI, a leading Demand-Side Platform (DSP) offering advanced solutions for targeted advertising campaigns. Optimize your ad spend and reach your audience effectively."
        />
        <meta
          name="keywords"
          content="BidBox AI, Demand-Side Platform, DSP solutions, targeted advertising, ad campaign optimization, programmatic advertising, real-time bidding, ad tech, audience segmentation, AI-driven advertising, digital marketing, performance marketing, cross-channel advertising, ad exchange, ad placement, ad inventory, ad network, data-driven marketing, media buying."
        />
      </Helmet>
      <div className="w-full overflow-hidden">
        {/* <ProgressBar /> */}

        {/* Hero Section */}
        {/* <motion.div 
        className="relative h-screen"
        style={{ opacity: heroOpacity, scale: heroScale }}
      >
        <div className="video-background">
          <video 
            autoPlay 
            muted 
            loop 
            className="w-full h-screen object-cover"
          >
            <source src="/assets/bg.mp4" type="video/mp4" />
          </video>
        </div>

        <motion.div 
          className="absolute inset-0 flex items-center justify-center z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <div className="px-2 max-w-3xl mx-auto text-center">
            <motion.h1 
              className="text-white text-4xl md:text-6xl font-bold mb-6"
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.8 }}
            >
              AI-Powered Advertising for Maximum Impact
            </motion.h1>
            <motion.p 
              className="text-white text-xl md:text-2xl mb-8 "
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 1 }}
            >
              Transform your digital advertising strategy with Bidbox.ai—an intelligent DSP that optimizes ad spend, enhances audience targeting, and delivers measurable results with AI precision.
            </motion.p>
            <motion.div
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 1.2 }}
            >
              <Link to="/contact">
                <motion.button 
                  className="px-8 py-4 bg-gradient-to-r from-gray-700 to-blue-900 text-white rounded-full text-lg font-medium"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Start Your Campaign
                </motion.button>
              </Link>
            </motion.div>
          </div>
        </motion.div>
        
        <ScrollIndicator />
      </motion.div> */}
        {/* <motion.div
          className="relative min-h-screen"
          style={{ opacity: heroOpacity, scale: heroScale }}
        >
          <div className="video-background">
            <video
              autoPlay
              muted
              loop
              playsinline
              preload="auto"
              className="w-full h-screen object-cover"
            >
              <source src="/bg.mp4" type="video/mp4" />
              <source src="/assets/bg.webm" type="video/webm" /> 
              Your browser does not support the video tag.
            </video>
          </div>

          <motion.div
            className="absolute inset-0 flex items-center justify-center z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <div className="px-2 max-w-3xl mx-auto text-center">
              <motion.h1
                className="text-white text-4xl md:text-6xl font-bold mb-6"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.8 }}
              >
                AI-Powered Advertising for Maximum Impact
              </motion.h1>
              <motion.p
                className="text-white text-xl md:text-2xl mb-8"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 1 }}
              >
                Transform your digital advertising strategy with Bidbox.ai—an
                intelligent DSP that optimizes ad spend, enhances audience
                targeting, and delivers measurable results with AI precision.
              </motion.p>
              <motion.div
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 1.2 }}
              >
                <Link to="/contact">
                  <motion.button
                    className="px-8 py-4 bg-gradient-to-r from-gray-700 to-blue-900 text-white rounded-full text-lg font-medium"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Start Your Campaign
                  </motion.button>
                </Link>
              </motion.div>
            </div>
          </motion.div>

          <ScrollIndicator />
        </motion.div> */}
   <div className="relative w-full min-h-screen overflow-hidden">
      {/* Scroll Indicator Bar */}
      <motion.div
        className="fixed top-0 left-0 h-1 bg-blue-500"
        style={{ width: scrollYProgress }}
      />

      {/* Background Video with Fade-In Effect */}
      <motion.div
        className="absolute inset-0 w-full h-full z-[-1] bg-black"
        initial={{ opacity: 0 }}
        animate={{ opacity: isLoaded ? 1 : 0 }}
        transition={{ duration: 1.5 }}
      >
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
          // poster="/fallback-image.jpg" // Fallback image
          className="w-full h-full object-cover"
        >
          <source src="/bg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </motion.div>

      {/* Content with Scroll Animations */}
      <div className="relative flex items-center justify-center min-h-screen text-center px-4">
        <div className="max-w-3xl mx-auto">
          <motion.h1
            className="text-white text-4xl md:text-6xl font-bold mb-6"
            style={{ y: titleY, opacity: textOpacity }}
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            AI-Powered Advertising for Maximum Impact
          </motion.h1>

          <motion.p
            className="text-white text-xl md:text-2xl mb-8"
            style={{ opacity: textOpacity }}
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
          >
            Transform your digital advertising strategy with Bidbox.ai—an
            intelligent DSP that optimizes ad spend, enhances audience
            targeting, and delivers measurable results with AI precision.
          </motion.p>

          <motion.div
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <a href="/contact">
              <motion.button
                className="px-8 py-4 bg-gradient-to-r from-gray-700 to-blue-900 text-white rounded-full text-lg font-medium"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                Start Your Campaign
              </motion.button>
            </a>
            {/* <ScrollIndicator /> */}
          </motion.div>
         
        </div>
      </div>
    </div>
        {/* About Section */}
        <motion.section
          className="md:py-14  lg:py-14 py-2 px-4 bg-white mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.4 }}
        >
          <motion.div
            className="max-w-4xl mx-auto text-center"
            variants={fadeInUpVariant}
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-2">
              About Bidbox
            </h2>
            <p className="text-xl text-gray-600 mb-12">
              Bidbox.ai is an AI-driven Demand-Side Platform (DSP) designed for
              brands, agencies, and advertisers looking to maximize ROI through
              data-driven programmatic advertising. Our advanced automation and
              real-time AI optimizations ensure every ad dollar is allocated
              effectively, driving higher engagement, better conversions, and
              seamless omnichannel execution.
            </p>
          </motion.div>

          {/* Video Section (Outside Content Wrapper) */}
          <div className="flex justify-center mt-12 md:mt-20">
            <div className="w-full max-w-6xl h-[500px]">
              <iframe
                className="w-full h-full"
                src="https://www.youtube.com/embed/z8uvimXXlx4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          {/* <div className="flex justify-center mt-12 md:mt-20">
    <div className="w-full max-w-6xl h-[500px]">
      <iframe
        className="w-full h-full"
        src="https://www.youtube.com/embed/QsY8fnvMn6c"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  </div> */}
        </motion.section>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.4 }}
          variants={fadeInUpVariant}
        >
          <ServiceHome />
        </motion.div>

        {/* Other Sections with Optimized Animations */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.4 }}
          variants={fadeInUpVariant}
        >
          <SliderHome />
        </motion.div>
        <div className="max-w-7xl mx-auto p-4 mt-10">
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-10">
            Case Studies
            <p className="text-gray-600 text-lg text-center">
              Real-world examples of how top brands have leveraged Bidbox.ai to
              achieve higher engagement, optimized ad spend, and superior
              campaign performance.
            </p>
          </h2>

          {/* Cards Grid */}
          <div className="grid gap-9 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
            {caseStudies.map((study) => (
              <div className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transform transition-all duration-300 hover:shadow-xl">
                <img
                  src={study.image}
                  alt={study.title}
                  className="w-full h-56 object-cover rounded-t-2xl"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {study.title}
                  </h3>
                  <p className="text-gray-600 mt-2">{study.description}</p>

                  {/* Read More Button */}
                  <Link
                    to={study.link}
                    className="inline-block mt-4 px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {/* View All Button */}
          {/* <div className="text-center mt-12">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className="px-6 py-3 text-lg font-medium text-white bg-gradient-to-r from-blue-600 to-purple-500 rounded-lg shadow-lg transition-all"
        >
          View All
        </motion.button>
      </div> */}
        </div>
        {/* <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.4 }}
        variants={fadeInUpVariant}
      >
        <CaseStudyApp />
      </motion.div> */}
        <div className="py-10 bg-gray-50 dark:bg-slate-800 sm:py-16 lg:py-24">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="max-w-xl mx-auto text-center">
              {/* <p class="text-sm tracking-widest text-blue-600 dark:text-sky-300 font-bold uppercase">Open Source Theme and UI Components</p> */}

              <h2 class="mt-6 text-3xl font-bold leading-tight text-black dark:text-slate-50 sm:text-4xl lg:text-5xl">
                BidBox AI simplifies your ad campaigns with a seamless,
                intuitive UI
              </h2>
            </div>

            <div class="grid items-center grid-cols-1 mt-12 gap-y-10 lg:grid-cols-5 sm:mt-20 gap-x-4">
              <div class="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-12">
                <div class="flex items-start">
                  <FaChartLine className="flex-shrink-0 text-green-500 w-9 h-9" />

                  <div class="ml-5">
                    <h3 class="text-xl font-semibold text-black dark:text-slate-50">
                      Effortless Campaign Management
                    </h3>
                    <p class="mt-3 text-base text-gray-600 dark:text-slate-300">
                      Easily create, manage, and optimize campaigns with a
                      streamlined dashboard that puts everything at your
                      fingertips.
                    </p>
                  </div>
                </div>

                <div class="flex items-start">
                  <FaBullseye className="flex-shrink-0 text-blue-600 w-9 h-9" />

                  <div class="ml-5">
                    <h3 class="text-xl font-semibold text-black dark:text-slate-50">
                      Advanced Targeting Made Simple
                    </h3>
                    <p class="mt-3 text-base text-gray-600 dark:text-slate-300">
                      Leverage precise targeting options, including geo-fencing,
                      retargeting, and lookalike and custom audience
                      segmentation, to maximize your ad performance.
                    </p>
                  </div>
                </div>

                <div class="flex items-start">
                  <FaRocket className="flex-shrink-0 text-red-500 w-9 h-9" />

                  <div class="ml-5">
                    <h3 class="text-xl font-semibold text-black dark:text-slate-50">
                      Lightning-Fast Execution
                    </h3>
                    <p class="mt-3 text-base text-gray-600 dark:text-slate-300">
                      With an intuitive interface and real-time analytics,
                      launch and adjust campaigns in seconds for peak
                      efficiency.
                    </p>
                  </div>
                </div>
              </div>

              <div class="lg:col-span-3">
                <img class="w-full " src="/assets/demo1.gif" />
              </div>
            </div>
          </div>
        </div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.4 }}
          variants={fadeInUpVariant}
        >
          <HeroCarousel />
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={fadeInUpVariant}
        >
          <EmailCampaignUI />
        </motion.div>

        <ServiceSectionAll />

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.4 }}
          variants={fadeInUpVariant}
        >
          <StatsSection />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.4 }}
          variants={fadeInUpVariant}
        >
          <BannerHome />
        </motion.div>

        {/* <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.4 }}
        variants={fadeInUpVariant}
      >
        <TestimonialSlider />
      </motion.div> */}

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={fadeInUpVariant}
        >
          <BlogSection />
        </motion.div>
      </div>
    </>
  );
};

export default Home;

// import React, { useEffect, useState } from "react";
// import CardSection from "./CardSection";
// import AOS from "aos";
// import "aos/dist/aos.css"; // Import AOS CSS for styling
// import { Link } from "react-router-dom";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import EmailCampaignUI from "./HomeContentUi";
// import { motion } from "framer-motion";

// import { FaBookmark, FaChartBar, FaUsers, FaRocket, FaFolder, FaSyncAlt, FaUsersCog, FaEye } from "react-icons/fa";
// import SliderHome from "./SliderHome";
// import TestimonialSlider from "./TestimonialSlider";
// import HeroCarousel from "./HeroCarousel";
// import BannerHome from "./BannerHome";
// import CaseStudyApp from "./CaseStudies";
// import BlogSection from "./BlogHome";

// const Home = () => {
//   useEffect(() => {
//     AOS.init({
//       duration: 500, // Animation duration in milliseconds
//       delay: 50, // Delay between elements
//     easing: "ease-in-out",
//     offset: 100
//     });
//   }, []);

//   const slides = [
//     { image: "/swiggy.png" },
//     { image: "/zivame.jpeg" },
//     { image: "/tata.png" },
//     { image: "/citroen.jpeg" },
//     { image: "/slider5.jpg" },
//     { image: "/slider6.jpg" },
//     { image: "/slider7.jpg" },
//     { image: "/slider8.jpg" },
//   ];

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 6,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const services = [
//     { icon: <FaBookmark />, title: "Smart Bookmarks", description: "Save and quickly access essential client details, streamlining your workflow and boosting productivity." },
//     { icon: <FaChartBar />, title: "Analytics & Insights", description: "Monitor sales trends and client data with powerful analytics to make smarter, data-driven decisions." },
//     { icon: <FaUsers />, title: "Lead Management", description: "Track and nurture leads effortlessly to convert prospects into loyal customers with a focused approach." },
//     { icon: <FaRocket />, title: "Sales Acceleration", description: "Automate sales processes and close deals faster with tools that help your team meet their targets efficiently." },
//     { icon: <FaFolder />, title: "Document Storage", description: "Securely store and organize client files in one central location, making them easy to find and manage anytime." },
//     { icon: <FaSyncAlt />, title: "Cross-Device Access", description: "Access your CRM on any device, ensuring real-time updates and uninterrupted workflow wherever you are." },
//     { icon: <FaUsersCog />, title: "Team Collaboration", description: "Enable seamless teamwork with tools for sharing notes, assigning tasks, and staying aligned on client goals." },
//     { icon: <FaEye />, title: "Activity Monitoring", description: "Track client interactions, from calls to emails, and gain visibility into their journey." },
//   ];
//   const steps = [
//     {
//       id: 1,
//       title: "Organize Contacts Efficiently",
//       description:
//         "Centralize all client details, including names, emails, and phone numbers, for easy management and quick access anytime.",
//       image: "image1.png",
//     },
//     {
//       id: 2,
//       title: "Track Progress Seamlessly",
//       description:
//         "Monitor your project milestones and key statistics in real-time to stay on top of every task and goal.",
//       image: "image1.png",
//     },
//     {
//       id: 3,
//       title: "Engage Smarter",
//       description:
//         "Enhance collaboration with smart insights and tools that keep you connected with your team and clients.",
//       image: "image1.png",
//     },
//   ];

//     const [activeStep, setActiveStep] = useState(1);
//   return (
//     <>
//       <div className="w-full overflow-hidden">
//         <div className="parallaxContainer w-full h-screen">
//           <div className="video-background">
//             <video autoPlay muted loop className="w-full h-auto">
//               <source src="/bitbox_bg.mp4" type="video/mp4" />
//             </video>
//           </div>

//           <div className="parallaxContent top-0 z-40 w-full">
//             <div className="w-full flex flex-col items-center">
//               <div className="w-[95%] mt-60 flex items-center justify-between">
//                 <div className="md:w-[50%] caption flex flex-col items-center md:flex-none md:items-start">
//                   <h1 className="text-white  py-4 px-4 text-center md:text-left text-3xl md:text-5xl font-bold">
//                   Unlock the Future of Advertising with AI-Powered Precision
//                   </h1>
//                   <p className="text-white text-center px-4 md:text-left text-xl md:text-2xl font-medium pt-5">
//                   Bidbox helps brands optimize ad spend, target audiences intelligently, and drive better results—effortlessly.
//                   </p>
//                   <Link to="/contact">
//                     <button className="mt-10 px-4 md:px-8 py-2 md:py-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white md:text-lg font-medium shadow-xl shadow-gray-900 hover:opacity-90">
//                     Launch Your Campaign
//                     </button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="w-full bg-white ">
//           {/* <h1 className="text-center text-3xl md:text-6xl textColor font-bold mt-20">
//             Discover The Bidbox Hub
//           </h1> */}
//          {/* <div className="w-[95%] md:flex mb-10 md:mb-0 md:justify-between">
//             <div className="w-full md:w-[40%] flex flex-col items-center md:items-start justify-center">
//               <h1 className="text-xl mt-5 md:mt-0 text-center md:text-start md:text-4xl font-bold textColor pb-3">
//                 Precision Targeting
//               </h1>
//               <div className="w-full md:hidden md:w-[60%]">
//                 <img
//                   className="w-[100%] h-[100%] object-cover"
//                   src="/TG1.jpg"
//                   alt="imagee"
//                 />
//               </div>
//               <p className="text-base md:text-xl text-center md:text-start text-black">
//                 Unleash the full potential of your ad campaigns with
//                 laser-focused targeting.
//               </p>
//               <p className="text-base md:text-xl text-center md:text-start text-black pt-2">
//                 Our cutting-edge tools ensure you connect with your ideal
//                 audience, leading to higher engagement and increased conversion
//                 rates.
//               </p>
//               <div className="mt-5">
//                 <a href="/contact">
//                   <button className="rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5">
//                     Let&apos;s Start
//                   </button>
//                 </a>
//               </div>
//             </div>
//             <div className="w-full hidden md:block md:w-[60%]">
//               <img
//                 className="w-[90%] h-[90%] object-cover"
//                 src="/TG1.jpg"
//                 alt="imagee"
//               />
//             </div>
//           </div> */}

//           {/* <div className="w-[95%] md:flex mb-10 md:mb-0 md:justify-between]">
//             <div className="md:w-[55%] hidden md:flex">
//               <img
//                 className="w-[85%] h-[90%] object-cover"
//                 src="/tg2.jpg"
//                 alt="image2"
//               />
//             </div>

//             <div className="md:w-[40%] flex flex-col items-center md:items-start justify-center">
//               <h1 className="text-3xl mt-5 md:mt-0 text-center md:text-start md:text-4xl font-bold textColor pb-3">
//                 Instant ROI Insights
//               </h1>

//               <div className="md:w-[55%] md:hidden">
//                 <img
//                   className="w-[100%] h-[100%] object-cover"
//                   src="/tg2.jpg"
//                   alt="image2"
//                 />
//               </div>
//               <p className="text-base md:text-xl text-center md:text-start text-black">
//                 Elevate your marketing strategy with our real-time ROI insights.
//               </p>
//               <p className="text-base md:text-xl text-center md:text-start text-black pt-2">
//                 Witness the magic as you optimize and refine your campaigns on
//                 the fly, delivering exceptional returns that exceed your
//                 expectations.
//               </p>
//               <div className="mt-5">
//                 <a href="/contact">
//                   <button className="rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5">
//                     Let&apos;s Start
//                   </button>
//                 </a>
//               </div>
//             </div>
//           </div>  */}

//           {/* <div className="w-[95%] mb-20 md:mb-32 md:flex md:justify-between">
//             <div className="w-full md:w-[45%] flex flex-col items-center md:items-start justify-center">
//               <h1 className="text-3xl mt-5 md:mt-0 text-center md:text-start md:text-4xl font-bold textColor pb-3">
//                 Intuitive Dashboard Excellence
//               </h1>
//               <div className="w-full md:hidden my-5 md:w-[50%]">
//                 <video autoPlay muted loop className="w-full h-auto">
//                   <source src="/dashboard1.mp4" type="video/mp4" />
//                 </video>
//               </div>
//               <p className="text-base md:text-xl text-center md:text-start text-black">
//                 Intuitive Dashboard Excellence Experience the future of
//                 programmatic advertising at your fingertips.
//               </p>
//               <p className="text-base md:text-xl text-center md:text-start text-black pt-2">
//                 Our user-friendly dashboard is not just a tool; it's a
//                 game-changer. Customize, control, and conquer your advertising
//                 goals with ease, setting new standards for campaign management.
//               </p>
//               <div className="mt-5">
//                 <a href="/contact">
//                   <button className="rounded-lg text-lg font-semibold shadow-md border-2 border-[#00308F] textColor hover:bg-[#00308F] hover:text-white px-6 py-1.5">
//                     Let&apos;s Start
//                   </button>
//                 </a>
//               </div>
//             </div>
//             <div className="md:w-[50%] hidden md:block">
//               <video autoPlay muted loop className="w-full h-auto">
//                 <source src="/dashboard1.mp4" type="video/mp4" />
//               </video>
//             </div>
//           </div> */}

//           <section className="text-center p-6 mt-10">
//       <h4 className="text-blue-600  mt-10 text-1xl md:text-5xl font-bold textColor">About Us</h4>
//       <h2 className="text-5xl font-bold mt-2">What We Do</h2>
//       <p className="text-gray-600 text-lg mt-4 max-w-2xl mx-auto">
//       Bidbox is an AI-powered Demand-Side Platform (DSP) that enables brands to execute smarter, data-driven ad campaigns across multiple channels. With intelligent automation and real-time optimization, we ensure every ad dollar is spent effectively.
//       </p>
//       <div className="flex justify-center mt-12">
//       <iframe
//   width="1100"
//   height="500"
//   src="https://www.youtube.com/embed/QsY8fnvMn6c"
//   title="YouTube video player"
//   frameBorder="0"
//   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//   allowFullScreen
// ></iframe>
//       </div>
//     </section>
//     <section className="flex flex-col md:flex-row items-center justify-between px-6 md:px-16 py-12 bg-black gap-12">
//   {/* Left Image Section */}
//   <div className="md:w-1/2 relative">
//     <div className="bg-gradient-to-r from-purple-600 to-blue-600 p-6 rounded-lg shadow-lg transform scale-105 hover:scale-110 transition-transform duration-500 ease-in-out">
//       <img
//         src="/image1.png"
//         alt="Digital Team"
//         className="w-full h-auto rounded-lg"
//       />
//     </div>
//     <div className="absolute top-5 left-5 bg-white px-4 py-2 rounded-lg shadow-md text-gray-900 font-bold text-lg">
//       Digital <span className="text-gray-500 font-medium">SINCE 2018</span>
//     </div>
//   </div>

//   {/* Right Content */}
//   <div className="md:w-1/2 mt-10 md:mt-0 py-10">
//     {/* Section Title */}
//     <h2 className="text-3xl md:text-5xl font-bold text-white">
//       With Digital{" "}
//       <span className="bg-gradient-to-r from-purple-600 to-blue-600 px-2 rounded-md">We Orient</span> Teams
//       Around Revenue
//     </h2>
//     <p className="text-gray-300 text-lg mt-4">
//       We are here to allow teams to center their operations around revenue, driving
//       growth, efficiency, and sustainable success.
//     </p>

//     {/* Feature Cards */}
//     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
//       {/* Feature 1 */}
//       <div className="flex items-start space-x-4 bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300">
//         <span className="text-yellow-500 text-2xl">📁</span>
//         <div>
//           <h3 className="text-white font-semibold text-lg">
//             AI-Powered Targeting
//           </h3>
//           <p className="text-gray-300 text-sm">
//             Reach the right audience with real-time optimization.
//           </p>
//         </div>
//       </div>
//       {/* Feature 2 */}
//       <div className="flex items-start space-x-4 bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300">
//         <span className="text-yellow-500 text-2xl">📁</span>
//         <div>
//           <h3 className="text-white font-semibold text-lg">
//             Smart Budget Allocation
//           </h3>
//           <p className="text-gray-300 text-sm">
//             Maximize ROI with automated bidding strategies.
//           </p>
//         </div>
//       </div>
//       {/* Feature 3 */}
//       <div className="flex items-start space-x-4 bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300">
//         <span className="text-yellow-500 text-2xl">📁</span>
//         <div>
//           <h3 className="text-white font-semibold text-lg">
//             Advanced Analytics
//           </h3>
//           <p className="text-gray-300 text-sm">
//             Track, analyze, and optimize campaigns effortlessly.
//           </p>
//         </div>
//       </div>
//       {/* Feature 4 */}
//       <div className="flex items-start space-x-4 bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300">
//         <span className="text-blue-500 text-2xl">💡</span>
//         <div>
//           <h3 className="text-white font-semibold text-lg">
//             Multi-Channel Execution
//           </h3>
//           <p className="text-gray-300 text-sm">
//             Run campaigns across CTV, social, and programmatic ads seamlessly.
//           </p>
//         </div>
//       </div>
//     </div>
//   </div>
// </section>

// <SliderHome/>
//  <CaseStudyApp/>
//           {/* <div className="w-full flex flex-col items-center my-5 md:my-10">
//             <div className="w-[95%] p-2 md:mt-10">
//               <div className="container mt-16 font-Kanit, sans-serif">
//                 <div className="text-center my-10">
//                   <h1 className="text-center text-3xl md:text-6xl textColor font-bold mt-10">
//                     Our Clientele
//                   </h1>
//                   <p className="text-xl font-medium text-black py-5">
//                     We proudly serve a diverse clientele, spanning various
//                     industries and backgrounds, reflecting our commitment to
//                     inclusivity and adaptability.
//                   </p>
//                 </div>
//                 <Slider {...settings}>
//                   {slides.map((slide, index) => (
//                     <div key={index}>
//                       <img
//                         src={process.env.PUBLIC_URL + slide.image}
//                         alt={`Image ${index + 1}`}
//                         style={{
//                           width: "90%",
//                           height: "150px",
//                           border: "1px solid #",
//                         }}
//                       />
//                     </div>
//                   ))}
//                 </Slider>
//               </div>
//             </div>
//           </div> */}

// <HeroCarousel/>
//           {/* <section className="flex flex-col md:flex-row items-center justify-between px-6 md:px-16 py-12 bg-white">

//       <div className="md:w-1/2 text-center md:text-left">

//         <div className="w-12 h-12 bg-gray-100 flex items-center justify-center rounded-lg mb-4">
//           <span className="text-blue-500 text-xl">⚡</span>
//         </div>

//         <h1 className="text-3xl md:text-5xl font-bold text-gray-900">
//           Cutting-Edge <span className="bg-indigo-100 px-2 rounded-md">Solutions</span> for
//           Modern Tech Challenges
//         </h1>

//         <p className="text-gray-500 text-lg mt-4">
//           Whether you're looking to centralize your content repository or leverage analytics
//           for insights, our system has got you covered.
//         </p>

//         <button className="mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 px-6 rounded-lg">
//           Know More
//         </button>
//       </div>

//       <div className="md:w-1/2 mt-10 md:mt-0">
//         <img
//           src="/image1.png"
//           alt="Tech Solutions"
//           className="w-full rounded-lg shadow-md"
//         />
//       </div>
//     </section> */}
//           <EmailCampaignUI/>

//           <div className="relative  bg-gradient-to-r from-purple-600 to-blue-600 border  py-16 px-6 ">
//       <h2 className="text-center text-3xl md:text-5xl font-bold  text-white">Services</h2>
//       <p className="text-center text-lg text-white max-w-2xl mx-auto mt-4 mb-12">
//         Explore a wide array of specialized services tailored to meet diverse client needs.
//       </p>

//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
//         {services.map((service, index) => (
//           <motion.div
//             key={index}
//             className="relative bg-white backdrop-blur-md p-6 rounded-2xl shadow-lg text-center border border-gray-200 transition-all duration-300"
//             initial={{ opacity: 0, y: 30 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ delay: index * 0.1 }}
//             whileHover={{ scale: 1.05, translateY: -5, boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.2)" }}
//           >
//             <div className="w-16 h-16 mx-auto mb-4 flex items-center justify-center bg-gradient-to-r from-blue-500 to-indigo-500 text-white text-3xl rounded-2xl shadow-xl transition-transform transform hover:rotate-6">
//               {service.icon}
//             </div>
//             <h3 className="text-xl font-semibold text-gray-900">{service.title}</h3>
//             <p className="text-gray-700 mt-2">{service.description}</p>

//             <motion.div
//               className="absolute inset-0 bg-white/40 rounded-2xl opacity-0 transition-all duration-300 pointer-events-none"
//               whileHover={{ opacity: 0.5, filter: "blur(8px)" }}
//             />
//           </motion.div>
//         ))}
//       </div>
//     </div>
//     {/* <div className="w-full py-16 px-4 md:px-8 lg:px-16 ">
//       <div className="max-w-[1200px] mx-auto flex flex-col-reverse lg:flex-row items-center justify-between">

//         <div className="w-full lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
//           <h2 className="text-[32px] sm:text-[36px] lg:text-[40px] font-bold text-[#0f172a] mb-4">
//             Your Trusted Partner in Success
//           </h2>
//           <p className="text-[#475569] text-lg sm:text-xl lg:text-2xl leading-relaxed">
//             We are committed to providing innovative solutions that help your business
//             grow. With our expertise and dedication, we ensure your success every step
//             of the way.
//           </p>
//         </div>

//         <div className="w-full lg:w-1/2 flex justify-center">
//           <img
//             src="image1.png"
//             alt="Your image description"
//             className="w-full h-auto rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300 ease-in-out"
//           />
//         </div>
//       </div>
//     </div> */}

//     {/* <div className="flex flex-col lg:flex-row items-center justify-between p-6 lg:p-12 bg-gray-100">

//       <div className="w-full lg:w-1/2">
//         <h2 className="text-3xl font-bold text-gray-900 mb-6">
//           Three Guiding Steps for Business Success
//         </h2>
//         <p className="text-gray-600 mb-8">
//           Manage your business effortlessly with our streamlined tools.
//         </p>

//         <div className="space-y-6">
//           {steps.map((step, index) => (
//             <div
//               key={index}
//               className="flex items-start cursor-pointer"
//               onClick={() => setActiveStep(index)}
//             >
//               <div
//                 className={`w-10 h-10 flex items-center justify-center rounded-full text-white font-semibold transition-all duration-300 ${
//     activeStep === index ? "bg-purple-600" : "bg-gray-300"
//   }`}
//               >
//                 {index + 1}
//               </div>
//               <div className="ml-4">
//                 <h3
//                   className={`text-lg font-semibold transition-all duration-300 ${
//     activeStep === index ? "text-gray-900" : "text-gray-500"
//   }`}
//                 >
//                   {step.title}
//                 </h3>
//                 {activeStep === index && (
//                   <motion.p
//                     initial={{ opacity: 0, y: 10 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.3 }}
//                     className="text-gray-600 mt-2"
//                   >
//                     {step.description}
//                   </motion.p>
//                 )}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       <motion.div
//         key={activeStep}
//         initial={{ opacity: 0, x: 20 }}
//         animate={{ opacity: 1, x: 0 }}
//         transition={{ duration: 0.5 }}
//         className="w-full lg:w-1/2 mt-8 lg:mt-0"
//       >
//         <img
//           src={steps[activeStep].image}
//           alt={steps[activeStep].title}
//           className="rounded-lg shadow-lg w-full h-auto"
//         />
//       </motion.div>
//     </div> */}
//     {/* <div className="flex flex-col lg:flex-row items-center justify-between p-6 lg:p-12 bg-gray-100">

//   <div className="w-full lg:w-1/2">
//     <h2 className="text-3xl font-bold text-gray-900 mb-6">
//       Three Guiding Steps for Business Success
//     </h2>
//     <p className="text-gray-600 mb-8">
//       Manage your business effortlessly with our streamlined tools.
//     </p>

//     <div className="relative space-y-6">
//       {steps.map((step, index) => (
//         <div key={index} className="relative flex items-start cursor-pointer" onClick={() => setActiveStep(index)}>

//           {index > 0 && (
//             <div className={`absolute left-[19px] top-[-25px] h-10 w-[2px] ${activeStep >= index ? "bg-purple-600" : "bg-gray-300"} transition-all duration-300`} />
//           )}

//           <div className={`relative z-10 w-10 h-10 flex items-center justify-center rounded-full text-white font-semibold transition-all duration-300 ${activeStep === index ? "bg-purple-600" : "bg-gray-300"}`}>
//             {index + 1}
//           </div>

//           <div className="ml-4">
//             <h3 className={`text-lg font-semibold transition-all duration-300 ${activeStep === index ? "text-gray-900" : "text-gray-500"}`}>
//               {step.title}
//             </h3>
//             {activeStep === index && (
//               <motion.p initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }} className="text-gray-600 mt-2">
//                 {step.description}
//               </motion.p>
//             )}
//           </div>
//         </div>
//       ))}
//     </div>
//   </div>

//   <motion.div key={activeStep} initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} className="w-full lg:w-1/2 mt-8 lg:mt-0">
//     <img src={steps[activeStep].image} alt={steps[activeStep].title} className="rounded-lg shadow-lg w-full h-auto" />
//   </motion.div>
// </div> */}
// {/* <section class="py-10 bg-gray-50 dark:bg-slate-800 sm:py-16 lg:py-24">
//     <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
//         <div class="max-w-xl mx-auto text-center">
//             <p class="text-sm tracking-widest text-blue-600 dark:text-sky-300 font-bold uppercase">Open Source Theme and UI Components</p>

//             <h2 class="mt-6 text-3xl font-bold leading-tight text-black dark:text-slate-50 sm:text-4xl lg:text-5xl">Geaux Astro helps you craft beautiful websites efficiently</h2>
//         </div>

//         <div class="grid items-center grid-cols-1 mt-12 gap-y-10 lg:grid-cols-5 sm:mt-20 gap-x-4">
//             <div class="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-12">
//                 <div class="flex items-start">
//                     <svg class="flex-shrink-0 text-green-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                         <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
//                     </svg>
//                     <div class="ml-5">
//                         <h3 class="text-xl font-semibold text-black dark:text-slate-50">Optimize Your Funnel</h3>
//                         <p class="mt-3 text-base text-gray-600 dark:text-slate-300">Our Call To Action components can be customized to fit your funnel or drop them in and see how they work for you.</p>
//                     </div>
//                 </div>

//                 <div class="flex items-start">
//                     <svg class="flex-shrink-0 text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                         <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
//                     </svg>
//                     <div class="ml-5">
//                         <h3 class="text-xl font-semibold text-black dark:text-slate-50">Easy to Customize</h3>
//                         <p class="mt-3 text-base text-gray-600 dark:text-slate-300">This template is easy to customize so it meets your needs - same with the components.</p>
//                     </div>
//                 </div>

//                 <div class="flex items-start">
//                     <svg class="flex-shrink-0 text-red-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                         <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
//                     </svg>
//                     <div class="ml-5">
//                         <h3 class="text-xl font-semibold text-black dark:text-slate-50">Made with TailwindCSS</h3>
//                         <p class="mt-3 text-base text-gray-600 dark:text-slate-300">The best CSS framework out there allows you to easily customize the theme and components any way you like.</p>
//                     </div>
//                 </div>
//             </div>

//             <div class="lg:col-span-3">
//                 <img class="w-full rounded-lg shadow-xl" src="https://cdn.rareblocks.xyz/collection/celebration/images/features/7/dashboard-screenshot.png" alt="Dashboard screenshot" />
//             </div>
//         </div>
//     </div>
// </section> */}

// {/* <section>
//  <div class="px-8 py-24 mx-auto md:px-12 lg:px-4 max-w-screen-xl flex flex-col lg:h-svh justify-center">
//   <div class="flex flex-col ">
//    <div class="prose text-gray-500 prose-sm prose-headings:font-normal prose-headings:text-xl">
//     <div>
//      <h1>Simplified markup</h1>
//      <p class="text-balance">
//       See the code for this page to see how you can use Astro to simplify your markup.
//      </p>
//     </div>
//    </div>
//    <div class="mt-6 border-t pt-12">
//     <div class="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center ">
//      <div> <span class="text-gray-600  uppercase text-xs font-medium "> Because why not </span>
//       <p class="text-4xl mt-8 tracking-tighter font-semibold text-gray-700 text-balance"> Innovative financial solutions for every situation </p>
//       <p class="text-sm  mt-4 text-gray-700 text-balance"> Discover a variety of tools, services, and expert guidance tailored to your unique financial needs. </p>
//       <div class="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950">
//        <div class="inline-flex items-center gap-2  text-xs text-gray-700"> <svg class="icon icon-tabler text-gray-700 size-4 icon-tabler-360" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
//          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//          <path d="M17 15.328c2.414 -.718 4 -1.94 4 -3.328c0 -2.21 -4.03 -4 -9 -4s-9 1.79 -9 4s4.03 4 9 4"></path>
//          <path d="M9 13l3 3l-3 3"></path>
//         </svg> <span class="text-gray-950 font-medium text-sm"> Clear data visibility </span> </div>
//        <div class="inline-flex items-center gap-2  text-xs text-gray-700"> <svg class="icon icon-tabler text-gray-700 size-4 icon-tabler-antenna-bars-3" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
//          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//          <path d="M6 18l0 -3"></path>
//          <path d="M10 18l0 -6"></path>
//          <path d="M14 18l0 .01"></path>
//          <path d="M18 18l0 .01"></path>
//         </svg> <span class="text-gray-950 font-medium text-sm"> Reduced external factors </span> </div>
//        <div class="inline-flex items-center gap-2  text-xs text-gray-700"> <svg class="icon icon-tabler text-gray-700 size-4 icon-tabler-load-balancer" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
//          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//          <path d="M12 13m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
//          <path d="M12 20m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
//          <path d="M12 16v3"></path>
//          <path d="M12 10v-7"></path>
//          <path d="M9 6l3 -3l3 3"></path>
//          <path d="M12 10v-7"></path>
//          <path d="M9 6l3 -3l3 3"></path>
//          <path d="M14.894 12.227l6.11 -2.224"></path>
//          <path d="M17.159 8.21l3.845 1.793l-1.793 3.845"></path>
//          <path d="M9.101 12.214l-6.075 -2.211"></path>
//          <path d="M6.871 8.21l-3.845 1.793l1.793 3.845"></path>
//         </svg> <span class="text-gray-950 font-medium text-sm"> enhanced stabilty </span> </div>
//        <div class="inline-flex items-center gap-2  text-xs text-gray-700"> <svg class="icon icon-tabler text-gray-700 size-4 icon-tabler-brand-speedtest" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
//          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//          <path d="M5.636 19.364a9 9 0 1 1 12.728 0"></path>
//          <path d="M16 9l-4 4"></path>
//         </svg> <span class="text-gray-950 font-medium text-sm"> accelerated times </span> </div>
//       </div>
//      </div>
//      <div class="h-full md:order-first"> <img src="https://i.pinimg.com/564x/59/41/a0/5941a02c048f6226031a0487451c2651.jpg" alt="#_" class=" bg-gray-200 shadow-box shadow-gray-500/30 overflow-hidden aspect-square  w-full h-full object-cover object-center"/> </div>
//     </div>
//     <div class="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center md:flex-row-reverse">
//      <div> <span class="text-gray-600  uppercase text-xs font-medium "> Just because we can </span>
//       <p class="text-4xl mt-8 tracking-tighter font-semibold text-gray-700 text-balance"> Tailored financial solutions for any scenario </p>
//       <p class="text-sm  mt-4 text-gray-700 text-balance"> Discover a range of financial instruments and personalized advice designed to meet your unique requirements. </p>
//       <div class="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950">
//        <div class="inline-flex items-center gap-2  text-xs text-gray-700"> <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-database text-gray-700 size-4" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
//          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//          <path d="M12 6m-8 0a8 3 0 1 0 16 0a8 3 0 1 0 -16 0"></path>
//          <path d="M4 6v6a8 3 0 0 0 16 0v-6"></path>
//          <path d="M4 12v6a8 3 0 0 0 16 0v-6"></path>
//         </svg> <span class="text-gray-950 font-medium text-sm"> Transparent Data Access </span> </div>
//        <div class="inline-flex items-center gap-2  text-xs text-gray-700"> <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building text-gray-700 size-4" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
//          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//          <path d="M3 21l18 0"></path>
//          <path d="M9 8l1 0"></path>
//          <path d="M9 12l1 0"></path>
//          <path d="M9 16l1 0"></path>
//          <path d="M14 8l1 0"></path>
//          <path d="M14 12l1 0"></path>
//          <path d="M14 16l1 0"></path>
//          <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16"></path>
//         </svg> <span class="text-gray-950 font-medium text-sm"> Lowered Interference </span> </div>
//        <div class="inline-flex items-center gap-2  text-xs text-gray-700"> <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-augmented-reality-2 text-gray-700 size-4" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
//          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//          <path d="M10 21h-2a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v3.5"></path>
//          <path d="M17 17l-4 -2.5l4 -2.5l4 2.5v4.5l-4 2.5z"></path>
//          <path d="M13 14.5v4.5l4 2.5"></path>
//          <path d="M17 17l4 -2.5"></path>
//          <path d="M11 4h2"></path>
//         </svg> <span class="text-gray-950 font-medium text-sm"> Improved Reliability </span> </div>
//        <div class="inline-flex items-center gap-2  text-xs text-gray-700"> <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-time-duration-0 text-gray-700 size-4" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
//          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//          <path d="M3 12v.01"></path>
//          <path d="M21 12v.01"></path>
//          <path d="M12 21v.01"></path>
//          <path d="M12 3v.01"></path>
//          <path d="M7.5 4.2v.01"></path>
//          <path d="M16.5 4.2v.01"></path>
//          <path d="M16.5 19.8v.01"></path>
//          <path d="M7.5 19.8v.01"></path>
//          <path d="M4.2 16.5v.01"></path>
//          <path d="M19.8 16.5v.01"></path>
//          <path d="M19.8 7.5v.01"></path>
//          <path d="M4.2 7.5v.01"></path>
//          <path d="M10 11v2a2 2 0 1 0 4 0v-2a2 2 0 1 0 -4 0z"></path>
//         </svg> <span class="text-gray-950 font-medium text-sm"> Faster Processing Times </span> </div>
//       </div>
//      </div>
//      <div class="h-full "> <img src="https://i.pinimg.com/564x/4e/a7/ff/4ea7ff230ad9f3bc1c30b1b6cbaccad3.jpg" alt="#_" class=" bg-gray-200 shadow-box shadow-gray-500/30 overflow-hidden aspect-square  w-full h-full object-cover object-center"/> </div>
//     </div>
//    </div>

//    <div class="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center p-2">
//     <div class="pointer-events-auto flex w-full max-w-md divide-x divide-neutral-200 rounded-lg bg-white shadow-xl border">
//      <div class="flex w-0 flex-1 items-center p-4">
//       <div class="w-full">
//        <p class="text-sm font-medium text-neutral-900">Tutorial</p>
//        <p class="mt-1 text-sm text-neutral-500">
//         How to simplify your code and avoid repeating your markup with
//         Astrojs and Tailwind CSS
//        </p>
//        <p class="mt-2 text-xs text-orange-500 underline"> <a href="https://lexingtonthemes.com">
//          by © Lexington Themes</a> </p>
//       </div>
//      </div>
//      <div class="flex">
//       <div class="flex flex-col divide-y divide-neutral-200">
//        <div class="flex h-0 flex-1"> <a href="https://lexingtonthemes.com/tutorials/how-to-simplify-your-code-and-avoid-repeating-your-markup-with-astrojs-and-tailwind-css/" type="button" class="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 text-sm font-medium text-orange-600 hover:text-orange-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-orange-500">Tutorial</a> </div>
//        <div class="flex h-0 flex-1"> <a href="https://github.com/Lexington-Themes/lexington-tutorials/blob/main/src/pages/simplified-markup/index.astro" class="flex w-full items-center justify-center rounded-none rounded-br-lg border border-transparent px-4 py-3 text-sm font-medium text-neutral-700 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-orange-500">Get the code</a> </div>
//       </div>
//      </div>
//     </div>
//    </div>
//   </div>
//  </div>
// </section> */}

//           <BannerHome/>
//           <TestimonialSlider/>
//           <BlogSection/>
// {/*
//           <div className="w-[95%] flex justify-between flex-wrap gap-10 md:mt-10">
//             <div className="w-full rounded-xl px-8 py-3 pb-10 border shadow-lg overflow-hidden">
//               <div className="my-10">
//                 <h1 className="text-3xl md:text-3xl textColor font-bold">
//                   Exchanges & Direct Publishers
//                 </h1>
//                 <h2 className="pt-5 pb-2 text-xl font-bold">
//                   Integrated Supply Partners
//                 </h2>
//                 <p className="textColor">
//                   Unparalleled reach for global mobile and web inventories
//                   through leading direct programmatic integrations that enable
//                   higher audience outreach.
//                 </p>
//               </div>

//               <div className="w-full flex flex-wrap gap-0 md:gap-5 lg:gap-10">
//                 <div className="flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40">
//                   <img
//                     className="w-[100%] h-[100%] object-contain"
//                     src="/partner1.jpg"
//                   />
//                 </div>
//                 <div className="flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40">
//                   <img
//                     className="w-[100%] h-[100%] object-contain"
//                     src="/partner2.jpg"
//                   />
//                 </div>
//                 <div className="flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40">
//                   <img
//                     className="w-[100%] h-[100%] object-contain"
//                     src="/partner3.jpg"
//                   />
//                 </div>
//                 <div className="flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40">
//                   <img
//                     className="w-[100%] h-[100%] object-contain"
//                     src="/partner4.jpg"
//                   />
//                 </div>
//                 <div className="flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40">
//                   <img
//                     className="w-[100%] h-[100%] object-contain"
//                     src="/partner5.jpg"
//                   />
//                 </div>
//                 <div className="flex justify-center items-center overflow-hidden w-36 h-36 lg:w-40 lg:h-40">
//                   <img
//                     className="w-[100%] h-[100%] object-contain"
//                     src="/partner8.jpg"
//                   />
//                 </div>
//               </div>
//             </div> */}

//             {/* <div className='w-full rounded-xl px-8 py-3 pb-10 border shadow-lg overflow-hidden'>
//                             <div className='my-10'>
//                                 <h1 className='text-3xl md:text-3xl textColor font-bold'>Verified Partners</h1>
//                                 <h2 className='pt-5 pb-2 text-xl font-bold'>Integrated Supply Partners</h2>
//                                 <p className='textColor'>Unparalleled reach for global mobile inventory through leading direct
//                                     programmatic integrations that enable higher audience outreach.
//                                 </p>
//                             </div>

//                             <div className='w-full flex flex-wrap gap-5 md:gap-10'>
//                                 <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                     <img className='w-[100%] h-[100%] object-contain' src='/aajtak.png' />
//                                 </div>
//                                 <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                     <img className='w-[100%] h-[100%] object-contain' src='/swiggy.png' />
//                                 </div>
//                                 <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                     <img className='w-[100%] h-[100%] object-contain' src='/zivame.jpeg' />
//                                 </div>
//                                 <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                     <img className='w-[100%] h-[100%] object-contain' src='/tata.png' />
//                                 </div>
//                                 <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                     <img className='w-[100%] h-[100%] object-contain' src='/citroen.jpeg' />
//                                 </div>
//                                 <div className='flex justify-center items-center overflow-hidden w-32 h-32'>
//                                     <img className='w-[100%] h-[100%] object-contain' src='/zee5.png' />
//                                 </div>
//                             </div>
//                         </div> */}
//           {/* </div> */}

//           {/* <div className="w-[95%] p-2 mt-10">
//             <h1 className="text-center text-3xl md:text-6xl textColor font-bold my-10">
//               Latest Blog Posts
//             </h1>
//             <div className="flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between pb-10">
//               <CardSection
//                 url="/Programmatic-advertising"
//                 image="/blog1.jpeg"
//                 heading="The Power of Programmatic Advertising: A Deep Dive into DSPs"
//                 content="In the fast-paced world of digital marketing, staying ahead of the curve is crucial for success. One of the key tools that has revolutionized the advertising landscape is programmatic advertising. Within this realm, Demand-Side Platforms (DSPs) are the unsung heroes."
//               />
//               <CardSection
//                 url="/Targeted-advertising"
//                 image="blog2.jpeg"
//                 heading="Effective Strategies for Targeted Advertising with DSPs"
//                 content="In today's digital age, the success of any advertising campaign depends on its ability to reach the right audience at the right time. One of the most effective tools for achieving this goal is through the use of Demand-Side Platforms (DSPs)."
//               />
//               <CardSection
//                 url="/Data-driven-advertising"
//                 image="blog3.jpeg"
//                 heading="Maximizing ROI with Data-Driven Advertising through DSPs"
//                 content="In today's digital landscape, advertisers face an ever-growing challenge in ensuring their marketing efforts provide a strong return on investment (ROI). The key to success lies in harnessing the power of data-driven advertising through Demand-Side Platforms (DSPs)."
//               />
//             </div>
//             <div className="flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between pb-10">
//               <CardSection
//                 url="/cross-channel-advertising"
//                 image="blog4.jpeg"
//                 heading="The Role of DSPs in Cross-Channel Advertising Campaigns"
//                 content="In today's ever-evolving digital landscape, successful advertising campaigns require a strategic, cross-channel approach to reach the right audience at the right time. One of the key tools that has revolutionized the way advertisers manage and optimize their campaigns is the Demand-Side Platform (DSP)."
//               />
//               <CardSection
//                 url="/future-of-digital-advertising"
//                 image="blog5.jpeg"
//                 heading="Navigating the Future of Digital Advertising: Trends in DSP Technology"
//                 content="In today's fast-paced digital landscape, the success of your advertising campaigns largely depends on staying ahead of the curve and harnessing the power of cutting-edge technologies. One such technology that plays a pivotal role in the world of digital advertising is the Demand-Side Platform (DSP)."
//               />
//               <CardSection
//                 url="/video-advertising"
//                 image="blog6.jpeg"
//                 heading="Optimizing Video Advertising Campaigns with DSPs"
//                 content="In today's digital age, video advertising has become an integral part of marketing strategies. To ensure that your video ads reach the right audience at the right time, marketers often turn to Demand-Side Platforms (DSPs) for assistance."
//               />
//             </div>
//           </div> */}
//         </div>

//         {/* <div className="w-full md:h-screen bg-white flex flex-col items-center justify-center overflow-hidden">
//           <div className="w-[95%] md:flex items-center">
//             <div className="hidden md:block md:w-[50%] h-[450px]">
//               <img
//                 className="w-[100%] h-[100%] object-contain"
//                 src="/About.jpg"
//                 alt="imagee"
//               />
//             </div>

//             <div className="md:w-[40%] my-10 md:my-0 px-5 flex flex-col justify-between">
//               <div>
//                 <p className="text-lg text-center md:text-left text-gray-500 font-bold md:mb-5">
//                   About Bidbox
//                 </p>
//                 <h1 className="text-2xl md:text-4xl text-center md:text-left font-bold textColor md:pb-5">
//                   Where innovation thrives!
//                 </h1>
//                 <div className="md:hidden md:w-[50%] my-5">
//                   <img
//                     className="w-[100%] h-[100%] object-contain"
//                     src="About.jpg"
//                     alt="imagee"
//                   />
//                 </div>
//                 <p className="md:text-lg text-center md:text-left text-black">
//                   Welcome to BidBox, your cutting-edge DSP (Demand-Side
//                   Platform) solution for programmatic advertising. We are
//                   dedicated to empowering advertisers with the tools and
//                   insights needed to reach their target audiences effectively.
//                 </p>
//                 <p className="md:text-lg text-center md:text-left text-black pt-4">
//                   With BidBox, you gain access to a world of possibilities in
//                   the digital advertising realm, ensuring your brand&apos;s
//                   success in the ever-evolving landscape of programmatic
//                   advertising.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div> */}

//       </div>

//     </>

//   );
// };

// export default Home;
