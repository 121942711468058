import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { motion } from "framer-motion"; // For animations
import { Helmet } from "react-helmet";

const BlogPost = () => {
  const { id } = useParams(); // `id` here is actually the `content_url`
  const [blog, setBlog] = useState(null);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchBlog = async () => {
      const response = await fetch("https://zonemedia.co/bidbox-blog/react-app-directory/blog-api.php");
      const data = await response.json();
      const selectedBlog = data.blogs.find((b) => b.content_url === id); // Find by `content_url`
      setBlog(selectedBlog);
      setLoading(false); // Stop loading when data is fetched
    };

    const fetchRecentBlogs = async () => {
      const response = await fetch("https://zonemedia.co/bidbox-blog/react-app-directory/blog-api.php");
      const data = await response.json();

      // Sort recent blogs by date (newest first)
      const sortedRecentBlogs = data.blogs
        .sort((a, b) => new Date(b.content_date) - new Date(a.content_date))
        .slice(0, 10); // Fetch only the first 3 blogs for recent posts

      setRecentBlogs(sortedRecentBlogs);
    };

    fetchBlog();
    fetchRecentBlogs();
  }, [id]);

  if (loading) {
    return <Skeleton height={300} count={3} />;
  }

  return (
    <>
    <Helmet>
        <title>{blog.content_meta}</title>
        <meta name='description' content={blog.content_meta_description} />
        <meta name='keywords' content={blog.content_keyword} />
      </Helmet>
    <div className="container mx-auto p-4 py-8">
      <div className="flex flex-wrap px-4">
        <div className="w-full md:w-3/4 pr-6 py-10">
          <motion.img
            src={blog.content_image}
            alt={blog.content_title}
            className="w-full h-60 sm:h-72 md:h-80 lg:h-[28rem] object-contain mb-4 mt-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
          <motion.h1
            className="text-4xl font-bold mb-4"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {blog.content_title}
          </motion.h1>
          <div className="flex items-center text-gray-500 text-sm mb-4">
            <span className="mr-4">
              <i className="fa fa-eye font-bold text-lg">Published : </i> {blog.content_date}
            </span>
            {/* <span className="mr-4">
              <i className="fa fa-clock"></i> 0 Reads
            </span>
            <span>
              <i className="fa fa-comments"></i> 0 Comments
            </span> */}
          </div>
          <motion.p
            className="text-gray-700 text-base mb-6"
            dangerouslySetInnerHTML={{ __html: blog.content_description }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          ></motion.p>
        </div>

        {/* Sidebar */}
        <div className="w-full md:w-1/4 py-10 bg-gray-100 p-4 rounded-lg shadow-lg mt-14">
          <h3 className="text-xl font-bold mb-4 border-b-2 border-pink-500 pb-2">Recent Posts</h3>
          <ul>
            {recentBlogs.map((recentBlog) => (
              <motion.li
                key={recentBlog.id}
                className="mb-4"
                initial={{ opacity: 0, x: -30 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <a href={`/blog/${recentBlog.content_url}`} className="flex hover:bg-gray-100 p-2 rounded-lg transition-all duration-300">
                  <img src={recentBlog.content_image} alt={recentBlog.content_title} className="w-16 h-16 object-cover mr-4 rounded-lg" />
                  <div>
                    <h4 className="font-semibold text-gray-800 hover:text-pink-500 transition-colors">{recentBlog.content_title}</h4>
                    <p className="text-gray-500 text-sm">{new Date(recentBlog.content_date).toLocaleDateString()}</p>
                  </div>
                </a>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </>
  );
};

export default BlogPost;



// import React from "react";
// import { useParams } from "react-router-dom";
// import { blogs } from "../data";

// const BlogPost = () => {
//   const { id } = useParams();
//   const blog = blogs.find((b) => b.id.toString() === id);

//   return (
//     <div className="container mx-auto p-4 py-10">
//       <div className="flex flex-wrap">
//         <div className="w-full md:w-3/4 pr-6 py-12">
//           <img src={blog.image} alt={blog.title} className="w-full h-64 object-cover mb-4 " />
//           <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
//           <div className="flex items-center text-gray-500 text-sm mb-4">
//             <span className="mr-4">
//               <i className="fa fa-eye"></i> {blog.views} View
//             </span>
//             <span className="mr-4">
//               <i className="fa fa-clock"></i> {blog.reads} reads
//             </span>
//             <span>
//               <i className="fa fa-comments"></i> {blog.comments} Comment
//             </span>
//           </div>
//           <p className="text-gray-700 mb-8">{blog.content}</p>
//         </div>

//         {/* Sidebar */}
//         <div className="w-full md:w-1/4 py-10">
//           <h3 className="text-xl font-bold mb-4">Recent Posts</h3>
//           <ul>
//             {blogs.slice(0, 3).map((recentBlog) => (
//               <li key={recentBlog.id} className="mb-4">
//                 <a href={`/blog/${recentBlog.id}`} className="flex">
//                   <img src={recentBlog.image} alt={recentBlog.title} className="w-16 h-16 object-cover mr-4" />
//                   <div>
//                     <h4 className="font-semibold text-gray-800 hover:text-blue-500">{recentBlog.title}</h4>
//                     <p className="text-gray-500 text-sm">{recentBlog.date}</p>
//                   </div>
//                 </a>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BlogPost;
