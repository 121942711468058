import React, { useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { 
  ChevronLeft, 
  ChevronRight,
  ArrowRight,
  Globe,
  BarChart3,
  
  Award,
  Smartphone,
  Video,
  MapPin,
  Target as TargetIcon,
  MessageCircle,
  User
} from 'lucide-react';
import { Link } from 'react-router-dom';

const ServiceSectionAll = () => {
  const { scrollY } = useScroll();
  const [activeTab, setActiveTab] = useState(0);

  const services = [
    {
      icon: <Globe className="w-8 h-8" />,
      title: "CTV & OTT Advertising",
      description: "Reach premium audiences on Connected TV platforms.",
      stat: "Learn More",
      link: "/ctv&ott",
      color: "from-blue-600 to-cyan-500"
      
    },
    {
      icon: <BarChart3 className="w-8 h-8" />,
      title: "Display & Native Ads",
      description: "Deliver highly engaging visual ad placements across premium platforms.",
      stat: "Learn More",
      link: "/display-native-ads",
      color: "from-purple-600 to-pink-500"
   
    },
    {
      icon: <Smartphone className="w-8 h-8" />,
      title: "Push Notification Ads",
      description: " Reconnect with users via high-engagement messaging.",
      stat: "Learn More",
      link: "/push-ads",
      color: "from-orange-500 to-red-500"
     
    },
    {
        icon: <Video className="w-8 h-8" />,
        title: "In-App Mobile Ads",
        description: "Optimize conversions with real-time ad placements.",
        stat: "Learn More",
        link: "/inapp-mobile-Ad",
        color: "from-orange-500 to-red-500"
       
      },
      {
        icon: <Award className="w-8 h-8" />,
        title: "Brand Awareness Campaigns ",
        description: "Maximize reach and visibility with premium placements.",
        stat: "Learn More",
        link: "/brand-awareness",
        color: "from-purple-600 to-pink-500"

      },
      {
        icon: <Globe className="w-8 h-8" />,
        title: "Premium Takeovers",
        description: "Capture high-value ad placements for maximum exposure.",
        stat: "Learn More",
        link: "/premium-takeovers",
        color: "from-blue-600 to-cyan-500"
      
      },
      {
        icon: <MapPin className="w-8 h-8" />,
        title: "Retargeting & Re-Engagement ",
        description: "Boost conversions with AI-powered customer reconnect strategies.",
        stat: "Learn More",
        link: "/reengagement-retargeting",
        color: "from-blue-600 to-cyan-500"
     
      },
      {
        icon: <TargetIcon className="w-8 h-8" />,
        title: "Geo & Hyperlocal Targeting",
        description: "Serve location-specific ads at the right time & place.",
        stat: "Learn More",
        link: "/geo-hyperlocal-targeting",
        color: "from-purple-600 to-pink-500"
   
      },
      {
        icon: <User className="w-8 h-8" />,
        title: "Lookalike Audience Targeting",
        description: "Expand reach with AI-powered audience modeling.",
        stat: "Learn More",
        link: "/lookalike-custom-audiences",
        color: "from-orange-500 to-red-500"
       
      },
      {
        icon: <MessageCircle className="w-8 h-8" />,
        title: "Video & Storytelling Ads ",
        description: "Engage audiences with compelling, high-impact video creatives.",
        stat: "Learn More",
        link: "/video-storytelling-ads",
        color: "from-orange-500 to-red-500"
       
        },
      {
        icon: <BarChart3 className="w-8 h-8" />,
        title: "Household Sync Targeting",
        description: " Engage multiple devices within a household for a unified brand experience.",
        stat: "Learn More",
        link: "/householdsync-targeting",
        color: "from-purple-600 to-pink-500"
        
      },
      {
        icon: <Globe className="w-8 h-8" />,
        title: "Interest & Behavioral Targeting",
        description: "Target users based on browsing habits, content consumption, and digital behaviors.",
        stat: "Learn More",
        link: "/interest-behavior-targeting",
        color: "from-blue-600 to-cyan-500"
      },
  ];




  return (
    <div className="w-full">
      {/* Services Section */}
      <section className="py-20 px-6 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              Smart Advertising. Maximum Impact.

            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Leverage AI-powered programmatic solutions to deliver high-impact branding across premium digital platforms. Reach the right audience, at the right time, with precision and scale.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {services.map((service, index) => (
                <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }} // Hover effect
                className="relative group"
              >
                <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:bg-gray-100">
                  <div className={`absolute inset-x-0 h-2 top-0 rounded-t-2xl bg-gradient-to-r ${service.color}`} />
                  <div className="mb-6 text-blue-600">{service.icon}</div>
                  <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <div className="flex items-center justify-between">
                  <Link to={service.link} className="text-lg font-semibold text-blue-600 hover:underline">
    {service.stat}
  </Link>
                  </div>
                </div>
              </motion.div>
            
            ))}
          </div>
        </div>
      </section>

     
    </div>
  );
};

export default ServiceSectionAll;