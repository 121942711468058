import React from "react";
import CaseStudyCard from "./CaseStudyCard";
import { Helmet } from "react-helmet";

const Automobile = () => {
  return (
    <>
        <Helmet>
                <title>Automotive Industry Digital Advertising – BidBox AI Case Study</title>
                <meta name='description' content='Discover how automotive brands increased lead generation, showroom visits, and vehicle sales through precision-targeted digital ad campaigns with BidBox AI.' />
                <meta name='keywords' content='Automobile advertising, car sales marketing, digital campaigns for automakers, programmatic ads for auto brands, vehicle launch promotions, dealership marketing, test drive ads, auto lead generation, electric vehicle advertising, luxury car branding, online car shopping trends, regional targeting for auto brands, car model promotions, OEM advertising, performance-driven automotive marketing.' />               
            </Helmet>
    <div className="w-full">
      <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl font-bold text-center mt-14">
          Automobile Industry
        </h1>
      </div>

      <div className="w-full overflow-hidden">
        <div className="w-full bg-white flex flex-col items-center">
          <div className="w-[95%] my-10">
            <h1 className="text-3xl md:text-5xl font-bold text-center">
              Explore Diverse Case Studies
            </h1>
            <p className="text-center py-5 text-xl font-medium">
              Dive into success stories spanning various industries and
              verticals to see how we deliver value.
            </p>

            <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5 justify-center md:justify-between">
            <CaseStudyCard
                image="/assets/ola.png"
                heading="Accelerating Brand Growth with a Performance-Driven Strategy"
                impression="Impressions - 18 Million+"
                vtr="Showroom Visit - 3k Approx"
                content="To establish Ola Electric as a dominant force in the electric vehicle industry, we implemented a CMM-driven digital advertising strategy that seamlessly integrated audience segmentation, multi-channel outreach, and targeted content. This comprehensive approach ensured maximum brand visibility while driving meaningful engagement and high-quality leads."
                content1="By leveraging precision targeting across multiple digital platforms, we connected with consumers actively interested in sustainable mobility. Our strategy combined programmatic advertising, social media, and search campaigns to create a cohesive brand narrative that resonated with Ola Electric’s audience. This not only enhanced brand awareness but also positioned Ola Electric as an industry innovator."
                content2='The results were remarkable—significant engagement metrics, a surge in high-intent leads, and strengthened brand authority within the competitive EV market. By continuously optimizing campaign performance through real-time insights, we ensured maximum efficiency and return on investment.'
                content3='This case study underscores the impact of a performance-focused, multi-channel strategy in shaping the future of the electric vehicle industry. Through strategic execution and data-driven decision-making, we propelled Ola Electric’s brand presence, reinforcing its leadership in the sustainable mobility revolution.'

             />
              <CaseStudyCard
                image="/jeepnew.jpg"
                heading="Jeep Meridian’s Market Impact"
                impression="Impressions - 18 Million+"
                vtr="Showroom Visit - 3k Approx"
                content="In our strategic branding campaign for Jeep Meridian, our mission was to engage premium SUV enthusiasts and drive tangible showroom visits. With a laser-focused approach, we implemented city-wise targeting, prioritizing Tier 1 metropolitan areas where the demand for luxury vehicles thrives. Additionally, we refined our audience by focusing on iOS users and automotive interest cohorts, ensuring our messaging reached individuals with a strong inclination toward high-end SUVs."
                content1="The results were nothing short of remarkable. Our campaign delivered over 18 million impressions, significantly amplifying brand awareness among the right audience. However, the true testament to our strategy’s success was its direct impact on customer engagement—within just two months, nearly 3,000 potential buyers visited Jeep showrooms for test drives, signaling strong purchase intent."
                 content2='This case study underscores our expertise in precision-targeted advertising, blending digital innovation with consumer insights to drive both brand visibility and real-world action. By strategically aligning Jeep Meridian with the right audience, we not only reinforced its status as a premium SUV choice but also demonstrated the power of performance-driven branding in the automotive industry.'
              />
              <CaseStudyCard
                image="/citrorennew.jpg"
                heading=" A Strategic Road to Success"
                impression="Website Visits - 158000+"
                vtr="CTR - 1.2%"
                content="In a mission to elevate the Citroën C3 Aircross’s presence in the Indian market, we executed a precision-driven campaign targeting premium automotive enthusiasts. By leveraging auto portals and meticulously segmenting audiences, we ensured that our messaging resonated with high-intent consumers. Our approach combined automotive cohort targeting with geo-targeting in the top eight metropolitan cities, ensuring a hyper-focused reach to potential buyers."
                content1="The impact of our strategy was both immediate and impressive. Within just one month, Citroën witnessed a remarkable 50% surge in website users, demonstrating a heightened interest in the C3 Aircross. This surge translated into increased brand engagement and a stronger foothold in the competitive SUV market."
              content2='This case study highlights our ability to craft data-driven, audience-centric campaigns that drive tangible business results. By aligning Citroën C3 Aircross with the right audience at the right time, we reinforced its position as an aspirational SUV, proving the effectiveness of strategic targeting in the automotive landscape.'
              />
              <CaseStudyCard
                image="/Skoda.png"
                heading="Škoda Slavia’s Resounding Market Impact"
                impression="Impressions: 5 Million+"
                vtr="Reach: 3.2 Million+"
                content="In a strategic push to enhance the Škoda Slavia’s market presence, we orchestrated a multi-faceted branding campaign that combined visually compelling display advertisements, eye-catching banner ads, and engaging video content. Our approach ensured seamless integration across diverse inventories, maximizing brand exposure and engagement."
                 content2="To drive high-intent interest, we implemented precise geo-targeting, focusing on key North Indian states where the demand for premium sedans is significant. By leveraging data-driven audience insights, we strategically positioned Škoda Slavia’s offerings in front of the right consumers, fostering strong brand recall and interest."
                 content3="The results spoke for themselves—higher engagement rates, increased brand searches, and a notable uptick in customer inquiries. Our innovative mix of creative assets and targeted placements played a crucial role in capturing the essence of Škoda’s premium appeal, reinforcing its positioning as a leading player in the Indian automotive market."
                 content4="This campaign stands as a testament to our expertise in executing holistic branding strategies, ensuring Škoda remains at the forefront of consumer consciousness in a competitive landscape."

              />
              <CaseStudyCard
                image="/assets/mercedes11.png"
                heading=" Expanding Brand Relevance Through Precision Targeting"
                impression="Impressions: 15+ million"
                vtr="Clicks: 1.3 lakh+"
                content="The campaign for Mercedes aimed to enhance brand relevance and amplify reach by driving highly engaged audiences to the website. By utilizing premium programmatic ad inventories and leveraging BidBox.ai as the DSP, the campaign ensured seamless targeting of affluent consumers actively interested in luxury automobiles."
                 content2="A well-defined targeting strategy was implemented, focusing on metro cities and an audience segment aged 28 to 45 years. The campaign reached premium credit card holders, automobile enthusiasts, in-market car buyers, luxury shoppers, C-suite executives, and entrepreneurs, ensuring that the ads were seen by potential high-value customers."
                 content3="With over 15 million impressions served, the campaign successfully positioned Mercedes in front of an audience that mattered, reinforcing its status as a premium brand. The strong engagement translated into 1.3 lakh+ clicks, highlighting the effectiveness of precise targeting and strategic ad placements. A 0.90% CTR further demonstrated the campaign’s ability to capture user interest and drive meaningful interactions."
                 content4="By tapping into high-intent audience segments through programmatic efficiency, the campaign played a key role in expanding brand relevance, strengthening consumer perception, and driving traffic to Mercedes’ digital touchpoints."

              />
               <CaseStudyCard
                image="/assets/chery12.png"
                heading=" Driving Engagement Through Targeted Display Advertising"
                impression="CTR: 0.82%  "
                vtr="Clicks: 52083+"
                content="A strategic display ad campaign was launched using Bidbox.ai as the DSP to enhance traffic and session-based engagement for Cherry. By leveraging precise audience segmentation and geo-targeted placements, the campaign successfully reached high-intent users across key regions. The focus was on users aged 35 to 45 years, ensuring alignment with the brand’s target demographic."
                 content2="The campaign spanned multiple cities, including Jakarta, Bandung, Surabaya, Bali, Medan, and Kalimantan, among others, delivering tailored ad experiences to audiences most likely to engage. The use of custom audience cohorts allowed for refined targeting, ensuring that the messaging resonated with users genuinely interested in the brand."
                 content3="The impact was evident, with the campaign generating over 23,100 sessions, signaling strong engagement levels. Click-through rates remained solid, with over 52,000 clicks and a CTR of 0.82%, reinforcing the effectiveness of the placement strategy."
                 content4="Through a combination of display innovation and data-driven targeting via Bidbox.ai, the campaign succeeded in increasing brand interaction and fostering deeper engagement, positioning Cherry prominently in the competitive market landscape."

              />
               <CaseStudyCard
                image="/assets/audi13.png"
                heading=" Expanding Brand Reach with Programmatic Precision"
                // impression="CTR: 0.82%  "
                // vtr="Clicks: 52083+"
                content="A meticulously crafted programmatic advertising campaign was executed for Audi, leveraging Bidbox.ai to maximize brand relevance and digital presence. By utilizing premium inventory and precise targeting, the campaign successfully engaged high-net-worth individuals and automotive enthusiasts, driving significant traffic to the brand’s website."
                 content2="The strategy focused on reaching a refined audience segment, including Sec A+ consumers, premium credit card holders, frequent international travelers, luxury shoppers, C-suite executives, and in-market automotive buyers. With an age group ranging from 28 to 45 years, the campaign was tailored to align with Audi’s premium positioning. The ads were strategically placed across metro cities, ensuring a strong impact in high-value urban markets."
                 content3="The results spoke for themselves. The campaign generated over 20 million impressions, effectively amplifying Audi’s visibility across digital platforms. This translated into more than 80,000 visits, reinforcing the effectiveness of programmatic placements in driving meaningful audience interactions. The seamless integration of high-quality creatives, audience intelligence, and Bidbox.ai's robust DSP capabilities played a pivotal role in delivering a compelling brand experience."
                 content4="By leveraging data-driven insights and premium ad placements, the campaign successfully strengthened Audi’s digital footprint, ensuring the brand remained top-of-mind among its target audience while reinforcing its reputation in the luxury automobile segment."
              />

                <CaseStudyCard
                image="/assets/wolks14.png"
                heading="Driving Awareness with Programmatic Precision"
                 impression="CTR: 0.82% "
                 vtr="Clicks: 32500+  "
                content="To amplify the launch of Volkswagen’s Taigun, a strategic digital campaign was executed, leveraging Bidbox.ai’s advanced programmatic capabilities. By integrating premium ad placements across push and display channels, the campaign effectively captured the attention of potential buyers, reinforcing brand awareness and engagement."
                 content2="The campaign focused on an audience segment comprising automobile enthusiasts, frequent travelers, compact SUV seekers, mid-range car buyers, and working professionals. With an age group spanning 18 to 45 years, the targeting ensured maximum reach among urban consumers in key metro cities. The approach was designed to position Taigun as a compelling choice in the competitive SUV market, engaging both first-time buyers and seasoned vehicle owners."
                 content3="The impact was evident, with over 32,500 clicks generated, leading to 24,375 visits to Volkswagen’s digital platforms. The 0.82% CTR highlighted the efficiency of precise audience engagement, ensuring that interactions translated into meaningful brand exploration. With Bidbox.ai’s intelligent audience segmentation and premium inventory access, the campaign successfully strengthened Volkswagen's digital presence, making Taigun a notable contender in the compact SUV segment."
                 content4="By blending data-driven insights with innovative ad placements, the campaign not only enhanced Volkswagen’s visibility but also fostered a deeper connection between the brand and its target audience, setting a strong foundation for Taigun’s market positioning."
              />
                <CaseStudyCard
                image="/assets/wuling15.png"
                heading="Accelerating Brand Presence with Programmatic Precision"
                 impression="Website: 25213+  "
                 vtr=" CTR: 1.25%   "
                content="Wuling’s brand expansion efforts were bolstered through a highly targeted digital campaign, designed to enhance visibility and engagement among the right audience. With a strategic approach, the campaign leveraged Bidbox.ai’s programmatic capabilities to optimize placements and drive meaningful interactions across Indonesia."
                 content2="Focusing on corporate professionals, tech enthusiasts, in-market car buyers, credit card holders, and entrepreneurs, the campaign ensured that Wuling’s messaging resonated with an audience actively interested in premium automotive experiences. By utilizing advanced data-driven insights, the brand effectively positioned itself as a top choice for modern car buyers."
                 content3="The campaign generated over 25,000 website visits, demonstrating strong audience interest and engagement. With a 1.25% CTR, the digital strategy successfully translated impressions into real interactions, allowing Wuling to make a lasting impact in the competitive automotive market. Furthermore, the campaign’s ability to generate 750+ qualified leads highlighted its success in connecting with high-intent consumers, reinforcing brand relevance and driving potential conversions."
                 content4="By blending premium ad placements with precision targeting, the campaign not only strengthened Wuling’s brand presence but also showcased the effectiveness of Bidbox.ai’s programmatic solutions in delivering measurable results. Through this strategic execution, Wuling solidified its position as a prominent player in Indonesia’s automotive sector, ensuring that its digital footprint continues to grow."
              />
              <CaseStudyCard
                image="/assets/olx16.png"
                heading="Driving Engagement with Strategic Push Advertising"
                 impression="Clicks: 40,000   "
                //  vtr=" CTR: 1.25%   "
                content="OLXmobbi’s campaign set out to maximize audience engagement and drive high-intent interactions by leveraging targeted push notifications and a data-driven strategy. With a focus on precision, the campaign tapped into key audience segments, including auto enthusiasts, in-market car buyers, corporate professionals, and tech-savvy consumers, ensuring relevance and impactful outreach."
                 content2="The campaign was strategically executed across popular apps such as Kompas, Detik Bolasport, IDN App, LiveScore, RCTI+ Superapp, Liputan6, and Tribunnews, securing strong visibility within high-traffic digital environments. By optimizing time targeting between 8 AM and 6 PM, engagement levels were maximized during peak browsing hours, leading to consistent user interaction and higher conversion potential."
                 content3="Through Bidbox.ai, the campaign successfully delivered on its engagement objectives, generating 40,000 clicks over 11 days. The seamless execution of audience-specific targeting, paired with the efficiency of programmatic ad placements, ensured that OLXmobbi’s message reached the right users at the right time."
                 content4="This data-driven approach not only strengthened brand visibility but also reinforced OLXmobbi’s positioning in the competitive automotive marketplace. By combining strategic delivery with precision targeting, the campaign showcased the power of Bidbox.ai’s programmatic expertise, proving that push advertising can be a highly effective tool in driving user action and brand engagement."
              />
            </div>
          </div>

          <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
            <div className="p-5 md:p-10 z-10 flex justify-between">
              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="text-white font-bold text-xl md:text-5xl">
                     Questions or ready to get started?
                  </h1>
                  <h2 className="text-white  text-xl md:text-2xl">
                    Reach out and let's supercharge your campaigns together!
                  </h2>
                </div>
                <div>
                  <a href="/contact">
                    <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                      Get Started
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Automobile;
