import React, { useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { 
  ChevronLeft, 
  ChevronRight,
  ArrowRight,
  Globe,
  BarChart3,
  Users,
  Target,
  TrendingUp,
  Award
} from 'lucide-react';
import { Link } from 'react-router-dom';

const CtvService = () => {
  const { scrollY } = useScroll();
  const [activeTab, setActiveTab] = useState(0);

  const services = [
    {
      icon: <Globe className="w-8 h-8" />,
      title: "Precision-Powered Targeting",
      description: "Reach high-intent audiences with AI-driven targeting and privacy-compliant data, ensuring maximum relevance and impact.",
    //   stat: "Learn More",
      color: "from-blue-600 to-cyan-500"
      
    },
    {
      icon: <BarChart3 className="w-8 h-8" />,
      title: "Unmatched Streaming Access",
      description: "Tap into a vast inventory of premium content, from live sports and news to blockbuster movies, top-tier entertainment, and exclusive series.",
    //   stat: "Learn More",
      color: "from-purple-600 to-pink-500"
   
    },
    {
      icon: <Target className="w-8 h-8" />,
      title: "Cross-Platform Synergy",
      description: "Integrate CTV seamlessly with mobile, web, and social ad campaigns, creating a cohesive brand experience across every screen.",
    //   stat: "Learn More",
      color: "from-orange-500 to-red-500"
     
    },
    {
        icon: <Target className="w-8 h-8" />,
        title: "AI-Optimized Performance",
        description: "Leverage BidBox’s smart algorithms to optimize ad spend, enhance engagement in real-time for maximum ROI.",
        // stat: "Learn More",
        color: "from-orange-500 to-red-500"
       
      },
      {
        icon: <BarChart3 className="w-8 h-8" />,
        title: "Engagement Without Disruption",
        description: "Deliver high-quality, non-intrusive ads that blend seamlessly into immersive environments, ensuring a positive viewer experience. ",
        // stat: "Learn More",
        color: "from-purple-600 to-pink-500"

      },
      {
        icon: <Globe className="w-8 h-8" />,
        title: "Real-Time Measurement",
        description: "Tracking advanced analytics with 25+ KPIs, including impressions, video completion rates, brand lift, and eCommerce conversions.",
        // stat: "Learn More",
        color: "from-blue-600 to-cyan-500"
      
      },
      {
        icon: <Globe className="w-8 h-8" />,
        title: "Smart Frequency Control",
        description: "Manage ad exposure across devices, households, and networks to avoid over-saturation, maintaining optimal brand recall while preventing ad fatigue.",
        // stat: "Learn More",
        color: "from-blue-600 to-cyan-500"
     
      },
      {
        icon: <BarChart3 className="w-8 h-8" />,
        title: "Actionable Insights ",
        description: "Unlocking deep analytical insights helping advertisers understand user interactions, measure ad effectiveness, and refine strategies in real-time, ensuring continuous improvement. ",
        // stat: "Learn More",
        color: "from-purple-600 to-pink-500"

      },
      {
        icon: <Globe className="w-8 h-8" />,
        title: "Dynamic Ad Insertion",
        description: "Seamlessly integrate targeted ads into live and on-demand streaming content, ensuring personalized ad experiences and engagement across diverse audience segments.",
        // stat: "Learn More",
        color: "from-orange-500 to-red-500"
      
      },
      
      
      
  ];

  const features = [
    { title: "Dynamic Optimization", value: "93%", subtitle: "Campaign Success Rate" },
    { title: "Global Coverage", value: "200+", subtitle: "Countries Reached" },
    { title: "Client Satisfaction", value: "98%", subtitle: "Retention Rate" },
    { title: "ROI Improvement", value: "180%", subtitle: "Average Increase" }
  ];

  const statsY = useTransform(scrollY, [0, 300], [100, 0]);
  const statsOpacity = useTransform(scrollY, [0, 300], [0, 1]);

  return (
    <div className="w-full">
      {/* Services Section */}
      <section className="py-20 px-6 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
            Unmatched Advantages of CTV Advertising with BidBox

            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Leverage AI-powered programmatic solutions to deliver high-impact branding across premium digital platforms. Reach the right audience, at the right time, with precision and scale.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {services.map((service, index) => (
                <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }} // Hover effect
                className="relative group"
              >
                <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:bg-gray-100">
                  <div className={`absolute inset-x-0 h-2 top-0 rounded-t-2xl bg-gradient-to-r ${service.color}`} />
                  <div className="mb-6 text-blue-600">{service.icon}</div>
                  <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <div className="flex items-center justify-between">
                  <Link to={service.link} className="text-lg font-semibold text-blue-600 hover:underline">
    {service.stat}
  </Link>
                  </div>
                </div>
              </motion.div>
              
            //   <motion.div
            //     key={index}
            //     initial={{ opacity: 0, y: 20 }}
            //     whileInView={{ opacity: 1, y: 0 }}
            //     viewport={{ once: true }}
            //     transition={{ duration: 0.6, delay: index * 0.2 }}
            //     className="relative group"
            //   >
            //     <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300">
            //       <div className={`absolute inset-x-0 h-2 top-0 rounded-t-2xl bg-gradient-to-r ${service.color}`} />
            //       <div className="mb-6 text-blue-600">{service.icon}</div>
            //       <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
            //       <p className="text-gray-600 mb-6">{service.description}</p>
            //       <div className="flex items-center justify-between">
            //         <span className="text-lg font-semibold">{service.stat}</span>
            //         <ArrowRight className="w-5 h-5 text-gray-400 group-hover:text-blue-600 transition-colors" />
            //       </div>
            //     </div>
            //   </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      {/* <motion.section 
        style={{ y: statsY, opacity: statsOpacity }}
        className="py-20 px-6 bg-gradient-to-r from-blue-600 to-purple-600"
      >
        <div className="max-w-7xl mx-auto">
          <div className="grid md:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4, delay: index * 0.1 }}
                className="text-center text-white"
              >
                <div className="text-5xl font-bold mb-2">{feature.value}</div>
                <div className="text-xl font-semibold mb-1">{feature.title}</div>
                <div className="text-blue-200">{feature.subtitle}</div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section> */}
    </div>
  );
};

export default CtvService;