import React, { useState } from "react";
import { motion } from "framer-motion";
import { Tv, Smartphone, Users, Layers, Monitor } from "lucide-react";
import ScrollAnimation from "./ScrollToSection";
import { MapPin, Target, Map, MessageSquare, BarChart3, TrendingUp, Globe } from "lucide-react";
import { Helmet } from "react-helmet";

const GeoHyperlocalTargeting = () => {
  const caseStudies = [
    {
      id: 1,
      image:
        "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=",
      title: "Retail & Storefront Marketing",
      description:
        "Drive in-store visits by targeting users within a specific radius.",
    },
    {
      id: 2,
      image:
        "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=",
      title: "Event Promotion & Local Awareness",
      description:
        "Engage attendees in real-time with location-based messaging.",
    },
  ];

 // Animation variants - refined for better responsiveness
const fadeInVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0, 
    transition: { 
      duration: 0.6,
      ease: "easeOut"
    } 
  }
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2
    }
  }
};

const staggerItem = {
  hidden: { opacity: 0, y: 10 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      type: "spring", 
      stiffness: 80,
      damping: 10
    }
  }
};

const featureSectionVariant = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { 
      duration: 0.4,
      staggerChildren: 0.2
    }
  }
};

const featureItemVariant = {
  hidden: { opacity: 0, x: -20 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: { 
      type: "spring", 
      stiffness: 70,
      damping: 10
    }
  }
};  const caseStudyVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        type: "spring", 
        stiffness: 50 
      }
    }
  };
  const imageVariant = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        type: "spring", 
        stiffness: 50,
        damping: 8
      }
    }
  };
  const scaleUp = {
    initial: { scale: 1 },
    hover: { 
      scale: 1.05,
      transition: { duration: 0.3 }
    }
  };
  return (
    <>
      <Helmet>
        <title>Geo & Hyperlocal Targeting – Precision Advertising with BidBox AI</title>
        <meta name='description' content='Deliver personalized ads based on user location with BidBox AIs geo and hyperlocal targeting solutions for enhanced relevance and engagement.' />
        <meta name='keywords' content='Geo-targeting ads, hyperlocal advertising, location-based marketing, GPS-powered targeting, retail footfall ads, local business advertising, proximity targeting, dynamic geo-fencing, regional ad targeting, offline-to-online advertising.' />               
      </Helmet>
     <div className="flex min-h-[100dvh] justify-center items-center dark:bg-slate-800 overflow-x-hidden">
                              <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
                                className="mx-auto flex h-full min-h-[100dvh] w-full items-center justify-center px-4 sm:px-6 lg:px-8"
                              >
                                <div className="text-center max-w-4xl">
                                  <motion.h1
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.3, duration: 0.5 }}
                                    className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
                                    style={{
                                      WebkitTransform: "translateY(0px)",
                                      transform: "translateY(0px)",
                                    }}
                                  >
                                    <span className="block xl:inline">
                                      <span className="mb-1 block">Precision Advertising,</span>
                                      <motion.span
                                        initial={{ backgroundPosition: "0%" }}
                                        animate={{ backgroundPosition: "100%" }}
                                        transition={{
                                          repeat: Infinity,
                                          duration: 3,
                                          ease: "linear",
                                          repeatType: "reverse",
                                        }}
                                        className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                                      >
                               with Precision Demographics
                                      </motion.span>
                                      <p className="mb-1 block">
                                        10X Efficiency
                                        <span className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                                          with Bidbox.Ai
                                        </span>{" "}
                                      </p>
                                    </span>
                                  </motion.h1>
                      
                                  <motion.p
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.6, duration: 0.5 }}
                                    className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
                                  >
Reach your audience with pinpoint accuracy using AI-powered Geo & Hyperlocal Targeting. Deliver contextually relevant ads based on real-time locations, ensuring maximum brand relevance.
</motion.p>                      
                                  <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8, duration: 0.5 }}
                                    className="mt-5 sm:mt-8 sm:flex sm:justify-center"
                                  >
                                    <motion.div
                                      variants={scaleUp}
                                      initial="initial"
                                      whileHover="hover"
                                      className="rounded-md shadow"
                                    >
                                      <a
                                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                                        href="/contact"
                                      >
                                      Get Started Now
                                      </a>
                                    </motion.div>
                                  </motion.div>
                                </div>
                              </motion.div>
                            </div>
      {/* <div className="flex min-h-screen justify-center items-center dark:bg-slate-800 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ 
            duration: 0.8,
            type: "spring",
            stiffness: 50
          }} 
          className="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8"
        >
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }} 
              className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
            >
              <span className="block xl:inline">
                <span className="mb-1 block">Precision Advertising, </span>
                <motion.span 
                  initial={{ backgroundPosition: "0%" }}
                  animate={{ backgroundPosition: "100%" }}
                  transition={{ repeat: Infinity, duration: 3, ease: "linear", repeatType: "reverse" }} 
                  className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                >
                  Right Place, Right Time
                </motion.span>
              </span>
              <div className="mt-2">
                10X Efficiency
                <span className="relative mt-3 whitespace-nowrap text-blue-600">
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 418 42"
                    className="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
                    preserveAspectRatio="none"
                  >
                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                  </svg>
                  <span className="relative">with Bidbox Ai.</span>
                </span>
              </div>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }} 
              className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
            >
              Reach your audience with pinpoint accuracy using AI-powered Geo &
              Hyperlocal Targeting. Deliver contextually relevant ads based on
              real-time locations, ensuring maximum brand relevance.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }} 
              className="mt-5 sm:mt-8 sm:flex sm:justify-center"
            >
              <motion.div 
                variants={scaleUp}
                initial="initial"
                whileHover="hover" 
                className="rounded-md shadow"
              > 
                <a
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                  href="/contact"
                >
                  Get Started Now
                </a>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div> */}
     
      <section>
        <div className="px-8 py-24 mx-auto md:px-12 lg:px-4 max-w-screen-xl flex flex-col lg:h-svh justify-center">
        <motion.div 
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1, margin: "-50px" }}
          variants={fadeInVariant}
            className="flex flex-col"
          >
            <div className="prose text-black prose-sm prose-headings:font-normal prose-headings:text-xl">
              <div
              >
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-5xl">
                  Advantages with Bidbox.Ai{" "}
                </h1>
              </div>
            </div>
            <div className="mt-6 border-t pt-12">
            <motion.div 
              variants={featureSectionVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
                className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center"
              >
                <motion.div variants={featureItemVariant} >
                  <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
                    Real-Time Location Targeting
                  </p>
                  <p className="text-lg mt-4 text-gray-600 text-balance">
                    Serve ads dynamically based on user proximity, movement
                    patterns, and real-world behaviors. This ensures precise ad
                    delivery to audiences in high-intent locations at the right
                    moment.
                  </p>
                  <motion.div 
                    variants={staggerContainer}
                  
                    className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950"
                  >
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <MapPin className="w-5 h-5 text-blue-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Proximity-Based Ads
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Target className="w-5 h-5 text-green-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        High-Intent Targeting
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Map className="w-5 h-5 text-purple-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Location Intelligence
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <MessageSquare className="w-5 h-5 text-orange-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Better Engagement
                      </span>
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div 
                  variants={imageVariant}
                  className="h-full md:order-first"
                >
                  <img
                    src="/assets/real.png"
                    alt="Real-time location targeting"
                    className="shadow-box shadow-gray-500/30 overflow-hidden aspect-square w-full h-full object-cover object-center"
                  />
                </motion.div>
              </motion.div>

              <motion.div 
                variants={featureSectionVariant}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center md:flex-row-reverse"
              >
                <motion.div variants={featureItemVariant}>
                  <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
                    Dynamic Creative Optimization (DCO)
                  </p>
                  <p className="text-lg mt-4 text-gray-700 text-balance">
                    Automatically customize ad creatives based on user location,
                    weather, time of day, or behavioral data. This enhances
                    personalization, improving engagement and conversion rates.
                  </p>
                  <motion.div 
                    variants={staggerContainer}
                    
                    className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950"
                  >
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <MapPin className="w-5 h-5 text-blue-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Personalized Messaging
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Target className="w-5 h-5 text-green-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Real-Time Adaptation
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Map className="w-5 h-5 text-purple-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Higher Conversions
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <MessageSquare className="w-5 h-5 text-orange-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        AI-Powered Customization
                      </span>
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div 
                  variants={imageVariant}
                  className="h-full"
                >
                  <img
                    src="/assets/dco.png"
                    alt="Dynamic Creative Optimization"
                    className="shadow-box shadow-gray-500/30 overflow-hidden aspect-square w-full h-full object-cover object-center"
                  />
                </motion.div>
              </motion.div>

              <motion.div 
                variants={featureSectionVariant}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center"
              >
                <motion.div variants={featureItemVariant}>
                  <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
                    Retail & Purchase Behavior Tracking
                  </p>
                  <p className="text-lg mt-4 text-gray-600 text-balance">
                    Track offline consumer actions, such as store visits or
                    purchases, to measure the impact of digital ads. Optimize
                    future campaigns by linking real-world behavior with online
                    ad exposure.
                  </p>
                  <motion.div 
                    variants={staggerContainer}
                   
                    className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950"
                  >
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <MapPin className="w-5 h-5 text-blue-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Offline Attribution
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Target className="w-5 h-5 text-green-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Behavioral Insights
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Map className="w-5 h-5 text-purple-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Cross-Channel Optimization
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <BarChart3 className="w-5 h-5 text-orange-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Better ROI Measurement
                      </span>
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div 
                  variants={imageVariant}
                  className="h-full md:order-first"
                >
                  <img
                    src="/assets/retail.png"
                    alt="Retail & Purchase Behavior Tracking"
                    className="shadow-box shadow-gray-500/30 overflow-hidden aspect-square w-full h-full object-cover object-center"
                  />
                </motion.div>
              </motion.div>

              <motion.div 
                variants={featureSectionVariant}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center md:flex-row-reverse"
              >
                <motion.div variants={featureItemVariant}>
                  <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-700 text-balance">
                    Geo-Fencing & Geo-Conquesting
                  </p>
                  <p className="text-lg mt-4 text-gray-700 text-balance">
                    Target users within predefined geographic areas, such as
                    malls, event venues, or competitor locations. Engage
                    potential customers by delivering relevant ads when they
                    enter or exit specific zones.
                  </p>
                  <motion.div 
                    variants={staggerContainer}
                   
                    className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950"
                  >
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-800">
                      <MapPin className="w-5 h-5 text-blue-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Precise Geo-Zones
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Target className="w-5 h-5 text-green-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Competitive Advantage
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Map className="w-5 h-5 text-purple-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Location-Based Triggers
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <MapPin className="w-5 h-5 text-orange-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Hyperlocal Reach
                      </span>
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div 
                  variants={imageVariant}
                  className="h-full"
                >
                  <img
                    src="/assets/geo.png"
                    alt="Geo-Fencing & Geo-Conquesting"
                    className="shadow-box shadow-gray-500/30 overflow-hidden aspect-square w-full h-full object-cover object-center"
                  />
                </motion.div>
              </motion.div>

              <motion.div 
                variants={featureSectionVariant}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center"
              >
                <motion.div variants={featureItemVariant}>
                  <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
                    City & Regional-Level Targeting
                  </p>
                  <p className="text-lg mt-4 text-gray-600 text-balance">
                    Expand campaigns from hyperlocal neighborhoods to national
                    reach with precision-based targeting. Adjust strategies
                    based on regional demographics, interests, and purchasing
                    trends.
                  </p>
                  <motion.div 
                    variants={staggerContainer}
                  
                    className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950"
                  >
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Globe className="w-5 h-5 text-blue-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Scalable Reach
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Users className="w-5 h-5 text-green-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Demographic Targeting
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <Map className="w-5 h-5 text-purple-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Localized Ad Messaging
                      </span>
                    </motion.div>
                    <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                      <TrendingUp className="w-5 h-5 text-orange-500" />
                      <span className="text-gray-700 font-medium text-lg">
                        Smart Budget Allocation
                      </span>
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div 
                  variants={imageVariant}
                  className="h-full md:order-first"
                >
                  <img
                    src="/assets/city.png"
                    alt="City & Regional-Level Targeting"
                    className="shadow-box shadow-gray-500/30 overflow-hidden aspect-square w-full h-full object-cover object-center"
                  />
                </motion.div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>

      <motion.div 
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInVariant}
        className="max-w-7xl mx-auto py-12 px-4"
      >
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-10"
        >
          Case Studies
        </motion.h2>
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 gap-8"
        >
          {caseStudies.map((study) => (
            <motion.div
              key={study.id}
              variants={caseStudyVariant}
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:shadow-xl"
            >
              {/* Image */}
              <div className="w-full">
                <img
                  src={study.image}
                  alt={study.title}
                  className="w-full h-auto object-cover"
                />
              </div>

              {/* Text Content */}
              <div className="p-6 text-center">
                <h3 className="text-xl font-bold mt-2">{study.title}</h3>
                <p className="text-gray-600 mt-2">{study.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </>
  );
};

export default GeoHyperlocalTargeting;



// import React, { useState } from "react";
// import { motion } from "framer-motion";
// import { Tv, Smartphone, Users, Layers, Monitor } from "lucide-react";
// import ScrollAnimation from "./ScrollToSection";
// import { MapPin, Target, Map, MessageSquare,BarChart3, TrendingUp, Globe } from "lucide-react";
// import { Helmet } from "react-helmet";

// const GeoHyperlocalTargeting = () => {
//   const caseStudies = [
//     {
//       id: 1,
//       image:
//         "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=",
//       title: "Retail & Storefront Marketing",
//       description:
//         "Drive in-store visits by targeting users within a specific radius.",
//     },
//     {
//       id: 2,
//       image:
//         "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=",
//       title: "Event Promotion & Local Awareness",
//       description:
//         "Engage attendees in real-time with location-based messaging.",
//     },
//   ];

//   // Animation variants
//   const fadeInVariant = {
//     hidden: { opacity: 0, y: 50 },
//     visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
//   };

//   const staggerContainer = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.1
//       }
//     }
//   };

//   const staggerItem = {
//     hidden: { opacity: 0, y: 20 },
//     visible: { 
//       opacity: 1, 
//       y: 0,
//       transition: { 
//         type: "spring", 
//         stiffness: 100 
//       }
//     }
//   };

//   const scaleUp = {
//     initial: { scale: 1 },
//     hover: { 
//       scale: 1.05,
//       transition: { duration: 0.3 }
//     }
//   };
 
  
//   return (
//     <>
//      <Helmet>
//         <title>Geo & Hyperlocal Targeting – Precision Advertising with BidBox AI</title>
//         <meta name='description' content='Deliver personalized ads based on user location with BidBox AI’s geo and hyperlocal targeting solutions for enhanced relevance and engagement.' />
//         <meta name='keywords' content='Geo-targeting ads, hyperlocal advertising, location-based marketing, GPS-powered targeting, retail footfall ads, local business advertising, proximity targeting, dynamic geo-fencing, regional ad targeting, offline-to-online advertising.' />               
//     </Helmet>
    
//       <div class="flex h-screen justify-center items-center dark:bg-slate-800">
//          <motion.div
//                   initial={{ opacity: 0, scale: 0.8 }}
//                   animate={{ opacity: 1, scale: 1 }}
//                   transition={{ 
//                     duration: 0.8,
//                     type: "spring",
//                     stiffness: 50
//                   }} class="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8">
//           <div class="text-center ">
//            <motion.h1
//                         initial={{ opacity: 0, y: -20 }}
//                         animate={{ opacity: 1, y: 0 }}
//                         transition={{ delay: 0.3, duration: 0.5 }} class="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl">
//               <span class="block xl:inline">
//                 <span class="mb-1 block">Precision Advertising, </span>
//            <motion.span 
//                             initial={{ backgroundPosition: "0%" }}
//                             animate={{ backgroundPosition: "100%" }}
//                             transition={{ repeat: Infinity, duration: 3, ease: "linear", repeatType: "reverse" }} class="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
//                   Right Place, Right Time
//                 </motion.span>
//               </span>
//               <div class="mt-2">
//                 10X Efficiency
//                 <span class="relative mt-3 whitespace-nowrap text-blue-600">
//                   <svg
//                     aria-hidden="true"
//                     viewBox="0 0 418 42"
//                     class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
//                     preserveAspectRatio="none"
//                   >
//                     <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
//                   </svg>
//                   <span class="relative">with Bidbox Ai.</span>
//                 </span>
//               </div>
//                </motion.h1>
//                         <motion.p
//                           initial={{ opacity: 0 }}
//                           animate={{ opacity: 1 }}
//                           transition={{ delay: 0.6, duration: 0.5 }} class="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5">
//               Reach your audience with pinpoint accuracy using AI-powered Geo &
//               Hyperlocal Targeting. Deliver contextually relevant ads based on
//               real-time locations, ensuring maximum brand relevance.
//             </motion.p>
//                         <motion.div
//                           initial={{ opacity: 0, y: 20 }}
//                           animate={{ opacity: 1, y: 0 }}
//                           transition={{ delay: 0.8, duration: 0.5 }} class="mt-5 sm:mt-8 sm:flex sm:justify-center">
//               <motion.div 
//                             variants={scaleUp}
//                             initial="initial"
//                             whileHover="hover" 
//                             className="rounded-md shadow"
//                           > 
//                 <a
//                   class="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
//                   href="/contact"
//                 >
//                   Get Started Now
//                 </a>
//              </motion.div>
//                         </motion.div>
//                       </div>
//                     </motion.div>
//       </div>
     
//       <section>
//         <div class="px-8 py-24 mx-auto md:px-12 lg:px-4 max-w-screen-xl flex flex-col lg:h-svh justify-center">
//           <div class="flex flex-col ">
//             <div class="prose text-black prose-sm prose-headings:font-normal prose-headings:text-xl">
//               <div>
//                 <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-5xl">
//                   Advantages with Bidbox.Ai{" "}
//                 </h1>
             
//               </div>
//             </div>
//             <div class="mt-6 border-t pt-12">
//               <div class="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center ">
//                 <div>
//                   <p class="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
//                     {" "}
//                     Real-Time Location Targeting{" "}
//                   </p>
//                   <p class="text-lg  mt-4 text-gray-600 text-balance">
//                     {" "}
//                     Serve ads dynamically based on user proximity, movement
//                     patterns, and real-world behaviors. This ensures precise ad
//                     delivery to audiences in high-intent locations at the right
//                     moment.{" "}
//                   </p>
//                   <div class="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950">
//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <MapPin className="w-5 h-5 text-blue-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Proximity-Based Ads{" "}
//                       </span>
//                     </div>
//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Target className="w-5 h-5 text-green-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         High-Intent Targeting{" "}
//                       </span>
//                     </div>

//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Map className="w-5 h-5 text-purple-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Location Intelligence{" "}
//                       </span>
//                     </div>

//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <MessageSquare className="w-5 h-5 text-orange-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Better Engagement{" "}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <div class="h-full md:order-first">
//                   {" "}
//                   <img
//                     src="/assets/real.png"
//                     alt="#_"
//                     class="  shadow-box shadow-gray-500/30 overflow-hidden aspect-square  w-full h-full object-cover object-center"
//                   />{" "}
//                 </div>
//               </div>
//               <div class="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center md:flex-row-reverse">
//                 <div>
//                   {" "}
//                   <p class="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
//                     {" "}
//                     Dynamic Creative Optimization (DCO){" "}
//                   </p>
//                   <p class="text-lg  mt-4 text-gray-700 text-balance">
//                     {" "}
//                     Automatically customize ad creatives based on user location,
//                     weather, time of day, or behavioral data. This enhances
//                     personalization, improving engagement and conversion rates.{" "}
//                   </p>
//                   <div class="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950">
//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <MapPin className="w-5 h-5 text-blue-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Personalized Messaging{" "}
//                       </span>
//                     </div>
//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Target className="w-5 h-5 text-green-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Real-Time Adaptation{" "}
//                       </span>
//                     </div>

//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Map className="w-5 h-5 text-purple-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Higher Conversions{" "}
//                       </span>
//                     </div>

//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <MessageSquare className="w-5 h-5 text-orange-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         AI-Powered Customization{" "}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <div class="h-full ">
//                   {" "}
//                   <img
//                     src="/assets/dco.png"
//                     alt="#_"
//                     class=" shadow-box shadow-gray-500/30 overflow-hidden aspect-square  w-full h-full object-cover object-center"
//                   />{" "}
//                 </div>
//               </div>
//               <div class="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center ">
//                 <div>
//                   <p class="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
//                     {" "}
//                     Retail & Purchase Behavior Tracking{" "}
//                   </p>
//                   <p class="text-lg  mt-4 text-gray-600 text-balance">
//                     {" "}
//                     Track offline consumer actions, such as store visits or
//                     purchases, to measure the impact of digital ads. Optimize
//                     future campaigns by linking real-world behavior with online
//                     ad exposure.{" "}
//                   </p>
//                   <div class="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950">
//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <MapPin className="w-5 h-5 text-blue-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Offline Attribution{" "}
//                       </span>
//                     </div>
//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Target className="w-5 h-5 text-green-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Behavioral Insights{" "}
//                       </span>
//                     </div>

//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Map className="w-5 h-5 text-purple-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Cross-Channel Optimization{" "}
//                       </span>
//                     </div>

//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <BarChart3 className="w-5 h-5 text-orange-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Better ROI Measurement
//                         {" "}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <div class="h-full md:order-first">
//                   {" "}
//                   <img
//                     src="/assets/retail.png"
//                     alt="#_"
//                     class="  shadow-box shadow-gray-500/30 overflow-hidden aspect-square  w-full h-full object-cover object-center"
//                   />{" "}
//                 </div>
//               </div>
//               <div class="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center md:flex-row-reverse">
//                 <div>
//                   {" "}
//                   <p class="text-4xl mt-8 tracking-tighter font-semibold text-gray-700 text-balance">
//                     {" "}
//                     Geo-Fencing & Geo-Conquesting{" "}
//                   </p>
//                   <p class="text-lg  mt-4 text-gray-700 text-balance">
//                     {" "}
//                     Target users within predefined geographic areas, such as
//                     malls, event venues, or competitor locations. Engage
//                     potential customers by delivering relevant ads when they
//                     enter or exit specific zones.{" "}
//                   </p>
//                   <div class="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950">
//                     <div class="inline-flex items-center gap-2 text-xs text-gray-800">
//                       <MapPin className="w-5 h-5 text-blue-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Precise Geo-Zones{" "}
//                       </span>
//                     </div>
//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Target className="w-5 h-5 text-green-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Competitive Advantage{" "}
//                       </span>
//                     </div>

//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Map className="w-5 h-5 text-purple-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Location-Based Triggers{" "}
//                       </span>
//                     </div>

//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                     <MapPin className="w-5 h-5 text-orange-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Hyperlocal Reach{" "}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <div class="h-full ">
//                   {" "}
//                   <img
//                     src="/assets/geo.png"
//                     alt="#_"
//                     class=" shadow-box shadow-gray-500/30 overflow-hidden aspect-square  w-full h-full object-cover object-center"
//                   />{" "}
//                 </div>
//               </div>
//               <div class="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center ">
//                 <div>
//                   <p class="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
//                     {" "}
//                     City & Regional-Level Targeting{" "}
//                   </p>
//                   <p class="text-lg  mt-4 text-gray-600 text-balance">
//                     {" "}
//                     Expand campaigns from hyperlocal neighborhoods to national
//                     reach with precision-based targeting. Adjust strategies
//                     based on regional demographics, interests, and purchasing
//                     trends.{" "}
//                   </p>
//                   <div class="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950">
//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Globe className="w-5 h-5 text-blue-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Scalable Reach{" "}
//                       </span>
//                     </div>
//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Users className="w-5 h-5 text-green-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Demographic Targeting{" "}
//                       </span>
//                     </div>

//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <Map className="w-5 h-5 text-purple-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Localized Ad Messaging{" "}
//                       </span>
//                     </div>

//                     <div class="inline-flex items-center gap-2 text-xs text-gray-700">
//                       <TrendingUp className="w-5 h-5 text-orange-500" />
//                       <span class="text-gray-700 font-medium text-lg">
//                         {" "}
//                         Smart Budget Allocation{" "}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <div class="h-full md:order-first">
//                   {" "}
//                   <img
//                     src="/assets/city.png"
//                     alt="#_"
//                     class="  shadow-box shadow-gray-500/30 overflow-hidden aspect-square  w-full h-full object-cover object-center"
//                   />{" "}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <div className="max-w-7xl mx-auto py-12 px-4">
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//           {caseStudies.map((study) => (
//             <div
//               key={study.id}
//               className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl"
//             >
//               {/* Image */}
//               <div className="w-full">
//                 <img
//                   src={study.image}
//                   alt={study.title}
//                   className="w-full h-auto object-cover"
//                 />
//               </div>

//               {/* Text Content */}
//               <div className="p-6 text-center">
//                 <h3 className="text-xl font-bold mt-2">{study.title}</h3>
//                 <p className="text-gray-600 mt-2">{study.description}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
  
//     </>
//   );
// };

// export default GeoHyperlocalTargeting;
