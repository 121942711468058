import React from 'react'
import Banner from '../Banner'
import CaseStudyCard from './CaseStudyCard'
import { Helmet } from 'react-helmet'

const Aviation = () => {
    return (
        <>
        <Helmet>
        <title>Electronics and Tech Advertising – BidBox AI Case Study</title>
        <meta name='description' content='Learn how top electronics brands enhanced brand awareness, customer engagement, and sales through advanced programmatic advertising and audience segmentation.' />
        <meta name='keywords' content='Electronics advertising, tech product marketing, gadget launch promotions, home appliances ads, consumer electronics branding, online sales strategy for tech, AI-powered ad targeting, omnichannel advertising for electronics, digital shelf placement, e-commerce tech campaigns, influencer marketing for electronics, product demo ads, CTV ads for gadgets, customer acquisition for electronics brands.' />               
    </Helmet>
        <div className='w-full'>
            <div className='w-full h-[36vh] bgColor text-white flex justify-center items-center'>
                <h1 className='text-3xl md:text-6xl font-bold  text-center mt-14'>Consumer Electronics</h1>
            </div>

            <div className='w-full overflow-hidden'>

                <div className='w-full bg-white flex flex-col items-center'>
                    <div className='w-[95%] my-10'>
                        <h1 className='text-3xl md:text-5xl font-bold text-center'>Explore Diverse Case Studies</h1>
                        <p className='text-center py-5 text-xl font-medium'>Dive into success stories spanning various industries and verticals to see how we deliver value.</p>

                        <div className='my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5 justify-center md:justify-between'>
                            <CaseStudyCard
                                image="/Nokia.png"
                                heading="Driving Digital & In-Store Engagement"
                                impression='Website Visits - 200k+'
                                vtr='Walk Ins -  86%↑ '
                                content="In a strategic campaign for Nokia, we leveraged hyperlocal targeting to maximize brand engagement and foot traffic. By deploying precision-targeted Display Banners and rich media creatives, we delivered highly relevant messaging to local audiences, resulting in exceptional campaign performance."
                                content1="Our approach generated over 200,000 website visits, achieving an impressive Click-Through Rate (CTR) of 0.95%. Engagement metrics further underscored the effectiveness of our targeting, with an average session duration of 2.5 minutes and a bounce rate of less than 30%, reflecting strong audience interaction and content relevance."
                                content2="Beyond digital success, the campaign drove a substantial impact on Nokia’s physical stores, with an average 86% increase in walk-ins. Some locations saw staggering month-over-month growth, reaching up to 316% during the campaign period."
                                content3="This case study highlights the power of hyperlocal targeting in seamlessly bridging digital engagement with offline conversions. By integrating data-driven strategies and compelling creatives, we successfully positioned Nokia as a brand that connects with its audience both online and in-store, driving measurable business growth."

                            />
                            <CaseStudyCard
                                image="OnePlusLogo.png"
                                heading="OnePlus Dominates Regional Markets with Targeted Precision"
                                impression='Impressions - 5.2 Million+'
                                vtr='CTR - 0.85%'
                                content="In a highly competitive landscape, OnePlus aimed to strengthen its brand presence and amplify visibility for the OnePlus 7, with a sharp focus on South India. Our strategic collaboration was tailored to maximize regional impact and drive meaningful engagement."
                                content1="By leveraging a combination of language-based and geo-specific targeting, we ensured precise audience reach, delivering compelling messaging that resonated with local consumers. This approach resulted in an impressive 5.2 million impressions and a strong Click-Through Rate (CTR) of 0.85%, highlighting the effectiveness of our targeted campaign."
                                 content2='This case study showcases our innovative strategy in elevating brand recognition and positioning the OnePlus 7 prominently in Southern India. Through hyperlocal insights and data-driven execution, we successfully propelled OnePlus to the forefront of consumer attention, reinforcing its dominance in key regional markets.'
                            />
                            <CaseStudyCard
                                image="/samsunglogo.jpg"
                                heading="Samsung A05’s Market Breakthrough in Indonesia"
                                impression='Impressions - 3.52 Cr+'
                                vtr='Clicks - 195K+'
                                content="In a strategic move to strengthen Samsung A05’s presence in the Indonesian market, we deployed a high-impact digital campaign leveraging display and native ads. Through visually compelling creatives and strategic ad placements across premium online platforms, we ensured maximum engagement and brand visibility."
                                content1="Executed through a sophisticated DSP, the campaign successfully positioned the Samsung A05 as a must-have device, driving widespread awareness and consumer interest. The results were remarkable—over 32 million impressions and an impressive 195K+ clicks, underscoring the campaign’s strong resonance and effectiveness."
                                 content2="This case study highlights the power of precision-driven digital advertising in creating a lasting market impact. By seamlessly integrating data-driven strategies with engaging creatives, we successfully amplified Samsung A05’s presence in Indonesia, reinforcing its appeal among tech-savvy consumers."
                            />
 <CaseStudyCard
                image="/lenovo.jpg"
                heading="Elevating Brand Presence Through CTV Innovation"
                impression="New Customers Acquired : 10000+"
                vtr=""
                content="In a strategic move to enhance brand visibility and engagement, we launched a Connected TV (CTV) branding campaign for Lenovo, targeting tech-savvy consumers with a passion for innovation and smart devices. By leveraging premium content platforms, we ensured that Lenovo’s messaging reached a highly relevant audience, reinforcing its position as a leader in the technology space."
                content1="Our approach focused on delivering tailored, high-impact ads to viewers immersed in premium digital content. Through data-driven audience segmentation and strategic ad placements, we maximized reach while maintaining precision in targeting. This not only amplified Lenovo’s brand presence but also fostered stronger connections with consumers actively seeking cutting-edge technology."
                 content2='The results were remarkable—brand recall surged by 25%, while web traffic from CTV viewers saw a significant uplift. By continuously optimizing campaign performance, we achieved maximum ROI, ensuring that every ad placement contributed to Lenovo’s overarching brand growth.'
                 content3='This success story underscores the power of CTV advertising in the digital age. By seamlessly integrating innovative targeting with engaging content, we helped Lenovo establish a lasting impact in the minds of its audience, driving both awareness and consumer action in an increasingly competitive market.'

              />
                        </div>
                    </div>

                </div>

            </div>

            <Banner />
        </div>
        </>
    )
}

export default Aviation
