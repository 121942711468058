import React, { useState } from 'react'
import { motion } from "framer-motion";
import { Tv, Smartphone, Users, Layers, Monitor, TrendingUp,Target,  Brain, UserCheck, Eye, Search, Heart, Globe, AlignCenter, Star, BarChart, Send, ShoppingCart, Zap, MapPin, Gauge } from "lucide-react";
import { Helmet } from 'react-helmet';

const InterestBehaviorTargeting = () => {
    const caseStudies = [ 
        {
            id: 1,
            image: "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", 
            title: "E-commerce & Online Shopping",
            description: "Target users browsing fashion, electronics, or lifestyle products."
        },
        {
            id: 2,
            image: "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", 
            title: "Streaming & Entertainment",
            description: "Engage viewers based on their content preferences."
        },
    ];
      const features = [
        {
          icon: <Tv size={40} className="text-purple-600" />,
          title: "Interest-Based Targeting",
          description:
            " Reach users based on browsing habits, interests, and search behaviors.",
        },
        {
          icon: <Users size={40} className="text-blue-600" />,
          title: "Contextual Targeting",
          description:
            "Serve ads in relevant content environments for higher engagement.",
        },
        {
          icon: <Layers size={40} className="text-green-600" />,
          title: "AI-Powered Predictive Modeling",
          description:
            "  Identify high-intent users before they take action.",
        },
        {
          icon: <Monitor size={40} className="text-red-600" />,
          title: "Purchase Intent & In-Market Audiences",
          description:
            "Target consumers actively looking for products in your category.",
        },
        {
          icon: <Smartphone size={40} className="text-indigo-600" />,
          title: "Custom Affinity Segments",
          description:
            "Build and refine niche audience segments for tailored campaigns.",
        },
      ];
      const sectionsNew = [
        {
          title: "Interest-Based Targeting",
          subtitle: "01 / VISION",
          description: "Reach users based on browsing habits, interests, and search behaviors.",
          buttonText: "Explore More →",
          imgSrc: "https://images.unsplash.com/photo-1682687220742-aba13b6e50ba",
          reverse: false,
        },
        {
          title: "Contextual Targeting",
          subtitle: "02 / DESIGN",
          description: "Serve ads in relevant content environments for higher engagement.",
          buttonText: "Learn More →",
          imgSrc: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab",
          reverse: true,
        },
        {
          title: "AI-Powered Predictive Modeling",
          subtitle: "03 / SPACE",
          description: "Identify high-intent users before they take action.",
          buttonText: "Discover More →",
          imgSrc: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab",
          reverse: false,
        },
        {
          title: "Purchase Intent & In-Market Audiences",
          subtitle: "04 / DESIGN",
          description: "Target consumers actively looking for products in your category.",
          buttonText: "Learn More →",
          imgSrc: "https://images.unsplash.com/photo-1682687220742-aba13b6e50ba",
          reverse: true,
        },
        {
          title: "Custom Affinity Segments",
          subtitle: "05 / SPACE",
          description: "Build and refine niche audience segments for tailored campaigns.",
          buttonText: "Discover More →",
          imgSrc: "https://images.unsplash.com/photo-1682687220742-aba13b6e50ba",
          reverse: false,
        },
      ];
      // Animation variants - refined for better responsiveness
const fadeInVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0, 
    transition: { 
      duration: 0.6,
      ease: "easeOut"
    } 
  }
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2
    }
  }
};

const staggerItem = {
  hidden: { opacity: 0, y: 10 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      type: "spring", 
      stiffness: 80,
      damping: 10
    }
  }
};

const featureSectionVariant = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { 
      duration: 0.4,
      staggerChildren: 0.2
    }
  }
};

const featureItemVariant = {
  hidden: { opacity: 0, x: -20 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: { 
      type: "spring", 
      stiffness: 70,
      damping: 10
    }
  }
};  const caseStudyVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        type: "spring", 
        stiffness: 50 
      }
    }
  };
  const imageVariant = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        type: "spring", 
        stiffness: 50,
        damping: 8
      }
    }
  };
  const scaleUp = {
    initial: { scale: 1 },
    hover: { 
      scale: 1.05,
      transition: { duration: 0.3 }
    }
  };
  return (
    <>
     <Helmet>
        <title>Interest & Behavior Targeting – Data-Driven Ad Personalization</title>
        <meta name='description' content='Reach users based on their interests and online behavior with BidBox AI’s intelligent ad targeting solutions, ensuring maximum engagement.' />
        <meta name='keywords' content='Behavioral targeting, interest-based advertising, AI-driven targeting, contextual advertising, consumer behavior insights, intent-based targeting, predictive audience segmentation, browsing history targeting, past purchase behavior ads, affinity targeting.' />               
    </Helmet>
     <div className="flex min-h-[100dvh] justify-center items-center dark:bg-slate-800 overflow-x-hidden">
                                       <motion.div
                                         initial={{ opacity: 0, scale: 0.8 }}
                                         animate={{ opacity: 1, scale: 1 }}
                                         transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
                                         className="mx-auto flex h-full min-h-[100dvh] w-full items-center justify-center px-4 sm:px-6 lg:px-8"
                                       >
                                         <div className="text-center max-w-4xl">
                                           <motion.h1
                                             initial={{ opacity: 0, y: -20 }}
                                             animate={{ opacity: 1, y: 0 }}
                                             transition={{ delay: 0.3, duration: 0.5 }}
                                             className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
                                             style={{
                                               WebkitTransform: "translateY(0px)",
                                               transform: "translateY(0px)",
                                             }}
                                           >
                                             <span className="block xl:inline">
                                               <span className="mb-1 block">Understand, Engage, Convert</span>
                                               <motion.span
                                                 initial={{ backgroundPosition: "0%" }}
                                                 animate={{ backgroundPosition: "100%" }}
                                                 transition={{
                                                   repeat: Infinity,
                                                   duration: 3,
                                                   ease: "linear",
                                                   repeatType: "reverse",
                                                 }}
                                                 className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                                               >
                                       Smarter Audience Targeting
                                               </motion.span>
                                               <p className="mb-1 block">
                                                 10X Efficiency
                                                 <span className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                                                   with Bidbox.Ai
                                                 </span>{" "}
                                               </p>
                                             </span>
                                           </motion.h1>
                               
                                           <motion.p
                                             initial={{ opacity: 0 }}
                                             animate={{ opacity: 1 }}
                                             transition={{ delay: 0.6, duration: 0.5 }}
                                             className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
                                           >
     Go beyond demographics by targeting users based on their online behavior, content consumption, and purchase intent. Ensure your ads resonate with the right people.
     </motion.p>                      
                                           <motion.div
                                             initial={{ opacity: 0, y: 20 }}
                                             animate={{ opacity: 1, y: 0 }}
                                             transition={{ delay: 0.8, duration: 0.5 }}
                                             className="mt-5 sm:mt-8 sm:flex sm:justify-center"
                                           >
                                             <motion.div
                                               variants={scaleUp}
                                               initial="initial"
                                               whileHover="hover"
                                               className="rounded-md shadow"
                                             >
                                               <a
                                                 className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                                                 href="/contact"
                                               >
                                               Explore Smart Targeting
                                               </a>
                                             </motion.div>
                                           </motion.div>
                                         </div>
                                       </motion.div>
                                     </div>
    {/* <div class="flex min-h-screen justify-center items-center dark:bg-slate-800 overflow-hidden">
     <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ 
                duration: 0.8,
                type: "spring",
                stiffness: 50
              }} class="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8">
        <div class="text-center ">
             <motion.h1
                         initial={{ opacity: 0, y: -20 }}
                         animate={{ opacity: 1, y: 0 }}
                         transition={{ delay: 0.3, duration: 0.5 }} 
                class="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl">
                <span class="block xl:inline"><span class="mb-1 block">Understand, Engage, Convert</span>
               <motion.span 
                                initial={{ backgroundPosition: "0%" }}
                                animate={{ backgroundPosition: "100%" }}
                                transition={{ repeat: Infinity, duration: 3, ease: "linear", repeatType: "reverse" }} class="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                Smarter Audience Targeting
             </motion.span>
                </span>
                <div class="mt-2">10X Efficiency
                    <span class="relative mt-3 whitespace-nowrap text-blue-600"><svg aria-hidden="true" viewBox="0 0 418 42"
                        class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
                        preserveAspectRatio="none">
                        <path
                            d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z">
                        </path>
                    </svg>
                    <span class="relative">with Bidbox Ai.</span>
                    </span>
                </div>
           </motion.h1>
                       <motion.p
                         initial={{ opacity: 0 }}
                         animate={{ opacity: 1 }}
                         transition={{ delay: 0.6, duration: 0.5 }}  class="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5">
            Go beyond demographics by targeting users based on their online behavior, content consumption, and purchase intent. Ensure your ads resonate with the right people.
             </motion.p>
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.8, duration: 0.5 }}  class="mt-5 sm:mt-8 sm:flex sm:justify-center">
                <motion.div 
                               variants={scaleUp}
                               initial="initial"
                               whileHover="hover" class="rounded-md shadow"><a
                        class="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                        href="/contact">Explore Smart Targeting
                    </a>
         </motion.div>
                     </motion.div>
                   </div>
                 </motion.div>
</div> */}
{/* <div className="bg-white py-12 px-4 md:px-6">
      <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
        
        
        <div className="relative w-full md:w-5/12">
          <div className="absolute inset-0 bg-purple-600 opacity-20 rounded-lg"></div>
          <img
            src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
            alt="Connected TV"
            className="w-full h-auto md:h-96 object-cover rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"
          />
        </div>

       
        <div className="w-full md:w-7/12 text-center md:text-left">
          <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
            Connected TV: The Future of Streaming & Smart Advertising
          </h2>
          <p className="text-gray-700 text-lg md:text-xl leading-relaxed mb-6">
            Connected TV (CTV) advertising delivers targeted video ads on smart TVs and streaming devices. It connects brands with viewers watching premium content across OTT platforms, extending reach beyond traditional cable and satellite TV.
          </p>
          <button className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-110 transition duration-300">
            Learn More
          </button>
        </div>

      </div>

   
      <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-12 mt-16">
        
      
        <div className="relative w-full md:w-5/12">
          <div className="absolute inset-0 bg-blue-600 opacity-20 rounded-lg"></div>
          <img
            src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
            alt="CTV Advertising"
            className="w-full h-auto md:h-96 object-cover rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"
          />
        </div>

      
        <div className="w-full md:w-7/12 text-center md:text-left">
          <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
            Why CTV Advertising? More Reach, More Impact, Zero Wastage
          </h2>
          <p className="text-gray-700 text-lg md:text-xl leading-relaxed mb-6">
            CTV connects you to cord-cutters and streaming-first audiences with precision. Unify TV and digital ad buys, control who sees your ads, and measure performance—ensuring maximum impact with minimal waste.
          </p>
          <button className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg hover:scale-110 transition duration-300">
            Learn More
          </button>
        </div>

      </div>
    </div> */}

    {/* <div className="bg-gray-100 py-16 px-6">
      <div className="container mx-auto">
       
        <motion.h2
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-3xl md:text-5xl font-bold text-center text-gray-900 mb-12"
        >
          Elevate Your Advertising Strategy 🚀
        </motion.h2>

       
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="bg-white p-6 rounded-2xl shadow-lg flex flex-col items-center text-center hover:shadow-xl transform hover:scale-105 transition-all duration-300"
            >
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600 text-lg">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div> */}
 
      <section>
      <div className="px-8 py-24 mx-auto md:px-12 lg:px-4 max-w-screen-xl flex flex-col lg:h-svh justify-center">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1, margin: "-50px" }}
          variants={fadeInVariant}
          className="flex flex-col"
        >
          <div className="prose text-black prose-sm prose-headings:font-normal prose-headings:text-xl">
            <div>
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-5xl">
                Advantages with Bidbox.Ai{" "}
              </h1>
              {/* <p class="text-balance">
                See the code for this page to see how you can use Astro to
                simplify your markup.
              </p> */}
            </div>
          </div>
          <div className="mt-6 border-t pt-12">
            <motion.div
              variants={featureSectionVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center"
            >
              <motion.div variants={featureItemVariant}>
                <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
                  {" "}
                  Interest-Based Targeting{" "}
                </p>
                <p className="text-lg  mt-4 text-gray-600 text-balance">
                  {" "}
                  Reach users based on their browsing habits, online activities, and search behaviors to deliver highly relevant ads. This approach ensures your brand connects with consumers who are genuinely interested in your products or services.
                </p>
                <motion.div
                  variants={staggerContainer}
                  className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950"
                >
                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Eye className="w-5 h-5 text-blue-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Browsing Behavior{" "}
                    </span>
                  </motion.div>
                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Search className="w-5 h-5 text-green-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Search Intent{" "}
                    </span>
                  </motion.div>

                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Heart className="w-5 h-5 text-purple-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Personalized Ads{" "}
                    </span>
                  </motion.div>

                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <TrendingUp className="w-5 h-5 text-orange-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Higher Engagement
                      {" "}
                    </span>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div
                variants={imageVariant}
                className="h-full md:order-first"
              >
                {" "}
                <img
                  src="/assets/interest.png"
                  alt="#_"
                  className="shadow-box shadow-gray-500/30 overflow-hidden aspect-square w-full h-full object-cover object-center"
                />{" "}
              </motion.div>
            </motion.div>
            <motion.div
              variants={featureSectionVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center md:flex-row-reverse"
            >
              <motion.div variants={featureItemVariant}>
                {" "}
                <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
                  {" "}
                  Contextual Targeting{" "}
                </p>
                <p className="text-lg  mt-4 text-gray-700 text-balance">
                  {" "}
                  Serve ads within relevant content environments to enhance engagement and brand alignment. By placing ads alongside contextually relevant articles, videos, or websites, you ensure better resonance with the audience.
                  {" "}
                </p>
                <motion.div
                  variants={staggerContainer}
                  className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950"
                >
                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Globe className="w-5 h-5 text-blue-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Relevant Environments{" "}
                    </span>
                  </motion.div>
                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <AlignCenter className="w-5 h-5 text-green-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Content Alignment{" "}
                    </span>
                  </motion.div>

                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Star className="w-5 h-5 text-purple-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Enhanced Brand Recall{" "}
                    </span>
                  </motion.div>

                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <TrendingUp className="w-5 h-5 text-orange-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Improved Ad Performance {" "}
                    </span>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div
                variants={imageVariant}
                className="h-full"
              >
                {" "}
                <img
                  src="/assets/context.png"
                  alt="#_"
                  className="shadow-box shadow-gray-500/30 overflow-hidden aspect-square w-full h-full object-cover object-center"
                />{" "}
              </motion.div>
            </motion.div>
            <motion.div
              variants={featureSectionVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center"
            >
              <motion.div variants={featureItemVariant}>
                <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
                  {" "}
                  AI-Powered Predictive Modeling{" "}
                </p>
                <p className="text-lg  mt-4 text-gray-600 text-balance">
                  {" "}
                  Leverage machine learning to identify high-intent users before they take action, improving conversion rates. AI analyzes patterns in user behavior to anticipate purchase likelihood and optimize ad delivery.
                </p>
                <motion.div
                  variants={staggerContainer}
                  className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950"
                >
                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Brain className="w-5 h-5 text-blue-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Machine Learning Insights{" "}
                    </span>
                  </motion.div>
                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Target className="w-5 h-5 text-green-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      High-Intent Users{" "}
                    </span>
                  </motion.div>

                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <BarChart className="w-5 h-5 text-purple-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Behavioral Analysis
                      {" "}
                    </span>
                  </motion.div>

                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Send className="w-5 h-5 text-orange-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Optimized Ad Delivery
                      {" "}
                    </span>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div
                variants={imageVariant}
                className="h-full md:order-first"
              >
                {" "}
                <img
                  src="/assets/ai1.png"
                  alt="#_"
                  className="shadow-box shadow-gray-500/30 overflow-hidden aspect-square w-full h-full object-cover object-center"
                />{" "}
              </motion.div>
            </motion.div>
            <motion.div
              variants={featureSectionVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center md:flex-row-reverse"
            >
              <motion.div variants={featureItemVariant}>
                {" "}
                <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
                  {" "}
                  Purchase Intent & In-Market Audiences{" "}
                </p>
                <p className="text-lg  mt-4 text-gray-700 text-balance">
                  {" "}
                  Target consumers actively searching for or considering products within your category. By identifying users at the decision-making stage, you can drive higher-quality leads and faster conversions.
                  {" "}
                </p>
                <motion.div
                  variants={staggerContainer}
                  className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950"
                >
                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <ShoppingCart className="w-5 h-5 text-blue-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Active Shoppers{" "}
                    </span>
                  </motion.div>
                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Target className="w-5 h-5 text-green-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Decision-Stage Targeting{" "}
                    </span>
                  </motion.div>

                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <UserCheck className="w-5 h-5 text-purple-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      High-Quality Leads{" "}
                    </span>
                  </motion.div>

                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Zap className="w-5 h-5 text-orange-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Faster Conversions
                      {" "}
                    </span>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div
                variants={imageVariant}
                className="h-full"
              >
                {" "}
                <img
                  src="/assets/purchase.png"
                  alt="#_"
                  className="shadow-box shadow-gray-500/30 overflow-hidden aspect-square w-full h-full object-cover object-center"
                />{" "}
              </motion.div>
            </motion.div>
            <motion.div
              variants={featureSectionVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center"
            >
              <motion.div variants={featureItemVariant}>
                <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-800 text-balance">
                  {" "}
                  Custom Affinity Segments{" "}
                </p>
                <p className="text-lg  mt-4 text-gray-600 text-balance">
                  {" "}
                  Build highly specific audience groups tailored to your campaign goals using detailed behavioral and interest data. Refine targeting strategies to reach niche consumer segments with personalized messaging.
                  {" "}
                </p>
                <motion.div
                  variants={staggerContainer}
                  className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950"
                >
                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Target className="w-5 h-5 text-blue-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Niche Targeting{" "}
                    </span>
                  </motion.div>
                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Brain className="w-5 h-5 text-green-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Behavioral Insights{" "}
                    </span>
                  </motion.div>

                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <MapPin className="w-5 h-5 text-purple-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Personalized Messaging{" "}
                    </span>
                  </motion.div>

                  <motion.div variants={staggerItem} className="inline-flex items-center gap-2 text-xs text-gray-700">
                    <Gauge className="w-5 h-5 text-orange-500" />
                    <span className="text-gray-700 font-medium text-lg">
                      {" "}
                      Campaign Precision
                      {" "}
                    </span>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div
                variants={imageVariant}
                className="h-full md:order-first"
              >
                {" "}
                <img
                  src="/assets/custom.png"
                  alt="#_"
                  className="shadow-box shadow-gray-500/30 overflow-hidden aspect-square w-full h-full object-cover object-center"
                />{" "}
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
   <motion.div 
         initial="hidden"
         whileInView="visible"
         viewport={{ once: true, amount: 0.2 }}
         variants={fadeInVariant}
         className="max-w-7xl mx-auto py-12 px-4"
       >
         <motion.h2
           initial={{ opacity: 0, y: 20 }}
           whileInView={{ opacity: 1, y: 0 }}
           viewport={{ once: true }}
           transition={{ duration: 0.5 }}
           className="text-3xl font-bold text-center mb-10"
         >
           Case Studies
         </motion.h2>
         <motion.div
           variants={staggerContainer}
           initial="hidden"
           whileInView="visible"
           viewport={{ once: true }}
           className="grid grid-cols-1 md:grid-cols-2 gap-8"
         >
           {caseStudies.map((study) => (
             <motion.div
               key={study.id}
               variants={caseStudyVariant}
               whileHover={{ y: -10, transition: { duration: 0.3 } }}
               className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:shadow-xl"
             >
               {/* Image */}
               <div className="w-full">
                 <img
                   src={study.image}
                   alt={study.title}
                   className="w-full h-auto object-cover"
                 />
               </div>
 
               {/* Text Content */}
               <div className="p-6 text-center">
                 <h3 className="text-xl font-bold mt-2">{study.title}</h3>
                 <p className="text-gray-600 mt-2">{study.description}</p>
               </div>
             </motion.div>
           ))}
         </motion.div>
       </motion.div>
  
   
    
    
    
    </>
  )
}

export default InterestBehaviorTargeting
