import React from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";
import Banner from "../Banner";
import { motion } from "framer-motion";
import FAQSection from "../landingPage/FAQSection";
import OurValues from "./OurValues";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

const About = () => {
 

 

  // Animation Variants from VideoStorytellingAds
  const fadeInVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
  };

  const staggerItem = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { type: "spring", stiffness: 100 } },
  };

  const scaleUp = {
    initial: { scale: 1 },
    hover: { scale: 1.05, transition: { duration: 0.3 } },
  };

  return (
    <>
      <Helmet>
                <title>About BidBox AI – Innovating Targeted Advertising Solutions</title>
                <meta name='description' content='Learn about BidBox AIs mission to revolutionize targeted advertising with cutting-edge DSP technology, ensuring efficient and effective ad campaigns.' />
                <meta name='keywords' content='BidBox AI, about us, company vision, programmatic advertising experts, ad tech innovators, advertising technology, media buying solutions, ad delivery system, digital advertising, AI-powered marketing, machine learning in advertising, precision targeting, advertising automation, real-time ad placement, brand growth, ad campaign management.' />               
            </Helmet>
      <div className="w-full">
        {/* Hero Section */}
        <motion.div
  initial="hidden"
  whileInView="visible"
  variants={fadeInVariant}
  viewport={{ once: true }}
  className="flex flex-col lg:flex-row items-center gap-6 p-4 sm:p-6"
>
  {/* Content Section */}
  <div className="w-full lg:w-7/12 text-center lg:text-left">
    <div className="container mx-auto h-full p-4 sm:p-6">
      <nav className="flex px-4 justify-between items-center">
        <div>
          <img
            src="https://image.flaticon.com/icons/svg/497/497348.svg"
            alt=""
            className="w-8"
          />
        </div>
      </nav>

      <header className="container px-4 mt-6 lg:mt-0">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="w-full"
        >
          <motion.div
            variants={staggerItem}
            className="w-20 h-2 bg-gradient-to-r from-purple-600 to-blue-600 my-4 mx-auto lg:mx-0 mt-12"
          ></motion.div>

          <motion.p
            variants={staggerItem}
            className="text-lg text-gray-800 font-bold"
          >
            About Bidbox
          </motion.p>

          <motion.h1
            variants={staggerItem}
            className="text-3xl sm:text-6xl font-bold "
          >
            Where innovation <span className="text-blue-800">thrives!</span>
          </motion.h1>

          <motion.p
            variants={staggerItem}
            className="text-md sm:text-lg my-4 text-gray-600"
          >
            Bidbox is an AI-powered Demand-Side Platform (DSP) designed to help brands and advertisers execute high-performance campaigns across Connected TV (CTV), social media, and programmatic advertising. With our cutting-edge technology, businesses can optimize their media spend, gain actionable insights, and reach the right audiences at the right time.
          </motion.p>

          <motion.p
            variants={staggerItem}
            className="text-md sm:text-lg text-gray-600"
          >
           We combine industry expertise, advanced machine learning algorithms, and real-time data analytics to drive efficiency, performance, and measurable success for brands of all sizes.
          </motion.p>
        </motion.div>
      </header>
    </div>
  </div>

  {/* Image Section */}
    <motion.div 
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } }}className="w-full lg:w-5/12 flex items-center justify-center">
    <motion.img
      variants={scaleUp}
      initial={{ scale: 0.9 }}
                    animate={{ scale: 1, transition: { duration: 0.8, ease: "easeOut" } }}
                    whileHover={{ scale: 1.02, rotate: 3 }} 
      src="/assets/about1.png"
      alt="Leafs"
      className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg  mt-10"
    />
  </motion.div>
</motion.div>


        {/* Who We Are Section */}
        <motion.section
          initial="hidden"
          whileInView="visible"
          variants={staggerContainer}
          viewport={{ once: true }}
          className="px-6 py-16 md:px-12 lg:px-10 xl:px-10 bg-white"
        >
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4  ">
            <motion.div
            
            variants={staggerItem} 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } }}className="rounded-2xl overflow-hidden">
              <motion.img
                variants={scaleUp}
                
                initial={{ scale: 0.9 }}
                              animate={{ scale: 1, transition: { duration: 0.8, ease: "easeOut" } }}
                              whileHover={{ scale: 1.02, rotate: 3 }} 
                src="/assets/about2.png"
                alt="Team"
                className="w-full h-full object-cover"
              />
            </motion.div>
           
            <motion.div variants={staggerItem} className="flex flex-col justify-center">
              <h4 className="text-blue-600 font-semibold mb-2">What Makes Us Apart</h4>
              <h2 className="text-3xl lg:text-5xl font-bold text-gray-900 mb-4">AI-Driven Advertising, Smarter Results</h2>
              <p className="text-gray-600 text-lg">
               
BidBox.ai is an AI-powered DSP that maximizes ad performance across CTV, social, and programmatic channels—driving smarter targeting, optimized media spend, and real-time insights.
              </p>
            </motion.div>
          </div>

          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10"
          >
            <motion.div variants={staggerItem} className="flex items-start gap-4">
              <div className="bg-blue-600 text-white p-3 rounded-xl">
                <span className="text-2xl">🎯</span>
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-900">Our Mission</h3>
                <p className="text-gray-600">
                At Bidbox, our mission is to revolutionize digital advertising by leveraging AI-driven insights and automation to help brands maximize their reach, efficiency, and impact.
                </p>
              </div>
            </motion.div>
            <motion.div variants={staggerItem} className="flex items-start gap-4">
              <div className="bg-blue-600 text-white p-3 rounded-xl">
                <span className="text-2xl">👁️</span>
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-900">Our Vision</h3>
                <p className="text-gray-600">
                We envision a future where every ad campaign is powered by intelligence, optimized in real-time, and delivers unparalleled results for brands worldwide.
                </p>
              </div>
            </motion.div>
          </motion.div>
        </motion.section>

        {/* Power of Smarter Advertising */}
        <motion.section
          initial="hidden"
          whileInView="visible"
          variants={fadeInVariant}
          viewport={{ once: true }}
          className="flex flex-col md:flex-row items-center justify-between px-6 md:px-16 py-12 bg-white"
        >
          <motion.div variants={staggerContainer} className="md:w-1/2 text-center md:text-left">
            <motion.div variants={staggerItem} className="w-12 h-12 bg-gray-100 flex items-center justify-center rounded-lg mb-4">
              <span className="text-blue-500 text-xl">⚡</span>
            </motion.div>
            <motion.h1 variants={staggerItem} className="text-3xl md:text-5xl font-bold text-gray-900">
              Discover the <span className="bg-indigo-100 px-2 rounded-md">Power</span> of Smarter Advertising
            </motion.h1>
            <motion.h1 variants={staggerItem} className="text-xl md:text-2xl text-gray-800 py-3">
              Unlock AI-driven precision for your campaigns.
            </motion.h1>
            <motion.p variants={staggerItem} className="text-gray-600 text-lg mt-4">
            Bidbox simplifies the complexities of digital media buying, ensuring every ad dollar is spent effectively. Experience intelligent automation, real-time optimization, and superior targeting.
</motion.p>
            {/* <motion.div variants={scaleUp} initial="initial" whileHover="hover" className="mt-6">
              <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 px-6 rounded-lg">
                Know More
              </button>
            </motion.div> */}
          </motion.div>
          <motion.div variants={staggerItem}
           initial={{ opacity: 0, y: 50 }}
           animate={{ opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } }} className="md:w-1/2 mt-10 md:mt-0 ">
            
            <motion.img
                variants={scaleUp}
                initial={{ scale: 0.9 }}
                animate={{ scale: 1, transition: { duration: 0.8, ease: "easeOut" } }}
                whileHover={{ scale: 1.02, rotate: 3 }}
             
              src="/assets/about.png"
              alt="Tech Solutions"
              className="w-full   ml-4 md:ml-6 lg:ml-10"
            />
          </motion.div>
        </motion.section>

        <OurValues />

        
     

        <FAQSection />
        <Banner />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default About;


// import React from 'react'
// import NavBar from '../NavBar'
// import Footer from '../Footer'
// import Banner from '../Banner'
// import { motion } from "framer-motion";
// import FAQSection from '../landingPage/FAQSection';
// import OurValues from './OurValues';
// import SliderAbout from './SliderAbout';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// const About = () => {
//   const slides = [
//     { image: '/swiggy.png' },
//     { image: '/zivame.jpeg' },
//     { image: '/tata.png' },
//     { image: '/citroen.jpeg' },
//     { image: '/slider5.jpg' },
//     { image: '/slider6.jpg' },
//     { image: '/slider7.jpg' },
//     { image: '/slider8.jpg' },
//   ];
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 6,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };
//     return (
//         <>
       
      
//             <div className='w-full'>
//             <div class="flex flex-wrap">
//     <div class="w-full sm:w-8/12 ">
//       <div class="container mx-auto h-full sm:p-10">
//         <nav class="flex px-4 justify-between items-center">
//           {/* <div class="text-4xl font-bold">
//             Plant<span class="text-green-700">.</span>
//           </div> */}
//           <div>
//             <img src="https://image.flaticon.com/icons/svg/497/497348.svg" alt="" class="w-8"/>
//           </div>
//         </nav>
//         <header class="container px-4 lg:flex mt-10 items-center h-full lg:mt-0">
          
//           <div class="w-full">
//           <div class="w-20 h-2 border-transparent bg-gradient-to-r from-purple-600 to-blue-600  my-4"></div>

//           <p className='text-lg text-center md:text-left text-gray-800 font-bold md:mb-5'>About Bidbox</p>

//             <h1 class="text-4xl lg:text-6xl font-bold  ">Where innovation  <span class="text-blue-800">thrives!</span> </h1>
//             <p class="text-xl mb-2  mt-4 text-gray-600">Bidbox is an AI-powered Demand-Side Platform (DSP) designed to help brands and advertisers execute high-performance campaigns across Connected TV (CTV), social media, and programmatic advertising. With our cutting-edge technology, businesses can optimize their media spend, gain actionable insights, and reach the right audiences at the right time.</p>
//             <p class="text-xl mb-10 text-gray-600">   We combine industry expertise, advanced machine learning algorithms, and real-time data analytics to drive efficiency, performance, and measurable success for brands of all sizes.</p>
//             <button class="rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg">Learn More</button>
//           </div>
//         </header>
//       </div>
//     </div>
//     <img src="/image1.png" alt="Leafs" class="w-full h-48 object-cover sm:h-screen sm:w-4/12"/>
//   </div>
//   <section className="px-6 py-24 md:px-12 lg:px-12 xl:px-12  bg-white">
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//         {/* Images */}
//         <div className="rounded-2xl overflow-hidden">
//           <img
//             src="/image1.png"
//             alt="Team"
//             className="w-full h-full object-cover"
//           />
//         </div>
//         <div className="rounded-2xl overflow-hidden">
//           <img
//             src="/image1.png"
//             alt="Office"
//             className="w-full h-full object-cover"
//           />
//         </div>

        
//         <div className="flex flex-col justify-center">
//           <h4 className="text-indigo-600 font-semibold mb-2">Who We Are</h4>
//           <h2 className="text-3xl font-bold text-gray-900 mb-4">
//             Design, Management, and Support Tailored to Your Needs
//           </h2>
//           <p className="text-gray-600 text-lg">
//             Whether you require expertise in coding, design, testing, or project management, our diverse team is
//             equipped to meet your specific needs. This collaborative approach ensures that your projects succeed.
//           </p>
//         </div>
//       </div>

    
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">
//         <div className="flex items-start gap-4">
//           <div className="bg-indigo-600 text-white p-3 rounded-xl">
//             <span className="text-2xl">🎯</span>
//           </div>
//           <div>
//             <h3 className="text-xl font-bold text-gray-900">Our Mission</h3>
//             <p className="text-gray-600">
//             At Bidbox, our mission is to revolutionize digital advertising by leveraging AI-driven insights and automation to help brands maximize their reach, efficiency, and impact.
//             </p>
//           </div>
//         </div>
//         <div className="flex items-start gap-4">
//           <div className="bg-indigo-600 text-white p-3 rounded-xl">
//             <span className="text-2xl">👁️</span>
//           </div>
//           <div>
//             <h3 className="text-xl font-bold text-gray-900">Our Vision</h3>
//             <p className="text-gray-600">
//             We envision a future where every ad campaign is powered by intelligence, optimized in real-time, and delivers unparalleled results for brands worldwide.
//             </p>
//           </div>
//         </div>
//       </div>
//     </section>
            
//                 {/* <div className='w-full h-[40vh] md:h-[40vh] bgColor text-white flex  justify-center items-center'>
//                     <h1 className='text-3xl md:text-6xl text-center font-bold mt-16'>Discover Our Journey</h1>
//                 </div> */}
// {/* 
//                 <div className='w-full md:h-screen bg-white flex flex-col items-center justify-center overflow-hidden'>

//                     <div className='w-[95%] md:flex items-center'>
//                         <div className='hidden md:block md:w-[50%] h-[450px]'>
//                             <img className='w-[100%] h-[100%] object-contain' src='/About.jpg' alt='imagee' />
//                         </div>

//                         <div className='md:w-[40%] my-10 md:my-0 px-5 flex flex-col justify-between'>
//                             <div>
//                                 <p className='text-lg text-center md:text-left text-gray-500 font-bold md:mb-5'>About Bidbox</p>
//                                 <h1 className='text-2xl md:text-4xl text-center md:text-left font-bold textColor md:pb-5'>Where innovation thrives!</h1>
//                                 <div className='md:hidden md:w-[50%] my-5'>
//                                     <img className='w-[100%] h-[100%] object-contain' src='/About.jpg' alt='imagee' />
//                                 </div>
//                                 <p className='md:text-lg text-center md:text-left text-black'>
//                                     Welcome to BidBox, your cutting-edge DSP (Demand-Side Platform) solution for programmatic advertising. We are dedicated to empowering advertisers with the tools and insights needed to reach their target audiences effectively.
//                                 </p>
//                                 <p className='md:text-lg text-center md:text-left text-black pt-4'>
//                                     With BidBox, you gain access to a world of possibilities in the digital advertising realm, ensuring your brand&apos;s success in the ever-evolving landscape of programmatic advertising.
//                                 </p>
//                             </div>
//                         </div>

//                     </div>

//                 </div> */}
        
//                 {/* <section className="flex flex-col items-center justify-center min-h-screen  text-gray-900 py-10 mt-12">
     
//       <div className="px-4 md:px-16 mb-8 text-center ">
//       <h2 className="text-3xl md:text-7xl font-bold textColor mt-2">Mission Statement</h2>
//       <p className="text-gray-600 mt-4 max-w-4xl mx-auto text-lg">
//       At Bidbox, our mission is to revolutionize digital advertising by leveraging AI-driven insights and automation to help brands maximize their reach, efficiency, and impact.
//       </p>
//       </div>

   
//       <div className="px-4 md:px-16 mb-8 text-center">
//       <h2 className="text-3xl md:text-5xl font-bold textColor mt-2">Vision Statement
//       </h2>
//       <p className="text-gray-600 mt-4 max-w-4xl mx-auto text-lg">
//       We envision a future where every ad campaign is powered by intelligence, optimized in real-time, and delivers unparalleled results for brands worldwide.

//       </p>
//       </div>

    
//       <div className="px-4 md:px-16 text-center">
//         <img
//           src="image1.png"
//           alt="Mission and Vision"
//           className="w-full max-w-4xl h-auto object-cover rounded-lg shadow-lg transform transition duration-500 hover:scale-105 mx-auto"
//         />
//       </div>
//     </section> */}
//   <section className="flex flex-col md:flex-row items-center justify-between px-6 md:px-16 py-12 bg-white">
    
//       <div className="md:w-1/2 text-center md:text-left">
   
//         <div className="w-12 h-12 bg-gray-100 flex items-center justify-center rounded-lg mb-4">
//           <span className="text-blue-500 text-xl">⚡</span>
//         </div>

//         <h1 className="text-3xl md:text-5xl font-bold textColor">
//         Discover the   <span className="bg-indigo-100 px-2 rounded-md">Power</span> of Smarter Advertising
//         </h1>

//         <h1 className="text-xl md:text-2xl  text-gray-800 py-3">
//         Unlock AI-driven precision for your campaigns.
//         </h1>
//         <p className="text-gray-600 text-lg mt-4">
//         Bidbox simplifies the complexities of digital media buying, ensuring every ad dollar is spent effectively. Experience intelligent automation, real-time optimization, and superior targeting.
//         </p>

   
//         <button className="mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 px-6 rounded-lg">
//           Know More
//         </button>
//       </div>

  
//       <div className="md:w-1/2 mt-10 md:mt-0">
//         <img
//           src="/image1.png"
//           alt="Tech Solutions"
//           className="w-full rounded-lg shadow-md"
//         />
//       </div>
//     </section>
    
   
//     {/* <div className='w-full md:h-screen bg-white flex flex-col items-center justify-center overflow-hidden'>

// <div className='w-[95%] md:flex items-center'>
//     <div className='hidden md:block md:w-[50%] h-[450px]'>
//         <img className='w-[100%] h-[100%] object-contain' src='/About.jpg' alt='imagee' />
//     </div>

//     <div className='md:w-[40%] my-10 md:my-0 px-5 flex flex-col justify-between'>
//         <div>
//             <p className='text-lg text-center md:text-left text-gray-500 font-bold md:mb-5'>About Bidbox</p>
//             <h1 className='text-2xl md:text-4xl text-center md:text-left font-bold textColor md:pb-5'>Where innovation thrives!</h1>
//             <div className='md:hidden md:w-[50%] my-5'>
//                 <img className='w-[100%] h-[100%] object-contain' src='/About.jpg' alt='imagee' />
//             </div>
//             <p className='md:text-lg text-center md:text-left text-black'>
//             Bidbox is an AI-powered Demand-Side Platform (DSP) designed to help brands and advertisers execute high-performance campaigns across Connected TV (CTV), social media, and programmatic advertising. With our cutting-edge technology, businesses can optimize their media spend, gain actionable insights, and reach the right audiences at the right time.
//             </p>
//             <p className='md:text-lg text-center md:text-left text-black pt-4'>
//             We combine industry expertise, advanced machine learning algorithms, and real-time data analytics to drive efficiency, performance, and measurable success for brands of all sizes.
//             </p>
//         </div>
//     </div>

// </div>

// </div> */}
// <OurValues/>

// {/* <section id="our-team" class="bg-gray-100 py-32">
//     <div class="container mx-auto px-4">
//         <h2 class="text-3xl font-bold text-center mb-8 text-primary">Meet Our Team</h2>

//         <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
           
//             <div class="bg-white rounded-lg shadow-md p-6 my-6 text-center">
//                 <img src="https://spacema-dev.com/elevate/assets/images/team/1.jpg" alt="Team Member 1" class="w-full rounded-full mb-4" />
//                 <h3 class="text-xl font-semibold mb-2">John Doe</h3>
//                 <p class="text-gray-700">Role: Software Engineer</p>
//             </div>

         
//             <div class="bg-white rounded-lg shadow-md p-6 my-6 text-center">
//                 <img src="https://spacema-dev.com/elevate/assets/images/team/4.jpg" alt="Team Member 2" class="w-full rounded-full mb-4" />
//                 <h3 class="text-xl font-semibold mb-2">Jane Smith</h3>
//                 <p class="text-gray-700">Role: Graphic Designer</p>
//             </div>

         
//             <div class="bg-white rounded-lg shadow-md p-6 my-6 text-center">
//                 <img src="https://spacema-dev.com/elevate/assets/images/team/3.jpg" alt="Team Member 3" class="w-full rounded-full mb-4" />
//                 <h3 class="text-xl font-semibold mb-2">Alex Johnson</h3>
//                 <p class="text-gray-700">Role: Marketing Manager</p>
//             </div>

           
//             <div class="bg-white rounded-lg shadow-md p-6 my-6 text-center">
//                 <img src="https://spacema-dev.com/elevate/assets/images/team/2.jpg" alt="Team Member 4" class="w-full rounded-full mb-4" />
//                 <h3 class="text-xl font-semibold mb-2">Peter Johnson</h3>
//                 <p class="text-gray-700">Role: Seo specialist</p>
//             </div>

         
          
//         </div>
        
//     </div>
//     <div className="px-4 md:px-16 mb-8 text-center">
//       <h2 className="text-4xl font-bold mt-2">What We Do</h2>
//       <p className="text-gray-600 mt-4 max-w-4xl mx-auto text-lg">
//         Our self-serve, transparent software helps advertisers use the best available
//         data to reach audiences on the open internet at every stage of the funnel —
//         from awareness to engagement to conversion.
//       </p>
//       </div>
// </section> */}
// <div className="w-full flex flex-col items-center my-5 md:my-10">
//           <div className="w-[95%] p-2 md:mt-10">
//           <div className='container mt-16 font-Kanit, sans-serif'>
//           <div className="text-center my-10">
//               <h1 className="text-center text-3xl md:text-6xl textColor font-bold mt-10">
//                 Our Clientele
//               </h1>
//               {/* <h2 className='pt-10 pb-2 text-2xl font-bold'>Integrated Supply Partners</h2> */}
//               <p className="text-xl font-medium text-black py-5">
//                 We proudly serve a diverse clientele, spanning various
//                 industries and backgrounds, reflecting our commitment to
//                 inclusivity and adaptability.
//               </p>
//             </div>
//             <Slider {...settings}>
//   {slides.map((slide, index) => (
//     <div key={index}>
//       <img
//         src={process.env.PUBLIC_URL + slide.image}
//         alt={`Image ${index + 1}`}
//         style={{ width: '90%', height: '150px', border: '1px solid #' }}
//       />
//     </div>
//   ))}
// </Slider>
//     </div>
//           </div>
//         </div>
// {/* <div className='px-10'>
// <div class="relative z-10 overflow-hidden bg-indigo-600 py-16 px-8 mt-10">
//     <div class="container">
//         <div class="-mx-4 flex flex-wrap items-center">
//             <div class="w-full px-4 lg:w-1/2">
//                 <div class="text-center lg:text-left ">
//                     <div class="mb-10 lg:mb-0 ">
//                         <h1
//                             class="mt-0 mb-3 text-3xl font-bold leading-tight sm:text-4xl sm:leading-tight md:text-[40px] md:leading-tight text-white ">
//                             Start building automated serverless forms</h1>
//                         <p
//                             class="w-full text-base font-medium leading-relaxed sm:text-lg sm:leading-relaxed text-white">
//                         </p>
//                     </div>
//                 </div>
//             </div>
//             <div class="w-full px-4 lg:w-1/2">
//                 <div class="text-center lg:text-right"><a
//                         class="font-semibold rounded-lg mx-auto inline-flex items-center justify-center bg-white py-4 px-9 hover:bg-opacity-90"
//                         href="#">Create Your First Form</a>
//                 </div>
//             </div> 
//         </div>
//     </div>
   
 
// </div>
// </div> */}
// <FAQSection/>
//                 {/* <section className="py-16 mb-10 px-6 md:px-16 bg-gradient-to-r from-purple-600 to-blue-600 text-white">
//       <div className="max-w-5xl mx-auto text-center">
//         <h2 className="text-4xl font-bold mb-8 text-white drop-shadow-lg">🚀 Our Mission, Vision & Values</h2>
//       </div>

//       <div className="grid md:grid-cols-2 gap-8 mt-8">
//         <motion.div
//           initial={{ opacity: 0, y: 50 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.6 }}
//           className="bg-white/10 p-6 rounded-lg shadow-lg border-l-4 border-indigo-300 hover:scale-105 transition-transform duration-300 backdrop-blur-lg"
//         >
//           <h3 className="text-xl font-semibold text-indigo-300">🌟 Our Mission</h3>
//           <p className="text-gray-200 mt-3">
//             At <span className="text-indigo-300 font-bold">Bidbox.ai</span>, our mission is to empower advertisers with AI-driven programmatic advertising solutions that maximize efficiency, precision, and measurable results.
//           </p>
//         </motion.div>

//         <motion.div
//           initial={{ opacity: 0, y: 50 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.6, delay: 0.2 }}
//           className="bg-white/10 p-6 rounded-lg shadow-lg border-l-4 border-green-300 hover:scale-105 transition-transform duration-300 backdrop-blur-lg"
//         >
//           <h3 className="text-xl font-semibold text-green-300">🔭 Our Vision</h3>
//           <p className="text-gray-200 mt-3">
//             We envision a future where **data-driven advertising** is seamless, transparent, and accessible, helping brands **maximize ROI** while ensuring **brand safety**.
//           </p>
//         </motion.div>
//       </div>

    
//       <div className="mt-12">
//         <h3 className="text-2xl font-semibold text-center text-yellow-300">💡 Our Core Values</h3>
//         <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
//           {[
//             { title: "🚀 Innovation", desc: "We embrace cutting-edge technology to stay ahead in digital advertising.", color: "blue-300" },
//             { title: "📊 Data-Driven Decisions", desc: "We prioritize insights & analytics for better ad performance.", color: "purple-300" },
//             { title: "🔍 Transparency & Trust", desc: "We provide a fraud-free and brand-safe environment.", color: "pink-300" },
//             { title: "🤝 Customer Success", desc: "We work closely with advertisers to create tailored solutions.", color: "yellow-300" },
//           ].map((value, index) => (
//             <motion.div
//               key={index}
//               initial={{ opacity: 0, y: 50 }}
//               whileInView={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.6, delay: index * 0.2 }}
//               className={`bg-white/10 p-6 rounded-lg shadow-md border-l-4 border-${value.color} hover:scale-105 transition-transform duration-300 backdrop-blur-lg`}
//             >
//               <h4 className={`text-lg font-semibold text-${value.color}`}>{value.title}</h4>
//               <p className="text-gray-200 mt-2">{value.desc}</p>
//             </motion.div>
//           ))}
//         </div>
//       </div>

      
//       <motion.div
//         initial={{ opacity: 0, y: 50 }}
//         whileInView={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.6, delay: 0.5 }}
//         className="mt-12 bg-white/10 p-8 rounded-lg shadow-lg text-center border-l-4 border-indigo-300 backdrop-blur-lg"
//       >
//         <h3 className="text-2xl font-semibold text-indigo-300">👨‍💻 Meet Our Experts</h3>
//         <p className="text-gray-200 mt-3">
//           Our **AI, data, and programmatic advertising experts** at <span className="text-indigo-300 font-bold">Bidbox.ai</span> are committed to delivering **exceptional** marketing solutions.
//         </p>
//       </motion.div>
//     </section> */}


//     {/* <section class="">
//     <div class="py-12 bg-white">
//         <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

//             <div class="lg:text-center">
//                 <h2
//                     class="font-heading mb-4 bg-orange-100 text-orange-800 px-4 py-2 rounded-lg md:w-64 md:mx-auto text-xs font-semibold tracking-widest text-black uppercase title-font">
//                     Why choose us?
//                 </h2>
//                 <p class="font-heading mt-2 text-3xl leading-8 font-semibold tracking-tight text-gray-900 sm:text-4xl">
//                     We know tech, we know finance. We are fintech experts.
//                 </p>
//                 <p class="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
//                     We know how to handle taxation for all the
//                     countried we operate in. We care for our customers and help them manage cashflows.
//                 </p>
//             </div>

//             <div class="mt-10">
//                 <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
//                     <div class="relative">
//                         <dt>
//                             <div
//                                 class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
//                                 <img src="https://www.svgrepo.com/show/503163/api-settings.svg" />
//                             </div>
//                             <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Powerful API</p>
//                         </dt>
//                         <dd class="mt-2 ml-16 text-base text-gray-500">
//                             Lorem ipsum, dolor sit amet consectetur
//                             adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate
//                             blanditiis ratione.
//                         </dd>
//                     </div>
//                     <div class="relative">
//                         <dt>
//                             <div
//                                 class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
//                                 <img src="https://www.svgrepo.com/show/503138/webpack.svg" />
//                             </div>
//                             <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Easy to integrate
//                                 SDK
//                             </p>
//                         </dt>
//                         <dd class="mt-2 ml-16 text-base text-gray-500"> Lorem ipsum, dolor sit amet consectetur
//                             adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate
//                             blanditiis ratione.
//                         </dd>
//                     </div>
//                     <div class="relative">
//                         <dt>
//                             <div
//                                 class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
//                                 <img src="https://www.svgrepo.com/show/511771/dashboard-671.svg" />

//                             </div>
//                             <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Low Transaction Cost
//                             </p>
//                         </dt>
//                         <dd class="mt-2 ml-16 text-base text-gray-500"> Lorem ipsum, dolor sit amet consectetur
//                             adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate
//                             blanditiis ratione.
//                         </dd>
//                     </div>
//                     <div class="relative">
//                         <dt>
//                             <div
//                                 class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
//                                 <img src="https://www.svgrepo.com/show/76267/free-commercial-label.svg" />

//                             </div>
//                             <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Powerful Dashboard
//                             </p>
//                         </dt>
//                         <dd class="mt-2 ml-16 text-base text-gray-500"> Lorem ipsum, dolor sit amet consectetur
//                             adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate
//                             blanditiis ratione.
//                         </dd>
//                     </div>
//                 </dl>
//             </div>

//         </div>
//     </div>
// </section> */}
// {/* <section class="text-gray-700 body-font">
//   <div class="flex justify-center mt-10 text-4xl font-regular">
//     Why Us?
//   </div>
//   <div class="container px-5 py-12 mx-auto">
//     <div class="flex flex-wrap text-center justify-center">
//       <div class="p-4 md:w-1/4 sm:w-1/2">
//         <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
//           <div class="flex justify-center">
//             <img src="https://image3.jdomni.in/banner/13062021/58/97/7C/E53960D1295621EFCB5B13F335_1623567851299.png?output-format=webp" class="w-32 mb-3"/>
//           </div>
//           <h2 class="title-font font-regular text-2xl text-gray-900">Latest Milling Machinery</h2>
//         </div>
//       </div>

//       <div class="p-4 md:w-1/4 sm:w-1/2">
//         <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
//           <div class="flex justify-center">
//             <img src="https://image2.jdomni.in/banner/13062021/3E/57/E8/1D6E23DD7E12571705CAC761E7_1623567977295.png?output-format=webp" class="w-32 mb-3"/>
//           </div>
//           <h2 class="title-font font-regular text-2xl text-gray-900">Reasonable Rates</h2>
//         </div>
//       </div>

//       <div class="p-4 md:w-1/4 sm:w-1/2">
//         <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
//           <div class="flex justify-center">
//             <img src="https://image3.jdomni.in/banner/13062021/16/7E/7E/5A9920439E52EF309F27B43EEB_1623568010437.png?output-format=webp" class="w-32 mb-3"/>
//           </div>
//           <h2 class="title-font font-regular text-2xl text-gray-900">Time Efficiency</h2>
//         </div>
//       </div>

//       <div class="p-4 md:w-1/4 sm:w-1/2">
//         <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
//           <div class="flex justify-center">
//             <img src="https://image3.jdomni.in/banner/13062021/EB/99/EE/8B46027500E987A5142ECC1CE1_1623567959360.png?output-format=webp" class="w-32 mb-3"/>
//           </div>
//           <h2 class="title-font font-regular text-2xl text-gray-900">Expertise in Industry</h2>
//         </div>
//       </div>

//     </div>
//   </div>
// </section> */}
//                 <Banner />
//             </div>
//         </>
//     )
// }

// export default About
