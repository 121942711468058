import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Http from "../../Http";
import { Blog_API } from "../../Helper";

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await Http.post(Blog_API);
        // Get latest 3 blogs
        const latestBlogs = response.blogs
          .sort((a, b) => new Date(b.content_date) - new Date(a.content_date))
          .slice(0, 3);
        setBlogs(latestBlogs);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <section className="w-full bg-white text-gray-900 py-16">
      <div className="max-w-7xl mx-auto px-4 text-center">
        {/* Section Title */}
        <motion.h2
          className="text-4xl md:text-5xl font-bold text-gray-800"
          initial={{ opacity: 0, y: -30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          Latest Blogs
        </motion.h2>
        <p className="text-gray-600 text-lg mt-4">
          Stay updated with our latest insights.
        </p>

        {/* Blog Cards */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
          {loading ? (
            // Loading skeletons
            [...Array(3)].map((_, index) => (
              <div key={index} className="bg-gray-100 rounded-lg h-96 animate-pulse" />
            ))
          ) : (
            blogs.map((blog, index) => (
              <motion.div
                key={blog.id}
                className="relative group bg-gray-100\ shadow-md rounded-lg overflow-hidden transform hover:scale-[1.05] transition duration-500"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                {/* Blog Image */}
                <div className="relative">
                  <img
                    src={blog.content_image}
                    alt={blog.content_title}
                    className="w-full h-56 object-cover transition-transform group-hover:scale-110 duration-500"
                  />
                  {/* Overlay Effect */}
                  {/* <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-25 transition duration-500"></div> */}
                </div>

                {/* Blog Content */}
                <div className="p-6 relative z-10">
                  <h3 className="text-xl font-semibold text-gray-800 group-hover:text-blue-600 transition">
                    {blog.content_title}
                  </h3>
                  <p className="text-gray-600 group-hover:text-gray-700 transition mt-2">
                    {new Date(blog.content_date).toLocaleDateString()}
                  </p>
                  <Link
                    to={`/blog/${blog.content_url}`}
                    className="inline-block mt-4 text-blue-600 font-semibold group-hover:underline transition"
                  >
                    Read More →
                  </Link>
                </div>
              </motion.div>
            ))
          )}
        </div>

        {/* View All Blogs Button */}
        <motion.div
          className="mt-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <Link
            to="/blog"
            className="bg-blue-600 text-white py-3 px-6 rounded-lg text-lg font-semibold hover:bg-blue-700 transition duration-300 transform hover:scale-105"
          >
            View All Blogs
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

export default BlogSection;

// import React, { useState, useEffect } from "react";
// import { motion } from "framer-motion";
// import { Link } from "react-router-dom";

// const BlogSection = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       try {
//         const response = await fetch("https://zonemedia.co/bidbox-blog/react-app-directory/blog-api.php");
//         const data = await response.json();
//         // Get latest 3 blogs
//         const latestBlogs = data.blogs
//           .sort((a, b) => new Date(b.content_date) - new Date(a.content_date))
//           .slice(0, 3);
//         setBlogs(latestBlogs);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching blogs:", error);
//         setLoading(false);
//       }
//     };

//     fetchBlogs();
//   }, []);

//   return (
//     <section className="w-full bg-white text-gray-900 py-16">
//       <div className="max-w-7xl mx-auto px-4 text-center">
//         {/* Section Title */}
//         <motion.h2
//           className="text-4xl md:text-5xl font-extrabold text-gray-800"
//           initial={{ opacity: 0, y: -30 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           viewport={{ once: true }}
//           transition={{ duration: 0.6, ease: "easeOut" }}
//         >
//           Latest Blogs
//         </motion.h2>
//         <p className="text-gray-600 text-lg mt-4">
//           Stay updated with our latest insights.
//         </p>

//         {/* Blog Cards */}
//         <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
//           {blogs.map((blog, index) => (
//             <motion.div
//               key={blog.id}
//               className="relative group bg-gray-100 shadow-md rounded-lg overflow-hidden transform hover:scale-[1.05] transition duration-500"
//               initial={{ opacity: 0, y: 20 }}
//               whileInView={{ opacity: 1, y: 0 }}
//               viewport={{ once: true }}
//               transition={{ duration: 0.5, delay: index * 0.2 }}
//             >
//               {/* Blog Image */}
//               <div className="relative">
//                 <img
//                   src={blog.content_image}
//                   alt={blog.content_title}
//                   className="w-full h-56 object-cover transition-transform group-hover:scale-110 duration-500"
//                 />
//                 {/* Overlay Effect */}
//                 <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-25 transition duration-500"></div>
//               </div>

//               {/* Blog Content */}
//               <div className="p-6 relative z-10">
//                 <h3 className="text-xl font-semibold text-gray-800 group-hover:text-blue-600 transition">
//                   {blog.content_title}
//                 </h3>
//                 <p className="text-gray-600 group-hover:text-gray-700 transition mt-2">
//                   {new Date(blog.content_date).toLocaleDateString()}
//                 </p>
//                 <Link
//                   to={`/blog/${blog.content_url}`}
//                   className="inline-block mt-4 text-blue-600 font-semibold group-hover:underline transition"
//                 >
//                   Read More →
//                 </Link>
//               </div>
//             </motion.div>
//           ))}
//         </div>

//         {/* View All Blogs Button */}
//         <motion.div
//           className="mt-12"
//           initial={{ opacity: 0, y: 20 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           viewport={{ once: true }}
//           transition={{ duration: 0.6 }}
//         >
//           <Link
//             to="/blog"
//             className="bg-blue-600 text-white py-3 px-6 rounded-lg text-lg font-semibold hover:bg-blue-700 transition duration-300 transform hover:scale-105"
//           >
//             View All Blogs
//           </Link>
//         </motion.div>
//       </div>
//     </section>
//   );
// };

// export default BlogSection;

// // import React from "react";
// // import { motion } from "framer-motion";
// // import { Link } from "react-router-dom";

// // const blogs = [
// //   {
// //     title: "The Future of AI in Business",
// //     description:
// //       "Explore how AI is transforming industries with automation and efficiency.",
// //     image: "https://source.unsplash.com/600x400/?technology,ai",
// //     link: "/blog/ai-in-business",
// //   },
// //   {
// //     title: "Top 5 Digital Marketing Trends",
// //     description:
// //       "Stay ahead in the digital marketing game with these latest trends.",
// //     image: "https://source.unsplash.com/600x400/?marketing,digital",
// //     link: "/blog/digital-marketing-trends",
// //   },
// //   {
// //     title: "Web Development Best Practices",
// //     description:
// //       "Optimize your web development workflow with these essential tips.",
// //     image: "https://source.unsplash.com/600x400/?web,development",
// //     link: "/blog/web-development-best-practices",
// //   },
// // ];

// // const BlogSection = () => {
// //   return (
// //     <section className="w-full bg-gray-50 py-16">
// //       <div className="max-w-6xl mx-auto px-4 text-center">
// //         {/* Section Title */}
// //         <motion.h2
// //           className="text-4xl md:text-5xl font-bold text-gray-800"
// //           initial={{ opacity: 0, y: -30 }}
// //           animate={{ opacity: 1, y: 0 }}
// //           transition={{ duration: 0.6, ease: "easeOut" }}
// //         >
// //           Latest Blogs
// //         </motion.h2>
// //         <p className="text-gray-600 text-lg mt-4">
// //           Stay updated with our latest insights.
// //         </p>

// //         {/* Blog Cards */}
// //         <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
// //           {blogs.map((blog, index) => (
// //             <motion.div
// //               key={index}
// //               className="relative group bg-white shadow-md rounded-lg overflow-hidden transform hover:scale-105 transition duration-500"
// //               initial={{ opacity: 0, y: 20 }}
// //               animate={{ opacity: 1, y: 0 }}
// //               transition={{ duration: 0.5, delay: index * 0.2 }}
// //             >
// //               {/* Blog Image */}
// //               <img
// //                 src={blog.image}
// //                 alt={blog.title}
// //                 className="w-full h-56 object-cover transition-transform group-hover:scale-110"
// //               />

// //               {/* Overlay Effect */}
// //               <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-500"></div>

// //               {/* Blog Content */}
// //               <div className="p-6 relative z-10">
// //                 <h3 className="text-xl font-semibold text-gray-800 group-hover:text-white transition">
// //                   {blog.title}
// //                 </h3>
// //                 <p className="text-gray-600 group-hover:text-gray-300 transition mt-2">
// //                   {blog.description}
// //                 </p>
// //                 <Link
// //                   to={blog.link}
// //                   className="inline-block mt-4 text-blue-600 group-hover:text-white transition"
// //                 >
// //                   Read More →
// //                 </Link>
// //               </div>
// //             </motion.div>
// //           ))}
// //         </div>

// //         {/* View All Blogs Button */}
// //         <div className="mt-10">
// //           <Link
// //             to="/blog"
// //             className="bg-blue-600 text-white py-3 px-6 rounded-lg text-lg font-semibold hover:bg-blue-700 transition duration-300"
// //           >
// //             View All Blogs
// //           </Link>
// //         </div>
// //       </div>
// //     </section>
// //   );
// // };

// // export default BlogSection;
// import React from "react";
// import { motion } from "framer-motion";
// import { Link } from "react-router-dom";

// const blogs = [
//   {
//     title: "The Future of AI in Business",
//     description:
//       "Explore how AI is transforming industries with automation and efficiency.",
//     image: "https://media.istockphoto.com/id/1326092736/photo/case-study-written-in-speech-bubble.jpg?s=612x612&w=0&k=20&c=PBt663QmLD2Fg17pkVZdUP63dQY_v-s4MYe8MpKFHnU=",
//     link: "/blog/ai-in-business",
//   },
//   {
//     title: "Top 5 Digital Marketing Trends",
//     description:
//       "Stay ahead in the digital marketing game with these latest trends.",
//     image: "https://media.istockphoto.com/id/1326092736/photo/case-study-written-in-speech-bubble.jpg?s=612x612&w=0&k=20&c=PBt663QmLD2Fg17pkVZdUP63dQY_v-s4MYe8MpKFHnU=",
//     link: "/blog/digital-marketing-trends",
//   },
//   {
//     title: "Web Development Best Practices",
//     description:
//       "Optimize your web development workflow with these essential tips.",
//     image: "https://media.istockphoto.com/id/1326092736/photo/case-study-written-in-speech-bubble.jpg?s=612x612&w=0&k=20&c=PBt663QmLD2Fg17pkVZdUP63dQY_v-s4MYe8MpKFHnU=",
//     link: "/blog/web-development-best-practices",
//   },
// ];

// const BlogSection = () => {
//   return (
//     <section className="w-full bg-white text-gray-900 py-16">
//       <div className="max-w-7xl mx-auto px-4 text-center">
//         {/* Section Title */}
//         <motion.h2
//           className="text-4xl md:text-5xl font-extrabold text-gray-800"
//           initial={{ opacity: 0, y: -30 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           viewport={{ once: true }}
//           transition={{ duration: 0.6, ease: "easeOut" }}
//         >
//           Latest Blogs
//         </motion.h2>
//         <p className="text-gray-600 text-lg mt-4">
//           Stay updated with our latest insights.
//         </p>

//         {/* Blog Cards */}
//         <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
//           {blogs.map((blog, index) => (
//             <motion.div
//               key={index}
//               className="relative group bg-gray-100 shadow-md rounded-lg overflow-hidden transform hover:scale-[1.05] transition duration-500"
//               initial={{ opacity: 0, y: 20 }}
//               whileInView={{ opacity: 1, y: 0 }}
//               viewport={{ once: true }}
//               transition={{ duration: 0.5, delay: index * 0.2 }}
//             >
//               {/* Blog Image */}
//               <div className="relative">
//                 <img
//                   src={blog.image}
//                   alt={blog.title}
//                   className="w-full h-56 object-cover transition-transform group-hover:scale-110 duration-500"
//                 />
//                 {/* Overlay Effect */}
//                 <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-25 transition duration-500"></div>
//               </div>

//               {/* Blog Content */}
//               <div className="p-6 relative z-10">
//                 <h3 className="text-xl font-semibold text-gray-800 group-hover:text-blue-600 transition">
//                   {blog.title}
//                 </h3>
//                 <p className="text-gray-600 group-hover:text-gray-700 transition mt-2">
//                   {blog.description}
//                 </p>
//                 <Link
//                   to={blog.link}
//                   className="inline-block mt-4 text-blue-600 font-semibold group-hover:underline transition"
//                 >
//                   Read More →
//                 </Link>
//               </div>
//             </motion.div>
//           ))}
//         </div>

//         {/* View All Blogs Button */}
//         <motion.div
//           className="mt-12"
//           initial={{ opacity: 0, y: 20 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           viewport={{ once: true }}
//           transition={{ duration: 0.6 }}
//         >
//           <Link
//             to="/blog"
//             className="bg-blue-600 text-white py-3 px-6 rounded-lg text-lg font-semibold hover:bg-blue-700 transition duration-300 transform hover:scale-105"
//           >
//             View All Blogs
//           </Link>
//         </motion.div>
//       </div>
//     </section>
//   );
// };

// export default BlogSection;
