import React from "react";
import CaseStudyCard from "./CaseStudyCard";
import { Helmet } from "react-helmet";

const Fmcg = () => {
  return (
    <>
     <Helmet>
                <title>FMCG Digital Growth with BidBox AI – Case Study</title>
                <meta name='description' content='See how BidBox AI helped FMCG brands drive product awareness, in-store purchases, and online sales through targeted programmatic ad campaigns.' />
                <meta name='keywords' content='FMCG advertising, product marketing, consumer goods branding, fast-moving consumer goods promotions, digital shelf visibility, grocery advertising, CPG marketing, e-commerce FMCG campaigns, hyperlocal targeting, seasonal promotions, shopper marketing, brand loyalty campaigns, ad spend efficiency for FMCG, food and beverage advertising, mass-market targeting.' />               
            </Helmet>
    <div className="w-full">
      <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl font-bold  text-center mt-14">
          Consumer Packaged Goods
        </h1>
      </div>

      <div className="w-full overflow-hidden">
        <div className="w-full bg-white flex flex-col items-center">
          <div className="w-[95%] my-10">
            <h1 className="text-3xl md:text-5xl font-bold text-center">
              Explore Diverse Case Studies
            </h1>
            <p className="text-center py-5 text-xl font-medium">
              Dive into success stories spanning various industries and
              verticals to see how we deliver value.
            </p>

            <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5 justify-center md:justify-between">
              <CaseStudyCard
                image="/ThumsUpLogo.jpg"
                heading=" Elevating Brand Presence for the Cricket World Cup"
                impression="Impressions 20 Million+"
                vtr="CTR: 2.3%"
                content="In our strategic collaboration with Thums Up leading up to the Cricket World Cup, we executed a high-impact programmatic advertising campaign designed to maximize brand visibility and engagement. By leveraging precision targeting across P1 & P2 markets and regional inventories, we ensured that Thums Up remained at the forefront of consumer attention during this highly anticipated sporting event."
                content1="Our tailored approach segmented audiences based on demographics, interests, and engagement behaviors, allowing us to craft personalized messaging that resonated with diverse consumer groups. Through real-time optimizations and data-driven insights, we continuously refined ad placements, maximizing reach and effectiveness."
                 content2='The results were remarkable—a substantial uplift in brand awareness, heightened engagement, and a stronger brand connection with cricket fans nationwide. This campaign demonstrated the power of strategic programmatic advertising in amplifying brand presence ahead of a major global event, reinforcing Thums Up’s position as the go-to beverage for high-energy moments.'
              />
              <CaseStudyCard
                image="/smallindofood.jpg"
                heading="A Programmatic Triumph in the Indonesian Market"
                impression="Reach: 8,53,278"
                vtr="VTR: 98%"
                content="In our strategic initiative to enhance Indofoods' brand presence across Indonesia, we harnessed the power of programmatic advertising to drive impactful engagement and visibility. By crafting precise, data-driven targeting strategies, we effectively connected with diverse consumer segments, ensuring maximum resonance with the Indonesian audience."
                content1="Our approach focused on high-intent cohorts, including fast food enthusiasts and individuals seeking ready-to-cook food products. Through real-time optimizations and engaging creatives, we delivered highly relevant messaging that captured consumer interest and reinforced Indofoods’ brand authority."
                 content2='The results were outstanding, with a Video Completion Rate (VTR) exceeding 90%, showcasing strong audience engagement and brand recall. This campaign highlights the effectiveness of programmatic advertising in navigating competitive markets, solidifying Indofoods’ position as a trusted and preferred choice in Indonesia’s dynamic food industry.'
              />
              <CaseStudyCard
                image="/alfanew.jpg"
                heading="Alfagifts’ Soaring Success in the Indonesian Market"
                impression="Impressions: 6 Mn+"
                vtr="VTR: 90%"
                content="In a strategic effort to amplify Alfagifts' brand presence in Indonesia, we implemented a data-driven programmatic campaign that seamlessly integrated web and app traffic. Utilizing the precision of Bidbox, we crafted a tailored advertising approach that combined impactful native ads and engaging banner creatives, ensuring maximum resonance with the diverse Indonesian audience."
                content1="By strategically optimizing ad placements and targeting high-intent consumers, we drove meaningful interactions across digital touchpoints. This approach not only enhanced Alfagifts’ visibility but also contributed to a significant increase in both brand recognition and sales conversions."
                content2='The campaign’s success underscores the effectiveness of programmatic advertising in reaching the right audience at the right time. By leveraging advanced targeting techniques and multi-platform optimization, we positioned Alfagifts as a dominant player in Indonesia’s evolving retail landscape, exceeding branding objectives and reinforcing its market leadership.'
              />
                <CaseStudyCard
                image="/assets/masako8.png"
                heading="A High-Impact Push Notification Campaign"
                impression="Clicks: 117k+"
                vtr="CTR: 1.96 "
                content="Masako’s campaign was designed to captivate cooking enthusiasts across key cities, leveraging push notification ads to drive maximum engagement while ensuring minimal impression leakage. Our strategic approach focused on reaching audiences with a high affinity for culinary content, reinforcing Masako’s position as a household staple."
                content1="With a bold target of achieving 100,000 clicks, we executed three precisely optimized campaigns over a 16-day period. Our strategy revolved around data-driven targeting, analyzing user behavior, app activity, and purchase intent to refine our approach and maximize performance. By continuously optimizing our ad placements, we ensured that Masako’s message reached the most relevant audience segments, enhancing brand recall and engagement."
                content2='The campaign’s success exceeded expectations, delivering 117,696 clicks with an impressive 1.96% CTR. Surpassing the initial benchmark, these results demonstrated the effectiveness of our programmatic push notification strategy in driving strong user interactions. The high engagement rate underscored the resonance of Masako’s messaging, effectively boosting brand visibility across the nation.'
             content3='By integrating precise targeting, real-time optimization, and compelling creatives, we positioned Masako at the forefront of audience engagement in the food and cooking space. The campaign’s outstanding performance validated the power of programmatic advertising in reaching niche audiences with precision, ensuring Masako’s continued dominance in the market. Through strategic execution and continuous refinement, we successfully turned Masako’s campaign into a model of digital advertising excellence.'
             />
               <CaseStudyCard
                image="/assets/pop10.png"
                heading="Boosting Brand Visibility Through Targeted Digital Campaigns"
                impression="Impressions: 1.2M+"
                vtr="Views: 152K+ "
                content="Our campaign aimed to enhance Pop Mie’s visibility, engaging fast-food lovers through precise audience targeting. By reaching users interested in beverages, snacks, and lifestyle, we ensured strong engagement and brand recall."
                content1="The objective was to maximize reach and consumer interaction, focusing on the 18-44 age group (54% female, 46% male). The campaign primarily targeted millennials, college students, and fast-food diners, aligning with Pop Mie’s brand positioning. Through Bidbox.ai’s DSP, we executed a data-driven approach that enabled precise audience segmentation, strategic ad placements, and real-time optimization for maximum effectiveness."
                content2='The results? A remarkable 1.22 million+ impressions and 152,000+ video views, demonstrating the campaign’s success in enhancing Pop Mie’s brand presence. The combination of targeted reach and engaging ad formats ensured strong consumer engagement, making the brand more visible and relevant to its key audience.'
             content3='This campaign reinforced Pop Mie’s dominance in the instant snack category, successfully driving awareness and engagement among its ideal consumers while leveraging programmatic efficiency for optimal results.'
             />
            </div>
          </div>

          <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
            <div className="p-5 md:p-10 z-10 flex justify-between">
              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="text-white font-bold text-xl md:text-5xl">
                     Questions or ready to get started?
                  </h1>
                  <h2 className="text-white  text-xl md:text-2xl">
                    Reach out and let's supercharge your campaigns together!
                  </h2>
                </div>
                <div>
                  <a href="/contact">
                    <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                      Get Started
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Fmcg;
