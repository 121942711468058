import React, { useState } from "react";
import SliderChannelwise from "./SliderChannelwise";
import { ChevronDown } from "lucide-react";
import BannerChannelWise from "./BannerCtv";
import BannerCtv from "./BannerCtv";
import { motion} from "framer-motion";
import { Link } from "react-router-dom";
import ServiceForPush from "./SeviceForPush";
import { Helmet } from "react-helmet";

const PushAdsNew  = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);
  const items = [
    {
      title: "Precision-Powered Targeting",
      content:
        "Go beyond generic demographics—reach high-intent audiences with AI-driven insights and privacy-compliant data for maximum impact.",
    },
    {
      title: "Unmatched Streaming Access",
      content:
        "Tap into an expansive inventory of premium content, from top-tier streaming platforms to live sports and exclusive entertainment.",
    },
    {
      title: "Cross-Platform Synergy",
      content:
        "Seamlessly integrate CTV with mobile, web, and social campaigns, ensuring a cohesive brand experience across every screen.",
    },
    {
      title: "AI-Optimized Performance",
      content:
        "Leverage BidBox’s smart algorithms to optimize ad spend, enhance engagement, and ensure better performance with real-time adjustments to maximize your ROI.",
    },
    {
      title: "Engagement Without Disruption",
      content:
        "Deliver high-quality, non-intrusive ads in immersive environments, keeping audiences engaged without interrupting their viewing experience.",
    },
    {
      title: "Real-Time Measurement & Insights",
      content:
        "Track every interaction with advanced analytics  with 25+ KPIs—monitor app installs, conversions, and even in-store visits with real-time precision —quantifying real impact.",
    },
    {
      title: "Smart Frequency Control",
      content:
        "Manage ad exposure across devices and networks to avoid over-saturation and enhance user experience while maintaining brand recall.",
    },
  ];
  const caseStudies = [
    {
      id: 1,
      image:
        "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
      category: "CASE STUDY",
      title: "Old Navy leans into decisioned upfronts with live sports",
      stat1: "1.2M",
      desc1:
        "incremental households reached with live sports by excluding users reached via programmatic guaranteed",
      stat2: "70%",
      desc2:
        "reduction in cost per unique household on live sports versus programmatic guaranteed",
      link: "#",
    },
    {
      id: 2,
      image:
        "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
      category: "CASE STUDY",
      title: "Brand X improves digital advertising efficiency",
      stat1: "850K",
      desc1:
        "users engaged through targeted ads leading to better conversion rates",
      stat2: "60%",
      desc2: "increase in ROI from programmatic advertising strategies",
      link: "#",
    },
  ];

  // Animation variants
  const fadeInVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const staggerItem = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const scaleUp = {
    initial: { scale: 1 },
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };
  return (
    <>
     <Helmet>
        <title>Push Notification Ads – Instant User Engagement with BidBox AI</title>
        <meta name='description' content='Drive instant engagement and conversions with push notification ads, delivering personalized messages directly to users in real time.' />
        <meta name='keywords' content='Push notification advertising, real-time notifications, mobile push ads, instant engagement, personalized push notifications, direct-to-user messaging, conversion-driven push ads, browser push notifications, in-app push alerts, real-time ad delivery.' />               
    </Helmet>
      {/* <div
      className="relative flex items-center justify-center h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('https://t4.ftcdn.net/jpg/09/49/36/63/240_F_949366383_tyZF61HUtUectJuzOQGxzUW8vjWXVO1b.jpg')" }}
    >
      <div className="bg-white/20 backdrop-blur-md p-8 rounded-lg shadow-lg text-center max-w-lg">
        <h1 className="text-4xl font-bold text-white mb-4">Welcome to Our Platform</h1>
        <p className="text-white text-lg mb-6">
          Explore amazing features and elevate your experience with our services.
        </p>
        <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-105 transition transform duration-300">
          Get Started
        </button>
      </div>
    </div> */}
      {/* <div class="flex min-h-screen justify-center items-center dark:bg-slate-800 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            type: "spring",
            stiffness: 50,
          }}
          class="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8"
        >
          <div class="text-center ">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              class="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
            >
              <span class="block xl:inline">
                <span class="mb-1 block">Push Beyond Boundaries </span>
                <motion.span
                  initial={{ backgroundPosition: "0%" }}
                  animate={{ backgroundPosition: "100%" }}
                  transition={{
                    repeat: Infinity,
                    duration: 3,
                    ease: "linear",
                    repeatType: "reverse",
                  }}
                  class="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                >
                  Instant Impact, Maximum Reach
                </motion.span>
              </span>
              <div class="mt-2">
                10X Efficiency
                <span class="relative mt-3 whitespace-nowrap text-blue-600">
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 418 42"
                    class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
                    preserveAspectRatio="none"
                  >
                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                  </svg>
                  <span class="relative">with Bidbox Ai</span>
                </span>
              </div>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              class="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
            >
          Engage users instantly with Push Advertising—delivering personalized, high-impact messages straight to their devices, ensuring real-time visibility and maximum conversions. Stay top-of-mind, anytime, anywhere.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              class="mt-5 sm:mt-8 sm:flex sm:justify-center"
            >
              <motion.div
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                class="rounded-md shadow"
              >
                <a
                  class="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                  href="/contact"
                >
                  Get started
                </a>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div> */}
       <div className="flex min-h-[100dvh] justify-center items-center dark:bg-slate-800 overflow-x-hidden">
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
                      className="mx-auto flex h-full min-h-[100dvh] w-full items-center justify-center px-4 sm:px-6 lg:px-8"
                    >
                      <div className="text-center max-w-4xl">
                        <motion.h1
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.3, duration: 0.5 }}
                          className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
                          style={{
                            WebkitTransform: "translateY(0px)",
                            transform: "translateY(0px)",
                          }}
                        >
                          <span className="block xl:inline">
                            <span className="mb-1 block">Push Beyond Boundaries</span>
                            <motion.span
                              initial={{ backgroundPosition: "0%" }}
                              animate={{ backgroundPosition: "100%" }}
                              transition={{
                                repeat: Infinity,
                                duration: 3,
                                ease: "linear",
                                repeatType: "reverse",
                              }}
                              className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
                            >
                             Instant Impact, Maximum Reach
                            </motion.span>
                            <p className="mb-1 block">
                              10X Efficiency
                              <span className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                                with Bidbox.Ai
                              </span>{" "}
                            </p>
                          </span>
                        </motion.h1>
            
                        <motion.p
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.6, duration: 0.5 }}
                          className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
                        >
                        Engage users instantly with Push Advertising—delivering personalized, high-impact messages straight to their devices, ensuring real-time visibility and maximum conversions. Stay top-of-mind, anytime, anywhere.
                        </motion.p>
            
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.8, duration: 0.5 }}
                          className="mt-5 sm:mt-8 sm:flex sm:justify-center"
                        >
                          <motion.div
                            variants={scaleUp}
                            initial="initial"
                            whileHover="hover"
                            className="rounded-md shadow"
                          >
                            <a
                              className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                              href="/contact"
                            >
                              Get started
                            </a>
                          </motion.div>
                        </motion.div>
                      </div>
                    </motion.div>
                  </div>
      <div className="bg-white py-12 px-4 md:px-6">
        <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
          {/* Image on Left */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0  opacity-20 rounded-lg"></div>
            <motion.img
             variants={{
              initial: { scale: 1, rotateY: 0 },
              hover: { scale: 1.05, rotateY: 10 },
              float: {
                y: [0, -10, 0], // Floating effect
                transition: {
                  duration: 3,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut"
                }
              }
            }}
            initial="initial"
            whileHover="hover"
            animate="float"
              
              src="/assets/push.gif"
              alt="Connected TV"
              className="w-full h-auto md:h-full object-cover "
            />
          </motion.div>

          {/* Text on Right */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left "
          >
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
            Push Ads: Engage Users the Moment It Matters
            </h2>
            <p className="text-gray-700 text-lg md:text-lg leading-relaxed mb-6">
            Push notifications are real-time, attention-grabbing messages sent directly to mobile and desktop devices. They appear even when users aren’t active, ensuring instant visibility and engagement. With a single tap, users can take action, making them one of the most effective digital ad formats. These ads bypass clutter and distractions, driving faster responses. Whether for promotions, updates, or re-engagement, push ads deliver results effortlessly.
            </p>
            {/* <Link to="/contact">
              <motion.button
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-110 transition duration-300"
              >
                Learn More
              </motion.button>
            </Link> */}
          </motion.div>
        </div>

        {/* SECOND SECTION - Alternating Layout */}
        <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-12 mt-16">
          {/* Image on Right */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0  opacity-20 rounded-lg"></div>
            <motion.img
             variants={{
              initial: { scale: 1, rotateY: 0 },
              hover: { scale: 1.05, rotateY: 10 },
              float: {
                y: [0, -10, 0], // Floating effect
                transition: {
                  duration: 3,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut"
                }
              }
            }}
            initial="initial"
            whileHover="hover"
            animate="float"
              src="/assets/push1.png"
              alt="CTV Advertising"
              // className="w-full h-auto md:h-96 object-cover rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"

              className="w-full h-auto md:h-full object-cover "
            />
          </motion.div>

          {/* Text on Left */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
            Why Push Ads? Stay One Tap Away from Your Audience
            </h2>
            <p className="text-gray-700 text-lg md:text-lg leading-relaxed mb-6">
            Push notifications deliver high-visibility, opt-in messages that reach users in real time, ensuring instant attention. Unlike traditional ads, they don’t rely on browsing behavior, making them more direct and effective. Personalized and non-intrusive, they enhance user experience while keeping brands top-of-mind. Their cost-effectiveness and ability to drive instant conversions make them a powerful marketing tool. For brands looking to boost engagement without disruption, push ads are the perfect solution.
            </p>
            {/* <Link to="/contact">
              <motion.button
                variants={scaleUp}
                initial="initial"
                whileHover="hover"
                className="px-6 py-3 md:px-8 md:py-4 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg hover:scale-110 transition duration-300"
              >
                Learn More
              </motion.button>
            </Link> */}
          </motion.div>
        </div>
      </div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="max-w-4xl mx-auto text-center py-10"
      >
        <h2 className="text-4xl md:text-5xl font-bold mb-6">
        Boost Engagement Instantly with BidBox Push Advertising
        </h2>
        <p className="text-lg text-gray-600 mb-12 leading-relaxed ">
        BidBox takes push advertising to the next level with AI-driven targeting, real-time delivery, and fraud-free traffic. Our platform ensures your message reaches high-intent users at the perfect moment, maximizing engagement. With advanced segmentation and predictive analytics, campaigns are optimized for better performance and ROI. Seamlessly integrate push ads into your marketing mix and stay ahead of competitors. BidBox makes sure your brand is always visible, impactful, and driving results.
        </p>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="px-4 md:px-10"
      >
        <div className=" mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-6 md:px-8 rounded-lg shadow-lg mt-6 mb-6">
          <div className="container mx-auto flex flex-col md:flex-row items-center text-white text-center md:text-left space-y-8 md:space-y-0 md:space-x-8">
            {/* Column 1 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">
              90%+ Open Rate
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
              BidBox delivers unskippable, high-impact CTV ads that keep
              audiences engaged till the very end.
              </p>
            </motion.div>
            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>{" "}
            {/* Mobile Divider */}
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>{" "}
            {/* Desktop Divider */}
            {/* Column 2 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1 "
            >
              <h2 className="text-2xl md:text-2xl font-bold">2-4% Higher CTR</h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
              Push ads outperform traditional display ads, driving stronger user interactions.

              </p>
            </motion.div>
            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>{" "}
            {/* Mobile Divider */}
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>{" "}
            {/* Desktop Divider */}
            {/* Column 3 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1 "
            >
              <h2 className="text-2xl md:text-2xl font-bold">
              Up to 30% Conversion Rate{" "}
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
              Targeted notifications turn interest into action, maximizing campaign success.
              </p>
            </motion.div>
          </div>
        </div>
      </motion.div>
      {/* <div className='px-10'>
    <div className=" mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-8 rounded-lg shadow-lg mt-6 mb-6">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between text-white text-center">
       
        <div className="flex-1 px-10">
          <h2 className="text-3xl font-bold">95%+ Video Completion</h2>
          <p className="text-lg opacity-90 mt-1">BidBox delivers unskippable, high-impact CTV ads that keep audiences engaged till the very end.
          </p>
        </div>

       
        <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>

        
        <div className="flex-1 px-10">
          <h2 className="text-3xl font-bold">35% Lower CPM</h2>
          <p className="text-lg opacity-90 mt-1">BidBox optimizes ad spend with AI-driven bidding, reducing costs while maximizing reach.</p>
        </div>

        <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>

        <div className="flex-1 px-10">
          <h2 className="text-3xl font-bold">80% engagement</h2>
          <p className="text-lg opacity-90 mt-1">Advanced targeting ensures your ads reach the right viewers, boosting interaction and brand recall.</p>
        </div>

      </div>
    </div>
    </div> */}
  
  
      {/* <div className="w-full max-w-7xl mx-auto py-12 px-4">
        
        <h2 className="text-4xl md:text-5xl font-bold text-center mb-10">
          Unskippable. Unmissable. Unmatched
        </h2>
\
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
         
          <div className="w-full md:w-[50%] flex justify-center">
            <img
              src="https://www.intandemcommunications.co.uk/wp-content/uploads/2019/08/What-is-marketing.jpg" // Replace with actual image
              alt="Marketing"
              className="w-full max-w-2xl h-auto rounded-lg shadow-lg"
            />
          </div>

          
          <div className="w-full md:w-[50%] space-y-4">
            {items.map((item, index) => (
              <div
                key={index}
                className="border-b border-gray-300 cursor-pointer"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
              
                <div className="flex justify-between items-center py-4">
                  <h2 className="text-lg font-semibold">{item.title}</h2>
                  <ChevronDown
                    className={`w-5 h-5 transition-transform ${
                      openIndex === index ? "rotate-180" : ""
                    }`}
                  />
                </div>

               
                {openIndex === index && (
                  <p className="text-gray-600 pb-4">{item.content}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div> */}
      {/* Expandable Panels on Hover */}
      {/* <div className="w-full max-w-7xl mx-auto py-12 px-4">
        
        <motion.h2
          initial={{ opacity: 0, y: -30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="text-4xl md:text-5xl font-bold text-center mb-10"
        >
          Unskippable. Unmissable. Unmatched
        </motion.h2>

      
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
        
          <div className="w-full md:w-[50%] flex justify-center">
            <img
              src="https://www.intandemcommunications.co.uk/wp-content/uploads/2019/08/What-is-marketing.jpg"
              alt="Marketing"
              className="w-full max-w-2xl h-auto rounded-lg shadow-lg"
            />
          </div>

          <div className="w-full md:w-[50%] space-y-4">
            {items.map((item, index) => (
              <div
                key={index}
                className="border-b border-gray-300 cursor-pointer"
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
              >
              
                <div className="flex justify-between items-center py-4">
                  <h2 className="text-lg font-semibold">{item.title}</h2>
                  <ChevronDown
                    className={`w-5 h-5 transition-transform ${
                      hoverIndex === index ? "rotate-180" : ""
                    }`}
                  />
                </div>

                
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={
                    hoverIndex === index
                      ? { height: "auto", opacity: 1 }
                      : { height: 0, opacity: 0 }
                  }
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="overflow-hidden"
                >
                  <p className="text-gray-600 pb-4">{item.content}</p>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      <ServiceForPush/>
      
<motion.div
    initial="hidden"
    whileInView="visible"
    variants={fadeInVariant}
    viewport={{ once: true, amount: 0.5 }}
    className="w-full flex justify-center mt-10">
   <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
             className="w-[95%] mb-20 h-[300px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
        <div className="p-5 md:p-10 z-10 flex justify-between">
          <div className="flex flex-col justify-between">
            <div>
            <motion.h1
                              initial={{ opacity: 0, y: -20 }}
                              whileInView={{ opacity: 1, y: 0 }}
                              transition={{ duration: 0.5 }}
                              viewport={{ once: true }} className="text-white font-bold text-xl md:text-5xl">          
              Push Your Brand to the Forefront—Instantly!
             </motion.h1>
                             <motion.h2
                               initial={{ opacity: 0 }}
                               whileInView={{ opacity: 1 }}
                               transition={{ duration: 0.5, delay: 0.2 }}
                               viewport={{ once: true }} className="text-gray-300  text-xl md:text-xl py-2">
Engage users in real time with high-impact push notifications delivered straight to their devices. Drive clicks, boost conversions, and stay top-of-mind—wherever your audience is.              </motion.h2>
                           </div>
                           <motion.div
                             initial={{ opacity: 0, y: 20 }}
                             whileInView={{ opacity: 1, y: 0 }}
                             transition={{ duration: 0.5, delay: 0.4 }}
                             viewport={{ once: true }}>
              <a href="/contact">
                <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                Get Started Now
                </button>
              </a>
          </motion.div>
                    </div>
                  </div>
                  </motion.div>
                </motion.div>
      {/* Case Studies */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.4 }}
        className="max-w-7xl mx-auto py-12 px-4"
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-3xl md:text-4xl font-bold text-center mb-10"
        >
          Success Stories
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {caseStudies.map((study, index) => (
            <motion.div
              key={study.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
              whileHover={{
                scale: 1.03,
                boxShadow:
                  "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
              }}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300"
            >
              {/* Top: Image with overlay effect */}
              <div className="w-full relative overflow-hidden">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                  src={study.image}
                  alt="Case Study"
                  className="w-full h-48 md:h-64 object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
                <div className="absolute bottom-0 left-0 p-4">
                  <span className="text-xs font-bold text-white bg-blue-600 px-2 py-1 rounded">
                    {study.category}
                  </span>
                </div>
              </div>

              {/* Bottom: Text Content */}
              <div className="p-6 text-center">
                <h3 className="text-xl font-bold mt-2">{study.title}</h3>
                <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-8 mt-4">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">
                      {study.stat1}
                    </p>
                    <p className="text-gray-600 text-sm">{study.desc1}</p>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">
                      {study.stat2}
                    </p>
                    <p className="text-gray-600 text-sm">{study.desc2}</p>
                  </motion.div>
                </div>
                <motion.a
                  whileHover={{ scale: 1.1 }}
                  href={study.link}
                  className="text-blue-600 font-semibold mt-6 inline-block border-b-2 border-transparent hover:border-blue-600"
                >
                  READ CASE STUDY
                </motion.a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export default PushAdsNew ;


// import React, { useState } from 'react'
// import SliderChannelwise from './SliderChannelwise'
// import { ChevronDown } from 'lucide-react';
// import BannerChannelWise from './BannerCtv';

// const PushAdsNew = () => {
//     const [openIndex, setOpenIndex] = useState(0);
//     const items = [
//         {
//           title: "Instant User Engagement",
//           content:
//             "Deliver real-time messages directly to users' devices, ensuring high visibility and immediate interaction—no waiting, no delays.",
//         },
//         { title: "100% Opt-In, Fraud-Free Traffic", content: "Connect with genuine, high-intent audiences who have voluntarily subscribed, eliminating bot traffic and maximizing authentic engagement." },
//         { title: "Personalized & Hyper-Targeted", content: "Leverage AI-driven targeting to customize notifications based on user behavior, location, and preferences—ensuring relevance and higher conversion rates." },
//         { title: "Cross-Device Reach", content: "Seamlessly connect with users across mobile, desktop, and tablet screens, ensuring your brand stays top-of-mind anytime, anywhere." },
//         { title: " Non-Intrusive Yet Unmissable", content: "Unlike pop-ups, push notifications appear naturally on screens without disrupting user experience—keeping engagement high and annoyance low." },
//         { title: "Cost-Effective with Maximum ROI", content: "Push ads deliver powerful results with minimal spend, offering high click-through rates and conversion potential without excessive ad budgets." },
//         { title: "Real-Time Performance Insights", content: "Track impressions, clicks, and conversions instantly with BidBox’s analytics dashboard—giving you full transparency and control over campaign performance." },

//     ];
//       const caseStudies = [
//         {
//           id: 1,
//           image: "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
//           category: "CASE STUDY",
//           title: "Old Navy leans into decisioned upfronts with live sports",
//           stat1: "1.2M",
//           desc1: "incremental households reached with live sports by excluding users reached via programmatic guaranteed",
//           stat2: "70%",
//           desc2: "reduction in cost per unique household on live sports versus programmatic guaranteed",
//           link: "#",
//         },
//         {
//           id: 2,
//           image: "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
//           category: "CASE STUDY",
//           title: "Brand X improves digital advertising efficiency",
//           stat1: "850K",
//           desc1: "users engaged through targeted ads leading to better conversion rates",
//           stat2: "60%",
//           desc2: "increase in ROI from programmatic advertising strategies",
//           link: "#",
//         },
//       ];
//   return (
//     <>
//        {/* <div
//       className="relative flex items-center justify-center h-screen bg-cover bg-center"
//       style={{ backgroundImage: "url('https://t4.ftcdn.net/jpg/09/49/36/63/240_F_949366383_tyZF61HUtUectJuzOQGxzUW8vjWXVO1b.jpg')" }}
//     >
//       <div className="bg-white/20 backdrop-blur-md p-8 rounded-lg shadow-lg text-center max-w-lg">
//         <h1 className="text-4xl font-bold text-white mb-4">Welcome to Our Platform</h1>
//         <p className="text-white text-lg mb-6">
//           Explore amazing features and elevate your experience with our services.
//         </p>
//         <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-105 transition transform duration-300">
//           Get Started
//         </button>
//       </div>
//     </div> */}
//     <div class="flex h-screen justify-center items-center dark:bg-slate-800">
//     <div class="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8">
//         <div class="text-center ">
//             <h1
//                 class="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl">
//                 <span class="block xl:inline"><span class="mb-1 block">Push Beyond Boundaries</span>
//                 <span class="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
//                 Instant Impact, Maximum Reach 
//                 </span>
//                 </span>
//                 <div class="mt-2">10X Efficiency
//                     <span class="relative mt-3 whitespace-nowrap text-blue-600"><svg aria-hidden="true" viewBox="0 0 418 42"
//                         class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
//                         preserveAspectRatio="none">
//                         <path
//                             d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z">
//                         </path>
//                     </svg>
//                     <span class="relative">with BidBox Ai</span>
//                     </span>
//                 </div>
//             </h1>
//             <p class="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5">
//             Engage users instantly with Push Advertising—delivering personalized, high-impact messages straight to their devices, ensuring real-time visibility and maximum conversions. Stay top-of-mind, anytime, anywhere.            </p>
//             <div class="mt-5 sm:mt-8 sm:flex sm:justify-center">
//                 <div class="rounded-md shadow"><a
//                         class="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
//                         href="#">Get started for free 🚀
//                     </a>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// <div className="bg-white py-16 px-6">

// {/* <div className="bg-gradient-to-r from-gray-100 to-gray-300 py-16 px-6"> */}
//       <div className="container mx-auto flex flex-col md:flex-row items-center gap-8 md:gap-16">
        
//         {/* Image on Left */}
//         <div className="relative w-full md:w-5/12">
//           <div className="absolute inset-0 bg-purple-600 opacity-20 rounded-lg"></div>
//           <img
//             src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
//             alt="About"
//             className="w-full h-auto rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"
//           />
//         </div>

//         {/* Text on Right */}
//         <div className="w-full md:w-7/12">
//           <h2 className="text-5xl font-bold text-gray-900 mb-4">Push Ads: Engage Users the Moment It Matters</h2>
//           <p className="text-gray-700 text-xl leading-relaxed mb-6">
//           Push notifications are real-time, attention-grabbing messages sent directly to mobile and desktop devices. They appear even when users aren’t active, ensuring instant visibility and engagement. With a single tap, users can take action, making them one of the most effective digital ad formats. These ads bypass clutter and distractions, driving faster responses. Whether for promotions, updates, or re-engagement, push ads deliver results effortlessly.          </p>
//           {/* <p className="text-gray-700 text-lg leading-relaxed mb-6">The poet also sees a child climbing up a steep ground collecting blackberries, a homeless man standing and watching as the train passes by, village women making garland out of daisies, a cart moving laboriously on the highway with the weight of the load and a man on it. He also sees a mill and rivers. They all pass by very quickly, disappearing as fast as they appeared.          </p> */}
//           <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-110 transition duration-300">
//             Learn More
//           </button>
//         </div>

//       </div>

//       {/* SECOND SECTION - Alternating Layout */}
//       <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-8 md:gap-16 mt-26">
        
//         {/* Image on Right */}
//         <div className="relative w-full md:w-5/12">
//           <div className="absolute inset-0 bg-blue-600 opacity-20 rounded-lg"></div>
//           <img
//             src="https://i.pinimg.com/474x/cd/01/7c/cd017c79c69ff1c9587b131c3cba9bbd.jpg"
//             alt="About"
//             className="w-full h-auto rounded-lg shadow-2xl transform scale-100 hover:scale-105 transition duration-500"
//           />
//         </div>

//         {/* Text on Left */}
//         <div className="w-full md:w-7/12">
//           <h2 className="text-5xl font-bold text-gray-900 mb-4"> Why Push Ads? Stay One Tap Away from Your Audience</h2>
//           <p className="text-gray-700 text-xl leading-relaxed mb-6">
//           Push notifications deliver high-visibility, opt-in messages that reach users in real time, ensuring instant attention. Unlike traditional ads, they don’t rely on browsing behavior, making them more direct and effective. Personalized and non-intrusive, they enhance user experience while keeping brands top-of-mind. Their cost-effectiveness and ability to drive instant conversions make them a powerful marketing tool. For brands looking to boost engagement without disruption, push ads are the perfect solution.          {/* <p className="text-gray-700 text-lg leading-relaxed mb-6"> The poet also sees a child climbing up a steep ground collecting blackberries, a homeless man standing and watching as the train passes by, village women making garland out of daisies, a cart moving laboriously on the highway with the weight of the load and a man on it. He also sees a mill and rivers. They all pass by very quickly, disappearing as fast as they appeared.          </p> */}
//           </p>
//           <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg hover:scale-110 transition duration-300">
//             Learn More
//           </button>
//         </div>

//       </div>
//     </div>


//     <div 
//     className="max-w-4xl mx-auto text-center"
    
//   >
//     <h2 className="text-4xl md:text-5xl font-bold mb-6">Boost Engagement Instantly with BidBox Push Advertising
//     </h2>
//     <p className="text-xl text-gray-600 mb-12">
//     BidBox revolutionizes push advertising with real-time, high-visibility messaging that reaches users instantly. Deliver personalized, opt-in notifications directly to mobile and desktop screens—driving engagement, boosting retention, and maximizing conversions. With AI-powered targeting and seamless automation, BidBox ensures your message lands at the right moment, keeping your brand top-of-mind while eliminating ad fraud.

//     </p>
//   </div>
//     <div className='px-4 md:px-10'>
//     <div className=" mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-6 md:px-8 rounded-lg shadow-lg mt-6 mb-6">
//       <div className="container mx-auto flex flex-col md:flex-row items-center text-white text-center md:text-left space-y-8 md:space-y-0 md:space-x-8">
        
//         {/* Column 1 */}
//         <div className="flex-1">
//           <h2 className="text-2xl md:text-2xl font-bold">85%+ Opt-in Rate</h2>
//           <p className="text-base md:text-sm opacity-90 mt-2">BidBox ensures your push ads reach engaged, permission-based users and maximizing real interactions.

//           </p>
//         </div>

//         {/* Divider */}
//       <div className="w-full h-px bg-white opacity-50 md:hidden"></div> {/* Mobile Divider */}
//       <div className="hidden md:block w-px h-16 bg-white opacity-50"></div> {/* Desktop Divider */}


//         {/* Column 2 */}
//         <div className="flex-1 ">
//           <h2 className="text-2xl md:text-2xl font-bold">40% Lower (CPC)</h2>
//           <p className="text-base md:text-sm opacity-90 mt-2">AI-powered optimizations ensure efficient bidding, reducing costs while maximizing ROI.</p>
//         </div>

//         {/* Divider */}
//       <div className="w-full h-px bg-white opacity-50 md:hidden"></div> {/* Mobile Divider */}
//       <div className="hidden md:block w-px h-16 bg-white opacity-50"></div> {/* Desktop Divider */}


//         {/* Column 3 */}
//         <div className="flex-1 ">
//           <h2 className="text-2xl md:text-2xl font-bold">25% Higher Conversion Rate </h2>
//           <p className="text-base md:text-sm opacity-90 mt-2">Timely, targeted push notifications drive real-time actions, from app installs to purchases.
//           </p>
//         </div>

//       </div>
//     </div>
//     </div>
//     <SliderChannelwise/>
//     <div className="w-full max-w-7xl mx-auto py-12 px-4">
//       {/* Title */}
//       <h2 className="text-4xl md:text-5xl font-bold text-center mb-10">Straight to Screens. Straight to Action</h2>

//       {/* Container */}
//       <div className="flex flex-col md:flex-row items-center justify-between gap-12">
//         {/* Left Side: Image */}
//         <div className="w-full md:w-[50%] flex justify-center">
//           <img
//             src="https://www.intandemcommunications.co.uk/wp-content/uploads/2019/08/What-is-marketing.jpg" // Replace with actual image
//             alt="Marketing"
//             className="w-full max-w-2xl h-auto rounded-lg shadow-lg"
//           />
//         </div>

//         {/* Right Side: Accordion */}
//         <div className="w-full md:w-[50%] space-y-4">
//           {items.map((item, index) => (
//             <div
//               key={index}
//               className="border-b border-gray-300 cursor-pointer"
//               onClick={() => setOpenIndex(openIndex === index ? null : index)}
//             >
//               {/* Title */}
//               <div className="flex justify-between items-center py-4">
//                 <h2 className="text-lg font-semibold">{item.title}</h2>
//                 <ChevronDown
//                   className={`w-5 h-5 transition-transform ${
//                     openIndex === index ? "rotate-180" : ""
//                   }`}
//                 />
//               </div>

//               {/* Content */}
//               {openIndex === index && (
//                 <p className="text-gray-600 pb-4">{item.content}</p>
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>

//     <div className="w-full flex justify-center mt-10">
//       <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
//         <div className="p-5 md:p-10 z-10 flex justify-between">
//           <div className="flex flex-col justify-between">
//             <div>
//               <h1 className="text-white font-bold text-xl md:text-5xl">          
// Push Your Brand to the Forefront—Instantly!
//               </h1>
//               <h2 className="text-gray-300  text-xl md:text-xl py-2">
//               Engage users in real time with high-impact push notifications delivered straight to their devices. Drive clicks, boost conversions, and stay top-of-mind—wherever your audience is.
//               </h2>
//             </div>
//             <div>
//               <a href="/contact">
//                 <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
//                 Get Started Now
//                 </button>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="max-w-7xl mx-auto py-12 px-4">
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//         {caseStudies.map((study) => (
//           <div
//             key={study.id}
//             className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl"
//           >
//             {/* Top: Image */}
//             <div className="w-full">
//               <img
//                 src={study.image}
//                 alt="Case Study"
//                 className="w-full h-auto object-cover"
//               />
//             </div>

//             {/* Bottom: Text Content */}
//             <div className="p-6 text-center">
//               <p className="text-sm text-blue-600 font-semibold">{study.category}</p>
//               <h3 className="text-xl font-bold mt-2">{study.title}</h3>
//               <div className="flex justify-center gap-8 mt-4">
//                 <div>
//                   <p className="text-2xl font-bold">{study.stat1}</p>
//                   <p className="text-gray-600">{study.desc1}</p>
//                 </div>
//                 <div>
//                   <p className="text-2xl font-bold">{study.stat2}</p>
//                   <p className="text-gray-600">{study.desc2}</p>
//                 </div>
//               </div>
//               <a href={study.link} className="text-blue-600 font-semibold mt-4 inline-block">
//                 READ CASE STUDY
//               </a>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//     </>
//   )
// }

// export default PushAdsNew
