import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton CSS
import { motion } from "framer-motion"; // For animations
import Http from "../../Http";
import { Blog_API } from "../../Helper";
import { Helmet } from "react-helmet";
const BlogMain = () => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null); // Track selected category
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch the blog data from API
    const fetchBlogs = async () => {
      // const response = await fetch("https://zonemedia.co/bidbox-blog/react-app-directory/blog-api.php");
      // const data = await response.json();
      try {
        const data = await Http.get(Blog_API);
      setBlogs(data.blogs);

      // Extract categories from blogs for sidebar
      const categoryMap = {};
      data.blogs.forEach(blog => {
        const category = blog.content_category;
        if (category) {
          categoryMap[category] = (categoryMap[category] || 0) + 1;
        }
      });
      const categoryArray = Object.keys(categoryMap).map(category => ({
        name: category,
        count: categoryMap[category]
      }));
      setCategories(categoryArray);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

    fetchBlogs();
  }, []);

  // Handle category click and filtering of blogs
  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
  };

  // Filter blogs by selected category and sort by date (newest first)
  const filteredBlogs = blogs
    .filter((blog) => selectedCategory === null || blog.content_category === selectedCategory)
    .sort((a, b) => new Date(b.content_date) - new Date(a.content_date)); // Sort by date, newest first

  return (
    <>
      <Helmet>
                <title>BidBox AI Blog – Insights on DSP and Advertising Trends</title>
                <meta name='description' content='Stay updated with the latest trends in DSP technology and targeted advertising through BidBox AIs expert blog articles and insights.' />
                <meta name='keywords' content='Programmatic advertising blog, DSP industry trends, digital marketing insights, ad tech news, AI in advertising, media buying strategies, advertising automation, real-time bidding updates, audience targeting tips, data-driven marketing, digital ad strategy, performance marketing techniques, online advertising trends, CTV and OTT advertising, brand growth through DSP.' />               
            </Helmet>
     <div
  className="relative h-96 bg-cover bg-center"
  style={{ backgroundImage: `url("/assets/blog.jpeg")` }}
>
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50" ></div>

      {/* Content */}
      <div className="relative z-10 h-full flex flex-col justify-center items-start pl-8 md:pl-16">
        <h1 className="text-white text-3xl md:text-5xl font-bold mb-4 mt-16">
           Resources 
        </h1>
        <p className="text-white text-lg md:text-xl mb-6">
        Insights. Trends. Innovation. Stay ahead with the latest in programmatic advertising.
        </p>
        {/* <a href="#" className="text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white text-lg font-semibold px-6 py-2 rounded transition duration-300" >
          Free Consultation
        </a> */}
      </div>
    </div>
    <div className="container mx-auto p-4 py-20">
      <div className="flex flex-wrap ">
        {/* Blog Content */}
        <div className="w-full md:w-3/4 pr-6  p-4 rounded-lg shadow-md ">
          {loading ? (
            <Skeleton height={300} count={3} />
          ) : filteredBlogs.length === 0 ? (
            <div>No blogs found for this category.</div>
          ) : (
            filteredBlogs.map((blog) => (
              <motion.div
                key={blog.id}
                className="mb-8"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {/* <img src={blog.content_image} alt={blog.content_title} className="w-full h-96 object-cover mb-4 mt-12" /> */}
              
                <img 
  src={blog.content_image} 
  alt={blog.content_title} 
  className="w-full h-60 sm:h-72 md:h-80 lg:h-[28rem] object-contain mb-4 mt-12" 
/>
 <div className="flex items-center text-gray-500 text-sm mb-2 ">
                  <span><i className="fa fa-comments font-bold text-lg">Published : </i> {blog.content_date}</span>
                </div>
                <h2 className="text-3xl font-semibold mb-2">{blog.content_title}</h2>
                <div className="mt-6">
                <a href={`/blog/${blog.content_url}`} className="hover:underline text-white  bg-gradient-to-r from-blue-600 to-purple-500 px-4 py-2 rounded-full">
                  Read More
                </a>
                </div>
              </motion.div>
            ))
          )}
        </div>

        {/* Sidebar */}
        <div className="w-full md:w-1/4 mt-10">
          <div className="mb-8 bg-gray-100 p-4 rounded-lg shadow-lg ml-2">
            <h3 className="text-xl font-bold mb-4 border-b-2 border-pblue-600 pb-2">Categories</h3>
            <ul className="space-y-3">
              <li>
                <button
                  onClick={() => setSelectedCategory(null)}
                  className={`flex justify-between items-center text-gray-700 hover:text-blue-600 font-medium py-2 px-4 rounded-lg transition-colors ${
                    selectedCategory === null ? 'bg-blue-100 text-blue-600' : ''
                  }`}
                >
                  All Categories
                </button>
              </li>
              {loading ? (
                <Skeleton count={5} height={30} />
              ) : (
                categories.map((category) => (
                  <li key={category.name}>
                    <button
                      onClick={() => handleCategoryClick(category.name)}
                      className={`flex justify-between items-center text-gray-700 hover:text-blue-600 font-medium py-2 px-1 rounded-lg transition-colors ${
                        selectedCategory === category.name ? 'bg-blue-100 text-blue-600' : ''
                      }`}
                    >
                      <span>{category.name}</span>
                      <span className="bg-gray-200 rounded-full px-2 py-1 text-xs text-gray-600 ml-1">
                        {category.count}
                      </span>
                    </button>
                  </li>
                ))
              )}
            </ul>
          </div>

          {/* Sidebar - New Blogs */}
          <div className="mb-8 bg-gray-100 p-4 rounded-lg shadow-lg ml-2">
            <h3 className="text-xl font-bold mb-4 border-b-2 border-blue-600 pb-2">New Blogs</h3>
            <ul>
              {loading ? (
                <Skeleton count={3} height={80} />
              ) : (
                blogs
                  .sort((a, b) => new Date(b.content_date) - new Date(a.content_date)) // Sort by date (newest first)
                  .slice(0, 3) // Show only the latest 3 blogs
                  .map((blog) => (
                    <motion.li
                      key={blog.id}
                      className="mb-4 flex"
                      initial={{ opacity: 0, x: -30 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <a href={`/blog/${blog.content_url}`} className="flex items-start w-full">
                        <img src={blog.content_image} alt={blog.content_title} className="w-16 h-16 object-cover mr-4 rounded-lg" />
                        <div>
                          <h4 className="font-semibold text-gray-800 hover:text-blue-600 transition-colors">{blog.content_title}</h4>
                          <p className="text-gray-500 text-sm">{blog.content_date}</p>
                        </div>
                      </a>
                    </motion.li>
                  ))
              )}
            </ul>
          </div>

          {/* Sidebar - Popular Posts */}
          <div className="bg-gray-100 p-4 rounded-lg shadow-lg ml-2">
            <h3 className="text-xl font-bold mb-4 border-b-2 border-blue-600 pb-2">Popular Posts</h3>
            <ul>
              {loading ? (
                <Skeleton count={3} height={80} />
              ) : (
                blogs.slice(0, 10).map((blog) => (
                  <motion.li
                    key={blog.id}
                    className="mb-4 flex"
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <a href={`/blog/${blog.content_url}`} className="flex items-start w-full">
                      <img src={blog.content_image} alt={blog.content_title} className="w-16 h-16 object-cover mr-4 rounded-lg" />
                      <div>
                        <h4 className="font-semibold text-gray-800 hover:text-blue-600 transition-colors">{blog.content_title}</h4>
                        <p className="text-gray-500 text-sm">{blog.content_date}</p>
                      </div>
                    </a>
                  </motion.li>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default BlogMain;