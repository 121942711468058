import React from "react";
import CaseStudyCard from "./CaseStudyCard";
import { Helmet } from "react-helmet";

const Bfsi = () => {
  return (
    <>
     <Helmet>
                <title>BFSI Advertising Success Story – BidBox AI Case Study</title>
                <meta name='description' content='Explore how BidBox AI helped BFSI brands achieve high conversions and customer engagement through targeted DSP strategies and programmatic advertising.' />
                <meta name='keywords' content='BFSI advertising, financial marketing, banking ad campaigns, insurance digital ads, fintech programmatic advertising, customer acquisition, lead generation for finance, digital banking promotions, ROI-driven marketing, personalized finance ads, wealth management advertising, BFSI audience targeting, secure online transactions, high-ROI finance campaigns, CPA for financial services.' />               
            </Helmet>
    <div className="w-full">
      <div className="w-full h-[36vh] bgColor text-white flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl font-bold text-center mt-14">
          Banking and Financial Services
        </h1>
      </div>

      <div className="w-full overflow-hidden">
        <div className="w-full bg-white flex flex-col items-center">
          <div className="w-[95%] my-10">
            <h1 className="text-3xl md:text-5xl font-bold text-center">
              Explore Diverse Case Studies
            </h1>
            <p className="text-center py-5 text-xl font-medium">
              Dive into success stories spanning various industries and
              verticals to see how we deliver value.
            </p>

            <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5 justify-center md:justify-between">
              <CaseStudyCard
                image="/Yes Securities.png"
                heading="Redefining Financial Success in the Digital Age"
                impression="Partnership Since : 2022 "
                vtr="New Customers Acquired : 24500+"
                content="In an ambitious move to reshape its brand identity and fortify its position in the competitive financial sector, Yes Securities embarked on a transformative journey. Our strategic collaboration was driven by a clear objective: to enhance brand recognition, attract new users, and establish Yes Securities as a trusted name in trading and investments."
                content1="With a deep understanding of the evolving financial landscape, we executed a meticulously crafted campaign that seamlessly blended tradition with innovation. The focus was on targeting a broad yet highly relevant audience—ranging from seasoned traders to first-time investors—ensuring that Yes Securities became the go-to platform for financial growth. To achieve this, we leveraged premium regional inventories and fintech websites, allowing for precise audience outreach and maximizing engagement."
                content2='Branding played a pivotal role in this strategy, with a strong emphasis on creating a distinct identity that resonated with both trust and modernity. Through a combination of high-impact digital placements and compelling messaging, we reinforced Yes Securities’ credibility while positioning it as a leader in the financial domain.'
                content3='The results spoke for themselves. The campaign not only drove an influx of new users but also significantly elevated brand perception. Yes Securities successfully emerged as a formidable force in the financial sector, breaking through the clutter and solidifying its reputation as a reliable, forward-thinking partner for investors. By bridging the gap between innovation and financial expertise, we redefined what success looks like in today’s digital-first investment landscape.'

             />
              {/* <CaseStudyCard
                                image="/angel.jpg"
                                heading="Driving Growth at Angel One"
                                impression='Account Open : 43500+'
                                vtr='VTR 97% +'
                                content='We have played a pivotal role in Angel One remarkable ascent to the pinnacle of trading platforms. Collaboratively, we've successfully executed over 10 campaigns, strategically targeting specific audiences and optimizing ad placements and inventories.'
                                
                                content1='This concerted effort has not only elevated Angel One' market presence but has also fueled significant growth in new account openings. Our enduring partnership with the brand stands as a testament to our ability to not just generate traffic, but to convert it into loyal consumers, showcasing our commitment to driving both visibility and consumer engagement.'
                                // content2="The success of this branding initiative stands as a pivotal achievement in the brand's journey toward industry prominence and the cultivation of enduring client confidence."
                            /> */}
              <CaseStudyCard
                image="/angel.jpg"
                heading="Powering Growth, One Smart Investment at a Time"
                impression="Campaigns Launched : 15+"
                vtr="New Customers Acquired : 43500+"
                content="Angel One’s journey to becoming a leading trading platform has been nothing short of extraordinary, and we have been at the forefront of this transformation. Through a strategic and data-driven approach, we have played a crucial role in amplifying the brand’s market presence, ensuring it not only attracts but also retains a growing community of investors."
                content1="Our collaboration has seen the successful execution of over ten targeted campaigns, each designed to engage specific audience segments and drive meaningful conversions. By optimizing ad placements and leveraging premium inventories, we ensured Angel One reached the right users at the right time, maximizing both visibility and impact. This precision-driven approach significantly boosted brand recognition and, more importantly, contributed to a substantial increase in new account openings."
                content2="However, our success with Angel One extends beyond generating traffic—it is about fostering long-term consumer trust and engagement. Every campaign was crafted to resonate with users, creating a seamless journey from initial awareness to active investment. The result? A strong, loyal user base that continues to grow, reinforcing Angel One’s position as a trusted leader in the trading ecosystem."
                content3="Our enduring partnership with Angel One is a testament to the power of strategic marketing in the financial sector. By combining innovation with targeted execution, we have not only propelled the brand to new heights but also redefined how modern traders engage with digital investment platforms."

             />
              <CaseStudyCard
                image="/assets/maybank29.png"
                heading="A Resounding Success in Indonesia’s Financial Landscape"
                impression="Impressions : 3760000+"
                vtr="CTR: 3.2%"
                content="Maybank’s journey to establishing a strong foothold in the Indonesian market was fueled by a bold and innovative branding strategy. Tasked with elevating brand awareness and creating a lasting impact, we embarked on a mission to position Maybank as a trusted financial institution among Indonesia’s diverse and dynamic audience."
                content1="Our approach seamlessly integrated both traditional and digital marketing channels, ensuring widespread reach while maintaining a targeted and strategic execution. By leveraging high-visibility placements, compelling messaging, and a deep understanding of local consumer behavior, we successfully connected Maybank with its key audience segments. This multifaceted strategy not only reinforced brand credibility but also fostered trust and recognition within the competitive financial sector."
                 content2='The results were nothing short of remarkable. Maybank quickly gained traction, evolving into a household name synonymous with reliability and innovation. The campaign not only solidified its presence but also strengthened consumer confidence in its financial offerings, further establishing Maybank as a leading player in Indonesia’s banking landscape.'
                 content3='This case study stands as a testament to our expertise in crafting impactful branding strategies that drive real, measurable success. By aligning innovation with market insights, we transformed Maybank’s visibility, ensuring its message resonated across Indonesia. The campaign’s success marks a significant milestone in Maybank’s journey, reinforcing its position as a trusted and influential financial institution in the region.'

             />
                 <CaseStudyCard
                image="/hdfc.webp"
                heading="Elevating Brand Presence in the Financial World"
                impression="Partnership Since : 2021"
                vtr="New Customers Acquired : 20000"
                content="Our collaboration with HDFC Sky was driven by a clear objective: to redefine its brand identity and establish a commanding presence in the competitive financial sector. With a strategic and multifaceted approach, we set out to enhance brand visibility, attract new customers, and reinforce HDFC Sky’s reputation as a trusted financial partner."
                content1="By leveraging a well-crafted mix of digital and traditional channels, we ensured maximum reach and engagement across diverse audience segments. Through targeted campaigns, premium ad placements, and compelling brand storytelling, we positioned HDFC Sky as an innovative and reliable financial institution. This seamless blend of strategy and execution not only strengthened brand recall but also instilled confidence among potential customers."
                content2='The impact was substantial—HDFC Sky saw a significant uptick in customer acquisition, solidifying its foothold in the financial market. More than just increasing visibility, the campaign helped foster meaningful connections with users, turning prospects into loyal customers.'
                content3='This success story is a testament to our expertise in driving brand transformation within the financial sector. By aligning strategic outreach with consumer insights, we helped HDFC Sky not just grow, but thrive, establishing it as a formidable and trusted force in modern banking.'

              />
                 <CaseStudyCard
                image="/jago.png"
                heading="Driving Growth with Performance-Driven Marketing"
                impression=" Impressions : 3000000+"
                vtr="CTR : 2.2%"
                content="In our latest campaign for Bank Jago, we executed a high-impact, performance-driven marketing strategy designed to optimize digital channels, attract high-value customers, and enhance personalization. By leveraging data-driven insights, we crafted tailored content that resonated with diverse audience segments, ensuring maximum engagement and conversion."
                content1="A key component of our approach was precision targeting—identifying and reaching the right users at the right time through strategic ad placements and customized messaging. This meticulous execution led to a remarkable 30% increase in account sign-ups, along with a significant surge in engagement across both mobile and web platforms."
                 content2='Additionally, by integrating Campaign Management and Measurement (CMM) strategies, we ensured that every aspect of the campaign delivered measurable results. This approach not only amplified brand visibility but also strengthened customer trust, reinforcing Bank Jago’s position as a leading financial institution.'
                 content3='The campaign’s success underscores our expertise in driving user acquisition and long-term loyalty in the competitive banking sector. By seamlessly blending technology, data, and creative execution, we transformed Bank Jago’s digital presence, paving the way for sustained growth and deeper customer connections.'

              />
                 <CaseStudyCard
                image="/fyersbfsi.jpg"
                heading="Revolutionizing Stock Trading with Performance-Optimized Marketing"
                impression="Customers Acquired : 15000+"
                vtr=""
                content=" Fyers, a leading Indian stockbroking platform, has redefined the trading experience with commission-free investing and advanced analytical tools. To amplify its reach and accelerate user growth, we executed a performance-driven marketing strategy designed to optimize digital channels, engage key customer segments, and deliver highly personalized messaging."
                content1="By leveraging data-driven insights and precision targeting, we crafted compelling campaigns that resonated with both seasoned traders and new investors. Our approach ensured maximum visibility and engagement, driving significant traction across multiple digital platforms. Through the integration of Campaign Management and Measurement (CMM) strategies, we streamlined campaign performance, enhancing efficiency while maintaining a strong return on investment."
                content2='The impact was substantial—Fyers saw a surge in new account sign-ups, increased customer engagement, and strengthened brand credibility within the competitive stock trading ecosystem. Beyond just acquiring users, the campaign fostered deeper connections, reinforcing Fyers’ position as a trusted and innovative trading partner.'
                content3='This success story stands as a testament to our ability to drive impactful marketing outcomes in the financial sector. By combining strategic execution with data-driven optimization, we empowered Fyers to scale its growth and establish itself as a go-to platform for modern investors.'

             />
                    <CaseStudyCard
                image="/fintech.png"
                heading="Driving Innovation and Growth with Performance Marketing"
                impression="Impressions : 3760000+"
                vtr="CTR : 3.2%"
                content="In the ever-evolving world of financial technology, delivering seamless and efficient financial services is key to success. To accelerate customer acquisition and engagement for a leading fintech brand, we implemented a results-driven performance marketing strategy that leveraged data-driven insights, optimized digital channels, and refined messaging for high-value customer segments. "
                content1="By tailoring campaigns to resonate with specific user needs, we ensured maximum impact, driving a remarkable 25% increase in account sign-ups. Our targeted approach not only expanded the customer base but also strengthened user engagement across digital platforms, reinforcing the brand’s credibility in the competitive fintech space."
                 content2='Integrating Campaign Management and Measurement (CMM) techniques further amplified campaign performance, allowing for real-time optimizations that maximized efficiency and impact. This strategic execution solidified the fintech company’s position as a trusted financial partner while fostering long-term customer loyalty and retention.'
                 content3='This case study exemplifies our expertise in blending technology with marketing to drive measurable success. By aligning strategy with innovation, we propelled the brand’s growth, ensuring it remained at the forefront of the digital financial revolution.'

              />
               <CaseStudyCard
                image="/bob.jpg"
                heading="Strengthening Engagement with Targeted Marketing"
                impression=" Partnership Since : 2021"
                vtr="New Customers Acquired : 20000+"
                content="In a strategic effort to enhance customer acquisition and engagement, we implemented a targeted marketing approach for Bank of Baroda, optimizing digital platforms to reach high-potential customer segments with precision. By tailoring communications to individual financial needs, we ensured that each interaction was relevant, compelling, and action-driven."
                content1="Leveraging Campaign Management and Measurement (CMM) techniques, we significantly boosted campaign performance, reinforcing Bank of Baroda’s position as a trusted financial institution. This data-driven strategy not only maximized reach but also fostered deeper connections with customers, resulting in substantial gains in both new user acquisition and long-term loyalty."
                 content2='A key element of our approach was the execution of personalized email campaigns designed to engage both potential and existing customers. By analyzing user interests—such as credit cards, loans, or savings products—we curated highly relevant product offerings. Customers received tailored incentives, including lower interest rates, exclusive benefits, and promotional bonuses, enhancing engagement and increasing conversion rates.'
                 content3='The results were clear: improved customer retention, heightened brand trust, and a significant uplift in product adoption. This case study highlights the power of targeted marketing in the financial sector, demonstrating how a strategic, personalized approach can drive measurable growth while fostering lasting customer relationships.'

              />
                 
                  <CaseStudyCard
                image="/lemon.jpeg"
                heading="A Data-Driven Approach to Branding Success"
                impression="Target Customers Acquired : 10000+"
                vtr=""
                content="To establish a strong brand presence for Lemonn, we executed a multi-channel marketing strategy that seamlessly integrated Google Ads, social media, programmatic advertising, and email campaigns. This unified approach ensured cohesive messaging across all touchpoints while enabling real-time performance tracking and optimization. By leveraging data insights, we refined our targeting and maximized engagement with the right audience."
                content1="Content played a crucial role in our strategy. We developed a series of blog posts and how-to guides optimized for high-intent keywords such as “how to invest in stocks” and “beginner’s guide to stock trading.” These resources not only boosted organic traffic but also positioned Lemonn as an authoritative voice in financial education, making complex investment concepts more accessible to users."
                 content2='Through in-depth analysis of previous campaigns, we identified the most effective channels and audience segments. Utilizing Campaign Management and Measurement (CMM) strategies, we dynamically allocated budgets to high-performing platforms, optimizing reach while minimizing cost-per-acquisition (CPA). This data-driven approach allowed us to continuously refine our efforts, ensuring that every marketing dollar delivered maximum impact.'
                 content3='The campaign successfully elevated Lemonn’s brand visibility, driving increased engagement and customer acquisition. By blending technology, content, and precision targeting, we positioned Lemonn as a trusted resource for investors, reinforcing its credibility in the competitive fintech landscape.'

             />
                  <CaseStudyCard
                image="/fatak.png"
                heading="Maximizing Lead Quality with Data-Driven Optimization"
                impression="New Customers Acquired : 25000+"
                vtr=""
                content="For Fatakpay, we executed a high-impact lead generation strategy by leveraging Cross-Media Management (CMM) to optimize performance across multiple platforms, including Google Ads, LinkedIn, and programmatic advertising. This multi-channel approach ensured a broad yet highly targeted reach, enabling us to connect with the most relevant audience segments."
                content1="By implementing real-time data tracking, we dynamically adjusted campaigns to focus on platforms generating the highest-quality leads. This agile optimization allowed us to allocate resources efficiently, ensuring that marketing efforts were directed toward channels with the best engagement and conversion potential."
                 content2='A key aspect of our strategy was the use of Cost Per Qualified Lead (CPQL) metrics to measure campaign effectiveness. Rather than focusing solely on lead volume, we prioritized lead quality, analyzing user intent and engagement levels to refine our targeting. This precision-driven approach provided valuable insights into which platforms and strategies delivered the strongest ROI.'
                 content3='As a result, Fatakpay experienced a significant improvement in both lead quality and conversion rates. By combining advanced analytics with adaptive marketing techniques, we not only enhanced campaign efficiency but also reinforced Fatakpay’s position as a leading financial service provider in a highly competitive market.'

              />
                  {/* <CaseStudyCard
                image="/ola.jpg"
                heading="Ola Electric"
                impression="New Customers Acquired : 15000+"
                vtr="CTR : 1.1%"
                content="By integrating a CMM-driven digital advertising strategy, we successfully positioned the EV manufacturing company as a rising leader in the electric vehicle industry."
                content1="Our tailored approach, focused on audience segmentation, multi-channel advertising, and targeted content, not only boosted brand visibility but also delivered tangible business results through high-quality leads and significant engagement."

                content2="The success of this campaign demonstrates the power of a performance-focused, multi-channel approach in establishing brand authority and generating meaningful results in a competitive market like electric vehicles."

              /> */}
                  {/* <CaseStudyCard
                image="/clove.jpg"
                heading="Clove Dental"
                impression="New Customers Acquired : 25000+"
                vtr=""
                content="By implementing a different strategy, we successfully elevated the dental care provider’s digital presence, generated significant patient leads, and increased appointment bookings. The clinic’s ability to stand out in a competitive market was reinforced through targeted digital campaigns, strategic content, and personalised outreach, resulting in both new customer acquisition and improved patient retention."
                content1="This case study showcases the effectiveness of multi-channel marketing in driving measurable success for healthcare providers."
              /> */}
                  <CaseStudyCard
                image="/paytem.webp"
                heading="Driving Ticket Sales and Brand Growth with Precision Marketing"
                impression="Target Audiences acquired: 12000+"
                vtr="Audience Engagement: 10000+ "
                content="To amplify Paytm Insider’s reach and boost ticket sales across diverse event categories, we implemented a data-driven, multi-channel marketing strategy that maximized audience engagement and conversion. By leveraging targeted digital campaigns, personalized outreach, and strategic content, we successfully positioned the platform as a go-to destination for event enthusiasts."
                content1="Our approach focused on delivering highly relevant promotions to users based on their interests, past event interactions, and real-time demand trends. By optimizing ad placements across search, social media, and programmatic platforms, we ensured that Paytm Insider connected with the right audience at the right moment, driving ticket sales and repeat engagement."
                 content2='The campaign led to a significant surge in event bookings, increased brand visibility, and enhanced customer loyalty. Through continuous performance monitoring and dynamic budget allocation, we maximized ROI while reinforcing Paytm Insider’s competitive edge in the event industry.'
                 content3='This case study highlights the power of performance-driven marketing in optimizing ticket sales and building a strong digital footprint. By combining precision targeting with compelling content, we helped Paytm Insider solidify its position as a leading ticketing platform in an increasingly competitive landscape.'

              />
                   <CaseStudyCard
                image="/assets/kotakk.png"
                heading="Scaling Digital Banking with Programmatic Precision"
                impression="Installs: 200K+  "
               vtr=" Registrations: 160K+ "
                content="Kotak811, a fully digital savings account, redefines modern banking with a paperless, hassle-free experience, offering users zero balance flexibility and high-interest savings. The campaign aimed to drive widespread adoption and boost account openings by engaging the right audience segments through programmatic display advertising."
                content1="With a focus on credit card holders, working professionals, e-wallet users, finance enthusiasts, and money management seekers, the campaign strategically targeted Gen Z and digital-first consumers—a demographic highly inclined towards convenient and innovative banking solutions. Premium programmatic placements ensured seamless integration into high-visibility platforms, maximizing exposure and engagement."
                content2='The impact was significant, generating 200K+ app installs, translating into 160K+ registrations within just two months. More importantly, 1,500+ savings accounts were opened, solidifying Kotak811’s position as a go-to digital banking solution for the new-age consumer. Through Bidbox.ai’s advance precision in programmatic display advertising, the campaign efficiently optimized conversions while reinforcing brand credibility in the competitive fintech landscape.'
             content3='By leveraging precise audience targeting and premium inventory, the campaign successfully connected Kotak811 with high-intent users, proving that strategic digital marketing can be a powerful catalyst for driving fintech adoption.'
             /> 
                   <CaseStudyCard
                image="/assets/axis2.png"
                heading="Driving Digital Banking Growth for Axis Bank"
                impression="Installs: 100K+ "
                vtr="Registrations: 75k+ "
                content="Axis Bank, a leading financial institution, introduced its Digital Savings Account to offer users a seamless, paperless banking experience. To accelerate digital adoption, a strategic programmatic advertising campaign was executed, focusing on increasing app installs, user registrations, and KYC completions."
                content1="By leveraging Bidbox.ai, an AI-powered Demand-Side Platform (DSP), the campaign utilized precise audience segmentation and real-time bidding strategies to target high-intent users. The approach included targeting working professionals, e-wallet users, finance enthusiasts, and students looking for smart money management solutions. Advanced machine learning algorithms ensured optimal media spend efficiency, placing ads across premium inventories and high-engagement digital touchpoints."
                content2='Over a two-month period, the campaign drove significant traction, resulting in over 100,000 app installs. Registrations surpassed 75,000, with a substantial number of users completing their KYC process, marking a strong conversion rate. The programmatic strategy ensured that the Digital Savings Account reached the right audience at the right time, maximizing visibility and engagement.'
             content3='With a data-driven execution model, the campaign not only increased Axis Bank’s digital user base but also reinforced its positioning as a leader in digital banking. The partnership with Bidbox.ai enabled seamless optimization, real-time analytics, and performance-driven targeting, ensuring measurable success in scaling digital acquisitions.'
             /> 
                  <CaseStudyCard
                image="/assets/airtel3.png"
                heading="Scaling Digital Adoption for Airtel Payment Bank"
                impression="Registrations: 300K+"
                vtr="KYC Completions: 13K+"
                content="Airtel Payment Bank is revolutionizing financial accessibility by bridging the gap between traditional banking and digital-first solutions. To accelerate user acquisition and engagement, a strategic programmatic advertising campaign was launched, focusing on app installs, registrations, KYC verifications, and savings account openings."
                content1="With the power of Bidbox.ai, an AI-powered Demand-Side Platform (DSP), the campaign utilized machine learning-driven audience segmentation and real-time bidding to target high-intent users. The focus was on credit card holders, working professionals, mobile users, and first-time banking users, ensuring optimal engagement with the most relevant audience segments. Through programmatic display advertising, ads were seamlessly placed across premium digital touchpoints, driving awareness and conversions."
                 content2='Over the course of three months, the campaign successfully drove over 300,000 registrations, reinforcing Airtel Payment Bank’s position as a trusted digital banking solution. With more than 13,000 users completing their KYC process and 3,000+ new savings accounts opened, the campaign demonstrated strong performance in digital onboarding. Bidbox.ai’s advanced optimization capabilities ensured efficient media spend, precise targeting, and real-time performance tracking, making it a key driver in achieving these results.'
              content3='By combining data-driven insights with cutting-edge ad technology, the campaign not only expanded Airtel Payment Bank’s digital user base but also strengthened its brand presence in the evolving fintech landscape.'
              
              /> 
                  <CaseStudyCard
                image="/assets/fi4.png"
                heading="Driving Digital Banking Adoption for Fi Money"
                impression="Account Opens: 25K+  "
                vtr="Installs: 2.5L+"
                content="Fi Money, a next-gen financial app with an integrated savings account, is designed to help users track, grow, and manage their money efficiently. To accelerate its adoption and increase account openings, a targeted programmatic advertising campaign was deployed, leveraging data-driven strategies and precision targeting."
                content1="Powered by Bidbox.ai, an AI-driven Demand-Side Platform (DSP), the campaign utilized machine learning algorithms and real-time bidding to identify and engage the right audience segments. The focus was on credit card holders, working professionals, e-wallet users, finance enthusiasts, and investment seekers—ensuring that the campaign resonated with users actively looking for smarter financial solutions. Through programmatic display advertising, high-impact ad placements were secured across premium digital platforms, maximizing visibility and engagement."
                 content2='The campaign delivered impressive results, driving over 2.5 lakh app installs and securing more than 25,000 new account openings. A significant success metric was the 17% conversion rate from account openings to funded accounts, showcasing the effectiveness of precision targeting in acquiring high-intent users. Bidbox.ai’s advanced optimization tools ensured that media spend was efficiently allocated, continuously refining audience targeting to improve campaign performance.'
              content3='By leveraging cutting-edge ad technology and real-time analytics, the campaign not only strengthened Fi Money’s brand presence but also played a pivotal role in expanding its digital banking user base.'
              
              /> 
   <CaseStudyCard
                image="/assets/ocbc5.png"
                heading="Driving High-Quality Acquisitions for OCBC Bank"
                impression="Acquisition: 1000+  "
                // vtr="Installs: 2.5L+"
                content="OCBC Bank, a leading financial institution, sought to expand its digital banking customer base in Indonesia by acquiring high-value users through a performance-driven campaign. With a focus on young professionals, entrepreneurs, business owners, and high-net-worth individuals, the campaign aimed to drive meaningful conversions and increase account acquisitions."
                content1="Powered by Bidbox.ai, an AI-driven Demand-Side Platform (DSP), the campaign utilized a CPA-based (Cost-Per-Acquisition) strategy, ensuring that every marketing dollar was optimized for direct, measurable results. Advanced machine learning and real-time bidding enabled precise audience targeting, focusing on salaried employees, self-employed professionals, and users actively seeking digital banking solutions. The campaign was strategically executed over a three-month period, leveraging high-impact programmatic display ads across premium digital platforms to maximize engagement."
                 content2='The results showcased the strength of data-driven advertising in financial services. Over 1,000 new acquisitions were recorded, demonstrating the efficiency of the CPA-driven approach. Bidbox.ai’s optimization tools continuously refined audience segments, ensuring that the campaign reached users most likely to convert. The combination of compelling ad creatives and intelligent bidding strategies contributed to strong engagement, reinforcing OCBC Bank’s position as a preferred digital banking solution in Indonesia.'
              content3='Through precision targeting and AI-powered media execution, the campaign not only drove new customer acquisitions but also enhanced OCBC Bank’s visibility among high-intent financial service seekers, setting the stage for continued growth in the digital banking sector.'
              
              /> 
                 <CaseStudyCard
                image="/assets/allo6.png"
                heading="Driving Digital Banking Adoption for AlloBank"
                impression="Acquisition: 2000+  "
                // vtr="Installs: 2.5L+"
                content="AlloBank, a leading digital-first banking solution in Indonesia, aimed to expand its user base by acquiring high-quality customers through a performance-driven campaign. With a focus on digital-savvy individuals, fintech adopters, and mobile banking users, the campaign sought to attract a diverse audience, including young professionals, entrepreneurs, freelancers, salaried employees, and credit seekers."
                content1="Powered by Bidbox.ai, an advanced AI-driven Demand-Side Platform (DSP), the campaign leveraged a CPA (Cost-Per-Acquisition) model to ensure marketing efficiency and maximize return on investment. Through intelligent programmatic display advertising, it strategically placed ads in front of high-intent users, optimizing engagement and conversions. With real-time bidding and machine learning algorithms, the campaign continuously refined audience targeting, ensuring that only the most relevant users were reached."
                 content2='Over the two-month campaign period, AlloBank successfully acquired 2,000 new customers, demonstrating the power of precision targeting and programmatic efficiency. The combination of compelling creatives and AI-powered bidding strategies enabled seamless lead acquisition, ensuring that the campaign not only delivered strong results but also strengthened brand visibility in Indonesia’s competitive digital banking space.'
              content3='By harnessing Bidbox.ai’s cutting-edge DSP technology, the campaign effectively connected AlloBank with users actively seeking seamless and reliable digital banking solutions. The success of the initiative reinforced the importance of AI-driven media buying in driving high-quality acquisitions, positioning AlloBank as a go-to choice for modern banking consumers.'
              
              /> 
                <CaseStudyCard
                image="/assets/dbs7.png"
                heading="Maximizing High-Intent Engagement for DBS"
                impression="Impressions: 7,083,805  "
                vtr="Clicks: 161,720 "
                content="DBS, a leading financial institution, sought to enhance its digital presence and drive high-value customer engagement through a precision-targeted campaign in Indonesia. The objective was to attract individuals actively seeking reliable financial solutions, including tech-savvy professionals, young adults exploring premium banking products, and those looking for financial growth opportunities."
                content1="Leveraging Bidbox.ai, an AI-powered Demand-Side Platform (DSP), the campaign was executed through a Cost-Per-Session (CPS) model to ensure that only quality interactions contributed to the results. Through advanced audience segmentation and programmatic display advertising, the campaign strategically placed DBS’s offerings in front of high-intent users across premium digital touchpoints. The use of machine learning algorithms optimized bidding strategies in real time, ensuring maximum reach and engagement efficiency."
                 content2='Over the course of 45 days, the campaign delivered over 7 million impressions, generating 161,720 clicks with a strong engagement rate. By intelligently aligning creatives with audience behavior and leveraging AI-driven targeting, the initiative effectively drove traffic to DBS’s digital banking solutions while maintaining cost efficiency.'
              content3='With Bidbox.ai’s expertise in real-time data analytics and programmatic precision, the campaign not only strengthened brand visibility but also reinforced DBS’s positioning as a premier choice for individuals seeking seamless and future-ready financial solutions.'
              
              /> 
            </div>
          </div>

          <div className="w-[95%] mb-20 h-[200px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden">
            <div className="p-5 md:p-10 z-10 flex justify-between">
              <div className="flex flex-col justify-between">
                <div>
                <h1 className="text-white font-bold text-xl md:text-5xl">
                 Questions or ready to get started?
              </h1>
              <h2 className="text-white  text-xl md:text-2xl">
                Reach out and let's supercharge your campaigns together!
              </h2>
                </div>
                <div>
              <a href="/contact">
                <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                  Get Started
                </button>
              </a>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Bfsi;
