import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

const faqs = [
  {
    question: "What makes Bidbox different from other DSP platforms?",
    answer:
      "Our AI-driven optimization, cross-channel execution, and real-time analytics set us apart.",
  },
  {
    question: "Can Bidbox help small businesses?",
    answer:
      "Absolutely! Our platform is designed for brands of all sizes to maximize ad performance.",
  },
  {
    question: "How do I get started with Bidbox?",
    answer:
      "Simply [Book a Demo] or contact our team for a consultation.",
  },
  {
    question: "Can I try your service for free?",
    answer:
      "Yes, we offer a free trial for new users. Sign up to start your trial and explore the features of our platform.",
  },
  {
    question: "How can I contact customer support?",
    answer:
      "You can reach our customer support team through email, live chat, or by calling our support hotline.",
  },
  {
    question: "Is your service secure?",
    answer:
      "Yes, we use state-of-the-art encryption and security protocols to ensure that your data remains safe and secure at all times.",
  },
];

export default function FAQSection() {
  const [openIndex, setOpenIndex] = useState(0); // Ensure the first question is open by default
  const [showAll, setShowAll] = useState(false); // Track if all questions are visible

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-white text-gray-900 py-12 px-6 max-w-4xl mx-auto text-center">
      <h5 className="text-sm text-blue-600 font-semibold uppercase">Frequently Asked Questions</h5>
      <h2 className="text-3xl lg:text-5xl font-bold text-gray-900 mt-2">Answers & Questions</h2>
      <p className="text-gray-600 mt-3">
        Clarify any uncertainties you might have, ensuring a smooth and hassle-free experience for everyone.
      </p>
      <div className="mt-8 space-y-4">
        {faqs.slice(0, showAll ? faqs.length : 4).map((faq, index) => (
          <div key={index} className="bg-gray-100 rounded-lg shadow-md overflow-hidden">
            <button
              onClick={() => toggleFAQ(index)}
              className="flex justify-between items-center w-full p-4 text-left font-semibold text-gray-900"
            >
              {faq.question}
              {openIndex === index ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
            </button>
            {openIndex === index && (
              <div className="p-4 text-gray-700 border-t border-gray-300">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
      <button
        onClick={() => setShowAll(!showAll)}
        className="mt-4 text-blue-600 font-semibold hover:underline"
      >
        {showAll ? "View Less" : "View All"}
      </button>
    </div>
  );
}



// import { useState } from "react";
// import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

// const faqs = [
//   {
//     question: "What is the primary function of your service?",
//     answer:
//       "Our service primarily aims to provide users with seamless digital solutions tailored to their needs, helping them enhance productivity and make the most of their time.",
//   },
//   {
//     question: "How can I get started with your service?",
//     answer:
//       "You can get started by signing up on our platform, selecting the services you need, and following the guided onboarding process.",
//   },
//   {
//     question: "Are there any costs associated with using your service?",
//     answer:
//       "Yes, we offer various pricing plans based on the services you choose. Contact our sales team for detailed pricing information.",
//   },
// ];

// export default function FAQSection() {
//   const [openIndex, setOpenIndex] = useState(null);

//   const toggleFAQ = (index) => {
//     setOpenIndex(openIndex === index ? null : index);
//   };

//   return (
//     <div className="bg-white text-gray-900 py-12 px-6 max-w-4xl mx-auto text-center">
//     <h5 className="text-sm text-blue-600 font-semibold uppercase">Frequently Asked Questions</h5>
//     <h2 className="text-3xl font-bold text-gray-900 mt-2">Answers & Questions</h2>
//     <p className="text-gray-600 mt-3">
//       Clarify any uncertainties you might have, ensuring a smooth and hassle-free experience for everyone.
//     </p>
//     <div className="mt-8 space-y-4">
//       {faqs.map((faq, index) => (
//         <div
//           key={index}
//           className="bg-gray-100 rounded-lg shadow-md overflow-hidden"
//         >
//           <button
//             onClick={() => toggleFAQ(index)}
//             className="flex justify-between items-center w-full p-4 text-left font-semibold text-gray-900"
//           >
//             {faq.question}
//             {openIndex === index ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
//           </button>
//           {openIndex === index && (
//             <div className="p-4 text-gray-700 border-t border-gray-300">{faq.answer}</div>
//           )}
//         </div>
//       ))}
//     </div>
//   </div>
//   );
// }







