import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ChevronUp } from "lucide-react"; // Icon for up arrow

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <motion.button
      className="fixed bottom-6 right-6 flex items-center justify-center w-14 h-14 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 shadow-lg
                 text-white hover:scale-110 transition-all duration-300 ease-in-out
                 before:absolute before:inset-0 before:bg-white before:opacity-0 before:rounded-full before:blur-md
                 before:transition-opacity before:duration-500 before:hover:opacity-20"
      onClick={scrollToTop}
      initial={{ opacity: 0, y: 50, scale: 0.8 }}
      animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 50, scale: isVisible ? 1 : 0.8 }}
      transition={{ duration: 0.4, ease: "easeOut" }}
      whileHover={{ rotate: 360 }}
      whileTap={{ scale: 0.9 }}
      aria-label="Scroll to Top"
    >
      <ChevronUp className="w-7 h-7" />
    </motion.button>
  );
};

export default ScrollToTop;
