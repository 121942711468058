import React from 'react'
import Banner from '../Banner'
import CaseStudyCard from './CaseStudyCard'
import { Helmet } from 'react-helmet'

const Education = () => {
    return (
        <>
        <Helmet>
        <title>EdTech and Education Marketing – BidBox AI Case Study</title>
        <meta name='description' content='Explore how education brands, universities, and EdTech platforms scaled student enrollment and course sign-ups through BidBox AI’s targeted digital advertising.' />
        <meta name='keywords' content='Education marketing, EdTech advertising, student enrollment campaigns, online learning promotions, higher education digital ads, course lead generation, programmatic ads for education, university branding, online degree marketing, skill development advertising, K-12 digital campaigns, AI-powered learning platforms, career-based targeting, paid search for education, student outreach ads.' />               
    </Helmet>
        <div className='w-full'>
            <div className='w-full h-[36vh] bgColor text-white flex justify-center items-center'>
                <h1 className='text-3xl md:text-6xl font-bold  text-center mt-14'>Education</h1>
            </div>

            <div className='w-full overflow-hidden'>

                <div className='w-full bg-white flex flex-col items-center'>
                    <div className='w-[95%] my-10'>
                        <h1 className='text-3xl md:text-5xl font-bold text-center'>Explore Diverse Case Studies</h1>
                        <p className='text-center py-5 text-xl font-medium'>Dive into success stories spanning various industries and verticals to see how we deliver value.</p>

                        <div className='my-10 flex flex-wrap md:flex-nowrap gap-10 md:gap-5 justify-center md:justify-between'>
                            <CaseStudyCard
                                image="/assets/bits.png"
                                heading="Enhancing Student Enrollment with Personalized Engagement"
                                impression='Website Visits - 200k+'
                                vtr='Walk Ins -  86%↑ '
                                content="To streamline the student enrollment process and improve application completion rates, we implemented a targeted email marketing strategy for BITS Pilani. By delivering personalized communication at every stage of the enrollment journey, we successfully guided prospective students from inquiry to acceptance."
                                content1="Our approach segmented students based on their application status—whether they were exploring courses, in the process of applying, or had already been accepted. Tailored email campaigns provided crucial information, such as application deadlines, scholarship opportunities, and detailed course insights, ensuring that students remained informed and engaged."
                                content2="For those who had started but not completed their applications, we deployed strategic retargeting emails. These reminders included incentives like application fee waivers and deadline extensions, effectively encouraging students to finalize their enrollment. By leveraging behavioral insights, we ensured that each message was relevant, timely, and action-driven."
                                content3="This campaign not only increased application completion rates but also strengthened BITS Pilani’s engagement with prospective students. By combining data-driven personalization with strategic outreach, we optimized the enrollment experience, reinforcing the university’s reputation as a top choice for higher education."

                            />
                            <CaseStudyCard
                                image="/assets/cu.jpg"
                                heading="Strengthening Brand Presence and Student Enrollment"
                                impression='Impressions - 5.2 Million+'
                                vtr='CTR - 0.85%'
                                content="To elevate Chandigarh University’s digital presence and drive student enrollment, we implemented a strategic marketing approach focused on targeted advertising, personalized content, and continuous engagement across digital channels. This comprehensive strategy ensured maximum visibility among prospective students while reinforcing the university’s reputation as a top-tier educational institution."
                                content1="By leveraging precision-targeted campaigns across search engines, social media, and programmatic platforms, we effectively reached students exploring higher education opportunities. Our personalized content strategy provided valuable insights on courses, scholarships, and campus life, fostering deeper engagement and trust with potential applicants."
                                 content2='Consistent digital outreach played a crucial role in nurturing student inquiries and guiding them through the enrollment process. Through retargeting efforts and customized communication, we successfully increased application submissions and improved student acquisition rates.'
                                 content3='The campaign’s success highlights the power of a well-rounded digital marketing strategy in higher education. By combining data-driven targeting with compelling content, we helped Chandigarh University enhance its brand authority, attract more students, and solidify its position as a leading academic institution.'

                            />
                            <CaseStudyCard
                                image="/assets/leverage.png"
                                heading="Driving High-Intent Student Engagement with Precision Marketing"
                                impression='Impressions - 3.52 Cr+'
                                vtr='Clicks - 195K+'
                                content="To maximize student acquisition for Leverage Education, we implemented a data-driven marketing strategy focused on search visibility and personalized retargeting. By strategically targeting high-intent keywords such as “top universities for business,” “best engineering programs,” and “postgraduate degree in medicine,” we ensured that prospective students actively searching for educational opportunities were directed to relevant admissions and course-specific pages."
                                content1="In addition to search optimization, we leveraged display advertising to retarget visitors who had shown interest in specific programs but had not yet converted. These personalized ads featured tailored messaging, highlighting relevant courses, application deadlines, and exclusive enrollment benefits to encourage students to take the next step in their journey."
                                 content2="This approach not only increased website traffic from qualified prospects but also improved conversion rates by keeping prospective students engaged through multiple touchpoints. By combining precise search strategies with personalized display advertising, we successfully strengthened Leverage Education’s digital presence, driving both inquiries and enrollments while reinforcing its reputation as a trusted platform for higher education opportunities."
                            />

                        </div>
                    </div>

                </div>

            </div>

            <Banner />
        </div>
        </>
    )
}

export default Education
