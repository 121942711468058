import React from 'react'
import { BsTwitter } from 'react-icons/bs'
import { AiFillInstagram } from 'react-icons/ai'
import { FaFacebookF, FaSnapchatGhost } from 'react-icons/fa'

const Footer = () => {
    return (
        
        
<footer class="bg-[#1A1D2B]">
    <div class="container mx-auto p-0 md:p-8 xl:px-0">
        <div class="mx-auto max-w-7xl px-6 pb-10 pt-16">
            <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <div class="space-y-4">
                    <div>
                        <a href="/">
                            <div class="flex items-center space-x-2 text-2xl font-medium">
                                <span>
                                    <img src="/b.jpeg" alt="AI Logo"
                                        width="64" height="64" class="w-10"/>
                                </span>
                                <span class="text-white">Bidbox.ai</span>
                            </div>

                        </a>
                    </div>
                    <div class="max-w-md pr-16 text-md text-gray-200">Enhance productivity and
                        efficiency with cutting-edge artificial intelligence solutions for your business operations.
                    </div>
                    <div class="flex space-x-2">
                        <a href="https://www.linkedin.com/company/106384335" target="_blank" class="text-gray-200 hover:text-gray-200">
                            <span class="sr-only">Linkedin</span><svg fill="currentColor" viewBox="0 0 24 24"
                                class="h-6 w-6" aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </a>
                        <a href="" target="_blank" class="text-gray-200 hover:text-gray-200">
                            <span class="sr-only">Twitter</span><svg fill="currentColor" viewBox="0 0 24 24"
                                class="h-6 w-6" aria-hidden="true">
                                <path
                                    d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84">
                                </path>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-md font-semibold leading-6 text-white">Campaign Wise</h3>
                            <ul role="list" class="mt-6 space-y-4">

                            <li>
                                    <a href="/brand-awareness"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Brand Awareness
                                    </a>
                                </li> 

                                <li>
                                    <a href="/video-storytelling-ads"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Video Storytelling Ads
                                    </a>
                                </li>
                                <li>
                                    <a href="/reengagement-retargeting"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Reengagement Retargeting
                                        
                                    </a>
                                </li>
                                <li>
                                    <a href="/premium-takeovers"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Premium Takeovers
                                       
                                    </a>
                                </li>
                              
                               
                              
                              
                            </ul>
                        </div>
                        <div class="mt-10 md:mt-0">
                            <h3 class="text-md font-semibold leading-6 text-white">Channel Wise </h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li>
                                    <a href="/ctv&ott"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">CTV & OTT
                                        
                                    </a>
                                </li>
                                <li>
                                    <a href="/display-native-ads"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Display & Native Ads
                                    </a>
                                </li>
                                <li>
                                    <a href="/push-ads"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Push Notification Ads
                                    </a>
                                </li>
                                <li>
                                    <a href="/inapp-mobile-Ads"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">In-App Mobile Ads
                                    </a>
                                </li>
                               
                               
                               
                            </ul>
                        </div>
                    </div>
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-md font-semibold leading-6 text-white">Targeting Option</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li>
                                    <a href="/geo-hyperlocal-targeting"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Geo Hyperlocal Targeting
                                    </a>
                                </li>
                                <li>
                                    <a href="/demographics-targeting"
                                     class="text-md leading-6 text-gray-300 hover:text-gray-50">Demographics Targeting
                                    </a>
                                </li>
                                <li>
                                    <a href="/interest-behavior-targeting"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Interest Behavior Targeting
                                    </a>
                                </li>
                                <li>
                                    <a href="/householdsync-targeting" 
                                    class="text-md leading-6 text-gray-300 hover:text-gray-50">Household Sync Targeting
                                    </a>
                                </li>
                                <li>
                                    <a href="/lookalike-custom-audiences"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Lookalike Custom Audience
                                      
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                        <div class="mt-10 md:mt-0">
                            <h3 class="text-md font-semibold leading-6 text-white">Company</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li>
                                    <a href="/about"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">About Us
                                    </a>
                                </li>
                              
                                <li>
                                    <a href="/contact"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a href="/privacy-policy"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="/terms-and-conditions"
                                        class="text-md leading-6 text-gray-300 hover:text-gray-50">Terms And Conditions
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-16 border-t border-gray-400/30 pt-8 sm:mt-20 lg:mt-24">
                <div class="text-md text-center text-white">
                Bidbox.ai © 2024, All rights reserved.
                    {/* <span class="text-gray-50"></span> by AI enthusiasts at
                    <a rel="noopener" href="/">AIOps.
                    </a> */}
                </div>
            </div>
        </div>
    </div>
</footer>
        // <div className='w-full bgColor flex flex-col items-center overflow-hidden'>
        //     <div className='w-[95%] flex flex-wrap justify-between my-10'>
        //         {/* <div className='py-5 w-[50%] md:w-auto'>
        //             <h1 className='text-center text-white text-lg font-semibold mb-4 underline'>Company</h1>
        //             <p className='text-center text-white'>Advertising</p>
        //             <p className='text-center text-white py-1'>Affiliate Marketing</p>
        //             <p className='text-center text-white'>Development</p>
        //         </div> */}
        //         <div className='py-5 w-[50%] md:w-auto'>
        //             <h1 className='text-center text-white text-lg font-semibold mb-4 underline'>Solutions</h1>
        //             <a href='/awareness'>
        //                 <p className='text-center text-white'>Awareness</p>
        //             </a>
        //             <a href='/re-targeting'>
        //                 <p className='text-center text-white py-1'>Re-Targeting</p>
        //             </a>
        //             {/* <p className='text-center text-white'>Legacy</p> */}
        //         </div>
        //         <div className='py-5 w-[50%] md:w-auto'>
        //             <h1 className='text-center text-white text-lg font-semibold mb-4 underline'>Channel</h1>
        //             <a href='/display'>
        //                 <p className='text-center text-white'>Display</p>
        //             </a>
        //             <a href='/in-app'>
        //                 <p className='text-center text-white '>In-App</p>
        //             </a>
        //             <a href='/ctv'>
        //                 <p className='text-center text-white'>CTV</p>
        //             </a>
        //             <a href='/video'>
        //                 <p className='text-center text-white'>Video</p>
        //             </a>
        //             <a href='/push-ads'>
        //                 <p className='text-center text-white'>Push-Ads</p>
        //             </a>
        //             <a href='/native-ads'>
        //                 <p className='text-center text-white'>Native-Ads</p>
        //             </a>
        //             <a href='/interstitial-ads'>
        //                 <p className='text-center text-white'>Interstitial-Ads</p>
        //             </a>
        //         </div>
        //         <div className='py-5 w-[50%] md:w-auto'>
        //             <h1 className='text-center text-white text-lg font-semibold mb-4 underline'>Case Studies</h1>
        //             <a href='/bfsi'>
        //                 <p className='text-center text-white'>BFSI</p>
        //             </a>
        //             <a href='/fmcg'>
        //                 <p className='text-center text-white '>FMCG</p>
        //             </a>
        //             <a href='/entertainment'>
        //                 <p className='text-center text-white'>Entertainment</p>
        //             </a>
        //             <a href='/automobile'>
        //                 <p className='text-center text-white'>Automobile</p>
        //             </a>
        //             <a href='/gaming'>
        //                 <p className='text-center text-white'>Fashion & Lifestyle</p>
        //             </a>
        //             <a href='/aviation'>
        //                 <p className='text-center text-white'>Electronics</p>
        //             </a>
        //         </div>
        //         <div className='py-5 w-[50%] md:w-auto'>
        //             <h1 className='text-center text-white text-lg font-semibold mb-4 underline'>Company</h1>
                    
        //             <a href='/about'>
        //                 <p className='text-center text-white'>About Us</p>
        //             </a>
        //             <a href='/contact'>
        //                 <p className='text-center text-white '>Contact Us</p>
        //             </a>
        //             <a href='/privacy-policy'>
        //                 <p className='text-center text-white py-1'>Privacy Policy</p>
        //             </a>
        //             <a href='/terms-and-conditions'>
        //                 <p className='text-center text-white '>Terms And Conditions</p>
        //             </a>
        //             {/* <p className='text-center text-white'>Benefits</p> */}
        //         </div>
        //     </div>

        //     <div className='w-[95%] mb-5'>
        //         <div className='flex gap-5 justify-center mb-4'>
        //             <div className='w-14 h-14 hover:scale-110 duration-100 flex justify-center items-center rounded-full bg-white shadow-lg'>
        //                 <FaFacebookF className='text-3xl textColor' />
        //             </div>
        //             <div className='w-14 h-14 hover:scale-110 duration-100 flex justify-center items-center rounded-full bg-white shadow-lg'>
        //                 <BsTwitter className='text-3xl textColor' />
        //             </div>
        //             <div className='w-14 h-14 hover:scale-110 duration-100 flex justify-center items-center rounded-full bg-white shadow-lg'>
        //                 <FaSnapchatGhost className='text-3xl textColor' />
        //             </div>
        //             <div className='w-14 h-14 hover:scale-110 duration-100 flex justify-center items-center rounded-full bg-white shadow-lg'>
        //                 <AiFillInstagram className='text-3xl textColor' />
        //             </div>
        //         </div>
        //         <p className='text-white text-center'>Bidbox.ai © 2024</p>
        //     </div>
        // </div>
    )
}

export default Footer
