import React, { useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { 
  ChevronLeft, 
  ChevronRight,
  ArrowRight,
  Globe,
  BarChart3,
  Users,
  Target,
  TrendingUp,
  Award,
  RefreshCcw,
  Repeat,
  MonitorPlay,
  Activity,
  Layers
} from 'lucide-react';
import { Link } from 'react-router-dom';

const ServiceRetargeting = () => {
  const { scrollY } = useScroll();
  const [activeTab, setActiveTab] = useState(0);

  const services = [
    {
      icon: <Repeat className="w-8 h-8" />,
      title: "Dynamic Retargeting",
      description: "Deliver tailored ads that resonate with individual user behavior, enhancing relevance and boosting engagement with each interaction.",
    //   stat: "Learn More",
      color: "from-blue-600 to-cyan-500"
      
    },
    {
      icon: <MonitorPlay className="w-8 h-8" />,
      title: " Cross-Device Engagement",
      description: "Maintain a consistent and impactful brand experience across various devices, ensuring your message is visible to users wherever they are.",
      
      //   stat: "Learn More",
      color: "from-purple-600 to-pink-500"
   
    },
    {
      icon: <Target className="w-8 h-8" />,
      title: "Lookback Window Optimization",
      description: "Optimize ad exposure by controlling post-interaction duration, delivering timely reminders while preventing over-saturation.",
    //   stat: "Learn More",
      color: "from-orange-500 to-red-500"
     
    },
    {
        icon: <Layers className="w-8 h-8" />,
        title: "Sequential Messaging",
      description: "Guide prospects seamlessly through the buying process with thoughtfully crafted, step-by-step messaging that deepens their connection with your brand.",
        // stat: "Learn More",
        color: "from-orange-500 to-red-500"
       
      },
      {
        icon: <Users  className="w-8 h-8" />,
        title: " Custom Audience Targeting",
        description: "Leverage granular audience segments such as website visits, ad interactions, or cart abandonment to deliver highly relevant content that drives conversions.",
        // stat: "Learn More",
        color: "from-purple-600 to-pink-500"

      },
      {
        icon: <RefreshCcw className="w-8 h-8" />,

        title: "Advanced Retargeting",
        description: "Engage users with ads that recall their previous interactions, reinforcing brand awareness and encouraging them to re-engage at the right moment.",
        // stat: "Learn More",
       
        // stat: "Learn More",
        color: "from-blue-600 to-cyan-500"
      
      },
      {
        icon: <Activity className="w-8 h-8" />,
        title: "Performance-Based Optimization",
        description: "Refine ad delivery by customizing the duration users are exposed to your ads post-interaction, ensuring timely and relevant reminders without over-saturation.",
        color: "from-blue-600 to-cyan-500"
     
      },
      {
        icon: <BarChart3 className="w-8 h-8" />,
        title: "Behavioral Intelligence Optimization",
        description: "Harness valuable behavioral data to create highly targeted retargeting campaigns, ensuring your message reaches the right audience at the ideal time.",
        // stat: "Learn More",
        color: "from-purple-600 to-pink-500"

      },
      {
        icon: <TrendingUp className="w-8 h-8" />,
        title: "Contextual & Intent-Based Retargeting",
        description: "Enhance retargeting efforts by factoring in contextual and intent signals, delivering ads that resonate deeply with users' current interests and behaviors.",
        // stat: "Learn More",
        color: "from-orange-500 to-red-500"
      
      },
      
      
      
  ];

  const features = [
    { title: "Dynamic Optimization", value: "93%", subtitle: "Campaign Success Rate" },
    { title: "Global Coverage", value: "200+", subtitle: "Countries Reached" },
    { title: "Client Satisfaction", value: "98%", subtitle: "Retention Rate" },
    { title: "ROI Improvement", value: "180%", subtitle: "Average Increase" }
  ];

  const statsY = useTransform(scrollY, [0, 300], [100, 0]);
  const statsOpacity = useTransform(scrollY, [0, 300], [0, 1]);

  return (
    <div className="w-full">
      {/* Services Section */}
      <section className="py-20 px-6 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
            Bring Your Brand Story to Life

            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">

            Leverage AI-powered retargeting and re-engagement strategies to reconnect with high-intent users, ensuring maximum brand recall and conversion efficiency.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {services.map((service, index) => (
                <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }} // Hover effect
                className="relative group"
              >
                <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:bg-gray-100">
                  <div className={`absolute inset-x-0 h-2 top-0 rounded-t-2xl bg-gradient-to-r ${service.color}`} />
                  <div className="mb-6 text-blue-600">{service.icon}</div>
                  <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <div className="flex items-center justify-between">
                  <Link to={service.link} className="text-lg font-semibold text-blue-600 hover:underline">
    {service.stat}
  </Link>
                  </div>
                </div>
              </motion.div>
              
            //   <motion.div
            //     key={index}
            //     initial={{ opacity: 0, y: 20 }}
            //     whileInView={{ opacity: 1, y: 0 }}
            //     viewport={{ once: true }}
            //     transition={{ duration: 0.6, delay: index * 0.2 }}
            //     className="relative group"
            //   >
            //     <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300">
            //       <div className={`absolute inset-x-0 h-2 top-0 rounded-t-2xl bg-gradient-to-r ${service.color}`} />
            //       <div className="mb-6 text-blue-600">{service.icon}</div>
            //       <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
            //       <p className="text-gray-600 mb-6">{service.description}</p>
            //       <div className="flex items-center justify-between">
            //         <span className="text-lg font-semibold">{service.stat}</span>
            //         <ArrowRight className="w-5 h-5 text-gray-400 group-hover:text-blue-600 transition-colors" />
            //       </div>
            //     </div>
            //   </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      {/* <motion.section 
        style={{ y: statsY, opacity: statsOpacity }}
        className="py-20 px-6 bg-gradient-to-r from-blue-600 to-purple-600"
      >
        <div className="max-w-7xl mx-auto">
          <div className="grid md:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4, delay: index * 0.1 }}
                className="text-center text-white"
              >
                <div className="text-5xl font-bold mb-2">{feature.value}</div>
                <div className="text-xl font-semibold mb-1">{feature.title}</div>
                <div className="text-blue-200">{feature.subtitle}</div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section> */}
    </div>
  );
};

export default ServiceRetargeting;