import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Plus, Minus, ChevronDown } from "lucide-react";
import ServicePremiumTake from "./ServicePremiumTake";
import { Helmet } from "react-helmet";
const PremiumTakeovers = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Handle screen resize for responsive behaviors
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const togglePanel = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const caseStudies = [
    {
      id: 1,
      image:
        "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
      category: "CASE STUDY",
      title: "Old Navy leans into decisioned upfronts with live sports",
      stat1: "1.2M",
      desc1:
        "incremental households reached with live sports by excluding users reached via programmatic guaranteed",
      stat2: "70%",
      desc2:
        "reduction in cost per unique household on live sports versus programmatic guaranteed",
      link: "#",
    },
    {
      id: 2,
      image:
        "http://media.istockphoto.com/id/1362512120/photo/businessman-hand-drawing-colorful-light-bulb-as-a-concept-of-new-idea-for-start-up-concept-of.jpg?s=612x612&w=0&k=20&c=cyEYhhBncOhLuWu0PRJAYkFMQZSrrGAhutS-8bRpx7s=", // Replace with actual image
      category: "CASE STUDY",
      title: "Brand X improves digital advertising efficiency",
      stat1: "850K",
      desc1:
        "users engaged through targeted ads leading to better conversion rates",
      stat2: "60%",
      desc2: "increase in ROI from programmatic advertising strategies",
      link: "#",
    },
  ];

  const content = [
    {
      title: "Full-Screen Takeovers",
      description:
        "Dominate the screen with interactive, visually striking ads for maximum impact.",
    },
    {
      title: "High-Impact Display & Rich Media",
      description:
        "Engage audiences with dynamic, immersive, and interactive ad formats.",
    },
    {
      title: "Homepage & App Takeovers",
      description: "Secure exclusive real estate on premium websites and apps.",
    },
    {
      title: "CTV & OTT Sponsorships",
      description: "Own premium ad slots on the biggest connected TV networks.",
    },
    {
      title: "AI-Driven Personalization",
      description:
        "Tailor high-impact creatives dynamically for maximum engagement.",
    },
    {
      title: "Brand Lift & Engagement Metrics",
      description:
        "Track awareness and engagement to measure campaign success.",
    },
  ];
  // Animation variants
  const fadeInVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const staggerItem = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const scaleUp = {
    initial: { scale: 1 },
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };
  return (
    <>
         <Helmet>
        <title>Dominate Digital Spaces with Premium Takeover Ads – BidBox AI</title>
        <meta name='description' content='Make a lasting impression with premium ad placements, homepage takeovers, and high-visibility ads across top-tier publishers with BidBox AI.' />
        <meta name='keywords' content='Premium takeover ads, homepage takeover, high-impact advertising, roadblock ads, 100% share of voice, brand exclusivity, large-format ads, immersive advertising, full-screen ad placements, digital domination, prime ad placements, masthead ads, video homepage takeover.' />               
    </Helmet>
      {/* <div
      className="relative flex items-center justify-center h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('https://t4.ftcdn.net/jpg/09/49/36/63/240_F_949366383_tyZF61HUtUectJuzOQGxzUW8vjWXVO1b.jpg')" }}
    >
      <div className="bg-white/20 backdrop-blur-md p-8 rounded-lg shadow-lg text-center max-w-lg">
        <h1 className="text-4xl font-bold text-white mb-4">Welcome to Our Platform</h1>
        <p className="text-white text-lg mb-6">
          Explore amazing features and elevate your experience with our services.
        </p>
        <button className="px-6 py-3 font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg shadow-lg hover:scale-105 transition transform duration-300">
          Get Started
        </button>
      </div>
    </div> */}
     {/* <div className="flex min-h-[100dvh] justify-center items-center dark:bg-slate-800 overflow-x-hidden">
       <motion.div
         initial={{ opacity: 0, scale: 0.8 }}
         animate={{ opacity: 1, scale: 1 }}
         transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
         className="mx-auto flex h-full min-h-[100dvh] w-full items-center justify-center px-4 sm:px-6 lg:px-8"
       >
         <div className="text-center max-w-4xl">
           <motion.h1
             initial={{ opacity: 0, y: -20 }}
             animate={{ opacity: 1, y: 0 }}
             transition={{ delay: 0.3, duration: 0.5 }}
             className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
             style={{ WebkitTransform: "translateY(0px)", transform: "translateY(0px)" }}
           >
             <span className="block xl:inline">
               <span className="mb-1 block">Make Your Brand </span>
               <motion.span 
                 initial={{ backgroundPosition: "0%" }}
                 animate={{ backgroundPosition: "100%" }}
                 transition={{ repeat: Infinity, duration: 3, ease: "linear", repeatType: "reverse" }}
                 className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
               >
                 Unforgettable.
               </motion.span>
               <p className="mb-1 block">10X Efficiency<span className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">with Bidbox Ai</span> </p>
             </span>
           </motion.h1>
           
           <motion.p
             initial={{ opacity: 0 }}
             animate={{ opacity: 1 }}
             transition={{ delay: 0.6, duration: 0.5 }}
             className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
           >
             Build lasting connections with your audience through strategic, high-impact brand awareness campaigns. Stay top-of-mind and drive recognition across premium digital channels.
           </motion.p>
     
           <motion.div
             initial={{ opacity: 0, y: 20 }}
             animate={{ opacity: 1, y: 0 }}
             transition={{ delay: 0.8, duration: 0.5 }}
             className="mt-5 sm:mt-8 sm:flex sm:justify-center"
           >
             <motion.div 
               variants={scaleUp}
               initial="initial"
               whileHover="hover" 
               className="rounded-md shadow"
             >
               <a
                 className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                 href="/contact"
               >
                 Get started 
               </a>
             </motion.div>
           </motion.div>
         </div>
       </motion.div>
     </div> */}
     <div className="flex min-h-[100dvh] justify-center items-center dark:bg-slate-800 overflow-x-hidden">
       <motion.div
         initial={{ opacity: 0, scale: 0.8 }}
         animate={{ opacity: 1, scale: 1 }}
         transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
         className="mx-auto flex h-full min-h-[100dvh] w-full items-center justify-center px-4 sm:px-6 lg:px-8"
       >
         <div className="text-center max-w-4xl">
           <motion.h1
             initial={{ opacity: 0, y: -20 }}
             animate={{ opacity: 1, y: 0 }}
             transition={{ delay: 0.3, duration: 0.5 }}
             className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl"
             style={{ WebkitTransform: "translateY(0px)", transform: "translateY(0px)" }}
           >
             <span className="block xl:inline">
               <span className="mb-1 block">Command Attention with Premium</span>
               <motion.span 
                 initial={{ backgroundPosition: "0%" }}
                 animate={{ backgroundPosition: "100%" }}
                 transition={{ repeat: Infinity, duration: 3, ease: "linear", repeatType: "reverse" }}
                 className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
               >
                Takeovers & High-Impact Ads
               </motion.span>
               <p className="mb-1 block">10X Efficiency<span className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">with Bidbox.Ai</span> </p>
             </span>
           </motion.h1>
           
           <motion.p
             initial={{ opacity: 0 }}
             animate={{ opacity: 1 }}
             transition={{ delay: 0.6, duration: 0.5 }}
             className="mx-auto mt-3 max-w-xl text-lg text-gray-500 dark:text-slate-400 sm:mt-5 md:mt-5"
           >
     Maximize brand visibility with exclusive placements and immersive ad experiences. Dominate top-tier digital real estate, captivate audiences with stunning, high-impact formats, and drive unparalleled engagement for your brand.
      </motion.p>
     
           <motion.div
             initial={{ opacity: 0, y: 20 }}
             animate={{ opacity: 1, y: 0 }}
             transition={{ delay: 0.8, duration: 0.5 }}
             className="mt-5 sm:mt-8 sm:flex sm:justify-center"
           >
             <motion.div 
               variants={scaleUp}
               initial="initial"
               whileHover="hover" 
               className="rounded-md shadow"
             >
               <a
                 className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                 href="/contact"
               >
                 Get started 
               </a>
             </motion.div>
           </motion.div>
         </div>
       </motion.div>
     </div>
      <div className="bg-white py-12 px-4 md:px-6">
        <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
          {/* Image on Left */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0  opacity-20 rounded-lg"></div>
            <motion.img
              variants={{
                initial: { scale: 1, rotateY: 0 },
                hover: { scale: 1.05, rotateY: 10 },
                float: {
                  y: [0, -10, 0], // Floating effect
                  transition: {
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut"
                  }
                }
              }}
              initial="initial"
              whileHover="hover"
              animate="float"
              src="/assets/premium.png"
              alt="Connected TV"
              className="w-full h-auto md:h-96 object-cover "
            />
          </motion.div>

          {/* Text on Right */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
              Make an Instant & Lasting Impression
            </h2>
            <p className="text-gray-700 text-lg md:text-lg leading-relaxed mb-6">
              In the fast-paced digital world, first impressions are everything.
              Premium takeovers and high-impact ad formats place your brand
              front and center, ensuring it grabs attention instantly. With bold
              visuals, dynamic creatives, and full-screen experiences, these
              formats disrupt scrolling habits, making your brand impossible to
              ignore. Whether launching a new product or amplifying brand
              awareness, high-impact placements create a memorable and lasting
              impact that turns passive viewers into engaged customers.
            </p>
          </motion.div>
        </div>

        {/* SECOND SECTION - Alternating Layout */}
        <div className="container mx-auto flex flex-col md:flex-row-reverse items-center gap-12 mt-16">
          {/* Image on Right */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true }}
            className="relative w-full md:w-5/12"
          >
            <div className="absolute inset-0  opacity-20 rounded-lg"></div>
            <motion.img
              variants={{
                initial: { scale: 1, rotateY: 0 },
                hover: { scale: 1.05, rotateY: 10 },
                float: {
                  y: [0, -10, 0], // Floating effect
                  transition: {
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut"
                  }
                }
              }}
              initial="initial"
              whileHover="hover"
              animate="float"
              src="/assets/premium1.png"
              alt="CTV Advertising"
              className="w-full h-auto md:h-96 object-cover  "
            />
          </motion.div>

          {/* Text on Left */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={fadeInVariant}
            viewport={{ once: true, amount: 0.4 }}
            className="w-full md:w-7/12 text-center md:text-left"
          >
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4">
              Why Premium Takeovers? Maximize Visibility, Drive Engagement
            </h2>
            <p className="text-gray-700 text-lg md:text-lg leading-relaxed mb-6">
              High-impact advertising is about more than just visibility—it’s
              about ownership. Premium takeovers grant your brand an exclusive
              share of voice, cutting through the clutter of standard digital
              ads. These large-format, immersive experiences create deeper
              connections with audiences, leading to significantly higher recall
              rates and stronger brand affinity. Whether through homepage
              takeovers, roadblocks, or dynamic full-screen experiences, premium
              formats drive better engagement, increased interaction, and
              measurable results for your campaigns.{" "}
            </p>
          </motion.div>
        </div>
      </div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="max-w-4xl mx-auto text-center mt-20"
      >
        <h2 className="text-4xl md:text-5xl font-bold mb-6">
          Unmatched Impact with Bidbox.Ai
        </h2>
        <p className="text-lg text-gray-600 mb-12">
          Bidbox.Ai delivers premium ad experiences designed to maximize
          engagement and brand recall. With AI-powered ad placements, strategic
          optimization, and access to the most impactful inventory, your brand
          gains a competitive edge in digital advertising.
        </p>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="px-4 md:px-10"
      >
        <div className="mx-auto bg-gradient-to-r from-blue-600 to-purple-600 py-12 px-6 md:px-8 rounded-lg shadow-lg mt-6 mb-6">
          <div className="container mx-auto flex flex-col md:flex-row items-center text-white text-center md:text-left space-y-8 md:space-y-0 md:space-x-8">
            {/* Column 1 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">
                98-100% Ad Viewability for Maximum Exposure
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
                Roadblock ads ensure uninterrupted visibility, guaranteeing your
                brand message is seen with absolute clarity for maximum impact.
              </p>
            </motion.div>
            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>{" "}
            {/* Mobile Divider */}
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>{" "}
            {/* Desktop Divider */}
            {/* Column 2 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">
                Up to 3X Greater Impact on Brand Awareness
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
                Achieve unparalleled brand recall, as roadblock ads drive up to
                three times higher awareness than conventional ad formats.
              </p>
            </motion.div>
            {/* Divider */}
            <div className="w-full h-px bg-white opacity-50 md:hidden"></div>{" "}
            {/* Mobile Divider */}
            <div className="hidden md:block w-px h-16 bg-white opacity-50"></div>{" "}
            {/* Desktop Divider */}
            {/* Column 3 */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-2xl md:text-2xl font-bold">
                2-5% Higher (CTR) for Exclusive Engagement
              </h2>
              <p className="text-base md:text-sm opacity-90 mt-2">
                {" "}
                Capture premium audience attention with roadblock ads,
                delivering significantly higher click-through rates and deeper
                user interaction.
              </p>
            </motion.div>
          </div>
        </div>
      </motion.div>

      <ServicePremiumTake />
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.5 }}
        className="w-full flex justify-center mt-10"
      >
        <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
          className="w-[95%] mb-20 h-[320px] md:h-[250px] bg-gradient-to-r from-purple-600 to-blue-600 border flex justify-between rounded-xl overflow-hidden"
        >
          <div className="p-5 md:p-10 z-10 flex justify-between">
            <div className="flex flex-col justify-between">
              <div>
                <motion.h1
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  viewport={{ once: true }}
                  className="text-white font-bold text-xl md:text-5xl"
                >
                  Ready to Own the Digital Spotlight?
                </motion.h1>
                <motion.h2
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  viewport={{ once: true }}
                  className="text-gray-300  text-xl md:text-xl py-2"
                >
                  Launch a high-impact takeover campaign today and ensure your
                  brand is seen, remembered, and engaged with like never before.
                </motion.h2>
              </div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                viewport={{ once: true }}
              >
                <a href="/contact">
                  <button className="bg-white hover:opacity-90 rounded shadow-md px-4 py-2 text-sm md:text-lg font-semibold">
                    Get Started Now
                  </button>
                </a>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </motion.div>

      {/* Case Studies */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInVariant}
        viewport={{ once: true, amount: 0.4 }}
        className="max-w-7xl mx-auto py-12 px-4"
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-3xl md:text-4xl font-bold text-center mb-10"
        >
          Success Stories
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {caseStudies.map((study, index) => (
            <motion.div
              key={study.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
              whileHover={{
                scale: 1.03,
                boxShadow:
                  "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
              }}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300"
            >
              {/* Top: Image with overlay effect */}
              <div className="w-full relative overflow-hidden">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                  src={study.image}
                  alt="Case Study"
                  className="w-full h-48 md:h-64 object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
                <div className="absolute bottom-0 left-0 p-4">
                  <span className="text-xs font-bold text-white bg-blue-600 px-2 py-1 rounded">
                    {study.category}
                  </span>
                </div>
              </div>

              {/* Bottom: Text Content */}
              <div className="p-6 text-center">
                <h3 className="text-xl font-bold mt-2">{study.title}</h3>
                <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-8 mt-4">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">
                      {study.stat1}
                    </p>
                    <p className="text-gray-600 text-sm">{study.desc1}</p>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gray-50 p-3 rounded-lg"
                  >
                    <p className="text-2xl font-bold text-blue-600">
                      {study.stat2}
                    </p>
                    <p className="text-gray-600 text-sm">{study.desc2}</p>
                  </motion.div>
                </div>
                <motion.a
                  whileHover={{ scale: 1.1 }}
                  href={study.link}
                  className="text-blue-600 font-semibold mt-6 inline-block border-b-2 border-transparent hover:border-blue-600"
                >
                  READ CASE STUDY
                </motion.a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export default PremiumTakeovers;
